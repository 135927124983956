import React from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Import useTheme hook
import { navigate } from 'gatsby';

const GetStarted = () => {
  const theme = useTheme(); // Use the useTheme hook to access the theme object

  const handleGetStarted = () => {
    navigate('/assessment');
  };

  return (
    <>
      <Box
        p={'1.47rem'}
        mb="1.47rem"
        borderRadius={2}
        sx={{
          border: `1px solid ${theme.palette.primary.main}`,
        }}
      >
        <Stack spacing={2} textAlign={'center'} alignItems={'center'}>
          <Typography variant="h5" gutterBottom>
            You don't have any active subscriptions.
          </Typography>
          <Button
            onClick={handleGetStarted}
            variant="contained"
            color="primary"
            sx={{
              maxWidth: '420px',
              width: '100%',
            }}
          >
            Get Started
          </Button>
        </Stack>
      </Box>
      <Divider />
    </>
  );
};

export default GetStarted;
