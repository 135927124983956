import React from 'react';
import styled from 'styled-components';

const Drawer = ({ onClose, visible, id, className, children }: any): JSX.Element => {
  const [open, setOpen] = React.useState(visible);
  React.useEffect(() => {
    document.body.style = visible ? 'overflow: hidden; max-height: 100vh;' : '';
    setOpen(visible);
  }, [visible]);
  const handleClose = () => {
    onClose();
  };
  return (
    <DrawerContainer id={id} className={`${open ? 'open' : ''}`}>
      <div className="backdrop" onClick={() => handleClose()} />
      <div className={'content ' + className}>
        <div>{children}</div>
      </div>
    </DrawerContainer>
  );
};
export default Drawer;
const DrawerContainer = styled.div`
  position: relative;
  &.open {
    .backdrop,
    .content {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .backdrop {
    z-index: 9999;
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -100px;
    left: 0;
    height: calc(100vh + 100px);
    width: 100%;
    opacity: 0;
    transform: translateX(100%);
    transition: all 0.25s ease-in-out;
  }
  .content {
    z-index: 99999;
    position: absolute;
    top: -100px;
    left: 0;
    height: calc(100vh + 100px);
    width: 80%;
    background: white;
    transform: translateX(-120%);
    transition: all 0.25s ease-in-out;
    padding-bottom: 100px;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.15);
    > div {
      padding: 20px 20px 100px;
      position: relative;
      height: 100%;
    }
  }
`;
