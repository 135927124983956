import * as React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { SectionProps } from 'types';
import { Image } from 'ui';

export default function Video({ video, mobileVideo, products }: SectionProps): JSX.Element {
  const bg = isMobile ? mobileVideo ?? video : video;

  return (
    <Container>
      <Image {...bg} />
    </Container>
  );
}
const Container = styled.div``;
