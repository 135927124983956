import React from 'react';
import styled from 'styled-components';
import { SectionContainer2, Container, Box, Image } from 'src/features/ui';
import { Button } from '@mui/material';
import AppStore from 'src/images/applestore.svg';

import head from 'lodash/head';
import { Link } from 'gatsby';
import { responsive, responsiveV2 } from 'src/utils';
import { Typography } from 'src/features/ui';

import { CheckoutData } from '../util';
import { getProductWithSubscription } from '../../../../product/utils';
import { useEloStore } from '../../../../store/EloStore.hooks';
import { navigate } from 'gatsby';

import posthog from 'posthog-js';

type Props = {
  data?: CheckoutData;
};

const ReceiptNoApp = ({ data }: Props): JSX.Element => {
  // Data is the response from the stripe checkout api

  /**************************************************************
   * GET ALL AVAILABLE SUBSCRIPTOIN MODELS FROM THE STORE
   * ------------------------------------------------------------
   * The ELO Store in the app contenxt contains all of the products.
   * Each product has an array of subscription models.
   * First create an array of subscription models from the store.
   * - Map all products in the store
   * - Map all subscription models in each product
   **************************************************************/

  const {
    store: { products },
  } = useEloStore();

  /**************************************************************
   * GET THE LINE ITEM and PRICE ID FROM THE CHECKOUT DATA
   * ------------------------------------------------------------
   * the data prop is passed into this component
   * data comes from the stripe checkout api data response.
   * The response is an array with a single item. Get the first
   * item in the array and set it to the lineItem variable.
   **************************************************************/
  const lineItem = head(data?.lineItems);
  const priceId = lineItem?.priceId;

  /**************************************************************
   * CREATE NEW PRODUCT OBJECT
   * ------------------------------------------------------------
   * Use the important getProductWithSubscription function from
   * utils to return the target product and subscription model.
   **************************************************************/
  //@ts-ignore
  const product = getProductWithSubscription(products, priceId);
  // ISOLATE THE SUBSCRIPTION MODEL
  const subscriptionModel = product?.subscriptionModel;
  // GET PRICING INFO FROM SUBSCRIPTION MODEL
  const pricing = head(subscriptionModel?.pricing);

  const formatPrice = (price: number | undefined, currency: string = 'USD') => {
    if (price === 0) {
      return 'FREE';
    }

    if (!price) return '';

    const dollars = price;
    const formattedPrice = dollars.toLocaleString('en-US', { style: 'currency', currency });

    return formattedPrice;
  };

  const formatTaxPrice = (price: number | undefined, currency: string = 'USD') => {
    if (!price) {
      return 0;
    }

    const dollars = price;
    const formattedPrice = dollars.toLocaleString('en-US', { style: 'currency', currency });

    return formattedPrice;
  };
  // POSTHOG EVENT: Checkout receipt viewed
  posthog.capture('checkout receipt viewed', {});

  const openInNewTab = (url: string) => {
    if (typeof window !== 'undefined') {
      window.open(url, '_blank');
    }
  };

  console.log('data', data);

  return (
    <SectionContainer2>
      <Container>
        <Grid>
          <ColumnLeft>
            {/* {subscriptionModel?.checkoutSuccessImage?.gatsbyImageData && (
              <ProductImageWrapper>
                <Image
                  gatsbyImageData={subscriptionModel?.checkoutSuccessImage?.gatsbyImageData}
                  loading="eager"
                />
                
              </ProductImageWrapper>
            )} */}
            <img src='https://cdn.sanity.io/images/a3su0y11/production/e32afa65abad4c4d8f76ea0cb8c5a3905798dcda-640x597.webp' />
          </ColumnLeft>

          <ColumnRight>
            <Typography variant={'h1'} component="div">
              {data?.name ? (
                <>
                  <Typography variant={'h1'} fontColor={['#F69B32']} component="span">
                    {data?.name},{' '}
                  </Typography>
                  thanks for your order.
                </>
              ) : (
                <>Thanks for your order.</>
              )}
              {/* <GradientText gradient="to right, #9BC101, #9BC101">better health</GradientText>{' '} */}
            </Typography>

            <Box mt="2rem">
              <Typography variant={'body1'}>
                We’ll let you know as soon as your order ships
              </Typography>
            </Box>
            <Box pt={'1rem'}>
              <Typography variant={'body2'}>
                Orders are made fresh. Please allow up to 2 weeks for your order to ship.
              </Typography>
            </Box>

            <Box pt={'1rem'}>
              <Typography variant={'body2'}>
                This order might be eligible for HSA/FSA reimbursement.{' '}
                <Typography variant={'body2'} component="span" sx={{ color: 'orange' }}>
                  <a
                    href="https://app.truemed.com/qualify/tm_qual_hwrci11rkr?source=post_purchase"
                    style={{ color: '#f69b32' }}
                    target="_blank"
                  >
                    Get reimbursed today
                  </a>
                </Typography>
                .
              </Typography>
            </Box>

            <Box my="2rem" mt="4rem">
              <PaymentSummaryWrapper>
                <Box pb={['8px']}>
                  <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }} gutterBottom>
                    Luminary Vitamins Subscription
                  </Typography>
                </Box>
              </PaymentSummaryWrapper>

              <PaymentSummaryContainer>
                <PaymentSummaryWrapper>
                  <Box display={'flex'} justifyContent={'flex-start'}>
                    <Typography variant="body3">First Month Payment</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Typography variant="body3">
                      {formatPrice(data?.totalPrice?.total, data?.totalPrice?.currency)}
                    </Typography>
                  </Box>
                </PaymentSummaryWrapper>

                {
                  /***********************************************************
                   * DISPLAY DISCOUNT IF THERE IS ONE
                   * ---------------------------------------------------------
                   * This applies to protein and gummies
                   ***********************************************************/

                  data?.totalPrice?.discount && data.totalPrice.discount > 0 ? (
                    <PaymentSummaryWrapper>
                      <Box display={'flex'} justifyContent={'flex-start'}>
                        <Typography variant="body3">Discount</Typography>
                      </Box>
                      <Box display={'flex'} justifyContent={'flex-end'}>
                        <Typography variant="body3">
                          {formatPrice(
                            (data?.totalPrice?.discount ?? 0) * -1,
                            data?.totalPrice?.currency
                          )}
                        </Typography>
                      </Box>
                    </PaymentSummaryWrapper>
                  ) : null
                }

                {/* // * TAX */}
                {data?.totalPrice && data?.totalPrice?.tax > 0 && (
                  <PaymentSummaryWrapper>
                    <Box display={'flex'} justifyContent={'flex-start'}>
                      <Typography variant="body3">Tax</Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      <Typography variant="body3">
                        {formatTaxPrice(data?.totalPrice?.tax, data?.totalPrice?.currency)}
                      </Typography>
                    </Box>
                  </PaymentSummaryWrapper>
                )}

                {/* // *Shipping */}
                <PaymentSummaryWrapper>
                  <Box display={'flex'} justifyContent={'flex-start'}>
                    <Typography variant="body3">Shipping</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Typography variant="body3">
                      {formatPrice(data?.totalPrice?.shipping, data?.totalPrice?.currency)}
                    </Typography>
                  </Box>
                </PaymentSummaryWrapper>

                <PaymentSummaryWrapper>
                  <Box display={'flex'} justifyContent={'flex-start'}>
                    <Typography variant="body2">Total</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    <Typography variant="body1">
                      {formatPrice(data?.totalPrice?.total, data?.totalPrice?.currency)}
                    </Typography>
                  </Box>
                </PaymentSummaryWrapper>
              </PaymentSummaryContainer>
            </Box>

            {/* <MemberContainer> */}
            <MemberGrid>
              {data?.shippingAddress && (
                <Box>
                  <Typography variant="body1" gutterBottom>
                    Shipping
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {data?.shippingAddress?.name}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {data?.shippingAddress?.street}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {data?.shippingAddress?.street2}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {data?.shippingAddress?.city} {data?.shippingAddress?.state}
                    {(data?.shippingAddress?.city || data?.shippingAddress?.state) &&
                    data?.shippingAddress?.zip
                      ? ', '
                      : ''}
                    {data?.shippingAddress?.zip}
                  </Typography>
                </Box>
              )}

              {data?.paymentMethodType && (
                <Box>
                  {/* <PaymentMethodContainer> */}
                  <Typography variant="body1" gutterBottom>
                    Payment
                  </Typography>
                  <Box>
                    <Typography variant="body2" gutterBottom>
                      {data?.paymentMethodType == 'creditCard' ? 'Card' : ''} ending in{' '}
                      {data?.paymentMethodDescription}
                    </Typography>
                  </Box>

                  {/* </PaymentMethodContainer> */}
                </Box>
              )}
              <Box>
                <Typography variant="body1" gutterBottom>
                  Support
                </Typography>
                <Link to="mailto:help@luminaryvitamins.com">
                  <Typography variant={'body2'} gutterBottom>
                    help@luminaryvitamins.com
                  </Typography>
                </Link>
              </Box>

              <Box>
                <a href="https://apps.apple.com/us/app/luminary-vitamins/id6483369133">
                  <AppStore />
                </a>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() =>
                    openInNewTab('https://apps.apple.com/us/app/luminary-vitamins/id6483369133')
                  }
                  sx={{
                    color: 'black',
                    py: '1rem',
                    boxShadow: 'none', // Remove the drop shadow
                    ':hover': {
                      boxShadow: 'none', // Remove the drop shadow on hover as well
                    },
                  }}
                >
                  <Typography variant="body2">Download Luminary Vitamins iOS app</Typography>
                </Button>
              </Box>
            </MemberGrid>

            {/* </MemberContainer> */}
          </ColumnRight>
        </Grid>
      </Container>
    </SectionContainer2>
  );
};

const Grid = styled.div`
  display: grid;
  gap: 55px;

  ${responsive('grid-template-columns', ['1fr', '1fr', '1fr 1fr'])}
  ${responsive('margin-top', ['40px'])}
`;

const MemberGrid = styled.div`
  display: grid;
  gap: 40px;
  margin-top: 40px;
  ${responsiveV2({
    cssPropsVals: {
      'grid-template-columns': ['1fr'],
    },
  })}
`;

const ColumnLeft = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;
const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
`;

const ProductImageWrapper = styled.div`
  background: ${props => props.theme.colors.eloGreyge};
  margin-bottom: -30px;
`;

const PaymentSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentSummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: 1rem 0;
  border-bottom: 1px solid ${props => props.theme.colors.eloGreyge};
`;

export default ReceiptNoApp;
