// AMPLIFY V5 to V6 UPGRADE
import * as React from 'react';

// import { API } from 'aws-amplify';
// import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

import { Form, Input, Typography } from 'antd';
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';
import * as mutations from '../../../../graphql/mutations';

import {
  SubmitContactFormMutation,
  SubmitContactFormMutationVariables,
} from '../../../../graphql/elomapi';
import { SectionProps } from 'types';
import { Icon, Button } from 'ui';

/********************************************************************************
 * IS AUTHENTICATED
 * ------------------------------------------------------------------------------
 * Import the isAuthenticated function from the ELO package to determine the
 * authentication mode that is necessary to use. The isAuthenticated function
 * simplifies the process of determining the authentication mode by checking the
 * state of the user.
 * *****************************************************************************/
import { isAuthenticated } from 'src/elo';
// Set the auth mode

const { Title } = Typography;
const { TextArea } = Input;

const PageSectionContactForm = ({ slug, headline, subHeading, ctaButton }: SectionProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [sent, setSent] = React.useState(false);
  const [form] = Form.useForm();
  
  const authMode = isAuthenticated() ? "userPool" : "iam" as 'userPool' | 'iam';

  const sentMsg = 'Thank you. Your message has been received.';
  const errorMsg =
    'Something went wrong when submitting the form. Please try again or email elo@elo.health.';

  async function onSubmit(data: SubmitContactFormMutationVariables) {
    try {
      setError(false);
      setSent(false);
      setIsLoading(true);
      const options: GraphQLOptions = {
        query: mutations.submitContactForm,
        variables: data,
        authMode: authMode,
      };
      const result = (await client.graphql(options)) as GraphQLResult<SubmitContactFormMutation>;
      
      if (result?.data?.submitContactForm) {
        setSent(true);
        form.resetFields();
        setIsLoading(false);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error('failed to submit', error);
      setError(true);
    }
  }

  return (
    <div className={'page-section-form ' + slug}>
      <Title className="headline" level={1}>
        {headline}
      </Title>
      <div className="body">
        <p>{subHeading}</p>
        <Form form={form} onFinish={onSubmit}>
          <Form.Item name="name" rules={[{ required: true, message: 'Please enter your name' }]}>
            <Input type="text" placeholder="Full name" />
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
            <Input type="email" placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="message"
            rules={[{ required: true, message: 'Please enter your message' }]}
          >
            <TextArea rows={5} placeholder="Message" />
          </Form.Item>
          <Form.Item className={slug + '-button'}>
            <Button
              type="submit"
              key={slug}
              onClick={() => {}}
              showLoader={isLoading}
            >
              {ctaButton.title} <Icon id="arrow-right-black" />
            </Button>
            {error && <p className="error">{errorMsg}</p>}
            {sent && <p className="sent">{sentMsg}</p>}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PageSectionContactForm;
