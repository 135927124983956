import {SiteMetadata} from 'types';
import {Buffer} from 'buffer';

const salt = 'EloHealth';

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events
export enum GA4Action {
    begin_checkout = 'begin_checkout',
    purchase = 'purchase',
    add_to_cart = 'add_to_cart',
    remove_from_cart = 'remove_from_cart',
    view_item = 'view_item',
    add_payment_info = 'add_payment_info',
    view_item_list = 'view_item_list',
}

export enum CustomAction {
    cta_button_click = 'cta_button_click',
}

export interface PlainEvent {
    event: string;
    user_id?: string;
}

export interface TrackingEvent extends PlainEvent {
    event: GA4Action | CustomAction;
    ecommerce: EcommerceEvent | null;
    custom: CTAEvent | null;
}

interface EcommerceEvent {
    items: EcommerceItem[];
}

interface WithValue {
    currency?: string;
    value: number;
}

export interface CTAEvent {
    target: string;
}

export interface ViewItemListEvent extends EcommerceEvent {
    item_list_id?: string;
    item_list_name?: string;
}

export interface ViewItemEvent extends EcommerceEvent, WithValue {
}

export interface AddPaymentInfoEvent extends EcommerceEvent, WithValue {
}

export interface CartActionEvent extends EcommerceEvent, WithValue {
}

export interface BeginCheckoutEvent extends EcommerceEvent, WithValue {
}

export interface PurchaseEvent extends EcommerceEvent, WithValue {
    transaction_id: string;
}

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase_item
export type EcommerceItem = {
    item_name: string;
    item_id: string;
    quantity?: number;
    item_variant?: string;
};

export function buildAnalyticsPageUrl(meta: SiteMetadata, page: any): string {
    if (meta && page.slug) {
        return `${meta.siteUrl}${page.path || '/'}${page.slug}`;
    }
    return `/${page.slug}`;
}

export const createUserId = async (email: string) => {
    let digest: string = '';
    if (crypto && crypto.subtle && encoder && email && email.length) {
        const hash = await crypto.subtle.digest('SHA-256', encoder.encode(email + salt));
        digest = Buffer.from(hash).toString('base64');
    }

    return digest;
};

const encoder = new TextEncoder();
const pushToDataLayer = async (
    eventData: TrackingEvent | PlainEvent,
    user_email?: string | null
) => {
    if("development" == process.env.NODE_ENV) {        
        return;
    }
    
    const digest = user_email?.toLowerCase() ? await createUserId(user_email?.toLowerCase()) : null;
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        digest && window.dataLayer.push({user_id: digest, crm_id: digest});
        window.dataLayer.push(eventData);
    }
};

export default {
    ctaClicked: (eventData: CTAEvent) =>
        pushToDataLayer({
            event: CustomAction.cta_button_click,
            ecommerce: null,
            custom: eventData,
        }),

    viewItem: (eventData: ViewItemEvent, customData: CTAEvent | null = null) =>
        pushToDataLayer({
            event: GA4Action.view_item,
            ecommerce: eventData,
            custom: customData,
        }),

    viewItemList: (eventData: ViewItemListEvent, customData: CTAEvent | null = null) =>
        pushToDataLayer({
            event: GA4Action.view_item_list,
            ecommerce: eventData,
            custom: customData,
        }),

    addToCart: (eventData: CartActionEvent, customData: CTAEvent | null = null) =>
        pushToDataLayer({
            event: GA4Action.add_to_cart,
            ecommerce: eventData,
            custom: customData,
        }),

    removeFromCart: (eventData: CartActionEvent, customData: CTAEvent | null = null) =>
        pushToDataLayer({
            event: GA4Action.remove_from_cart,
            ecommerce: eventData,
            custom: customData,
        }),

    beginCheckout: (eventData: BeginCheckoutEvent, customData: CTAEvent | null = null) =>
        pushToDataLayer({
            event: GA4Action.begin_checkout,
            ecommerce: eventData,
            custom: customData,
        }),

    addPaymentInfoEvent: (
        eventData: AddPaymentInfoEvent,
        customData: CTAEvent | null = null,
        user_email: string
    ) =>
        pushToDataLayer(
            {
                event: GA4Action.add_payment_info,
                ecommerce: eventData,
                custom: customData,
            },
            user_email
        ),

    purchase: (eventData: PurchaseEvent, user_email?: string | null) =>
        pushToDataLayer(
            {
                event: GA4Action.purchase,
                ecommerce: eventData,
                custom: null,
            },
            user_email
        ),

    activateGoogleOptimize: () =>
        pushToDataLayer({
            event: 'optimize.activate',
        }),
};
