// AMPLIFY V5 to V6 UPGRADE
import * as React from 'react';

// import { API } from 'aws-amplify';
// import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

import styled from 'styled-components';
import { Link } from 'gatsby';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';
import * as mutations from '../../../../graphql/mutations';
import { StartCheckoutMutation, StartCheckoutMutationVariables } from '../../../../graphql/elomapi';

import SectionContainer, { SectionPadding } from '../../SectionContainer';
import analytics, { EcommerceItem } from '../../../../utils/legacy_analytics';
import EloLogoLight from '../../../../images/EloLogoLight.svg';

/********************************************************************************
 * IS AUTHENTICATED
 * ------------------------------------------------------------------------------
 * Import the isAuthenticated function from the ELO package to determine the
 * authentication mode that is necessary to use. The isAuthenticated function
 * simplifies the process of determining the authentication mode by checking the
 * state of the user.
 * *****************************************************************************/
import { isAuthenticated } from 'src/elo';
// Set the auth mode

const errorMsg = (
  <>
    Something went wrong when submitting the form. Please try again or{' '}
    <Link to="/contact/">contact us</Link>.
  </>
);
const { Option } = Select;

const Logo = styled.div`
  margin-top: 20px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.mobile} {
    text-align: center;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    float: left;
  }
`;


export default function PageSectionQualifyingQuestions(): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false);
  const [completedFields, setCompletedFields] = React.useState(0);
  const [checkoutError, setCheckoutError] = React.useState(false);
  const [form] = Form.useForm();

  const authMode = isAuthenticated() ? "userPool" : "iam" as 'userPool' | 'iam';
  
  async function onSubmit(formData: any) {
    setCheckoutError(false);
    setIsLoading(true);

    const variables = {
      input: {
        lineItems: [
          {
            productId: 'elo-membership-sisu',
            quantity: 1,
          },
        ],
        customerEmail: formData.email,
        referenceId: undefined,
        metaData: JSON.stringify(formData),
      },
    } as StartCheckoutMutationVariables;

    const query: GraphQLOptions = {
      query: mutations.startCheckout,
      authMode: authMode,
      variables,
    };

    try {
      const result = (await client.graphql(query)) as GraphQLResult<StartCheckoutMutation>;

      if (result?.data?.startCheckout && result.data.startCheckout.checkoutUrl) {
        const items: EcommerceItem[] | undefined = result.data.startCheckout.lineItems?.flatMap(
          (li) =>
            !li
              ? []
              : {
                  item_name: li.title,
                  item_id: li.id,
                  quantity: li.quantity,
                }
        );

        analytics.beginCheckout(
          {
            items: items ?? ([] as EcommerceItem[]),
            value: result.data.startCheckout.totalPrice?.total ?? 0,
            currency: (result.data.startCheckout.totalPrice?.currency as string) ?? null,
          },
          formData,
          formData.email
        );

        window.location.href = result.data.startCheckout.checkoutUrl;
      } else {
        console.error('No checkoutUrl available', result.errors);
        setCheckoutError(true);
      }
    } catch (error) {
      console.error('API call failed', error);
      setCheckoutError(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <SectionContainer id="qualifyingQuestions" usePaddingNoMobile contentBackgroundColor={'white'}>
      <ProgressBar answered={completedFields}></ProgressBar>

      <Logo>
        <Link to="/">
          <EloLogoLight className="logo" />
        </Link>
      </Logo>
      <Content>
        <h1 className="topic">First, let&apos;s get to know you.</h1>
        <p className="instruction">Answer all items to proceed.</p>
        <Form
          form={form}
          onFinish={onSubmit}
          labelAlign="left"
          layout="vertical"
          requiredMark={false}
          onFieldsChange={(_, allFields) => {
            const completedFields = allFields.reduce(
              (completedFields: number, field) =>
                (completedFields += field.touched && field.errors?.length === 0 ? 1 : 0),
              0
            );
            setCompletedFields(completedFields);
          }}
        >
          <Form.Item
            label="Email"
            name="email"
            className="emailInput"
            validateTrigger="onBlur"
            rules={[{ required: true, type: 'email', message: 'Email address is invalid.' }]}
          >
            <Input type="email" placeholder="Your email address" />
          </Form.Item>
          <Form.Item
            label="&nbsp;"
            name="channel"
            rules={[{ required: true, message: 'Choose one' }]}
          >
            <Select placeholder="How did you hear about us?">
              <Option value="web_search">Web Search</Option>
              <Option value="instagram">Instagram</Option>
              <Option value="facebook">Facebook</Option>
              <Option value="twitter">Twitter</Option>
              <Option value="linkedin">LinkedIn</Option>
              <Option value="youtube">YouTube</Option>
              <Option value="email">Email</Option>
              <Option value="news_article">News Article</Option>
              <Option value="friend_or_family">Friend / Family</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="&nbsp;"
            name="primary_goal"
            rules={[{ required: true, message: 'Choose one' }]}
          >
            <Select placeholder="What is your primary goal?">
              <Option value="track_biomarkers">Track biomarkers</Option>
              <Option value="optimize_nutrition">Optimize nutrition</Option>
              <Option value="lose_weight">Lose weight</Option>
              <Option value="improve_performance">Improve performance</Option>
              <Option value="improve_sleep">Improve sleep</Option>
              <Option value="improve_focus">Improve focus</Option>
              <Option value="increase_longevity">Increase longevity</Option>
            </Select>
          </Form.Item>

          <RequiredCheckbox
            name="isOver18"
            label="I am over 18"
            requiredMessage="You must be at least 18 years old."
          />
          <RequiredCheckbox
            name="hasIphone"
            label="My primary device is an iPhone"
            requiredMessage="An iPhone is required to use the Elo app."
          />
          <RequiredCheckbox
            name="stateOk"
            label="I live outside New York"
            requiredMessage="Elo is not yet available in New York."
          />
          <Form.Item>
            <Button htmlType="submit" type={'primary'} className={'elobutton'} loading={isLoading}>
              Proceed to checkout
            </Button>
            {checkoutError && <p className="error">{errorMsg}</p>}
          </Form.Item>
        </Form>
      </Content>
    </SectionContainer>
  );
}

type RequiredCheckboxProps = {
  name: string;
  label: string;
  requiredMessage: string;
};
const RequiredCheckbox = ({ name, label, requiredMessage }: RequiredCheckboxProps): JSX.Element => {
  const validator = (_, value) =>
    value ? Promise.resolve() : Promise.reject(new Error(requiredMessage));
  return (
    <Form.Item
      name={name}
      label={requiredMessage}
      valuePropName="checked"
      className="requiredCheckboxes"
      rules={[{ required: true, message: requiredMessage, validator }]}
    >
      <Checkbox>{label}</Checkbox>
    </Form.Item>
  );
};

const ProgressBar = styled.div.attrs((props: { answered: number }) => props)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 10px;
  background-color: ${({ theme }) => theme.colors.primary}4D;

  :after {
    width: ${(props) => Math.floor((props.answered / 6) * 100)}%;
    content: '';
    display: block;
    background: orange;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const Content = styled.div`
    margin: auto;
    width: 100%;
    padding: ${SectionPadding.horizontal.mobile}px;
    h1, .instruction {
        text-align: center;
    }
    h1 {
        margin-top: 0em;
        margin-bottom: 0;

        ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 24px;
        }
        ${({ theme }) => theme.breakpoints.tablet} {
            font-size: 32px;
        }
        ${({ theme }) => theme.breakpoints.desktop} {
            font-size: 48px;
        }
    }
    .instruction {
        color: ${({ theme }) => theme.colors.eloMidGrey};
        margin-bottom: 3em;
    }

    .ant-form-item {
        font-family: 'Everett Regular';
        padding: 0;
        margin: 5px 0;
        border: none;
        font-weight: 200;
        font-size: 18px;
        line-height: 21px;
    }

    .emailInput {

        .ant-form-item-explain {
            margin-left: 0;
            font-size: 14px;
        }

        .ant-form-item-label > label {
            color: ${({ theme }) => theme.colors.eloMidGrey}00;
        }
        
        :focus-within {
            .ant-form-item-label > label {
                color: ${({ theme }) => theme.colors.eloMidGrey};
            }
        }
    }

    .ant-form-item-has-success {
        .ant-form-item-label > label {
            color: ${({ theme }) => theme.colors.eloMidGrey} !important;
        }
    }

    .ant-select, .ant-select-single, .ant-select-focused {
        font-size: 18px;
        line-height: 21px;
        border-color: ${({ theme }) => theme.colors.primary} !important;
        box-shadow: none !important;
        
        :hover, :focus, :active {
            border-color: ${({ theme }) => theme.colors.primary} !important;
            box-shadow: none !important;

            .ant-select-arrow {
                color: ${({ theme }) => theme.colors.primary} !important;
            }
        }

        .ant-select-selection-placeholder {
            color: #000;
            font-size: 18px;
            line-height: 21px;
        }

        .ant-select-arrow {
            color: #000;
        }

        .ant-select-selector {
            box-shadow: none !important;
            border-top: none !important;;
            border-left: none !important;;
            border-right: none !important;;
            border-bottom 1px solid ${({ theme }) => theme.colors.eloMidGrey} !important;
            border-radius: 0;
            
            margin: 0;
            padding: 0;

            :hover, :focus, :active {
                border-color: ${({ theme }) => theme.colors.primary} !important;
                box-shadow: none !important;
            }
        }
    }

    .ant-input {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom 1px solid ${({ theme }) => theme.colors.eloMidGrey};
        border-radius: 0;
        
        font-family: 'Everett Regular';
        font-size: 18px;
        line-height: 23px;
        opacity: 1;
        padding: 0;
        
        :hover, :focus, :active {
            border-color: ${({ theme }) => theme.colors.primary} !important;
            box-shadow: none !important;
        }

        :placeholder-shown: {
            color: #000;
            opacity: 1;
        }

        ::-moz-placeholder { /* Firefox 19+ */
            color: #000;
            opacity: 1;
        }
        ::placeholder: {
            color: #000;
            opacity: 1;
        }
    }

    .ant-form-item-label-left {
        padding: 0;
        margin: 0;
        color: ${({ theme }) => theme.colors.eloMidGrey};
    }

    .requiredCheckboxes {
        .ant-form-item-label {
            margin-left: 1.85em;
        }

        .ant-form-item-explain {
            margin-left: 2.2em;
            font-size: 14px;
        }
    }

    .ant-checkbox {
        margin-right: 0.5em;
        .ant-checkbox-inner {
            :hover {
                border-color: black;
            }
        }

        :after {
            border-color: black !important;
        }
    }


    .ant-checkbox-checked::after {
        border-color: black !important;
    }

    .ant-checkbox-checked {
        color: black;
        border-color: black;

        .ant-checkbox-inner {
            background-color: white;
            border-color: black;

            :after {
                border-width: 1px;
                border-color: black !important;
            }
        }
    }

    

    .ant-form-item-label {
        order: 2;
        padding: 0;
        margin: 0;

        label {
            padding: 2px 0;
            font-size: 14px;
            vertical-align: top;
            color: ${({ theme }) => theme.colors.eloMidGrey};
        }
    }

    .ant-form-item-has-error {
        .ant-form-item-label {
            display: none;
        }
    }

    .ant-form-item-control {
        order: 1;
        padding: 0;
        margin: 0;
    }

    .ant-form-item-explain {
        margin-left: 0px;
        ${({ theme }) => theme.breakpoints.tablet} {
            margin-left: 0px;
        }
        ${({ theme }) => theme.breakpoints.wide} {
            margin-left: 0px;
        }

        div[role=alert] {
            font-family: 'Everett Regular';
            color: ${({ theme }) => theme.colors.error};
        }
    }

    .ant-form-item:last-child {
        border-top: none;
        text-align: center;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus,
    .ant-btn-primary:active {
        color: ${({ theme }) => theme.colors.black};
        background-color: ${({ theme }) => theme.colors.primary};
    }

    // This is essentially copied from EloButton.
    // Should be replaced with the actual component once ant-form/ant-btn is removed
    .elobutton {
        border: none;
        border-radius: 48px;
        border-radius: 7px;
        position: sticky;
        margin: 0;
        color: ${({ theme }) => theme.colors.black};
        background-color: ${({ theme }) => theme.colors.primary};
        &:hover {
            color: ${({ theme }) => theme.colors.black};
            transition: background 1s;
            background-color: ${({ theme }) => theme.colors.primaryHighlight};
        }
        &.inverted {
            color: ${({ theme }) => theme.colors.white};
            background-color: ${({ theme }) => theme.colors.black};
            &:hover {
                transition: background 1s;
                background-color: ${({ theme }) => theme.colors.blackHighlight};
            }
        }

        font-size: 18px;
        line-height: 21px;
        min-width: 200px;
        min-height: 45px;
        ${({ theme }) => theme.breakpoints.mobile} {
            width: 100%;
        }
        ${({ theme }) => theme.breakpoints.tablet} {
            min-width: 300px;
        }
        ${({ theme }) => theme.breakpoints.wide} {
            font-size: 24px;
            line-height: 28px;
            min-width: 350px;
            min-height: 50px;
        }
    }
`;
