import React, { useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@mui/material';
import { cancelSubscription } from '../../../../actions';
import { UserSubscription } from '../../../../types/API';
import { refreshAccountStore } from '../../../../data';

interface CancelModalProps {
  cancel: (value: boolean) => void;
  subscriptionId: string;
  subscription: UserSubscription;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #AAA',
  boxShadow: 24,
  pt: 1,
  px: 2,
  pb: 2,
  borderRadius: 2,
};

const CancelReasons = [
  'Customer service',
  'Looking to reduce my monthly expenses',
  'Gummy taste',
  'Gummy size',
  'Too much sugar',
  'Sensitive to smell',
  'Upset stomach',
  'Not achieved anticipated results',
  'Other',
];

const CancelModal = ({ cancel, subscriptionId, subscription }: CancelModalProps) => {
  
  const [ cancelReason, setCancelReason ] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const handleCancelSubscription = async () => {
    setLoading(true);
    // * Cancel Subscription
    const res = await cancelSubscription({
      subscriptionId,
      reason: cancelReason,
    });

    // * Refresh Account Store
    await refreshAccountStore();

    setLoading(false);

    if (res?.errors) {
      setError(true);
    } else {
      setSuccess(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCancelReason(event.target.value);
  }

  return (
    <>
      <Box
        width={['94vw', '420px', '480px']}
        minHeight={['320px']}
        maxHeight={['94vh']}
        sx={{
          ...style,
          display: 'flex',
          flexDirection: 'column',
          // minHeight: '400px',
          overflow: 'auto', // This ensures that the content area can scroll if needed
        }}
      >
        <Stack spacing={1} py={['1rem']}>
          <Typography variant="h4" textAlign={'center'}>
            Cancel Subscription
          </Typography>
          <Divider />
          <Box
            // height={'120px'}
            display={'flex'}
            justifyContent={'center'}
            p={['.47rem']}
            flexDirection={'column'}
          >
            <Typography variant="body2">
              Help us understand the primary reason why you’d like to cancel so that we could serve
              you better in future.
            </Typography>

            <Box py={'1.47rem'}>
            <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={cancelReason}
            onChange={handleChange}
          >
              {CancelReasons.map((reason, index) => (
                <FormControlLabel
                  key={index}
                  value={reason}
                  control={<Radio />}
                  label={<Typography variant="body2">{reason}</Typography>}
                />
              ))}
            </RadioGroup>
            </Box>
          </Box>

          {loading && (
            <Box py={['1.47rem']} width={'100%'} display={'flex'} justifyContent={'center'}>
              {loading && loadingScreen()}
            </Box>
          )}

          {success && (
            <>
              <Typography variant="body1" color={'success'} textAlign={'center'}>
                Subscription has been canceled
              </Typography>
              <Button onClick={() => cancel(false)}>Ok</Button>
            </>
          )}

          {error && (
            <>
              <Typography variant="body2" color={'success'} textAlign={'center'}>
                There was a problem canceling your subscription
              </Typography>
              <Typography variant="body2" color={'success'} textAlign={'center'}>
                <a href="mailto:help@luminaryvitamins.com">Please contact support</a>
              </Typography>
            </>
          )}

          {!loading && !error && !success && (
            <Stack spacing={2}>
              <Button disabled={loading || !cancelReason} variant="contained" onClick={handleCancelSubscription}>
                Yes, cancel my subscription
              </Button>
              {!loading && (
                <Button variant="outlined" onClick={() => cancel(false)}>
                  No, keep my subscription
                </Button>
              )}
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
};

const loadingScreen = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'rgba(255, 255, 255, 1)',
      }}
    >
      <Stack spacing={2} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
        <Typography variant="h4" gutterBottom textAlign={'center'}>
          Please Wait...
        </Typography>
        <CircularProgress />
        <Typography variant="body1" textAlign={'center'}>
          We are processing your request
        </Typography>
      </Stack>
    </Box>
  );
};

export default CancelModal;
