import React from 'react';
import { Collapse } from 'ui';
import ReactMarkdown from 'react-markdown';
import { QuestionAndAnswer } from 'src/types/questionAndAnswer';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BasicBlockRichTextRenderOptions } from 'src/utils/render';

const Accordian = ({ data }: { data: QuestionAndAnswer }) => {
  const { question = null, icon, iconColor, defaultOpen = false, answer, richTextAnswer } = data;

  if (!question) return <></>;
  if (!answer && !richTextAnswer) return <></>;

  const clone = JSON.parse(JSON.stringify(richTextAnswer));

  return (
    <>
      <Collapse 
      title={question} 
      icon={icon} 
      iconColor={iconColor}       
      isOpen={defaultOpen}
      >
        {answer && <ReactMarkdown>{answer?.answer}</ReactMarkdown>}
        {richTextAnswer && renderRichText(clone, BasicBlockRichTextRenderOptions)}
      </Collapse>
    </>
  );
};

export default Accordian;
