import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Cart } from 'src/features/store/EloStore.types';
import { Product as ELOMAPIProduct } from 'src/types/product';
import CheckoutButton from './CheckoutButton';
import { Box, SectionContainer2, Container } from 'ui';
import { compact } from 'lodash';
// import { useAssessmentStore } from 'src/features/assessment-summary/store';

interface iProps {
  id?: string;
  cart: Cart;
  product: ELOMAPIProduct;
  member: any;
  responseId: string;
  setCheckoutButtonVisible: (visible: boolean) => void;
}

const SubscriptionOptions = (props: iProps) => {
  const { product, member, responseId, setCheckoutButtonVisible } = props;
  
  // const storeMember = useAssessmentStore(state => state.storeMember);

  // Make subOptions a state variable
  const [subOptions, setSubOptions] = useState<any>(null);
  // const subOptions = Object.values(subscriptionModels);

  useEffect(() => {
    setSubOptions(compact(Object.values(product.subscriptionModels)));
  }, [product]);

  // Set the state of the selected plan
  const [selected, setSelected] = useState<string | null>('luminary-gummy-create-monthly');

  /**************************************************************************
   * DEFAULT MONTHLY PRICE
   * ------------------------------------------------------------------------
   * TODO: Write a function to get the default monthly price
   * The default monthly price below is hard coded and needs to be updated
   * so that it is dynamic.
   * ************************************************************************/

  if (!subOptions) return null;

  return (
    <>
      {/* <SectionContainer2 backgroundColor={'#FFFFFF'}>
        <Container py={['1rem']}>
          <Box my={['2rem']}> */}
            <CheckoutButton
              id="checkout-button"
              personalizedPlanId={responseId}
              // member={member}
              // member={storeMember}
              setCheckoutButtonVisible={
                setCheckoutButtonVisible as React.Dispatch<React.SetStateAction<boolean>>
              }
            />
          {/* </Box>
        </Container>
      </SectionContainer2> */}
    </>
  );
};

export default SubscriptionOptions;

// LEAVE GRID EXAMPLE BELOW FOR NOW
// const Grid = styled.div`
//   display: grid;
//   gap: 40px;
//   grid-template-columns: 1fr 1fr;
//   grid-template-areas: 'image options';
//   overflow: visible;
//   width: 100%;

//   @media (max-width: 960px) {
//     grid-template-areas:
//       'image image'
//       'options options';
//   }
// `;
