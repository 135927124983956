import React, { ReactNode, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { List, Tag, Typography } from 'antd';
import { useInView } from 'react-intersection-observer';

import { SectionProps } from 'types';

import ListingItem, { ArticleListItem, ListItem } from 'src/features/ui/listingitem/ListingItem';

const { Title } = Typography;
const { CheckableTag } = Tag;

type PageSectionListingWithTagsProps = SectionProps & {
  data: any;
};

const PageSectionListingWithTags = ({
  slug,
  headline,
  listingObjectType,
  data,
}: PageSectionListingWithTagsProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [listing, setListing] = useState<ListItem[] | ArticleListItem[]>([]);

  const listingData = (data[listingObjectType] || []).map((item) => {
    if (listingObjectType === 'Article') {
      return {
        discriminator: 'ArticleListItem',
        id: item.id,
        title: item.title,
        description: item.description || item.blurb.blurb,
        url: `/${listingObjectType.toLowerCase()}s/${item.slug}`,
        thumbnail: item.thumbnail,
        timeToRead: item.timeToRead,
        author: item.author,
        tags: item.tags,
      } as ArticleListItem;
    } else {
      return {
        discriminator: 'ListItem',
        id: item.id,
        title: item.title,
        description: item.description || item.blurb.blurb,
        url: `/${listingObjectType.toLowerCase()}s/${item.slug}`,
        thumbnail: item.thumbnail,
      } as ListItem;
    }
  });

  const allTags = listingData
    .map((item) => {
      if (item?.tags) {
        return item.tags;
      }
      return null;
    })
    .filter((item) => item)
    .flat();
  const tags = Array.from(new Set(allTags)) as string[];

  const { ref, inView, entry } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    setListing(listingData);
  }, [data]);

  function renderListingItem(item: ListItem, index: number): ReactNode {
    return (
      <List.Item>
        <ListingItem item={item} index={index} />
      </List.Item>
    );
  }

  function handleChange(tag, checked: boolean) {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
    if (nextSelectedTags.length > 0) {
      setListing([
        ...listingData.filter((item) =>
          nextSelectedTags.some((nst) => (item.tags || []).includes(nst))
        ),
      ]);
    } else {
      setListing(listingData);
    }
  }

  function renderTags(tags: string[] = []) {
    return tags.map((tag) => {
      return (
        <CheckableTag
          key={tag}
          checked={selectedTags.indexOf(tag) > -1}
          onChange={(checked) => handleChange(tag, checked)}
        >
          {tag.toUpperCase()}
        </CheckableTag>
      );
    });
  }

  return (
    <div ref={ref} className={`page-section-listing ${slug}`}>
      {headline && (
        <Title className="headline" level={1}>
          {headline}
        </Title>
      )}
      {listingData && listingData.length > 0 && (
        <List
          header={<div className="tags">{renderTags(tags || [])}</div>}
          split={isMobile}
          bordered={false}
          pagination={{
            onChange: (page) => {},
            pageSize: 20,
            position: 'bottom',
          }}
          size="small"
          itemLayout="horizontal"
          dataSource={listing}
          renderItem={renderListingItem}
        />
      )}
    </div>
  );
};

export default PageSectionListingWithTags;
