export type Storage = {
  getItem: (key: string) => Promise<string | null>;
  setItem: (key: string, value: string) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
};

export const LocalStorage: Storage = {
  async getItem(key: string) {
    return window.localStorage.getItem(key);
  },
  async setItem(key: string, value: string) {
    return window.localStorage.setItem(key, value);
  },
  async removeItem(key: string) {
    return window.localStorage.removeItem(key);
  },
};

export const SessionStorage: Storage = {
  async getItem(key: string) {
    return window.sessionStorage.getItem(key);
  },
  async setItem(key: string, value: string) {
    return window.sessionStorage.setItem(key, value);
  },
  async removeItem(key: string) {
    return window.sessionStorage.removeItem(key);
  }
};
