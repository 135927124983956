import * as React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import countBy from 'lodash/countBy';

import { QualificationForm } from '../store/EloStore.types';

import { useEloStore } from '../store/EloStore.hooks';

import Form from './form/form';
import { resolver } from './form/util';
import analytics, { BeginCheckoutEvent } from '../../utils/analytics';
import noop from 'lodash/noop';

export const qualificationQuestions: { [k in keyof QualificationForm]: string } = {
  name: 'What is your first name?',
  email: 'What is your email?',
  referrer: 'How did you hear about us?',
  primaryGoal: 'What is your primary goal?',
  phoneType: 'Confirm phone type',
  state: 'Confirm your state',
  over18: 'I am over 18.',
};

const QualificationFormComponent = ({
  product,
  productErrors,
  setError = noop,
  setAnswered = noop,
  setTotalCount = noop,
}: any) => {
  const {
    store,
    cart: { checkout },
  } = useEloStore();
  const { register, handleSubmit, getValues, formState, getFieldState, setValue } =
    useForm<QualificationForm>({
      mode: 'onChange',
      resolver,
    });
  const { errors } = formState;
  const [submitting, setSubmitting] = React.useState(false);

  const values = getValues();
  let totalCount = Object.keys(getValues()).length;

  React.useEffect(() => {
    // load previous values
    Object.keys(qualificationQuestions).map((value) => {
      const v = sessionStorage.getItem(value);
      setValue(value, v);
    });
  }, []);

  React.useEffect(() => {
    try {
      const eventData = Object.values(store.cart.items).reduce(
        (acc, cur) => {
          const product = store.products[cur.productSlug];
          const sub = product.subscriptionModels[cur.subscriptionSlug];
          const subscriptionPrice = sub.pricing.length > 0 ? sub.pricing[0] : null;

          return {
            items: [
              {
                item_name: product.title,
                item_id: product.stripeProductId ?? 'N/A',
                quantity: 1,
              },
            ],
            value: acc.value + (subscriptionPrice?.price ?? 0),
            currency: subscriptionPrice
              ? subscriptionPrice.currency === '$'
                ? 'USD'
                : subscriptionPrice.currency
              : 'USD',
          };
        },
        { items: [], value: 0 } as BeginCheckoutEvent
      );

      analytics.beginCheckout(eventData).finally(/* NOP */);
    } catch (err) {
      console.debug('analytics action failed');
    }
  }, []);

  React.useEffect(() => {
    // @ts-ignore
    const fieldStates = Object.keys(values).map((v) => {
      sessionStorage.setItem(v, values[v]);
      return getFieldState(v, formState);
    });
    const count = countBy(fieldStates, (v) => !v.error && v.isDirty && v.isTouched);

    setAnswered(count.true || 0);
    setTotalCount(totalCount);
  }, [values, formState]);

  const onSubmit = handleSubmit((data) => {
    setSubmitting(true);
    sessionStorage.setItem('elo-cart-flow', 'basic');

    if (data.phoneType) {
      if (data.phoneType !== 'iphone' && productErrors.includes('noiphone')) {
        setError('noiphone');
        window.scrollTo(0, 0);
        return;
      }
    }
    if (data.state && data.state === 'NY' && productErrors.includes('newyork')) {
      setError('newyork');
      window.scrollTo(0, 0);
      return;
    }

    checkout(data, qualificationQuestions, product.returnToCartPath)
      .then(() => setSubmitting(false))
      .catch(() => setSubmitting(false));
  });

  return (
    <Content>
      <Form
        questions={qualificationQuestions}
        errors={errors}
        onSubmit={(event: React.BaseSyntheticEvent) => {
          event.preventDefault();
          onSubmit(event);
        }}
        register={register}
        submitting={submitting}
      />
    </Content>
  );
};

const Content = styled.div(
  ({ theme }) => `
    width: 100%;
    font-family: 'Everett Light';

`
);

export default QualificationFormComponent;
