import * as React from 'react';
import { Typography } from 'antd';
import TitleWithAnchor from '../titlewithanchor/TitleWithAnchor';

import EloE from '../../../images/EloE.svg';

const { Text } = Typography;

type QATopicProps = {
  headline: string;
  questionsAnswers: [
    {
      initials: string;
      question: string;
      answer: {
        answer: string;
      };
    }
  ];
};

const QATopic = ({ headline, questionsAnswers }: QATopicProps): JSX.Element => {
  return (
    <div className="qa-topic">
      <TitleWithAnchor level={2}>{headline}</TitleWithAnchor>
      <ul>
        {questionsAnswers.map((qa) => {
          return (
            <>
              <li>
                <div className="initials logo">
                  <EloE />
                </div>
                <Text strong>{qa.question}</Text>
              </li>
              <li>
                <div className="initials text">{qa.initials}</div>
                <Text>{qa.answer?.answer}</Text>
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default QATopic;
