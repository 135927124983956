import { EloStoreState } from '../types';

export const initialState: EloStoreState = {
  test: 'test',

  authenticated: false,

  /********************************************************************************
   * LOADING DATA
   * -----------------------------------------------------------------------------
   * Initilize the loadingData value to false. This will be used to determine if
   * the data is currently being loaded from the cloud. This can be used to display
   * a loading indicator to the user in order to ensure a smooth user experience.
   * *****************************************************************************/
  loadingData: false,
  /********************************************************************************
   * SHOW LOGIN MODAL
   * -----------------------------------------------------------------------------
   * Initilize the showLoginModal value to false. This will be used to determine
   * if the login modal is shown.
   * *****************************************************************************/
  showLoginModal: false,
  /********************************************************************************
   * * USER ACCOUNT DATA
   * -----------------------------------------------------------------------------
   * The initial state of the user account data is set to null. This will be used
   * to store the user's account information once they have logged in. This will
   * be popluated and initilized with user data once the user logs in.
   * *****************************************************************************/
  userAccount: null,
  /********************************************************************************
   * * NEW USER DATA
   * -----------------------------------------------------------------------------
   * The newUserData is used to buffer or store data that is being changed or
   * updated as the user navigates through the account management tools and updates
   * their account information. This data is then used to update the user's account
   * information in the ELO cloud once the user has confirmed the changes.
   * 
   * This data is not allowed to be null and must be initilized with stating
   * values.
   * *****************************************************************************/
  newUserData: {
    firstName: null,
    lastName: null,
    preferences: {
      __typename: 'UserPreferences',
      gummyFlavor: null,
      pregnancyStage: null,
    },
    shippingAddress: null,
    productId: null,
    priceId: null,
  },

  /************************************************************************
   * * LINE ITEMS
   * ----------------------------------------------------------------------
   * This contains data concerning what the user is able to buy, what flavors are
   * available, etc. Not to be confused with the 'me' query which contains actual
   * user data.
   * **********************************************************************/
  lineItems: null,
  
  /************************************************************************
   * * USER CONFIGURATION
   * ----------------------------------------------------------------------
   * This contains data concerning what the user is able to buy, what flavors are
   * available, etc. Not to be confused with the 'me' query which contains actual
   * user data.
   * **********************************************************************/
  userConfiguration: null,

  /************************************************************************
   * * INVOICES
   * ----------------------------------------------------------------------
   * This contains the user's invoices, including past and future invoices.
   * **********************************************************************/
  invoices: null,

};

export default initialState;
