import { createGlobalStyle } from 'styled-components';

export const BaseStyles = createGlobalStyle(
  ({ theme }) => `
html {
    overflow: initial;
    scroll-behavior: smooth;
    height: auto !important;
    ${theme.breakpoints.mobile} {
        overflow-x: hidden;
    }
}
body {
    font-variant-numeric: normal !important;
    font-feature-settings: normal !important;
    overflow-x: hidden;
    max-width: 100%;
    height: auto !important;
    > div {
        overflow-x: hidden;
        max-width: 100%;
    }
    * {
        font-feature-settings: normal !important;
        font-variant: normal !important;
    }
    footer {
        transition: all 0.25s ease-in-out;
        opacity: 100;
    }
    &.hide-footer {
        footer {
            opacity: 0;
            display: none;
        }
    }
    &.smart-protein-pdp {
        ${theme.breakpoints.small} {
            .features-headline-container {
                .typography-p {
                    max-width: 480px !important;
                }
            }
            .reviews-headline-container {
                .typography-p {
                    max-width: 492px !important;
                }
            }
        }
    }
}
// This aligns highlight bar images on mobile, 
// since we move the hero cta under the elem so we need more space
.landingpage {
    margin-bottom: 20px;
    ${theme.breakpoints.small} {
        margin-bottom: 0;
    }
}

body.outline {
    *, :after, :before {
        outline: 1px solid;
    }
}
.elo-mobile-menu .ant-drawer-content-wrapper {
    margin: 10px;
    max-width: calc(100% - 20px);
    .ant-drawer-body {
        padding: 11px 25px 20px 20px;
        position: relative;
    }
}
.elo-cart .ant-drawer-content-wrapper {
    width: 100% !important;
    ${theme.breakpoints.tablet} {
        width: 650px !important;
        margin: 10px;
    }
    .ant-drawer-wrapper-body {
        height: auto !important;
    }
}
.ant-modal-content {
    border-radius: 3px !important;
}
.ant-btn {
    font-family: 'Everett Medium' !important;
    cursor: pointer !important;
    border: none !important;
    border-radius: 48px !important;
    padding: 0.5em 1.5em !important;
    margin: 0 !important;
    transition: background 1s !important;
    color: ${theme.colors.black} !important;
    background-color: ${theme.colors.primary} !important;
    &:hover {
        transition: background 1s !important;
        background-color: ${theme.colors.primaryHighlight} !important;
    }
}
button {
    cursor: pointer !important;
}
.category-articles-listing,.page-section-listing {
    padding-bottom: 5rem;
    .ant-list-item {
        align-items: flex-start !important;
        h3 {
            //min-height: 100px;
        }
        .author {
            //min-height: 44px !important;

        } 
    }
}
.article-layout {
    .body {
        div.ant-typography, .typography-p {
            line-height: 1.5rem;
        }
    }

    ul {
        margin-bottom: 2rem;
    }
    blockquote  {
        .ant-typography, .typography-p {
            line-height: 2rem !important;
        }
    }
    .takeaways,.references  {
        .ant-typography, .typography-p {
            line-height: 1.5rem !important;
        }
    }
}
img,video {
    border-radius: 3px;
}
`
);

export const TypographyStyles = createGlobalStyle`


`;
