/********************************************************************************
 * THEME
 * -----------------------------------------------------------------------------
 * This file contains the theme file for the ELO components. It is used to style
 * the components in the ELO library. There is a default theme that can be used
 * but a custom theme can be created and passed to the theme.
 * ******************************************************************************/

import React from 'react';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import defaultTheme from './defaultTheme';

export const EloTheme = ({ children }: { children: React.ReactNode }) => {
  let theme = createTheme(defaultTheme);

  theme = responsiveFontSizes(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default EloTheme;
