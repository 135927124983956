import * as React from 'react';
import { Link } from 'gatsby';

type AnswerCategoryLinkProps = {
  categories: {
    slug: string;
    headline: string;
  }[];
};

const AnswerCategoryLink = ({ categories }: AnswerCategoryLinkProps): JSX.Element => {
  const prefix = 'This question is about';

  return (
    <p>
      {prefix}{' '}
      {categories.map((link, i) => (
        <>
          <Link key={`${categories[i].slug}_${i}`} to={`/answers/${categories[i].slug}/`}>
            {categories[i].headline}
          </Link>
          {i > 0 && i < categories.length - 2 && ', '}
          {i === categories.length - 2 && ' and '}
        </>
      ))}
    </p>
  );
};

export default AnswerCategoryLink;
