/**********************************************************************
 * SECTION CONTAINER V2
 * --------------------------------------------------------------------
 * The section container a reusable component that wraps individual
 * sections at the top level of the section. It can be used to wrap
 * and apply responsive styles. It enalbles styles to be applied
 * by hard codeing them into the component or by passing them in
 * via the designOptions prop which comes from contentful CMS.
 *
 * Values passed in via the designOptions prop will override the
 * corresponding hard coded values.
 *********************************************************************/

import React from 'react';
import { Box } from 'ui';
import { ISectionContainerProps } from './ISectionContainerProps';
import { useQueryString } from 'src/hooks';
import styled from 'styled-components';
import { responsiveV2 } from 'src/utils';

/**
 * SECTION CONTAINER V2
 * Section Container normally wraps individual sections at the top level of the section.
 *
 * @param designNodes - This comes from contentful and contains the design options for the section.
 * any parameter provided by designOptions will override the corresponding parameter that has been hard coded.
 *
 * @param backgroundColor - The background color of the section.
 * @param margin - The margin of the section.
 * @param padding - The padding of the section.
 * @param textAlign - The text alignment of the section.
 *
 *
 * @param children - The children of the section.
 */

const SectionContainer = ({
  id = null,
  children,
  designNodes,
  backgroundColor,
  margin,
  marginTop,
  marginBottom,
  padding,
  paddingTop,
  paddingBottom,
  textAlign,
  sectionType,
}: ISectionContainerProps) => {
  /**************************************************************************
   * DEV MODE BORDERS
   * ------------------------------------------------------------------------
   * You can pass a query string parameter of borders to the URL to display
   * borders around the containers. This is useful for debugging layout issues.
   *************************************************************************/
  const qsv = useQueryString();
  const devMode = 'dev' in qsv ? true : false;

  /********************************************************************************
   * DESIGN OPTIONS
   * ------------------------------------------------------------------------------
   * Design options are passed in from contentful and are used to override the
   * hard coded values for the section. These values are passed in via an array
   * of Design Option Nodes called designNodes.
   *
   * The nodes are combined into a single object and then used to override the
   * hard coded values when setting the effective values.
   *
   * NOTE that the final nodes in the array will override the values of the
   * previous nodes.
   ********************************************************************************/

  const designOptions = designNodes
    ? designNodes.reduce((acc: any, curr: any) => {
        Object.keys(curr).forEach(key => {
          if (curr[key] !== null) {
            acc[key] = curr[key];
            // const kebabKey = camelToKebabCase(key);
            // acc[kebabKey] = curr[key];
          }
        });
        return acc;
      }, {})
    : null;

  // Determine the background color to use
  const effectiveBackgroundColor = designOptions?.backgroundColor || backgroundColor;
  const effectiveMargin = designOptions?.margin || margin;
  const effectiveMarginTop = designOptions?.marginTop || marginTop;
  const effectiveMarginBottom = designOptions?.marginBottom || marginBottom;
  const effectivePadding = designOptions?.padding || padding;
  const effectivePaddingTop = designOptions?.paddingTop || paddingTop;
  const effectivePaddingBottom = designOptions?.paddingBottom || paddingBottom;
  const effectiveTextAlign = designOptions?.textAlign || textAlign;

  return (
    <Box
      {...(id && { id: id })}
      background={effectiveBackgroundColor} // Set the effective background color
      m={effectiveMargin} // Set the effective margin
      mt={effectiveMarginTop}
      mb={effectiveMarginBottom}
      p={effectivePadding} // Set the effective padding
      pt={effectivePaddingTop}
      pb={effectivePaddingBottom}
      textAlign={effectiveTextAlign} // Set the effective text alignment
    >
      {devMode && <BreakPoint>{sectionType}</BreakPoint>}
      {children}
    </Box>
  );
};

const BreakPoint = styled.div`
  z-index: 1000;
  position: absolute;
  background: rgba(7, 58, 23, 0.8);
  color: #e4f5e4;
  border: 2px solid #082f08;
  padding: 10px;
  font-size: 12px;
  top: 0;
  left: 0;
  padding-top: 10px;
  &:before {
    ${responsiveV2({
      cssPropsVals: {
        content: ['"xs - "', '"sm - "', '"md - "', '"lg - "', '"xl - "'],
      },
    })};
  }
`;

export default SectionContainer;
