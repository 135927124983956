import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

const LuminaryLogoWhite = ({
  stroke = '#333333',
  strokeWidth = '1.25',
  width = '311',
  height = '311',
  viewBox = '0 0 311 311',
}: SVGIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M198.221 235.944C218.855 235.944 235.582 219.226 235.582 198.603C235.582 177.98 218.855 161.262 198.221 161.262C177.587 161.262 160.86 177.98 160.86 198.603C160.86 219.226 177.587 235.944 198.221 235.944Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
      fill="white"
    />
    <path
      d="M135.408 169.129C135.408 169.129 135.284 169.046 135.242 169.005C126.627 162.277 126.627 149.237 135.242 142.51C135.305 142.468 135.367 142.427 135.408 142.386C144.044 135.658 149.656 125.205 149.822 113.448C150.112 92.9145 133.544 75.8584 113 75.5686C92.1452 75.2788 75.1217 92.0866 75.1217 112.889C75.1217 124.874 80.7755 135.534 89.5564 142.365C89.6186 142.406 89.6807 142.448 89.7221 142.489C98.3374 149.216 98.3374 162.257 89.7221 168.984C89.66 169.025 89.5979 169.067 89.5564 169.108C80.8997 175.856 75.2873 186.309 75.1217 198.066C74.8317 218.6 91.3996 235.656 111.944 235.946C132.819 236.236 149.843 219.407 149.843 198.605C149.843 186.62 144.189 175.96 135.408 169.129Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
      fill="white"
    />
  </svg>
);

export default LuminaryLogoWhite;
