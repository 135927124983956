/********************************************************************************
 * LOGIN PENDING
 * ----------------------------------------------------------------------------
 * While a login action is pending
 * *****************************************************************************/

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useAuthenticator } from '@aws-amplify/ui-react';
import CircularProgress from '@mui/material/CircularProgress';
import { Stack } from '@mui/material';

// Hold until data is loaded
import { useAccountStore } from '../../../../store';

const AuthPending: React.FC = () => {

  const { isPending } = useAuthenticator();
  const [open, setOpen] = useState<boolean>(false);

  const loadingData = useAccountStore(state => state.loadingData);

  // Define the delay time in milliseconds
  const delay: number = 100; // Example: 500ms delay

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isPending || loadingData) {
      setOpen(true);
    } else {
      // Delay the closing of the modal
      timer = setTimeout(() => setOpen(false), delay);
    }

    return () => clearTimeout(timer); // Cleanup the timer
  }, [isPending, delay, loadingData]);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ['80%', '400px'],
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
  };

  return (
    <Modal
      open={open}      
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      
    >
      <Box
        p={['20px', '20px', '20px', '20px']}
        sx={style}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
      >
        <Stack spacing={2} p="20px" direction="row" justifyContent="center">
          <CircularProgress color="primary" size={60} />
        </Stack>
        <Typography
          id="modal-modal-title"
          variant="body1"
          sx={{
            mt: 2,
          }}
        >
          Please wait...
        </Typography>
      </Box>
    </Modal>
  );
};

export default AuthPending;
