import * as React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { DefaultRichTextRenderOptions } from '../../../../utils/render';
import { SectionProps } from 'types';

import { SectionContainer, Image } from 'ui';

export default function TwoColumnLift({ name, blocks }: SectionProps): JSX.Element {
  return (
    <SectionContainer id={name} usePadding contentBackground={'#fff'}>
      <Container>
        {blocks.map((b, key) => {
          if (b.background) {
            const image = isMobile
              ? b?.mobileBackground?.gatsbyImageData
              : b?.background?.gatsbyImageData;
            return (
              <div className="image" key={`${name}_image_${key}`}>
                <Image gatsbyImageData={image} />
              </div>
            );
          }
          if (!b.background) {
            return (
              <div className="content" key={`${name}_text_${key}`}>
                <h2>{b.body && renderRichText(b.body, DefaultRichTextRenderOptions)}</h2>

                {b.shortBody?.shortBody && <p className="description">{b.shortBody?.shortBody}</p>}

                {b.subHeading && <p className="disclaimer">{b.subHeading}</p>}
              </div>
            );
          }
        })}
      </Container>
    </SectionContainer>
  );
}
const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  padding-top: 13px;
  padding-bottom: 260px;
  .image {
    background: ${({ theme }) => theme.colors.eloGreygeLight};
    padding: 30px 20px 0;
    border-radius: 5px;
    order: -1;
    width: 100%;
    margin-bottom: 10px;
    img {
      width: 100%;
    }
  }
  .content {
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    h2 .typography-p {
      margin-top: 10px;
      font-family: 'Everett Light';
      color: ${({ theme }) => theme.colors.eloDarkGreen};
      font-size: 29px;
      line-height: 32px;
      margin-bottom: 0;
    }
    p.description {
      color: ${({ theme }) => theme.colors.eloDarkGrey};
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 13px;
    }

    .img-header {
      font-family: 'Everett Medium';
      color: ${({ theme }) => theme.colors.eloBlack};
      font-size: 15.5px;
      line-height: 21px;
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
      position: relative;
      height: 60px;
      > div {
        position: absolute;
        left: -50px;
        top: 0;
        width: calc(100% + 100px);
      }
    }
    img {
      width: 60px;
      margin-right: 9px;
      margin-bottom: 9px;
    }
    p.disclaimer {
      color: ${({ theme }) => theme.colors.eloMidGrey};
      font-size: 10px;
      line-height: 13.5px;
      margin-top: 0px;
    }
  }

  ${({ theme }) => theme.breakpoints.small} {
    flex-flow: row;
    align-items: stretch;
    padding-top: 20px;
    padding-bottom: 20px;
    .content {
      width: 50%;
      margin-right: 30px;
      h2 .typography-p {
        font-size: 51px;
        line-height: 56px;
        max-width: 640px;
        margin-bottom: 20px;
      }
      p.description {
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 26px;
        max-width: 640px;
      }

      p.img-header {
        font-size: 20px;
        line-height: 27px;
      }
      .desktop {
        display: block;
      }
      .mobile {
        display: none;
      }
      p.disclaimer {
        font-size: 12px;
        line-height: 16px;
        margin-top: 15px;
      }
    }
    .image {
      width: 50%;
      order: 1;
      margin-bottom: 0;
      padding: 60px 40px 0;
    }
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    padding-top: 10px;
    padding-bottom: 150px;
  }
`;
