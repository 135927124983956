import React, { useEffect, useState } from 'react';
import Typewriter from 'typewriter-effect';
import { Box, Typography } from 'ui';
import { VideoProps } from 'types';
import styled from 'styled-components';

interface BuildingProps {
  video: VideoProps;
}

const Building = ({ video }: BuildingProps) => {
  /***************************************************************************
   * DELAY RENDER
   * -------------------------------------------------------------------------
   * Delay the render of this component in order to give already created
   * formula data a chance to load. This will prevent the Building component
   * from flashing on the screen for a split second before the formula data
   * is loaded.
   **************************************************************************/

  const [delayedRender, setDelayedRender] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedRender(true);
    }, 2200);

    return () => clearTimeout(timer);
  }, []);

  if (!delayedRender) {
    return null;
  }

  /***************************************************************************
   * IMPORTANT!!!
   * -------------------------------------------------------------------------
   * MODAL CONTAINER
   * -------------------------------------------------------------------------
   * For my future self and anyone else who may be working on this code:
   * DO NOT REMOVE THE MODAL CONTAINER FROM THIS COMPONENT.
   *
   * The Modal Container is used to create a fully opaque background for the
   * Building component. This prevents the user from interacting with the
   * or seeing the rest of the application while the Building component is
   * displayed.
   *
   * This is necessary because the Building component is a component of the
   * Assessment Summary section which is rendered along with all of the other
   * sections on the page. So if you don't want the user to be able to interact
   * with the rest of the page while the Building component is displayed, you
   * need to use the Modal Container.
   **************************************************************************/

  return (
    <>
      <ModalContainer>
        <Box          
          mt={'40px'}
          height={['380px', '420px', '480px']}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <video style={{ maxWidth: 'auto', height: '480px' }} autoPlay loop muted playsInline>
            <source src={video?.file?.url} type={video?.file?.contentType} />
          </video>
        </Box>
        <Box textAlign={'center'} mt={'-40px'}>
          <Typography variant="h2">
            Building your
            <br />
            recommended gummy
          </Typography>
        </Box>
        <Box textAlign={'center'}>
          <TypewriterWrapper>
            <Typewriter
              className={'typewriter'}
              options={{
                strings: [
                  '<h4>UPLOADING</h4>Uploading your data to Luminary Vitamins AI...',
                  '<h4>ASSESSING</h4>Analyzing your data...',
                  '<h4>SEARCHING</h4>Reviewing the clinical literature...',
                  '<h4>BUILDING</h4>Selecting your ingredients...',
                  '<h4>BUILDING</h4>Customizing your doses...',
                  '<h4>PLEASE WAIT</h4>Wrapping things up...',
                ],
                autoStart: true,
                //@ts-ignore
                pauseFor: 900,
                loop: false,
                delay: 68,
                // changeDelay: 'natural',
                deleteSpeed: 0,
              }}
            />
          </TypewriterWrapper>
        </Box>
      </ModalContainer>
    </>
  );
};

export default Building;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* high index to be above all other content */

  /* pseudo-element for overlay */
  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.5); semi-transparent black */
    background-color: rgba(255, 255, 255, 1); /* semi-transparent black */
    z-index: -1; /* below the content of ModalContainer */
  }
`;

const TypewriterWrapper = styled.div`
  .Typewriter__wrapper,
  .Typewriter__wrapper h4 {
    font-family: 'Montserrat';
    margin-bottom: 0.47rem;
  }
`;
