/* eslint react/display-name: "off" */

import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import { MARKS, BLOCKS } from '@contentful/rich-text-types';
import { SectionProps } from 'types';

import SectionContainer, { SectionPadding } from '../../SectionContainer';
import { Button, Icon, BackgroundImage, HeadlineGroup } from 'ui';

export default function PageSectionBuy({
  headline,
  subHeading,
  background,
  mobileBackground,
  body,
  slug,
  ctaButton,
}: SectionProps) {
  const richText = renderRichText(body, renderOptions);

  return (
    <>
      <a id="get-elo" />
      <SectionContainer id={slug} contentBackgroundColor={'#E2E2E2'}>
        <BackgroundImage desktopImage={background} mobileImage={mobileBackground}>
          <ContentContainer>
            <TextContainer>
              <HeadlineGroup headline={headline} subHeading={subHeading} inverted />
              {richText}
              <Button path={ctaButton?.link}>{ctaButton?.title}</Button>
            </TextContainer>
          </ContentContainer>
        </BackgroundImage>
      </SectionContainer>
    </>
  );
}

const ContentContainer = styled.div`
  color: ${({ theme }) => theme.colors.white};
  .ant-typography,
  .typography-p {
    color: ${({ theme }) => theme.colors.white};
  }
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 245px 20px 0 20px;
  @media (min-width: 600px) {
    margin-top: 80px;
    margin-bottom: 100px;
  }
  @media (min-width: 950px) {
    margin-top: 180px;
  }
  padding: ${SectionPadding.vertical.mobile}px ${SectionPadding.horizontal.mobile}px 0
    ${SectionPadding.horizontal.mobile}px;
  ${({ theme }) => theme.breakpoints.tablet} {
    padding: ${SectionPadding.vertical.tablet}px ${SectionPadding.horizontal.tablet}px 0
      ${SectionPadding.horizontal.tablet}px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    padding: ${SectionPadding.vertical.desktop}px ${SectionPadding.horizontal.desktop}px 0
      ${SectionPadding.horizontal.desktop}px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    padding: ${SectionPadding.vertical.wide}px ${SectionPadding.horizontal.wide}px 0
      ${SectionPadding.horizontal.wide}px;
  }
`;

const TextContainer = styled.div`
  font-family: 'Everett Medium';
  padding-bottom: 100px;
`;

const List = styled.ul`
  margin: 0 0 40px 0;
  padding: 0;
  /* for streched underlines */
  min-width: 50%;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  min-height: 50px;
  margin-bottom: 3px;
  list-style-type: none;
  p {
    margin: 0;
  }
`;

const Bold = styled.div`
  margin-bottom: 10px;
`;

const Space = styled.div`
  width: 10px;
  ${({ theme }) => theme.breakpoints.tablet} {
    width: 12px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    width: 20px;
  }
`;

const renderOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <ListItem>
        <Icon id="check" />
        <Space />
        {children}
      </ListItem>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => children,
  },
};
