/* eslint react/display-name: "off" */

import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import { BLOCKS } from '@contentful/rich-text-types';
import { SectionProps } from 'types';
import { BackgroundImage, HeadlineGroup } from 'ui';

import SectionContainer, { SectionPadding } from '../../SectionContainer';
import { base } from '../../../../theme/Theme';

function MobilePillsSection({ headline, subHeading, body, mobileBackground, slug }: SectionProps) {
  const mobileContent = renderRichText(body, mobileRenderOptions);

  return (
    <SectionContainer
      id={slug}
      usePaddingNoMobile
      contentBackground={base.gradients.vertialWhiteLightGray}
    >
      <PaddingTop>
        <PaddingHorizontal>
          <HeadlineGroup headline={headline} subHeading={subHeading} />
        </PaddingHorizontal>
      </PaddingTop>
      <PillsContainer>
        <BackgroundImage desktopImage={mobileBackground} mobileImage={mobileBackground}>
          <PaddingHorizontal>{mobileContent}</PaddingHorizontal>
        </BackgroundImage>
      </PillsContainer>
    </SectionContainer>
  );
}

function DesktopPillsSection({ headline, subHeading, body, background, slug }: SectionProps) {
  const content = renderRichText(body, renderOptions);

  return (
    <SectionContainer
      id={slug}
      usePaddingNoMobile
      contentBackground={base.gradients.vertialWhiteLightGray}
    >
      <PaddingTop>
        <HeadlineGroup headline={headline} subHeading={subHeading} />
      </PaddingTop>
      <PillsContainer>
        <BackgroundImage desktopImage={background} mobileImage={background}>
          {content}
        </BackgroundImage>
      </PillsContainer>
    </SectionContainer>
  );
}

export default function PageSectionPills(props: SectionProps) {
  const [hydrated, setHydrated] = React.useState<boolean>(false);

  React.useEffect(() => {
    setHydrated(true);
  }, []);

  const isMobileConf = isMobile || !hydrated;

  const MobileContent = React.memo(MobilePillsSection);
  const DesktopContent = React.memo(DesktopPillsSection);

  if (isMobileConf) {
    return <MobileContent {...props} />;
  }

  return <DesktopContent {...props} />;
}

const PaddingTop = styled.div`
  padding-top: ${SectionPadding.vertical.mobile}px;
  ${({ theme }) => theme.breakpoints.tablet} {
    padding-top: ${SectionPadding.vertical.tablet}px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    padding-top: ${SectionPadding.vertical.desktop}px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    padding-top: ${SectionPadding.vertical.wide}px;
  }
`;

const PaddingHorizontal = styled.div`
  padding: 0 ${SectionPadding.horizontal.mobile}px;

  ${({ theme }) => theme.breakpoints.tablet} {
    padding: 0 ${SectionPadding.horizontal.tablet}px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    padding: 0 ${SectionPadding.horizontal.desktop}px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    padding: 0 ${SectionPadding.horizontal.wide}px;
  }
`;

const PillsContainer = styled.div`
  margin-top: -150px;
  ${({ theme }) => theme.breakpoints.tablet} {
    margin-top: -180px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: -250px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    margin-top: -330px;
  }
`;

const List = styled.ul`
  padding: 0;
  li {
    position: absolute;
  }
  li:nth-child(1) {
    width: 80%;
    margin-top: 26%;
  }
  li:nth-child(2) {
    width: 70%;
    margin-top: 35%;
  }
  li:nth-child(3) {
    width: 69%;
    margin-top: 61%;
    right: 0;
    text-align: right;
    span {
      left: 0;
    }
  }
  li:nth-child(4) {
    width: 78%;
    margin-top: 70%;
    right: 0;
    text-align: right;
    span {
      left: 0;
    }
  }
  li p {
    width: 100%;
  }
`;

const MobileList = styled.ul`
  padding: 0;
  li {
    position: absolute;
  }
  li:nth-child(1) {
    width: 90%;
    margin-top: 52%;
    p {
      padding-right: 30%;
    }
  }
  li:nth-child(2) {
    width: 68%;
    margin-top: 75%;
    p {
      padding-right: 30%;
    }
  }
  li:nth-child(3) {
    width: 68%;
    margin-top: 125%;
    right: ${SectionPadding.horizontal.mobile}px;
    text-align: right;
    span {
      left: 0;
    }
    p {
      padding-left: 35%;
    }
  }
  li:nth-child(4) {
    width: 90%;
    margin-top: 150%;
    right: ${SectionPadding.horizontal.mobile}px;
    text-align: right;
    span {
      left: 0;
    }
    p {
      padding-left: 25%;
    }
  }
  li p {
    width: 100%;
  }
`;

const FilledCircle = styled.span`
  background-color: ${({ theme }) => theme.colors.primary};
  border: thin solid ${({ theme }) => theme.colors.primary};
  position: absolute;
  right: 0;
  top: -5px;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  ${({ theme }) => theme.breakpoints.tablet} {
    top: -7px;
    height: 14px;
    width: 14px;
    border-radius: 14px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    top: -9px;
    height: 18px;
    width: 18px;
    border-radius: 18px;
  }
`;

const ListItem = styled.li`
  padding-top: 0.5em;
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  list-style-type: none;
`;

const renderOptions = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <ListItem>
        <FilledCircle />
        {children}
      </ListItem>
    ),
  },
};

const mobileRenderOptions = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => <MobileList>{children}</MobileList>,
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <ListItem>
        <FilledCircle />
        {children}
      </ListItem>
    ),
  },
};
