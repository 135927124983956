import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';



const Trophy = ({ 
    stroke = '#333333',
    strokeWidth = '1.25',
    width = '50', 
    height = '50', 
    viewBox = '0 0 50 50'
  }: SVGIcon) => (
    <svg 
    width={width}
    height={height}
    viewBox={viewBox} 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M11.5059 17.1601V22.1158"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M6.0957 22.1157H16.9121"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M5.51316 1.34424H17.49V8.92736C17.49 12.2336 14.8041 14.9194 11.4979 14.9194C8.1917 14.9194 5.50586 12.2336 5.50586 8.92736V1.34424H5.51316Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M17.4883 3.08862H20.3274C21.9258 3.08862 22.6118 5.1176 21.3346 6.0883L17.5394 9.05878"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M5.51142 3.08862H2.67231C1.07394 3.08862 0.387886 5.1176 1.66512 6.0883L5.51142 9.05878"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
  </svg>
);

export default Trophy;
