/****************************************************************************************************
 * * USE DEV MODE HOOK
 * --------------------------------------------------------------------------------------------------
 * This hook checks for the presence of a 'dev' query string parameter to determine if the
 * application is in development mode. This is useful for enabling development-only features or
 * debugging tools. It also verifies the the node environment is not 'production' to prevent
 * accidental use of development features in a production environment.
 ***************************************************************************************************/
import { useState, useEffect } from 'react';

const useDevMode = (): boolean => {
  const [isDevMode, setIsDevMode] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      // const isDevParam = searchParams.has('dev');
      const isDevParam = Array.from(searchParams.keys()).some(key => key.toLowerCase() === 'dev');
      const isNotEloHealthDomain = !window.location.hostname.endsWith('luminaryvitamins.com');

      // Enable dev mode if the "dev" query parameter is present or if the domain is not elo.health
      const isDev = isDevParam && isNotEloHealthDomain;

      setIsDevMode(isDev);
    }
  }, []); // Runs once after the component mounts

  return isDevMode;
};

export default useDevMode;
