import * as React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { Image, TitleWithAnchor, Spacer, ResponsiveImage, VideoAsset, Button } from 'ui';
import Typography from 'src/features/ui/typography/Typography';

import { LinkType, SectionProps } from 'types';

import { Options } from '@contentful/rich-text-react-renderer';

// Moved from legacy components here:
import PageSectionHero from '../features/page-section/sections/hero/Hero';
import PageSectionArticleTags from '../features/page-section/sections/articletags/ArticleTags';
import PageSectionBasic from '../features/page-section/sections/basic/Basic';
import PageSectionForm from '../features/page-section/sections/form/Form';
import PageSectionContactForm from '../features/page-section/sections/contactform/ContactForm';
import PageSectionListingWithTags from '../features/page-section/sections/listingwithtags/ListingWithTags';
import PageSectionCategoryListing from '../features/page-section/sections/categorylisting/CategoryListing';
import PageSectionRecentArticles from '../features/page-section/sections/recentarticles/RecentArticles';
import PageSectionCategoryRoundup from '../features/page-section/sections/categoryroundup/CategoryRoundup';
import PageSectionFeaturedArticle from '../features/page-section/sections/featuredarticle/FeaturedArticle';
import PageSectionSpotlightArticles from '../features/page-section/sections/spotlightarticles/SpotlightArticles';
import PageSectionQuestionListing from '../features/page-section/sections/questionlisting/QuestionListing';
import PageSectionProductImage from '../features/page-section/sections/productimage/ProductImage';
import PageSectionSmartSupplements from '../features/page-section/sections/smartsupplements/SmartSupplements';
import PageSectionBuy from '../features/page-section/sections/buy/Buy';
import PageSectionCoaches from '../features/page-section/sections/coaches/Coaches';
import PageSectionCuratedArticles from '../features/page-section/sections/curatedarticles/CuratedArticles';
import PageSectionPills from '../features/page-section/sections/pills/Pills';
import PageSectionQualifyingQuestions from '../features/page-section/sections/qualifyingquestions/QualifyingQuestions';
import PageSectionResponsive from '../features/page-section/sections/responsive/Responsive'; // Paul Made This

// Renewed components here:
import PageSectionFaq from '../features/page-section/sections/faq/Faq'; // TODO storybook + code splitting
import PageSectionHowItWorks from '../features/page-section/sections/howitworks/HowItWorks'; // TODO storybook + code splitting
import PageSectionTestimonials from '../features/page-section/sections/reviews/Reviews'; // TODO storybook + code splitting
import PageSectionCheckoutSuccess from '../features/page-section/sections/checkoutsuccess/CheckoutSuccess'; // TODO storybook + code splitting

// New components
import PageSectionHighlightsBar from '../features/page-section/sections/highlightsbar/HighlightsBar';
import PageSectionOurMission from '../features/page-section/sections/ourmission/OurMission';
import PageSectionProductListing from '../features/page-section/sections/productlisting/ProductListing';
import PageSectionFeatures from '../features/page-section/sections/features/Features';
import PageSectionBuyProduct from '../features/page-section/sections/buyproduct/BuyProduct';
import PLPHero from '../features/page-section/sections/plphero/PLPHero';
import PageSectionWhatsInside from '../features/page-section/sections/whatsinside/WhatsInside';
import TwoColumnLift from '../features/page-section/sections/twocolumnlift/TwoColumnLift';
import TwoColumnTicker from '../features/page-section/sections/twocolumnlift/TwoColumnLiftTicker';
import Carousel from '../features/page-section/sections/carousel/Carousel';
import Steps from '../features/page-section/sections/steps/Steps';
import Tabs from '../features/page-section/sections/tabs/Tabs';
import Video from '../features/page-section/sections/video/Video';
import FullImage from '../features/page-section/sections/fullimage/FullImage';

// LUMINARY CREATED PAGE SECTIONS
import PageSectionVideoHero from 'src/features/page-section/sections/videohero/VideoHero';
import Assessment from 'src/features/assessments/smart-gummies/SmartGummies.Page'
import AssessmentSummary from 'src/features/assessment-summary/variants/luminary-gummies'


/******************************************************
 * BLOCKS
 * ----------------------------------------------------
 * These are small components that are used to render
 * individual blocks within a section of rich text
 * ***************************************************/
import { Accordian } from 'src/features/blocks';

import { AnchorLink, GradientText } from 'ui';

export function isInternalLink(link: LinkType) {
  if (link.url) {
    return link.url.startsWith('https://www.elo.health/');
  }
  return false;
}

/**
 * DEFAULT RICH TEXT EMBEDDED ENTRY RENDERER
 * 
 */
function renderEmbeddedEntry(node: any, children: React.ReactNode, options: Options | undefined) {
  
  const embed = node?.data?.target;
  
  switch (embed?.__typename) {
    case 'ContentfulLinkWrapper':
      return <a href={embed.url}>{renderRichText(embed.content, options)}</a>;
    case 'ContentfulSpacer':      
      return <Spacer target={embed}/>;
    case 'ContentfulQuestionAnswer':
      return <Accordian data={embed}/>
    case 'ContentfulResponsiveImage':
      return <ResponsiveImage data={embed}/>
    case 'ContentfulCta':
      return <Button ctaButton={embed}/>
    case 'ContentfulVideoAsset':
      return <VideoAsset url={embed.url} data={embed} />;
    default:
      return <>{children}</>;
  }  
}

function renderInlineEntry(node: any, children: React.ReactNode) {
  const embed = node?.data?.target;
  if (embed?.__typename === 'ContentfulGradientText') {    
    return <GradientText gradient={embed.gradient}>{embed?.content}</GradientText>;
  } else if (embed?.__typename === 'ContentfulAnchorLink') {
    return <AnchorLink target={embed.target}>{embed?.content}</AnchorLink>;
  } else {
    return <>{children}</>;
  }
}

export const simpleHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
};

function renderEmbeddedAsset(node: any) {
  const target = node?.data?.target;
  
  if (!target) {
    return <></>;
  }
  const { mimeType, title, file, svg, gatsbyImageData } = target;

  switch (mimeType) {
    case 'video/mp4':
      return <VideoAsset url={file.url} />;
    default:
      return file && (
        <Image
          className={'hero-image'}
          svg={svg}
          gatsbyImageData={gatsbyImageData}
          file={file['en-US']}
          alt={title}          
        />
      )
  }  
}

function renderEntryHyperlink(node: any, children: React.ReactNode) {
  const entry = node?.data?.target;
  if (entry?.__typename === 'ContentfulArticle') {
    return <a href={`/articles/${entry.slug}/`}>{children}</a>;
  } else {
    return <>{children}</>;
  }
}

export const NopRichTextRenderOptions = {
  renderMark: {
    [MARKS.BOLD]: (text: React.ReactNode) => <>{text}</>,
    [MARKS.ITALIC]: (text: React.ReactNode) => <>{text}</>,
    [MARKS.UNDERLINE]: (text: React.ReactNode) => <>{text}</>,
    [MARKS.CODE]: (text: React.ReactNode) => <>{text}</>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: () => <></>,
    [BLOCKS.HEADING_2]: () => <></>,
    [BLOCKS.HEADING_3]: () => <></>,
    [BLOCKS.HEADING_4]: () => <></>,
    [BLOCKS.HEADING_5]: () => <></>,
    [BLOCKS.PARAGRAPH]: () => <></>,
    [BLOCKS.UL_LIST]: () => <></>,
    [BLOCKS.OL_LIST]: () => <></>,
    [BLOCKS.LIST_ITEM]: () => <></>,
    [BLOCKS.QUOTE]: () => <></>,
    [BLOCKS.HR]: () => <></>,
    [BLOCKS.EMBEDDED_ENTRY]: () => <></>,
    [BLOCKS.EMBEDDED_ASSET]: () => <></>,
    [INLINES.EMBEDDED_ENTRY]: () => <></>,
    [INLINES.HYPERLINK]: () => <></>,
    [INLINES.ENTRY_HYPERLINK]: () => <></>,
    [INLINES.ASSET_HYPERLINK]: () => <></>,
  },
};

/******************************************************************************************
 * DEFAULT RICH TEXT RENDER OPTIONS
 * ---------------------------------------------------------------------------------------
 * NOTE: This is being refactored to use default typography and other styles that
 * are part of the design framework.
 * ****************************************************************************************/
export const DefaultRichTextRenderOptions = {
  renderMark: {
    [MARKS.BOLD]: (text: React.ReactNode) => <strong>{text}</strong>,
    [MARKS.CODE]: (text: React.ReactNode) => <p>{text}</p>,
    [MARKS.UNDERLINE]: (text: React.ReactNode) => <p>{text}</p>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node:any, children: React.ReactNode) => <Typography variant='h1'>{children}</Typography>,
    [BLOCKS.HEADING_2]: (node:any, children: React.ReactNode) => <Typography variant='h2'>{children}</Typography>,
    [BLOCKS.HEADING_3]: (node:any, children: React.ReactNode) => <Typography variant='h3'>{children}</Typography>,
    [BLOCKS.HEADING_4]: (node:any, children: React.ReactNode) => <Typography variant='h4'>{children}</Typography>,
    [BLOCKS.HEADING_5]: (node:any, children: React.ReactNode) => <Typography variant='h5'>{children}</Typography>,
    [BLOCKS.HEADING_6]: (node:any, children: React.ReactNode) => <Typography variant='h6'>{children}</Typography>,
    [BLOCKS.PARAGRAPH]: (node:any, children: React.ReactNode) => <p><Typography variant='body2'>{children}</Typography></p>,
    [BLOCKS.QUOTE]: (_: unused, children: React.ReactNode) => <blockquote>{children}</blockquote>,
    [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: React.ReactNode) =>
      renderEmbeddedEntry(node, children, DefaultRichTextRenderOptions),
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => renderEmbeddedAsset(node),
    [INLINES.ENTRY_HYPERLINK]: (node: any, children: React.ReactNode) =>
      renderEntryHyperlink(node, children),
    [INLINES.EMBEDDED_ENTRY]: (node: any, children: React.ReactNode) =>
      renderInlineEntry(node, children),
  },
};
/******************************************************************************************
 * BASIC BLOCK RICH TEXT RENDER OPTIONS
 * ---------------------------------------------------------------------------------------
 * 
 * ****************************************************************************************/
export const BasicBlockRichTextRenderOptions = {
  renderMark: {
    [MARKS.BOLD]: (text: React.ReactNode) => <strong>{text}</strong>,
    [MARKS.CODE]: (text: React.ReactNode) => <p>{text}</p>,
    [MARKS.UNDERLINE]: (text: React.ReactNode) => <p>{text}</p>,
  },
  renderNode: {    
    [BLOCKS.HEADING_1]: (node:any, children: React.ReactNode) => <Typography variant='h1'>{children}</Typography>,
    [BLOCKS.HEADING_2]: (node:any, children: React.ReactNode) => <Typography variant='h2'>{children}</Typography>,
    [BLOCKS.HEADING_3]: (node:any, children: React.ReactNode) => <Typography variant='h3'>{children}</Typography>,
    [BLOCKS.HEADING_4]: (node:any, children: React.ReactNode) => <Typography variant='h4'>{children}</Typography>,
    [BLOCKS.HEADING_5]: (node:any, children: React.ReactNode) => <Typography variant='h5'>{children}</Typography>,
    [BLOCKS.HEADING_6]: (node:any, children: React.ReactNode) => <Typography variant='h6'>{children}</Typography>,
    [BLOCKS.PARAGRAPH]: (node:any, children: React.ReactNode) => <p><Typography variant='body2'>{children}</Typography></p>,
    [BLOCKS.QUOTE]: (_: unused, children: React.ReactNode) => <blockquote>{children}</blockquote>,
    [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: React.ReactNode) =>
      renderEmbeddedEntry(node, children, DefaultRichTextRenderOptions),
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => renderEmbeddedAsset(node),
    [INLINES.ENTRY_HYPERLINK]: (node: any, children: React.ReactNode) =>
      renderEntryHyperlink(node, children),
    [INLINES.EMBEDDED_ENTRY]: (node: any, children: React.ReactNode) =>
      renderInlineEntry(node, children),
  },
};

// Only some tags are supported https://developers.google.com/search/docs/data-types/faqpage#answer
export const AnswerSchemaRichTextRenderOptions = {
  renderMark: {
    ...DefaultRichTextRenderOptions.renderMark,
    [MARKS.CODE]: (text: React.ReactNode) => <>{text}</>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_: unused, children: React.ReactNode) => <p>{children}</p>,
    [BLOCKS.HEADING_1]: (_: unused, children: React.ReactNode) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (_: unused, children: React.ReactNode) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (_: unused, children: React.ReactNode) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (_: unused, children: React.ReactNode) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (_: unused, children: React.ReactNode) => <h5>{children}</h5>,
    [BLOCKS.QUOTE]: (_: unused, children: React.ReactNode) => <p>{children}</p>,
    [BLOCKS.EMBEDDED_ENTRY]: () => <></>,
    [BLOCKS.EMBEDDED_ASSET]: () => <></>,
    [INLINES.ENTRY_HYPERLINK]: () => <></>,
  },
};

export const ArticleRichTextRenderOptions = {
  renderMark: DefaultRichTextRenderOptions.renderMark,
  renderNode: {
    ...DefaultRichTextRenderOptions.renderNode,
    [BLOCKS.HEADING_1]: (_: unused, children: React.ReactNode) => (
      <TitleWithAnchor level={1}>{children}</TitleWithAnchor>
    ),
    [BLOCKS.HEADING_2]: (_: unused, children: React.ReactNode) => (
      <TitleWithAnchor level={2}>{children}</TitleWithAnchor>
    ),
    [BLOCKS.HEADING_3]: (_: unused, children: React.ReactNode) => (
      <TitleWithAnchor level={3}>{children}</TitleWithAnchor>
    ),
    [BLOCKS.HEADING_4]: (_: unused, children: React.ReactNode) => (
      <TitleWithAnchor level={4}>{children}</TitleWithAnchor>
    ),
    [BLOCKS.HEADING_5]: (_: unused, children: React.ReactNode) => (
      <TitleWithAnchor level={5}>{children}</TitleWithAnchor>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: React.ReactNode) =>
      renderEmbeddedEntry(node, children, ArticleRichTextRenderOptions),
  },
};

export function renderPageContentSections(sections: SectionProps[], data: any = {}) {
  return sections.map(section => {
    switch (section.sectionType) {
      case 'Assessment Summary':
        return <AssessmentSummary key={section.slug} {...section} data={data} />;        
      case 'Typeform':
        return <Assessment key={section.slug} {...section} data={data} />
      case 'Hero':
        return <PageSectionHero key={section.slug} {...section} data={data} />;
      case'Video Hero':
        return <PageSectionVideoHero key={section.slug} {...section} data={data} />;
      case 'Basic':
        return <PageSectionBasic key={section.slug} {...section} />;
      case 'Blocks':
        return <PageSectionTestimonials key={section.slug} {...section} />;
      case 'ContactUsForm':
        return <PageSectionContactForm key={section.slug} {...section} />;
      case 'Form':
        return <PageSectionForm key={section.slug} {...section} />;
      case 'ListingWithTags':
        return <PageSectionListingWithTags key={section.slug} data={data} {...section} />;
      case 'CategoryListing':
        return <PageSectionCategoryListing key={section.slug} data={data} {...section} />;
      case 'ArticleTags':
        return <PageSectionArticleTags key={section.slug} data={data} {...section} />;
      case 'FeaturedArticle':
        return <PageSectionFeaturedArticle key={section.slug} data={data} {...section} />;
      case 'RecentArticles':
        return <PageSectionRecentArticles key={section.slug} data={data} {...section} />;
      case 'CategoryRoundup':
        return <PageSectionCategoryRoundup key={section.slug} data={data} {...section} />;
      case 'SpotlightArticles':
        return <PageSectionSpotlightArticles key={section.slug} data={data} {...section} />;
      case 'QuestionListing':
        return <PageSectionQuestionListing key={section.slug} data={data} {...section} />;
      case 'HowItWorks':
        return <PageSectionHowItWorks key={section.slug} {...section} />;
      case 'Pills':
        return <PageSectionPills key={section.slug} {...section} />;
      case 'BigImageCTA':
        return <PageSectionProductImage key={section.slug} {...section} />;
      case 'Coaches':
        return <PageSectionCoaches key={section.slug} {...section} />;
      case 'CuratedArticles':
        return <PageSectionCuratedArticles key={section.slug} {...section} />;
      case 'IconRow':
        return <PageSectionSmartSupplements key={section.slug} {...section} />;
      case 'Buy':
        return <PageSectionBuy key={section.slug} {...section} />;
      case 'FAQ':
        return <PageSectionFaq key={section.slug} {...section} />;
      case 'CheckoutSuccess':
        return <PageSectionCheckoutSuccess key={section.slug} {...section} />;
      case 'QualifyingQuestions':
        return <PageSectionQualifyingQuestions key={section.slug} {...section} />;
      case 'Highlights Bar':
        return <PageSectionHighlightsBar key={section.slug} {...section} data={data} />;
      case 'Product listing':
        return <PageSectionProductListing key={section.slug} {...section} />;
      case 'Features':
        return <PageSectionFeatures key={section.slug} {...section} />;
      case 'Our Mission':
        return <PageSectionOurMission key={section.slug} {...section} />;
      case 'BuyProduct':
        return <PageSectionBuyProduct key={section.slug} {...section} />;
      case 'Buy Product (story)':
        return <PLPHero key={section.slug} {...section} />;
      case 'WhatsInside':
        return <PageSectionWhatsInside key={section.slug} {...section} />;
      case 'Tabs':
        return <Tabs key={section.slug} {...section} />;
      case 'Steps':
        return <Steps key={section.slug} {...section} />;
      case 'Review Carousel':
        return <Carousel key={section.slug} {...section} />;
      case 'Video':
        return <Video key={section.slug} {...section} />;
      case 'Full image':
        return <FullImage key={section.slug} {...section} />;
      case 'Two Columns':
        return <TwoColumnLift key={section.slug} {...section} />;
      case 'Two Columns + ticker':
        return <TwoColumnTicker key={section.slug} {...section} />;
      case 'Responsive':
        return <PageSectionResponsive key={section.slug} {...section} />;
      default:
        console.error('omg - no section type', section.sectionType);
        return null;
    }
  });
}

export function calculateSpan(length: number): number {
  return Math.round(24 / length);
}
