/********************************************************************************
 * DATA FUNCTION: updateShippingAddress
 * ----------------------------------------------------------------------------
 * This function is used to update the user's shipping address.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/**********************************************************************
 * * IMPORT GRAPHQL MUTATION and Types
 * ********************************************************************/
import { UserData } from '../types';
import { updateUser } from './graphql/mutations';

/******************************************************************************************
 * * IMPORT ACCOUNT STORE
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { useAccountStore } from '../store';

/**********************************************************************
 * * UPDATE SHIPPING ADDRESS
 * --------------------------------------------------------------------
 * This function is used to update the user's shipping address.
 * ********************************************************************/
export const updateShippingAddress = async () => {    

  /**********************************************************************
   * * SUBSCRIBE TO STORE DATA
   * ********************************************************************/
//   const userAccount = useAccountStore(state => state.userAccount);
  const userAccount = useAccountStore.getState().userAccount;
  const { shippingAddress } = userAccount || {};

  /**********************************************************************
   * * BUILD UPDATE SHIPPING ADDRESS MUTATION
   * ********************************************************************/
  const buildMutation = (): GraphQLOptions => {
    const mutation = updateUser;
    const authMode = 'userPool';
    const variables = {
      input: {
        firstName: userAccount?.firstName,
        lastName: userAccount?.lastName,        
        shippingAddress: {
          name: userAccount?.firstName + ' ' + userAccount?.lastName,
          company: shippingAddress?.company,
          street: shippingAddress?.street,
          street2: shippingAddress?.street2,
          city: shippingAddress?.city,
          state: shippingAddress?.state,
          zip: shippingAddress?.zip,
          country: shippingAddress?.country,
        },
      } as UserData,
    };

    return {
      query: mutation,
      authMode,
      variables,
    };
  };

  try {
    const query = buildMutation();
    const result = (await client.graphql(query)) as GraphQLResult<UserData>;    
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    console.error('Error updating shipping address:', error);
    return error;
  }
};

export default updateShippingAddress;
