/********************************************************************************
 * UTIL: LOAD LINE ITEMS
 * ------------------------------------------------------------------------------
 * This utility function is used to load line items from the database into the
 * local state. This function is called when the app is first loaded. It will
 * load all line items from the database and store them in the zustand store.
 *
 * This function will initailly be called in the gatsby-browser.js file.
 * However, it should also be called whenever there's a need to refresh the
 * line items in the app. This could be if, for example, the data is somehow
 * lost and needs to be reloaded.
 * *******************************************************************************/

import { generateClient } from 'aws-amplify/api';
import { getLineItems } from 'src/graphql/queries';
import { GetLineItemsQuery } from 'src/API';
import { useStoreV2 } from 'src/store/StoreV2';

interface LoadLineItemsProps {
  affiliation?: string;
  referral_code?: string;
}

export const loadLineItems = async ({ affiliation, referral_code }: LoadLineItemsProps) => {
  const client = generateClient();

  const updateStore = useStoreV2.getState().updateState;
  
  try {
    updateStore(state => {
      state.loadingLineItems = true;
    });

    /********************************************************************************
     * INPUT PARAMETERS
     * ------------------------------------------------------------------------------
     * The input params will be constructed based on any querystring parameters
     * that have been passed to the page.
     * *******************************************************************************/

    const response = (await client.graphql({
      query: getLineItems,
      variables: {
        input: {
          affiliation: affiliation,
          referralCode: referral_code,
        },
      },
    })) as { data: GetLineItemsQuery };

    const lineItems = response.data;

    updateStore(state => {
      state.lineItems = lineItems;
      state.loadingLineItems = false;
    });

  } catch (error) {
    console.error('Error fetching line items', error);
    updateStore(state => {
      state.loadingLineItems = false;
    });

    console.error('Error fetching line items', error);
  }
};

export default loadLineItems;
