/**********************************************************************
 * * UTIL - FORMAT LABEL
 * --------------------------------------------------------------------
 * This utility function will format a label according to the specified
 * format option. It will default to the 'TitleCase' format if no format
 * option is specified by the config file, or calling function.
 * ******************************************************************/

import { labelFormat } from '../elo.config';
import { LabelFormat as LabelFormatOptions } from '../types/config.types';

export const formatLabel = (label: string, formatOption: LabelFormatOptions = labelFormat): string => {
  
  // Split the string into words
  const words = label.split(' ');

  switch (formatOption) {
    case 'AllLowerCase':
      return label; // already in all lower case
    case 'AllUpperCase':
      return label.toUpperCase();
    case 'TitleCase':
      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    case 'SentenceCase':
      return words
        .map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
        .join(' ');
    default:
      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
};

export default formatLabel;
