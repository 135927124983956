import React from 'react';
import styled from 'styled-components';
import { ContentfulAssessment } from 'src/types/ingredients';
import { Box, Typography } from 'ui';

interface AssessmentTagProps {
  assessments: ContentfulAssessment[];
}

const AssessmentTag = ({ assessments }: AssessmentTagProps) => {
  if (!assessments) return null;

  return (
    <>
      <Box mt={['1.2rem']} display={'flex'} justifyContent={'flex-start'} sx={{ gap: '.47rem' }}>
        {assessments.map(assessment => {
          return (
            <>
              <TagWrapper tagColor={assessment?.tagColor} key={assessment.contentful_id}>
                <Typography variant="tiny1" sx={{whiteSpace:'nowrap'}}>{assessment.name}</Typography>
              </TagWrapper>             
            </>
          );
        })}
      </Box>
    </>
  );
};

export default AssessmentTag;

// Define an interface for the component's props
interface TagWrapperProps {
  tagColor?: string; // Optional string prop for the background color
}

const TagWrapper = styled.div<TagWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center; // Ensure content is centered
  align-content: center; // Ensure content is centered
  text-align: center; // Ensure text is centered
  background-color: ${props => props.tagColor || '#efefef'}; // Use tagColor prop or fallback color
  padding: 4px 20px; // Horizontal padding larger than vertical padding
  border-radius: 9999px; // Very large border-radius to create a pill shape
`;
