import React, { useEffect } from 'react';
import Typography from 'src/features/ui/typography/Typography';
import { Link } from 'gatsby';
import { Box, Container, BareNav, Header } from 'ui';
// Use this to update the status
import { updateStatus } from 'src/features/archive/quiz/utils/updateStatus';

const StatusFailed = ({ data, store, responseId, formId }) => {
  /**
   * REPORT ERROR TO CUSTOMER.IO
   * If this component loads then there was an error.
   * Send an error message to customer.io
   * Message: "Error generating or displaying the formula for a user"
   * Minimum Stuff to include
   * - anonymousId
   * - formId
   * - responseId
   * - errorAt timestamp
   * - error true boolean
   * - error message
   */

  const handleError = async () => {
    try {
      const response = await updateStatus({
        anonymousId: store?.cart?.owner ?? '',
        formId: formId ?? 'undefined',
        responseId: responseId ?? 'undefined',
        error: true,
        errorAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        errorMessages: ['Error generating or displaying the formula for a user'],
      });

      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    handleError();
  }, [handleError]);

  return (
    <>
      {data?.header ? <Header {...data.header} pushdownBanner={undefined} /> : <BareNav />}

      <Container m={'80px 20px'}>
        <Box p={['20px', '40px', '80px', '100px']}>
          <Typography variant={'h2'}>
            Something’s gone wrong but our support team will follow up.
          </Typography>
          <Typography variant={'body2'}>
            We couldn’t load your Smart Recovery formula, but don’t worry – we’ve received your data
            and our customer support team will reach out with next steps.
          </Typography>
          <Box height={['1rem']}> </Box>          
          <Typography variant={'body2'}>
            If you have questions in the meantime, please{' '}            
            <Link to="mailto:help@luminaryvitamins.com">
              email us at help@luminaryvitamins.com
            </Link>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default StatusFailed;
