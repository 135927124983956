import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

const AvatarFemale = ({
  stroke = '#333333',
  strokeWidth = '1.25',
  width = '42',
  height = '46',
  viewBox = '0 0 42 46',
}: SVGIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1148 6.51123C25.1148 6.51123 24.6861 15.0543 31.6747 15.0543C31.6747 15.0543 34.1443 33.9122 21.0503 34.4223C21.0503 34.4223 11.1719 34.9975 10.7688 18.876C10.5802 12.8188 15.6137 12.9765 17.646 12.8559C20.2014 12.7075 25.0891 12.0675 25.1148 6.50195V6.51123Z"     
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M40.7397 44.7091C37.9185 40.0712 34.6514 38.5592 30.6726 38.2902C26.6938 38.0212 26.2822 33.1514 26.2822 33.1514"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M1.10547 37.5668C1.10547 37.5668 8.33421 36.7042 7.31379 24.4415C6.46486 14.238 6.00181 9.80412 9.37179 5.98245C13.4878 1.32596 25.4328 -1.96697 33.7506 6.03811C38.544 10.6482 36.0315 17.3918 35.4999 22.9944C34.6167 32.3352 36.2888 34.9232 40.885 38.0121"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M15.7688 32.8174C15.7688 32.8174 15.2028 37.9377 11.4298 38.3365C7.65679 38.7354 3.64368 40.0711 1.10547 44.7091"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M31.4865 38.3643C29.7372 40.3771 25.6469 41.7871 20.8792 41.7871C16.1115 41.7871 12.1584 40.4235 10.3662 38.4663"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
  </svg>
);

export default AvatarFemale;
