/* eslint react/display-name: "off" */

import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Anchor } from 'antd';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import { anchorId } from '../titlewithanchor/TitleWithAnchor';
import { Icon } from 'ui';
import { NopRichTextRenderOptions } from '../../../utils/render';
import styled from 'styled-components';

interface TableOfContentsProps {
  content: any;
}

const TocContainer = styled.div`
  background-color: rgb(248, 244, 240);
  margin: 40px 0;
  padding-bottom: 20px;
`;
const TocHeader = styled.p`
    margin 10px;
    font-weight: 900;
`;
const ContentLink = styled.a`
  display: block;
  margin-left: 10px;
  padding: 2px 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: initial;
  display: flex;
  align-items: center;
`;

const TableOfContents = ({ content }: TableOfContentsProps): JSX.Element => {
  const { body, takeaways, interview, references } = content;
  const H2 = '"heading-2"';
  const hasToc =
    body?.raw?.includes(H2) ||
    takeaways?.raw?.includes(H2) ||
    interview ||
    references?.raw?.includes(H2);

  return (
    <>
      {hasToc && (
        <TocContainer>
          <Anchor affix={false}>
            <TocHeader>Contents</TocHeader>
            {renderRichText(body, renderOptions)}
            {takeaways && renderRichText(takeaways, renderOptions)}
            {interview && <Link title={interview.headline} />}
            {references && renderRichText(references, renderOptions)}
          </Anchor>
        </TocContainer>
      )}
    </>
  );
};

type LinkProps = {
  title: any;
};
const Link = ({ title }: LinkProps): JSX.Element => {
  // Ant Design Link doesn't support adding a icon to the link so we create it manually
  // Native way: <Link href={'#' + anchorId(title)} title={title} />
  const titleString = ReactDOMServer.renderToString(title);
  return (
    <ContentLink href={'#' + anchorId(titleString)}>
      <Icon id="arrow-right-black" />
      &nbsp;&nbsp;{title}
    </ContentLink>
  );
};

const renderOptions = {
  renderMark: NopRichTextRenderOptions.renderMark,
  renderNode: {
    ...NopRichTextRenderOptions.renderNode,
    [BLOCKS.HEADING_2]: (_, children) => <Link title={children} />,
  },
};

export default TableOfContents;
