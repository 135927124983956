import React from 'react';
import { Box, Button, Stack, Typography, Divider } from '@mui/material';

interface ToPrenatalProps {
  cancel: (value: boolean) => void;
}

const ToPreconception = ({ cancel }: ToPrenatalProps) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #AAA',
    boxShadow: 24,
    pt: 1,
    px: 2,
    pb: 2,
    borderRadius: 2,
  };

  const handleCancel = () => {
    cancel(false);
  };

  return (
    <Box
      width={['94vw', '420px', '480px']}
      minHeight={['440px']}
      maxHeight={['94vh']}
      sx={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        // minHeight: '400px',
        overflow: 'auto', // This ensures that the content area can scroll if needed
      }}
    >
      {/* Content to fill the middle with potential for scrolling */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        mt={2}
        mb={1}
        sx={{
          flexGrow: 1,
        }}
        justifyContent={'space-between'}
      >
        <Stack spacing={2}>
          <Typography variant="h4" textAlign={'center'}>
            We are here to help
          </Typography>
          <Divider />
          <Box p={['.47rem']}>
            <Typography variant="body2" gutterBottom pb={['.47rem']}>
              We genuinely care about you and want to be here to support you during this time. If
              there's anything we can do to assist or if you'd like to discuss any other concerns,
              please know that we are here to help.
            </Typography>
            <Typography variant="body2" gutterBottom>
              We would understand if you want to make changes to your subscription at this time.
              Please reach out to our Customer Care team at <a href="mailto:help@luminaryvitamins.com">help@luminaryvitamins.com</a> for any
              modifications you'd like to make.
            </Typography>
          </Box>
        </Stack>

        <Box position={'static'} sx={{ background: 'white' }} my={'.47rem'} pt={['1.47rem']}>
          <Stack spacing={1}>
            <Button variant="contained" onClick={handleCancel}>
              Close
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ToPreconception;
