import { Immutable } from 'immer';
// import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import * as mutations from '../../graphql/mutations';
import {
  SendQualificationAnswersMutationVariables,
  StartCheckoutMutationVariables, TargetingInput,
} from '../../graphql/elomapi';

import { State } from './EloStore.Provider';
import { CartActionEvent } from '../../utils/analytics';
import { Product } from '../product/Product.types';
import { LineItem, QualificationForm, UTMData } from './EloStore.types';
import isEmpty from "lodash/isEmpty";

/********************************************************************************
 * IS AUTHENTICATED
 * ------------------------------------------------------------------------------
 * Import the isAuthenticated function from the ELO package to determine the
 * authentication mode that is necessary to use. The isAuthenticated function
 * simplifies the process of determining the authentication mode by checking the
 * state of the user.
 * *****************************************************************************/
import { isAuthenticated } from 'src/elo';


export const toCartEventData = (
  productSlug: string,
  subscriptionSlug: string,
  variantSlugs: readonly string[] = [],
  store: State
): CartActionEvent => {
  const product = store.products[productSlug];
  const subscription = product.subscriptionModels[subscriptionSlug];
  const pricing = subscription.pricing.length > 0 ? subscription.pricing[0] : null;

  return {
    items: [
      {
        item_name: product.title,
        item_id: product.stripeProductId ?? 'N/A',
        quantity: 1,
        item_variant: variantSlugs.join(','),
      },
    ],
    currency: pricing ? (pricing.currency === '$' ? 'USD' : pricing.currency) : 'USD',
    value: pricing ? pricing.price : 0,
  };
};

export const toSendQualificationAnswersMutation = (
  customerId: string | null,
  anonymousId: string | null,
  qualificationAnswers: QualificationForm,
  qualificationQuestions: object
): GraphQLOptions => {
  const variables: SendQualificationAnswersMutationVariables = {
    input: {
      customerId: customerId ?? undefined,
      anonymousId: anonymousId ?? undefined,
      email: qualificationAnswers.email,
      channel: qualificationAnswers.referrer,
      primary_goal: qualificationAnswers.primaryGoal,
      isOver18: qualificationAnswers.over18,
      phoneType: qualificationAnswers.phoneType,
      state: qualificationAnswers.state,
      metadata: JSON.stringify(qualificationQuestions),
    },
  };

  // conditional auth mode
  const authMode = isAuthenticated() ? "userPool" : "iam" as 'userPool' | 'iam';

  return {
    query: mutations.sendQualificationAnswers,
    authMode: authMode,
    variables,
  };
};

export const toStartCheckoutMutation = (
  customerId: string | null,
  customerEmail: string | null,
  anonymousId: string | null,
  lineItems: Immutable<{ [productSlug: string]: LineItem }>,
  availableProducts: Immutable<{ [slug: string]: Product }>,
  metadata: object,
  relativeCancelUrlPath?: string,
  preliminaryPlanId?: string,
  utmData?: UTMData,
): GraphQLOptions => {

  let promotionCode = '';

  const stripeLineItems = Object.values(lineItems).map((item) => {
    const product = availableProducts[item.productSlug];
    promotionCode = product.coupon?.stripeCouponId;
    return {
      productId: product?.stripeProductId,
      priceId: product?.subscriptionModels[item.subscriptionSlug].stripePriceId ?? null,
      quantity: 1,
    };
  });


  const cancelUrl = new URL(relativeCancelUrlPath ?? '/', window.location.origin);

  const targetingInput: TargetingInput|undefined = !isEmpty(utmData) ? {
    utmCampaign: utmData?.utm_campaign,
    utmSource: utmData?.utm_source,
    utmMedium: utmData?.utm_medium,
    utmContent: utmData?.utm_content,
    utmTerm: utmData?.utm_term
  } : undefined;

  const variables: StartCheckoutMutationVariables = {
    input: {
      lineItems: stripeLineItems,
      customerEmail,
      anonymousId,
      preliminaryPlanId,
      referenceId: undefined, // we don't really have this
      cancelUrl: cancelUrl.href,
      promotionCode,
      targeting: targetingInput,
      metaData: JSON.stringify(metadata)
    },
  };

  history.pushState({}, "", `${cancelUrl.pathname}${cancelUrl.search}`);

  // conditional auth mode
  const authMode = isAuthenticated() ? "userPool" : "iam" as 'userPool' | 'iam';

  return {
    query: mutations.startCheckout,
    authMode: authMode,
    variables,
  };
};
