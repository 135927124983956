import * as React from 'react';
import styled from 'styled-components';
import { SectionProps } from 'types';
import { Icon } from 'ui';

import SectionContainer from '../../SectionContainer';
import { applyRules } from '../../../../utils/headline-parser';
import { IconId } from 'src/features/ui/icon/Icon';

const BigHeadline = styled.h1`
  font-size: 55px;
  line-height: 55px;
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 130px;
    line-height: 143px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 130px;
    line-height: 143px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    font-size: 200px;
    line-height: 220px;
  }
`;

const IconsContainer = styled.div`
  display: grid;
  grid-template-columns: 17% 66% 17%;
  ${({ theme }) => theme.breakpoints.tablet} {
    display: flex;
    flex-direction: row;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: space-between;
  margin: 20px 0;
  height: 100px;
  ${({ theme }) => theme.breakpoints.tablet} {
    height: 130px;
    padding: 20px 10px;
    border-left: thin solid ${({ theme }) => theme.colors.black};
    :last-child {
      border-right: thin solid ${({ theme }) => theme.colors.black};
    }
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    height: 250px;
    padding: 65px 0;
  }
`;

const StyledText = styled.div`
  font-family: 'Everett Medium';
  font-size: 19px;
  margin-top: 20px;
  white-space: nowrap;
`;

const iconsMeta: [IconId, string][] = [
  ['energy', 'Energy'],
  ['performance', 'Performance'],
  ['focus', 'Focus'],
  ['aging', 'Aging'],
  ['immunity', 'Immunity'],
  ['sleep', 'Sleep'],
];

export default function PageSectionSmartSupplements({ subHeading, slug }: SectionProps) {
  return (
    <SectionContainer id={slug} usePadding contentBackgroundColor={'white'}>
      <BigHeadline>{applyRules(subHeading)}</BigHeadline>
      <IconsContainer>
        {iconsMeta.map(([iconId, label], i) => (
          <IconContainer key={`icon-${i}`}>
            <Icon id={iconId} />
            <StyledText>{label}</StyledText>
          </IconContainer>
        ))}
      </IconsContainer>
    </SectionContainer>
  );
}
