import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import {
  SectionContainer,
  SectionContainer2,
  HeadlineContainer,
  Image,
  Container,
  Box,
  Typography,
} from 'ui';
import { SectionProps } from 'types';

const PageSectionHowItWorks = ({
  headline,
  subHeading,
  body,
  slug,
  blocks,
  designNodes,
  sectionType,
}: SectionProps) => {
  const theme = useTheme();
  return (
    <SectionContainer2
      designNodes={designNodes}
      sectionType={sectionType}
      backgroundColor={theme.colors.eloGreyLight}
    >
      <Container>
        <Grid>
          <Typography variant="h2" component="h2">
            {headline}
          </Typography>

          {blocks &&
            blocks.map((block, i) => (
              // <Step key={`step-${i}`}>
              <Box
                key={`step-${i}`}
                p={['20px', '20px', '20px', '30px']}
                sx={{ background: 'white' }}
              >
                <Box>
                  <Typography variant="h3" component="div" fontColor={["#F69B32"]}>
                    0{i + 1}
                  </Typography>
                </Box>
                {block?.headline && (
                  <Box pt={'8px'}>
                    {/* <Typography variant="body1">{block.headline}</Typography> */}
                    <Typography variant="body1">{block.headline}</Typography>
                  </Box>
                )}
                {block?.subHeading && (
                  <Box pt={'6px'}>
                    <Typography variant="body2">{block.subHeading}</Typography>
                  </Box>
                )}
                {/* <ImageContainer>
                    <Image
                    gatsbyImageData={block?.thumbnail?.gatsbyImageData}
                    alt=""
                    loading="lazy"
                    />
                  </ImageContainer>                 */}
              </Box>
            ))}
        </Grid>
      </Container>
    </SectionContainer2>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 48px;
  }
`;

const Step = styled.div`
  background: white;
  border-radius: 3px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  flex-basis: 100%;
  padding-top: 29px;

  ${({ theme }) => theme.breakpoints.small} {
    flex-basis: calc(33.333% - 50px);
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  margin-top: auto;
  display: flex;
  padding: 36px 21px;
  .gatsby-image-wrapper {
    margin: 0 auto;
    > div {
      margin: 0 auto;
    }
  }
`;

export default PageSectionHowItWorks;
