import React from 'react';
import { Product as ProductType } from 'src/types/product';
import { Box } from 'ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useAssessmentStore } from 'src/features/assessment-summary/store';

/***************************************************
 * FORMULA IMAGE
 * -------------------------------------------------
 * Introduced a new image type called formulaImage.
 * This image deterministically sets the image that should
 * be used as the feature image for the preliminary formula
 * on the assessment results page where the user purchases.
 ***************************************************/

const ProductImage = () => {  
  const storeProduct = useAssessmentStore(state => state.storeProduct);
  const image = storeProduct?.formulaImage?.desktop?.gatsbyImageData;

  if (!image) return null;

  return (
    <>
      <Box        
        display={'flex'}
        flexDirection={'column'}        
        width={'100%'}        
      >
        <Box 
        width={['80%','80%','50%', '40%']} 
        mt={['-50px']}
        >
          <GatsbyImage
            image={image}
            alt={'Gummy Image'}
            objectFit="contain"            
          />
        </Box>
      </Box>
    </>
  );
};

export default ProductImage;
