import React from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { useAccountStore } from '../../../../../store';

const Footer = ({ forgotPassword }: any) => {
  const updateAccountState = useAccountStore(state => state.updateAccountState);

  const handleCloseLoginModal = () => {
    updateAccountState(state => {
      state.showLoginModal = false;
    });
  };
  return (
    <Stack mb={['.47rem']}>
      <Button onClick={forgotPassword}>
        <Typography variant="body2" color="text.primary">
          Forgot Password
        </Typography>
      </Button>
      <Button onClick={handleCloseLoginModal}>
        <Typography variant="body2" color="text.primary">
          Cancel
        </Typography>
      </Button>
    </Stack>
  );
};

export default Footer;
