import head from 'lodash/head';
import get from 'lodash/get';
import { Promotion } from '../store/EloStore.types';
import { Coupon, Pricing, Product, SubscriptionModel } from './Product.types';
import { Immutable } from 'immer';
import { type } from 'os';

// We add the minus character elsewhere if needed
export const displayPrice = (price: number) => {
  return ((price < 0 ? -1 * price : price) / 100).toFixed(2).toString().replace('.00', '');
};
const typeConfig: any = {
  month: 'mo',
};
const mapType = (type: string): string => typeConfig[type] || type;
const currencySymbol = (subscription: Pricing) =>
  `${subscription.price < 0 ? '-' : ''}${subscription.currency || '$'}`;

export const displayRecurringPrice = (subscription?: Pricing, showShortType = true) => {
  if (!subscription) return '';
  const currency = currencySymbol(subscription);
  if (subscription.recurrence) {
    return `${currency}${displayPrice(subscription.price || 0)}/${
      showShortType ? mapType(subscription.recurrence || '') : subscription.recurrence
    }`;
  } else {
    return `${currency}${displayPrice(subscription.price || 0)}`;
  }
};

export const applyDiscount = (
  product: Product,
  availablePromotions: Immutable<Promotion[]>
): Product & {
  discountApplied: null | Immutable<Promotion>;
} => {
  const promotion = availablePromotions.find(
    p => p.coupon?.appliesTo && p.coupon?.appliesTo.indexOf(product.stripeProductId) >= 0
  );

  if (promotion && promotion.coupon) {
    const discountedSubscriptions: { [key: string]: SubscriptionModel } = Object.values(
      product.subscriptionModels
    ).reduce((acc, sm) => {
      const pricing: Pricing[] = sm.pricing.map(p => {
        if (!p.price || p.price === 0) return p;
        if (promotion!.coupon!.amountOff) {
          return {
            ...p,
            discountedPrice: p.price - promotion!.coupon!.amountOff,
            price: p.price,
          };
        } else if (promotion!.coupon!.percentOff) {
          return {
            ...p,
            discountedPrice: p.price * ((100 - promotion!.coupon!.percentOff) / 100),
            price: p.price,
          };
        }

        return p;
      });

      let ret = { ...acc };
      ret[sm.slug] = { ...sm, pricing };
      return ret;
    }, {} as { [key: string]: SubscriptionModel });

    return {
      ...product,
      subscriptionModels: discountedSubscriptions,
      discountApplied: promotion,
    };
  }

  return {
    ...product,
    discountApplied: null,
  };
};

export const getSubscription = (
  product?: Product,
  priceId?: string
): SubscriptionModel | undefined => {
  if (!product || !product.subscriptionModels) return undefined;  

  // DEPRECATED METHOD FOR FINDING SUBSCRIPTION MODEL
  // if (Array.isArray(product.subscriptionModels)) {
  // This was the legacy method to find the correct subscription model
  // return product.subscriptionModels.find((s) => s.slug === priceId);
  // }

  /*********************************************************
   * NEW METHOD FOR FINDING SUBSCRIPTION MODEL
   * -------------------------------------------------------
   * product.subscriptionModels is an object with keys not an
   * array so we need to find the correct subscription model
   * by the priceId which is the key of the object
   *********************************************************/
  if (priceId) return product.subscriptionModels[priceId];

  // If no price ID is found then we just return the first subscription model
  return head(Object.values(product.subscriptionModels));
};

export const getPricing = (subscription?: SubscriptionModel): Pricing | undefined => {
  if (!subscription) return undefined;
  return subscription.pricing.find(p => p.primary);
};

export const getCartPrice = (subscription?: SubscriptionModel): number => {
  const totalCost = (subscription?.pricing || []).reduce((val, p) => {
    return (p.frontPayment ? p.price : 0) + val;
  }, 0);
  return totalCost;
};
export const getDiscountedPrice = (totalPrice: number, product?: Product): number | undefined => {
  const percentOff = product?.coupon?.type === 'Percentage off';
  const amountOff = product?.coupon?.type === 'Amount off';

  let price = totalPrice;
  if (percentOff) {
    price = -1 * totalPrice * ((100 - product?.coupon?.discount) / 100);
  }
  if (amountOff) {
    price = totalPrice - product?.coupon?.discount * 100;
  }
  if (!percentOff && !amountOff) {
    return undefined;
  }
  return price;
};


/**************************************************************
 * GET PRODUCT WITH SUBSCRIPTION
 * ------------------------------------------------------------
 * function: getProductWithSubscription
 *
 * This function finds the product in the store that contains
 * the subscription model matching the priceId from the lineItem.
 * The function returns a new object that contains the product and
 * subscription model.
 **************************************************************/

interface Products {
  [slug: string]: Product;
}

export const getProductWithSubscription = (products: Products, priceId: string | undefined) => {
  // Return null if there is no priceId
  if (!priceId) return null;

  

  // Find the subscription model in the product where the priceId matches

  for (const [_, value] of Object.entries(products)) {    

    // Value is an object that contains subscription models
    for (const [_, sm] of Object.entries(value.subscriptionModels)) {
      

      if (sm.stripePriceId === priceId) {
        
        return {
          ...value,
          subscriptionModel: sm,
        };
      }
    }
  }

  // Return null if no product is found
  return null;
};
