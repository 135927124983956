/******************************************************************************************
 * * APP: ACCOUNT
 * ---------------------------------------------------------------------------------------
 * The account app is an entire app that can be imported as a component and added to your
 * project. It includes all of the necessary UI and logic to handle user accounts,
 * including address updates, subscription management, and more.
 *
 * While it's possible to build your own account management system, using the Elo Library
 * components, this is a complete solution that can be added to your project with minimal
 * effort.
 * ****************************************************************************************/

import React, { useState } from 'react';
import TabPanel from './components/TabPanel';
import Login from './components/Login';
import { GetStarted } from './components';
import useAccountStore from '../../store';
import { formatDate } from '../../utils';
import { Typography, Divider, Tabs, Tab, Box, Stack } from '@mui/material';

/********************************************************************************
 * * IMPORT TAB PANELS
 * ----------------------------------------------------------------------------
 * Import the tab panels that will be rendered when the tab is active.
 * *****************************************************************************/
import { Account as AccountPanl, Subscriptions } from './panels';

/********************************************************************************
 * * TABS
 * ----------------------------------------------------------------------------
 * The account page is organized into tabs. Each tab has a label and a component
 * that is rendered when the tab is active. To add a new tab, add a new object to
 * the tabData array and reference the component you want to render.
 * *****************************************************************************/
const tabData = [
  { id: 'subscriptions', label: 'Subscriptions', component: Subscriptions },
  { id: 'account', label: 'Account', component: AccountPanl },
];

const Account = () => {
  const [value, setValue] = useState(0);

  const userAccount = useAccountStore(state => state.userAccount);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // No Active Subscriptions?
  const noActiveSubscriptions =
    !userAccount?.subscriptions?.items || userAccount.subscriptions.items.length < 1;
  // Account Scheduled for Deletion?
  const scheduledForDeletion = userAccount?.deleteScheduledAfter;
  
  /********************************************************************************
   * * NO USER ACCOUNT
   * ----------------------------------------------------------------------------
   * If there is no user account, render the login component. This will prompt the
   * user to log in or create an account.
   * *****************************************************************************/
  if (!userAccount) return <Login />;

  return (
    <Box>
      {
        /********************************************************************************
         * * ACCOUNT SCHEDULED FOR DELETION
         * ----------------------------------------------------------------------------
         * If the user account is scheduled for deletion, display a message to the user.
         * *****************************************************************************/

        scheduledForDeletion && (
          <>
            <Box border={'1px solid pink'} p={'1.47rem'} mb="1.47rem" borderRadius={2}>
              <Stack spacing={2}>
                <Typography variant="h5" gutterBottom>
                  Your account is scheduled for deletion on{' '}
                  {formatDate(userAccount.deleteScheduledAfter, 'MMM dd, yyyy')}
                </Typography>
                {/* <Typography variant="body2">
              Your account has been scheduled for deletion on{' '}
              {formatDate(userAccount.deleteScheduledAfter, 'MMM dd, yyyy')}.
            </Typography> */}
                <Typography variant="body2">
                  To cancel this action, please contact customer care at{' '}
                  <a href="mailto:help@luminaryvitamins.com">help@luminaryvitamins.com</a>.
                </Typography>
              </Stack>
            </Box>
            <Divider />
          </>
        )
      }

      {
        /********************************************************************************
         * * NO ACTIVE SUBSCRIPTIONS
         * ----------------------------------------------------------------------------
         * If there are no active subscriptions, prompt the user to create a subscription.
         * *****************************************************************************/
        noActiveSubscriptions && !scheduledForDeletion && <GetStarted />
      }

      {/* Navigation Tabs */}
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabData.map((tab, index) => (
            <Tab key={tab.id} label={tab.label} />
          ))}
        </Tabs>
      </Box>

      {/* Tab Content */}
      <Box mt={['1.47rem']} mb={['48px']}>
        {tabData.map((tab, index) => (
          <TabPanel key={tab.id} value={value} index={index}>
            <tab.component />
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default Account;
