/******************************************************************************************
 * IS AUTHENTICATED
 * ----------------------------------------------------------------------------------------
 * Description: This function checks if the user is authenticated. It uses the account
 * store to determine if the user is authenticated. If the user is authenticated, it will
 * return true. If the user is not authenticated, it will return false.
 * ****************************************************************************************/
import { useAccountStore } from '../store';

export const isAuthenticated = () => {

      const authenticated = useAccountStore.getState().authenticated;    
      return authenticated.valueOf() === true ? true : false;
}
export default isAuthenticated;