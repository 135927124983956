// AMPLIFY V5 to V6 UPGRADE
// import { API } from 'aws-amplify';
// import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';
// import { GetCheckoutQuery, GetCheckoutQueryVariables } from '../../../../graphql/elomapi';
import { GetCheckoutQuery, GetCheckoutQueryVariables } from 'src/API';
// import { getCheckout } from '../../../../graphql/queries';
import { getCheckout } from 'src/graphql/queries';

/********************************************************************************
 * IS AUTHENTICATED
 * ------------------------------------------------------------------------------
 * Import the isAuthenticated function from the ELO package to determine the
 * authentication mode that is necessary to use. The isAuthenticated function
 * simplifies the process of determining the authentication mode by checking the
 * state of the user.
 * *****************************************************************************/
import { isAuthenticated } from 'src/elo';

export type Address = {
  name: string;
  street: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  company?: any;
};

export type LineItem = {
  title: string;
  id: string;
  priceId?: string;
  sku?: any;
  type?: string;
  quantity: number;
  imageUrl?: string;
  description: string;
  unitPrice: number;
  currency: string;
  shipping?: any;
  trialUnitPrice?: any;
};

export type CheckoutData = {
  appStoreDownloadUrl: string;
  expiresAt: any;
  orderId: string;
  email: string;
  name: string;
  shippingAddress: Address;
  billingAddress: Address;
  paymentMethodType: string;
  paymentMethodDescription: string;
  variants?: any;
  totalPrice: {
    total: number;
    currency: string;
    discount: number;
    tax: number;
    shipping: number;
  };
  lineItems: LineItem[];
};

const buildQuery = (): GraphQLOptions => {
  const sessionId = location.search.replace('?session_id=', '');

  const variables = {
    sessionId,
  } as GetCheckoutQueryVariables;

  // Set the auth mode
  const authMode = isAuthenticated() ? 'userPool' : ('iam' as 'userPool' | 'iam');

  return {
    query: getCheckout,
    authMode: authMode,
    variables,
  };
};

export const getCheckoutData = async (): Promise<CheckoutData> => {
  // * ESTABLE REPEAT FUNCTION DELAY
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  try {
    const query = buildQuery();

    let lastResult: GraphQLResult<GetCheckoutQuery> | undefined = undefined; // Declare variable to hold the last result

    /**********************************************************************
     * ATTEMPT TO RETRIEVE CHECKOUT DATA
     * ---------------------------------------------------------------------
     * Try to retrieve the checkout data 5 times. If the checkout data is
     * not ready after 5 attempts, return the last result. Verify that the
     * checkout data is ready by checking if the shipping address has a name.
     *
     * At some point when the API supports it we can add a check for a more
     * specific and reliable indicator that the checkout data is ready.
     * ********************************************************************/
    for (let attempt = 1; attempt <= 5; attempt++) {
      const result = (await client.graphql(query)) as GraphQLResult<GetCheckoutQuery>;
      lastResult = result; // Update lastResult with the current attempt's result
      const isReady = result?.data?.getCheckout?.shippingAddress?.name;
      if (isReady) {
        return result.data.getCheckout as CheckoutData;
      } else {
        await delay(3000);
      }
    }

    /**********************************************************************
     * RETURN LAST RESULT
     * ---------------------------------------------------------------------
     * If the code reaches this point, it means none of the attempts were
     * successful in meeting the condition. Thus, return the last attempt's
     * result.
     * ********************************************************************/
    if (lastResult) {
      return lastResult.data.getCheckout as CheckoutData;
    } else {
      throw new Error('Failed to retrieve checkout data');
    }
  } catch (error) {
    throw error;
  }
};
