/**************************************************************************************
 * FUTURE SELF and FUTURE DEVELOPERS
 * ------------------------------------------------------------------------------------
 * <QuizContext /> component is critical to the performance of the quiz page
 * As a child component, it is responsible for updating the mode, owner and utmData
 * that is being used by the parent component. This is offloaded to the child component
 * because the child component is re-rendered every time the context is updated. This
 * prevents the parent component from re-rendering every time the context is updated.
 *
 * NOTE: onReady has been removed from the Widget component. This is because the onReady
 * callback was sending the owner data to anayltics. The owner data is not available until
 * the context is updated. Context will not be updated prior to the widget loading.
 **************************************************************************************/

import React, { useContext, useEffect, MutableRefObject } from 'react';
import { Context } from 'src/features/store/EloStore.Provider';

interface IProps {
  modeRef: MutableRefObject<string | null>;
  ownerRef: MutableRefObject<string | null>;
  utmDataRef: MutableRefObject<any>;
}

const AssessmentContext: React.FC<IProps> = ({ modeRef, ownerRef, utmDataRef }) => {
  const {
    store: {
      mode,
      cart: { owner },
      analytics: { utmData },
    },
  } = useContext(Context);

  useEffect(() => {
    modeRef.current = mode;
    ownerRef.current = owner;
    utmDataRef.current = utmData;

    // Uncomment Below to debug
    // console.log('=========================================');
    // console.log('QUIZ CONTEXT');
    // console.log('modeRef.current', modeRef.current);
    // console.log('ownerRef.current', ownerRef.current);
    // console.log('-----------------------------------------');
  }, [mode, owner, utmData]);

  return null;
};

export default AssessmentContext;
