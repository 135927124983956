import * as React from 'react';
import styled from 'styled-components';

type Props = {
  gradient?: string;
  children?: React.ReactNode;
};
export default function GradientText({ gradient, children }: Props): JSX.Element {
  return (
    <Gradient className="elo__colorful_text" gradient={gradient}>
      {children}
    </Gradient>
  );
}

interface GradientProps {
  gradient?: string;
}

const Gradient = styled.span<GradientProps>`
  background-image: ${({ gradient = 'to right, #EC681D, #EC681D' }) => `linear-gradient(${gradient})`};
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-style: italic;
`;