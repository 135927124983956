import React, { useEffect, useState, useRef } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Container, Box, Typography, Button, SectionContainer2 } from 'ui';
import { useEloStore } from 'src/features/store/EloStore.hooks';
import {
  Member,
  ProductImage,
  Layers,
  SubscriptionOptions,
  NutritionIcons,
  Building,
  ProductIngredients,
  StatusFailed,
  ModalSupplementFacts,
} from './components';
import { AllCategories } from 'src/types/categories';
import getData from '../../utils/getData';
import { get } from 'lodash';
import { PreliminaryPlan, PreliminaryPlanMember, Product } from 'src/API';
import { Product as ProductType } from 'src/types/product';
import posthog from 'posthog-js';
import { Button as MuiButton, Modal } from '@mui/material';

/*************************************************************************
 * Assessment Summary State Store
 * -----------------------------------------------------------------------
 * This file contains the state store for the Assessment Summary feature.
 * If you are curious why I implmented this state store, please see the
 * comment in the file src/features/assessment-summary/store/index.tsx
 *************************************************************************/
import { useAssessmentStore } from '../../store';

const LuminaryGummies = ({ designNodes, sectionType, video }: any) => {
  const ingredients: AllCategories = useStaticQuery(graphql`
    query FormulaPageQuery {
      allContentfulCategory {
        edges {
          node {
            ...categoryFragment
            ingredient {
              ...ingredientsFragment
            }
          }
        }
      }
    }
  `);

  const [product, setProduct] = useState<ProductType | null | undefined>(null);
  const [member, setMember] = useState<PreliminaryPlanMember | null>(null);
  const [formula, setFormula] = useState<PreliminaryPlan | null>(null);
  const [formulaLoaded, setFormulaLoaded] = useState(false);
  const [remainingLoadTime, setRemainingLoadTime] = useState(0);
  const [checkoutButtonVisible, setCheckoutButtonVisible] = useState<boolean>(false);
  const [noResult, setNoResult] = useState(false);
  const [failed, setFailed] = useState(false);
  const [formId, setFormId] = useState<string | null>(null);
  // const [minLoadTime, setMinLoadTime] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);

  const [openSupplementFacts, setOpenSupplementFacts] = useState(false);

  // Create a ref that indicates how many times the component has been rendered
  // This is used to determine if the viewed summary should fire to posthog or not.
  const renderCount = useRef(0);

  /*************************************************************************
   * LOAD GLOBAL cart and store CONTEXT
   * -----------------------------------------------------------------------
   * The cart and store are provided by the EloStore context.
   *************************************************************************/
  const { store, cart } = useEloStore();

  /*************************************************************************
   * LOAD ASSESSMENT SUMMARY CONTEXT VALUES AND FUNCTIONS
   * -----------------------------------------------------------------------
   * The assessment summary context is provided by the AssessmentSummary
   * context. This is a component level context store. Two of the key benefits
   * to this approach are:
   * 1. Removes the need to prop drill data to child components
   * 2. Simplifies managing the type definitions for the data
   *************************************************************************/
  // storeState contains the entire state of the component level context store
  // using this simplifies using the data in the compoment because it uses almost all of the values.
  const storeState = useAssessmentStore(state => state);
  // Using the generic updateState function because we are updating multiple values in the state store.
  const updateState = useAssessmentStore(state => state.updateState);
  // This function is pulled out specifically because it is used by a child component that needs access to this function.
  const updateStoreFormula = useAssessmentStore(state => state.updateStoreFormula);

  /*************************************************************************
   * GET QUERY STRING
   *************************************************************************/
  let searchParams: URLSearchParams | undefined;
  if (typeof window !== 'undefined') {
    searchParams = new URLSearchParams(window.location.search);
  }
  //  const productSlug = searchParams?.get('product') || null;
  const responseId = searchParams?.get('responseId') || '';
  //  const subscriptionSlug = searchParams?.get('sub') || null;

  /*************************************************************************
   * FUNCTION: loadCart
   * -----------------------------------------------------------------------
   * the loadCart function is used to load the cart with the correct product
   * by passing in the cart.addOrUpdateProduct function the product slug and
   * subscription slug.
   *
   * REFACTORED: This function has been refactored to use the useAssessmentStore
   * values in order to avoid prop drilling and simplify state management
   *************************************************************************/
  // const getProductData = async () => {
  const loadCart = async () => {
    await cart.addOrUpdateProduct(
      storeState.storeProductSlug as string, // product slug which must match contentful
      storeState.storeSubscriptionSlug as string, // subscription slug which must match contentful
      {}, // no variants provided
      `/cart/assessment-summary/?responseId=${responseId}` // redirect url with responseId
    );

    return;
  };

  /*************************************************************************
   * LOAD PRODUCT DATA INTO CART
   * -----------------------------------------------------------------------
   * This useEffect is used to load the product data into the cart.
   *************************************************************************/
  useEffect(
    () => {
      // Only load the cart if the product slug and subscription slug are set
      if (!storeState.storeProductSlug || !storeState.storeSubscriptionSlug) return;
      loadCart();
    },
    // rerun this useEffect if any of the following values change
    [selectedProduct, storeState.storeProductSlug, storeState.storeSubscriptionSlug]
  );

  /*************************************************************************
   * GET FORMULA DATA
   * -----------------------------------------------------------------------
   * This useEffect is used to load the formula data into the component state.
   * Formula is used to render the formula data in the UI.
   *************************************************************************/
  useEffect(() => {
    getData(
      responseId,
      formulaLoaded,
      setFormula,
      updateStoreFormula,
      setFormulaLoaded,
      setRemainingLoadTime,
      setNoResult,
      setFailed,
      0 // Load Time will either be the mlt value or the default
    );
  }, [responseId]);

  /*************************************************************************
   * LOAD COMPONENT DATA STORE
   * -----------------------------------------------------------------------
   * * storeMemberMember data
   * Data from the about the member that is returned by the formula API.
   *
   * * productSlug from the formula
   * Product slug, which is returned by the formula API.
   *
   * * storeProduct data
   * The 'store' is destructured from the EloStore context. The store
   * contains all of the product data in an object where the key is the
   * product slug.
   *
   * Using the lodash get function, we can isolate the product data
   * for the selected product based on the product slug.
   *
   * We then set the product data in component state store context
   *
   * * storeSubscriptionSlug
   * The subscription slug comes from the CMS. To get the subscription slug
   * we first need to know the product slug which is returned by the formula.
   *************************************************************************/
  useEffect(
    () => {
      const productData: ProductType | undefined = get(
        store.products,
        storeState.storeProductSlug as string,
        undefined
      ) as ProductType | undefined;

      updateState(state => {
        state.storeProductSlug = formula?.products?.[0]?.slug as string;
        state.storeMember = formula?.member || null;
        state.storeProduct = productData || null;
        state.storeSubscriptionSlug = Object.keys(
          storeState.storeProduct?.subscriptionModels ?? {}
        )[0];
        if (
          state.storeProductSlug &&
          state.storeMember &&
          state.storeProduct &&
          state.storeSubscriptionSlug
        ) {
          state.storeLoaded = true;
        }
      });
    },
    // rerun this useEffect if any of the following values change
    [
      formula?.products?.[0]?.slug,
      formula?.member,
      storeState.storeProductSlug,
      storeState.storeProduct,
      storeState.storeSubscriptionSlug,
    ]
  );


  /*************************************************************************
   * BUILDING / LOADING
   * -----------------------------------------------------------------------
   *
   *************************************************************************/
  
  // POSTHOG EVENT: Assessment summary loading
  // Capturing in a useeffect that only runds when the responseId changes
  useEffect(() => {
    posthog.capture('assessment summary loading', {});
  },[responseId])

  if (!formulaLoaded && !noResult) {    
    return (
      <SectionContainer2 id="elo-formula-building">
        <Container>
          <Box height={'100vh'}>
            <Building video={video} />
          </Box>
        </Container>
      </SectionContainer2>
    );
  }

  /*************************************************************************
   * FAILED TO LOAD
   * -----------------------------------------------------------------------
   * If the formula fails to load, display the failed component.
   *************************************************************************/
  if (noResult) {
    // POSTHOG EVENT: Assessment summary failed
    posthog.capture('assessment summary failed', {});

    return (
      <SectionContainer2 id="elo-formula-loaded-error">
        <StatusFailed data={product} store={store} responseId={responseId} formId={formId} />
      </SectionContainer2>
    );
  }

  /*************************************************************************
   * LOADING PRODUCT DATA PLACEHOLDER
   * -----------------------------------------------------------------------
   * After the formula is loaded, the product data takes some millisenconds
   * to load. This is a temporary placeholder until the product data is
   * loaded. It ensures that the UI does not jump around during the load.
   *************************************************************************/
  // if (!product)
  if (!storeState.storeProduct)
    return (
      <Box height={'100vh'}>
        <></>
      </Box>
    );

  // POSTHOG EVENT: Assessment summary loaded
  if (renderCount.current === 0) {
    posthog.capture('assessment summary viewed', {});
    renderCount.current++;
  }  

  return (
    <>
      {/*************************************************************************
       * PRODUCT IMAGE AND MEMBER SUMMARY
       * -----------------------------------------------------------------------
       *************************************************************************/}
      <SectionContainer2
        id={'elo-formula-loaded-success'}
        designNodes={designNodes}
        sectionType={sectionType}
        backgroundColor={'#F8F9F4'}
        paddingTop={'120px'}
      >
        <ModalSupplementFacts open={openSupplementFacts} onClose={setOpenSupplementFacts} />

        <Container>
          {/*************************************************************************
           * MEMBER IMAGE
           * -----------------------------------------------------------------------
           * The product image component has been refactored to use the
           * useAssessmentStore. It is no longer necessary to prop drill data.
           *************************************************************************/}
          <Box>
            <ProductImage />
          </Box>
          {/*************************************************************************
           * MEMBER SUMMARY
           * -----------------------------------------------------------------------
           * The Member component has been refactored to use the useAssessmentStore.
           * It is no longer necessary to prop drill member data.
           *************************************************************************/}
          <Box>
            <Member />
          </Box>
        </Container>
      </SectionContainer2>

      {/*************************************************************************
       * INGREDIENTS
       * -----------------------------------------------------------------------
       * The ProductIngredients component has been refactored to use the
       * useAssessmentStore. It is no longer necessary to prop drill data.
       *************************************************************************/}

      <SectionContainer2 backgroundColor={'#FFFFFF'}>
        <Container>
          <Box pb={['48px']}>
            <Box py={['26px']}>
              <Typography variant="h3" fontFamily={['Montserrat']} component="div" gutterBottom>
                {/* {member?.firstName ? `${member.firstName}'s ` : 'Your'}{' '} */}
                {storeState.storeMember?.firstName
                  ? `${storeState.storeMember.firstName}'s `
                  : 'Your'}{' '}
                {product?.ingredients?.ingredients?.length ?? ''} essential nutrients
              </Typography>

              <MuiButton onClick={() => setOpenSupplementFacts(true)} sx={{ padding: 'unset' }}>
                <Typography
                  variant="body1"
                  fontFamily={['Montserrat']}
                  component="div"
                  sx={{ color: '#EC8000' }}
                >
                  See Supplement Facts →
                </Typography>
              </MuiButton>
            </Box>

            <ProductIngredients />
          </Box>
        </Container>
      </SectionContainer2>

      {/*************************************************************************
       * NUTRIENT ICONS
       * -----------------------------------------------------------------------
       * This is a temp solution until we add grid and icon capabilities to the
       * contentful editor.
       *************************************************************************/}
      <SectionContainer2 backgroundColor={'#F8F9F4'}>
        <Container>
          <Box pt="2rem">
            <NutritionIcons />
          </Box>
        </Container>
      </SectionContainer2>

      {/*************************************************************************
       * SUBSCRIPTION OPTIONS and PURCHASE BUTTON
       * -----------------------------------------------------------------------
       *************************************************************************/}
      {/* <SectionContainer2 backgroundColor={'#FFFFFF'}>     
        <Container py={['1rem']}>
          <Box> */}
      {storeState.storeProduct && (
        <SubscriptionOptions
          cart={cart}
          product={storeState.storeProduct}
          member={member}
          id="checkout-button"
          responseId={responseId}
          setCheckoutButtonVisible={setCheckoutButtonVisible}
        />
      )}
      {/* </Box>
        </Container>
      </SectionContainer2> */}
    </>
  );
};

export default LuminaryGummies;
