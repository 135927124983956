import * as React from 'react';
import { Button } from 'ui';
import { SectionProps } from 'types';
import * as typeformEmbed from '@typeform/embed';

const PageSectionForm = ({ slug, headline, surveyForm, surveyTrigger }: SectionProps) => {
  function onSubmit() {    
  }

  function launchForm() {
    let survey: any = null;

    switch (surveyTrigger) {
      case 'Popup':
        survey = typeformEmbed.makePopup(surveyForm.typeForm, {
          autoClose: 2,
          hideHeaders: true,
          hideFooter: true,
          onSubmit: onSubmit,
        });
        break;
      case 'Slider':
        survey = typeformEmbed.makePopup(surveyForm.typeForm, {
          mode: 'drawer_right',
          autoClose: 2,
          hideHeaders: true,
          hideFooter: true,
          onSubmit: onSubmit,
        });
        break;
      case 'Popover':
      case 'Sidetab':
      case 'Standard':
      default:        
        break;
    }

    if (survey) {
      survey.open();
    }
  }

  return (
    <div className={'page-section-form ' + slug}>
      <Button onClick={launchForm} className={'form-launch-button'}>
        {headline}
      </Button>
    </div>
  );
};

export default PageSectionForm;
