import React, { useState, useEffect } from 'react';

import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { DefaultRichTextRenderOptions, BasicBlockRichTextRenderOptions } from 'src/utils/render';

import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Typography } from 'src/features/ui';
import { Ingredient } from 'src/types/ingredients';
import { motion } from 'framer-motion';
import useAccordian, {
  AccordianActionContainer,
  AccordianIndicator,
  AccordianContentContainer,
} from '../../../utils/useAccordianAnimation';
import AssessmentTag from './AssessmentTag';

import { Box } from 'ui';

interface iLayerProps {
  ingredientIndex: number;
  layerIndex: number;
  cmsIngredient: Ingredient;
  dose: any;
  assessments: object[];
}

const Layer = ({
  cmsIngredient,
  dose,
  ingredientIndex,
  layerIndex,
  assessments = [],
}: iLayerProps) => {
  /***************************************************************
   * INITIALIZE THE ACCORDIAN
   * -------------------------------------------------------------
   * The accordian hook will return a ref that needs to be applied
   * to the container element. The Accordian Hook requirs a
   * - open state
   * - hover state
   * - fuctions used to set the open and hover states
   ***************************************************************/
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const scope = useAccordian({
    isOpen,
    isHover,
    options: {
      actionHoverColor: 'rgba(239, 239, 239, 1)',
      // actionHoverColor: 'rgba(239, 239, 239, 0)',
    },
  });

  useEffect(() => {
    setIsOpen(ingredientIndex === 0 && layerIndex === 0);
  }, [layerIndex]);

  return (
    <>
      {/**************************************************************
       * ACCORDIAN PARENT CONTAINER WITH REF
       * -------------------------------------------------------------
       * The parent container is the element that will be animated. It
       * needs to be a parent of the action and content elements.
       * This can be any element as long as it has the accordian ref
       * applied to it.
       **************************************************************/}
      <AccordianContainer ref={scope}>
        {/***************************************************************
         * ACCORDIAN ACTION CONTAINER
         * -------------------------------------------------------------
         * The action area is the parent element that is clickable.
         * This will react to hover and click events.
         * Import the AccordianActionContainer component from the hook.
         * Pass it the open state, and functions needed to toggle the
         * open state and hover state.
         **************************************************************/}
        <AccordianActionContainer open={isOpen} toggleOpen={setIsOpen} toggleHover={setIsHover}>
          <ActionWrapper>            
            <Box width={['55px']} height={['55px']}>
              <GatsbyImage
                image={cmsIngredient.productImage?.mobile?.gatsbyImageData}
                alt={cmsIngredient.productImage?.name ?? 'Ingredient'}
              />
            </Box>
            <Box>
              <Typography variant="body1">{cmsIngredient?.label}</Typography>
              <Typography variant="tiny1">{cmsIngredient?.dailyValue ? `${cmsIngredient?.dailyValue}` : ``}</Typography>
              {dose && (
                <Typography variant="body3">
                  {dose.quantity.toLocaleString()} {dose.unit}
                </Typography>
              )}
            </Box>            
            <AccordianIndicator />
          </ActionWrapper>
        </AccordianActionContainer>

        {/***************************************************************
         * ACCORDIAN CONTENT
         *--------------------------------------------------------------
         * The content area is the child element that will be animated.
         **************************************************************/}
        <AccordianContentContainer>
          <ContentWrapper>
            {
              cmsIngredient?.content
                ? renderRichText(cmsIngredient.content, BasicBlockRichTextRenderOptions)
                : null // or provide an alternative content or handle the undefined case as needed
            }

            {cmsIngredient?.assessments && (
              <AssessmentTag assessments={cmsIngredient?.assessments} />
            )}

            {/* {assessments?.map((assessment, index) => {
              return (
                <>
                  <AssessmentPill type={assessment?.type ?? 'nothing'} key={index}>
                    <Typography
                      variant="body3"
                      // fontColor={assessment?.type === 'Goal' ? ['white'] : ['black']}        
                      fontColor={['red']}              
                    >
                      {assessment?.slug}
                    </Typography>
                  </AssessmentPill>
                </>
              );
            })} */}
          </ContentWrapper>
        </AccordianContentContainer>
      </AccordianContainer>
    </>
  );
};

export default Layer;

const AccordianContainer = styled.div`
  position: relative;
  padding: 10px 0;
  border-bottom: 1px solid ${props => props.theme.colors.eloMidGrey};
`;

const ActionWrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: 60px 1fr 30px;
  align-items: center;
  gap: 10px;
`;



const ContentWrapper = styled.div`
  overflow: hidden;
  padding: 1rem;
`;

type AssessmentPillProps = {
  type: string;
};

const AssessmentPill = styled.div<AssessmentPillProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding: 0 10px;
  background: ${props =>
    props.type === 'Goal' ? 'rgba(34,116,165,.75)' : props.theme.colors.eloAmber};
  border-radius: 20px;
  margin: 0 10px 10px 0;
`;
