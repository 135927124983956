import * as React from 'react';
import styled from 'styled-components';
import { Avatar, Row, Col, Space, Typography } from 'antd';
import Image from '../image/Image';
import { AuthorProps } from '../author/Author';

const { Text } = Typography;

interface ArticleAuthorProps extends AuthorProps {
  timeToRead?: number;
}

interface AuthorWithTimeToReadProps {
  author: ArticleAuthorProps;
}

export default function ArticleAuthor({ author }: AuthorWithTimeToReadProps) {
  return (
    <ArticleAuthorContainer>
      <Row align="middle">
        <Col span={16}>
          <Space direction="horizontal">
            <Avatar
              size={32}
              icon={
                author?.avatar?.gatsbyImageData && (
                  <Image className="avatar" gatsbyImageData={author.avatar.gatsbyImageData} />
                )
              }
            />
            <Text>
              {author.name}
              {author.title ? ', ' + author.title : ''}
            </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Text className="time-to-read">
            {author.timeToRead ? `${author.timeToRead} mins` : ''}
          </Text>
        </Col>
      </Row>
    </ArticleAuthorContainer>
  );
}

const ArticleAuthorContainer = styled.div`
  font-size: 14px;
`;
