import * as React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { SectionProps } from 'types';
import { SectionContainer, HeadlineContainer, Image, Icon } from 'ui';

const WhatsInside = (props: SectionProps): JSX.Element => {
  const [showAll, setShowAll] = React.useState(false);
  const howMany = props.ingredients.length;
  const width = howMany >= 12 ? 6 : 3;
  const amountShown = showAll ? howMany : width * 2;
  const hasEnoughToHide = howMany > width * 2;
  const lifts = (props.blocks || []).slice(0, 2);
  const textBlocks = (props.blocks || []).slice(2);

  return (
    <SectionContainer id={props.slug} usePadding contentBackground={'#fff'}>
      <Headline>
        <HeadlineContainer headline={props.headline} body={props.body} />
      </Headline>
      <SubHeading>{props.subHeading}</SubHeading>
      {lifts && (
        <MainLiftsContainer>
          {lifts.map(block => (
            <Lift key={block.slug}>
              <HeadlineContainer headline={block.subHeading} />
              <Image gatsbyImageData={block.thumbnail.gatsbyImageData} alt="" />
              <h3>{block.headline}</h3>
              {block.shortBody && <p>{block.shortBody.shortBody}</p>}
            </Lift>
          ))}
        </MainLiftsContainer>
      )}
      {textBlocks && (
        <>
          {textBlocks.map(block => (
            <TextBlockContainer key={block.slug}>
              <HeadlineContainer body={block.body} />

              <HeadlineContainer headline={block.headline} />
            </TextBlockContainer>
          ))}
        </>
      )}
      {props.ingredients && (
        <>
          <IngredientsContainer id="show-all-js">
            {props.ingredients.slice(0, amountShown).map(ing => (
              <Ingredient key={ing.headline} style={{ flexBasis: `${Math.floor(100 / width)}%` }}>
                <Image gatsbyImageData={ing.image?.gatsbyImageData} alt="" />
                <h3>{ing.headline}</h3>
                {ing.shortDescription && <p>{ing.shortDescription.shortDescription}</p>}
              </Ingredient>
            ))}
          </IngredientsContainer>
          {hasEnoughToHide && (
            <ShowAllContainer>
              <ShowAllCta
                onClick={() => {
                  setShowAll(!showAll);
                  setTimeout(() => {
                    const elem = document.querySelector(`#show-all-js`);
                    if (showAll && elem) {
                      elem.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }
                  }, 100);
                }}
              >
                {!showAll && (
                  <>
                    Show more
                    <Icon id="arrow-right-black" />
                  </>
                )}
                {showAll && (
                  <>
                    Show less
                    <Icon id="arrow-left-black" />
                  </>
                )}
              </ShowAllCta>
            </ShowAllContainer>
          )}
        </>
      )}
    </SectionContainer>
  );
};
export default WhatsInside;

const Headline = styled.div`
  .typography-p {
    margin-bottom: 30px;
    ${({ theme }) => theme.breakpoints.tablet} {
      max-width: 500px;
    }
  }
`;
const TextBlockContainer = styled.div`
  .typography-p {
    margin-bottom: 30px;
    ${({ theme }) => theme.breakpoints.tablet} {
      margin-top: 55px;
      margin-bottom: 60px;
      max-width: 620px;
    }
  }
`;

const SubHeading = styled.p`
  font-size: 15px;
  line-height: 20.25px;
  margin-bottom: 30px;
  ${({ theme }) => theme.breakpoints.tablet} {
    margin-bottom: 60px;
    font-size: 20px;
    line-height: 27px;
    max-width: 700px;
  }
`;
const MainLiftsContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 1rem;
  flex-flow: row wrap;
  ${({ theme }) => theme.breakpoints.tablet} {
    gap: 47px;
    flex-flow: row;
  }
`;
const Lift = styled.div`
  width: 100%;
  > div {
    width: 100%;
    border-radius: 3px;
  }
  h2 {
    height: 15px;
  }
  h3 {
    font-size: 15px;
    line-height: 20.25px;
    margin-top: 20px;
    ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 21px;
      line-height: 28.35px;
    }
  }
  p {
    font-size: 13px !important;
    line-height: 18px !important;
    ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 16px;
      line-height: 21.6px;
    }
  }

  ${({ theme }) => theme.breakpoints.tablet} {
    width: 50%;
  }
`;
const IngredientsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-flow: row wrap;

  ${({ theme }) => theme.breakpoints.tablet} {
    justify-content: space-between;
  }
`;
const Ingredient = styled.div`
  padding: 5px;
  .gatsby-image-wrapper {
    background: ${({ theme }) => theme.colors.eloGreyLight};
    width: 100%;
    > div {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  h3 {
    font-family: 'Everett Medium';
    margin-top: 12px;
    margin-bottom: 5px;
    font-size: 13px;
    line-height: 17.55px;
    ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 16px;
      line-height: 21.6px;
    }
  }
  p {
    font-size: 11px;
    line-height: 14.85px;
    white-space: pre-wrap;
    margin-bottom: 2rem;
    ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 12px;
      line-height: 16.2px;
    }
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    flex-basis: 50% !important;
  }
`;

const ShowAllContainer = styled.div`
  margin-top: 50px;
  ${({ theme }) => theme.breakpoints.mobile} {
    margin-top: 10px;
  }
`;
const ShowAllCta = styled.p`
  font-size: 19px;
  line-height: 25px;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  svg {
    margin-left: 10px;
  }
`;
