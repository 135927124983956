import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import { SubscriptionModel } from 'src/types/product'


interface iSubscriptionImageProps {
    subOptions: SubscriptionModel[];
    selected: string | null;
}

const SubscriptionImage = ({subOptions, selected}:iSubscriptionImageProps) => {

    const selectedSubscription = subOptions.find(sub => sub.slug === selected)
    // console.log('selectedSubscription', selectedSubscription)

    if (!selectedSubscription?.cartSummaryImage) return (<>NO IMAGE</>)

  return (
    <>
    <GatsbyImage image={selectedSubscription?.cartSummaryImage?.gatsbyImageData} alt={selectedSubscription?.cartSummaryImage?.altText ?? ''} />
    </>
  )
}

export default SubscriptionImage