/********************************************************************************
 * LOGIN BUTTON
 * ----------------------------------------------------------------------------
 *
 ********************************************************************************/
import React from 'react';
import LoginButton from './LoginButton';
import { useLoginEvents } from '../../../../hooks';

/********************************************************************************
 * AMPLIFY IMPORTS
 * ----------------------------------------------------------------------------
 ********************************************************************************/

interface LoginProps {
  onLoginClick?: () => void;
}

export const Login = ({onLoginClick}:LoginProps) => {

  useLoginEvents();
  
  return (
    <>      
      <LoginButton onLoginClick={onLoginClick}/>
    </>
  );
};

export default Login;
