/****************************************************************************************************
 * STORE V2
 * -------------------------------------------------------------------------------------------------
 * This is a new store that primarily server V2 pages and components. It 'may' become a resplacement
 * for the current store but that is NOT the stated goal.
 *
 * -------------------------------------------------------------------------------------------------
 * Current Conext Supported:
 * - V2 Brand Values for V2 Pages and Components
 *
 * -------------------------------------------------------------------------------------------------
 * * State Management Solution: Zustand
 * A small, fast, and scalable bearbones state management solution.
 * Zustand has a comfy API based on hooks. It isn't boilerplatey or opinionated, but has enough
 * convention to be explicit and flux-like. More importantly it supports:
 *
 * - Immutable State Updates
 * - Enables components to subscribe to and react to nested state updates.
 * - I supports persisting state to local storage if needed. ( not currently used in this app)
 *
 * *************************************************************************************************/

/**********************************************************************
 * IMPORT NODE MODULES
 **********************************************************************/
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools, persist } from 'zustand/middleware';

/**********************************************************************
 * IMPORT TYPES
 **********************************************************************/
import { Store, State, ActiveLineItem } from './StoreV2.types';

/**********************************************************************
 * INITIAL STATE
 **********************************************************************/
import { initialState } from './InitialState';

/**********************************************************************
 * STORE
 **********************************************************************/

export const useStoreV2 = create<Store>()(
  persist(
    immer(
      devtools(set => ({
        ...initialState,
        updateState: (fn: (draft: State) => void) => set(fn),      
        updateActiveLineItem: (fn) => set((state) => {
          if (state.activeLineItem) {
            fn(state.activeLineItem);
          } else {
            // If activeLineItem is not yet defined, initialize it first
            const newActiveLineItem: ActiveLineItem = {} as ActiveLineItem;
            fn(newActiveLineItem);
            state.activeLineItem = newActiveLineItem;
          }
        }),          
      }))
    ),
    {
      name: 'v2-store', // Name of the localStorage key
      partialize: (state) => ({
        activeLineItem: state.activeLineItem, // Persist only the necessary parts of the state        
        activeClinic: state.activeClinic,
      }),
      // Optionally, configure how the store should be serialized/deserialized
      // serialize: (state) => JSON.stringify(state),
      // deserialize: (str) => JSON.parse(str),
    }
  )
);

export default useStoreV2;
