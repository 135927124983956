import * as React from 'react';
import { Block, ImageFile } from 'types';
import { Button, Row, Typography } from 'antd';
import { Image } from 'ui';
import { navigate } from 'gatsby';

const { Title, Text } = Typography;

type EmbeddedBlockProps = {
  block: Block;
  className: string;
};

const EmbeddedBlock = ({ block, className }: EmbeddedBlockProps) => {
  function addClass(file: ImageFile | undefined): string {
    if (file) {
      return 'thumbnail';
    }
    return 'empty-thumbnail';
  }

  return (
    <>
      <Row
        className={`${addClass(block.thumbnail)} ${className} ${block.thumbnailSize || block.size}`}
      >
        <Image
          className={'thumbnail-image'}
          file={block.thumbnail?.file}
          gatsbyImageData={block.thumbnail?.gatsbyImageData}
        />
      </Row>
      {block.thumbnailCaption && (
        <Row className="thumbnail-caption">
          <Text>{block.thumbnailCaption}</Text>
        </Row>
      )}
      {block.headline && (
        <Row className="headline">
          <Title level={2}>{block.headline}</Title>
        </Row>
      )}
      {block.subHeading && (
        <Row className="subheading">
          <Title level={5}>{block.subHeading}</Title>
        </Row>
      )}
      {block.ctaButton && (
        <Row>
          <Button
            className={'ctabutton ' + block.ctaButton.slug}
            size="large"
            type={block.ctaButton.type === 'Primary' ? 'primary' : 'default'}
            onClick={() => navigate(block.ctaButton.link)}
          >
            {block.ctaButton.title}
          </Button>
        </Row>
      )}
    </>
  );
};

export default EmbeddedBlock;
