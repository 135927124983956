import { useLocation } from "@reach/router";
import queryString from "query-string";

const useQueryString = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  return queryParams;
};

export default useQueryString;
