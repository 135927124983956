import * as React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { SectionProps } from 'types';
import ReactMarkdown from 'react-markdown';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { DefaultRichTextRenderOptions } from '../../../../utils/render';
import { useEloStore } from '../../../store/EloStore.hooks';
import { SectionContainer, Button, Icon, Image } from 'ui';

import { getSubscription } from '../../../product/utils';
import { Product } from '../../../product/Product.types';
export default function Tabs({
  slug,
  subHeading,
  body,
  blocks,
  ctaButton,
  products,
}: SectionProps): JSX.Element {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [activeTabIndexMobile, setActiveTabIndexMobile] = React.useState([0]);
  const [tabUnderlineWidth, setTabUnderlineWidth] = React.useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = React.useState(0);

  const { store, cart } = useEloStore();
  const product = products && products[0];
  const contextProduct = store.products[product?.slug] || product;
  const selectedSubscription = getSubscription(contextProduct as Product);

  const tabsRef = React.useRef([]);
  React.useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    if (window !== undefined) {
      window.addEventListener('resize', setTabPosition);
    }

    return () => window.removeEventListener('resize', setTabPosition);
  }, [activeTabIndex]);

  return (
    <SectionContainer id={slug} usePadding contentBackground={'#FFF'}>
      <Container>
        <h2>{body && renderRichText(body, DefaultRichTextRenderOptions)}</h2>
        <p>{subHeading}</p>
        <div className="blocks-m">
          {blocks.map((t, key) => {
            const image = isMobile
              ? t?.mobileBackground?.gatsbyImageData
              : t?.background?.gatsbyImageData;
            return (
              <div
                key={t.headline}
                className={activeTabIndexMobile.includes(key) ? 'tab active' : 'tab'}
              >
                <div className="button-container">
                  <button
                    key={t.headline}
                    onClick={() => {
                      if (activeTabIndexMobile.includes(key)) {
                        // setActiveTabIndexMobile(activeTabIndexMobile.filter((f) => f !== key));
                        setActiveTabIndexMobile([]);
                      } else {
                        setActiveTabIndexMobile([key]);
                      }
                    }}
                    ref={(el) => (tabsRef.current[key] = el)}
                  >
                    {t.headline}
                    <Icon id="plus" />
                    <Icon id="minus" />
                  </button>
                </div>
                <div className="content">
                  <Image gatsbyImageData={image} />
                  <h3>{t.subHeading}</h3>
                  <ReactMarkdown>{t.shortBody?.shortBody || ''}</ReactMarkdown>
                </div>
              </div>
            );
          })}
        </div>
        <div className="blocks-d">
          <div className="nav-container">
            <div className="tabs">
              {blocks.map((t, key) => {
                return (
                  <button
                    key={t.headline + '_toggle'}
                    onClick={() => setActiveTabIndex(key)}
                    className={activeTabIndex === key ? 'tab active' : 'tab'}
                    ref={(el) => (tabsRef.current[key] = el)}
                  >
                    {t.headline}
                  </button>
                );
              })}
            </div>
            <span
              className="underline"
              style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
            />
          </div>
          <div className="content">
            <div className="text">
              {blocks.map((t, key) => {
                return (
                  <div
                    key={t.headline + '_text'}
                    className={activeTabIndex === key ? 'active' : ''}
                  >
                    <h3>{t.subHeading}</h3>
                    <ReactMarkdown>{t.shortBody?.shortBody || ''}</ReactMarkdown>
                    {ctaButton && product && (
                      <Button
                        onClick={() => {
                          cart.addOrUpdateProduct(
                            product.slug,
                            selectedSubscription?.slug || '',
                            {},
                            product.cartPath
                          );
                        }}
                      >
                        {ctaButton.title}
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="image">
              {blocks.map((t, key) => {
                const image = isMobile
                  ? t?.mobileBackground?.gatsbyImageData
                  : t?.background?.gatsbyImageData;
                return (
                  <Image
                    gatsbyImageData={image}
                    className={activeTabIndex === key ? 'active' : ''}
                    key={t.headline + '_image'}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </SectionContainer>
  );
}

const Container = styled.div`
  h2 .typography-p {
    font-family: 'Everett Light';
    color: ${({ theme }) => theme.colors.eloDarkGreen};
    font-size: 29px;
    line-height: 33px;
    max-width: 770px;
    margin-bottom: 7px;
  }
  > p {
    font-size: 12.5px;
    line-height: 17.5px;
    max-width: 690px;
    color: ${({ theme }) => theme.colors.eloDarkGrey};
    margin-bottom: 30px;
  }
  @media screen and (min-width: 900px) {
    h2 .typography-p {
      font-family: 'Everett Light';
      color: ${({ theme }) => theme.colors.eloDarkGreen};
      font-size: 51px;
      line-height: 56px;
      max-width: 770px;
      margin-bottom: 20px;
    }
    > p {
      font-size: 19px;
      line-height: 28px;
      max-width: 690px;
    }
  }
  .blocks-m {
    @media screen and (min-width: 900px) {
      display: none;
    }
    margin: 0 -56px;
    display: flex;
    flex-flow: column;
    border-top: 1px solid ${({ theme }) => theme.colors.eloMidGrey};
    .tab {
      .button-container {
        border-bottom: 1px solid ${({ theme }) => theme.colors.eloMidGrey};
        height: 50px;
        position: relative;
        > button {
          border: none !important;
          background: transparent;
          font-size: 15px;
          line-height: 20.25px;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 13px 56px 16px;
          span {
            &.anticon-minus {
              display: none;
            }
          }
        }
      }
      &.active {
        .button-container {
          > button {
            span {
              &.anticon-plus {
                display: none;
              }
              &.anticon-minus {
                display: block;
              }
            }
          }
        }
      }
      .content {
        height: 0;
        display: none;
        padding: 13px 56px 16px;

        border-bottom: 1px solid ${({ theme }) => theme.colors.eloMidGrey};
        overflow: hidden;
        transition: all 0.1s ease-in-out;
        * {
          //transition: all 0.25s ease-in-out;
          opacity: 0;
          visibility: hidden;
        }
        img {
          width: 100%;
          margin-bottom: 20px;
        }
        h3 {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 16px;
          margin-top: 15px;
        }
        strong {
          font-family: 'Everett Medium';
        }
        p,
        strong {
          font-size: 12px;
          line-height: 18px;
          white-space: pre-wrap;
          margin-bottom: 5px;
        }
      }
      &.active {
        .content {
          height: auto;
          display: block;

          * {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  .blocks-d {
    @media screen and (max-width: 900px) {
      display: none;
    }
    margin-top: 70px;
    .nav-container {
      position: relative;
      .tabs {
        display: flex;
        border-bottom: 1px solid ${({ theme }) => theme.colors.eloDarkGreen};
        button {
          border: none !important;
          background: transparent;
          font-size: 20px;
          line-height: 27px;
          padding: 0 50px 13px;
        }
      }
      .underline {
        position: absolute;
        bottom: -4px;
        transition: all 0.25s ease-in-out;
        background: ${({ theme }) => theme.colors.eloDarkGreen};
        display: block;
        height: 4px;
      }
    }
    .content {
      display: flex;
      flex-flow: row;
      padding: 44px 0;
      .text {
        width: 40%;
      }
      .image {
        width: 60%;
        img {
          object-fit: contain !important;
        }
      }
      > div {
        position: relative;
        height: 640px;

        > img {
          height: auto !important;
        }
        > div,
        > img {
          position: absolute;
          top: 0;
          left: 0;
          padding: 44px 0;
          transition: all 0.25s ease-in-out;
          opacity: 0;
          visibility: hidden;
          max-width: 850px;
          &.active {
            opacity: 1;
            visibility: visible;
          }
        }

        h3 {
          font-size: 25px;
          line-height: 30px;
          max-width: 492px;
          margin-bottom: 22px;
          margin-right: 40px;
        }
        strong {
          font-family: 'Everett Medium';
        }
        p,
        strong {
          font-size: 15px;
          line-height: 22px;
          max-width: 492px;
          margin-bottom: 22px;
        }
        p {
          margin-right: 40px;
          white-space: pre-wrap;
        }
        button {
          margin-top: 44px;
          width: 255px;
        }
        > img {
          width: 100%;
        }
      }
    }
  }
`;
