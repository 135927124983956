/********************************************************************************
 * DELETE LOADING
 * ----------------------------------------------------------------------------
 * This component is used to display a loading indicator when the user has
 * requested to delete their account. This will be displayed while the account
 * is being scheduled for deletion.
 * *****************************************************************************/

import React from 'react';
import { Box, Typography, CircularProgress, Stack } from '@mui/material';

const DeleteLoading = () => {
  return (
    <Box
      position={'absolute'}
      top={'0'}
      left={'0'}
      width={'100%'}
      height={'100%'}
      sx={{
        backgroundColor: '#FFFFFF',
        zIndex: 9999,
      }}
    >
      <Stack spacing={4} 
      height={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      border={'1px solid #AAA'}
      padding={'1rem'}
      textAlign={'center'}
      >
          <Typography variant="h2" textAlign={'center'}>
            Please Wait...
          </Typography>
          <CircularProgress />
            <Typography variant="body2">
              We are scheduling your account for deletion.
              This will take a few moments. Please do not close or refresh your browser.
            </Typography>
      </Stack>
      
        
    </Box>
  );
};

export default DeleteLoading;
