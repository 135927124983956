import { useState, useEffect } from 'react';

const useScrollThreshold = (threshold: number = 500) => {
  const [scrollThreshold, setScrollThreshold] = useState(false);

  useEffect(() => {
    // Check if window is defined (ensures this runs only in the browser)
    if (typeof window === 'undefined') {
      return;
    }

    const handleScroll = () => {
      if (window.scrollY >= threshold) {
        setScrollThreshold(true);
      } else {
        setScrollThreshold(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [threshold]);

  return scrollThreshold;
};

export default useScrollThreshold;
