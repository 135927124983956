/********************************************************************************
 * DATA: INITIALIZE INVOICES
 * ----------------------------------------------------------------------------
 * Invoies need to be loaded into state when the user logs in and possibly
 * when it may need to be updated. This is the function that will be used to
 * initialize or refresh the invoices in the user's account store.
 *
 * This function does not need to call the ELO API. Instead it will get data
 * from central store and update the invoices in the central store.
 *
 * * NOTE: While there is an invoices query in the ELO API, it does not return
 * the data necessary to organize and display the invoices in the way that we
 * need to. Specifically, it doesn't return attributes that allow us to identify
 * which product the invoice was for. Therefore we need stitch the data together
 * ourselves from various sources.
 *
 * ----------------------------------------------------------------------------
 * * STEPS
 * ----------------------------------------------------------------------------
 * 1. Get the subscriptions from the central store
 * 2. Map over the subscriptions.invoices and get the invoices
 * 3. Map over the subscriptions.upcomingInvoices and get the upcoming invoices
 * 4. Merge the invoices and sort them by paidAt
 * 5. Return the invoices
 *
 * *****************************************************************************/

import { useAccountStore } from '../store';
import { InvoiceData } from '../types';

export const initializeInvoices = async (): Promise<InvoiceData[] | null> => {
  // * GET SUBSCRIPTIONS FROM THE CENTRAL STORE
  const subscriptions = useAccountStore.getState().userAccount?.subscriptions;
  const updateAccountState = useAccountStore.getState().updateAccountState;

  // * EXIT EARLY IF THERE ARE NO SUBSCRIPTIONS
  if (!subscriptions) return null;

  /**********************************************************************
   * * PAST INVOCIES
   *********************************************************************/
  const pastInvoices: Array<InvoiceData> = subscriptions.items.flatMap(subscription => {
    // We are now looking at the subscription object
    if (!subscription || !subscription.invoices) return null;
    return subscription.invoices.items
      .map(invoice => {
        if (!invoice) return null;        
        return {
          title: subscription.title ?? undefined,
          status: invoice.status ?? undefined,
          paidAt: invoice.paidAt ?? undefined,
          total: invoice.total ?? undefined,
        };
      })
      .filter(invoice => invoice !== null); // Filter out any null values
  });  

  /**********************************************************************
   * * UPCOMING INVOCIES
   *********************************************************************/
  const upcomingInvoices: Array<InvoiceData> = subscriptions.items.flatMap(subscription => {
    // We are now looking at the subscription object
    if (!subscription || !subscription.upcomingInvoices) return null;
    return subscription.upcomingInvoices
      .map(invoice => {
        if (!invoice) return null;        
        return {
          title: subscription.title ?? undefined,
          status: invoice.status ?? undefined,
          paidAt: invoice.dueAt ?? undefined,
          total: invoice.total ?? undefined,
        };
      })
      .filter(invoice => invoice !== null); // Filter out any null values
  });  

  /**********************************************************************
   * * MERGE INVOCIES AND ORDER BY PAID AT
   *********************************************************************/
  const allInvoices = [...pastInvoices, ...upcomingInvoices].sort((a, b) => {
    if (a?.paidAt && b?.paidAt) {
      return a.paidAt > b.paidAt ? -1 : 1;
    }
    return 0;
  });

  // * UPDATE THE STORE
  if (allInvoices) {
    updateAccountState(state => {
      state.invoices = allInvoices;
    });
  }

  return allInvoices ?? null;
};

export default initializeInvoices;
