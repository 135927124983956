import * as React from 'react';
import styled from 'styled-components';

type Props = {
  target?: string;
  children?: React.ReactNode;
};
export default function AnchorLink({ target, children }: Props): JSX.Element {
  return (
    <Anchor
      onClick={() => {
        try {
          const elem = document.querySelector(`#${target}`);
          if (elem && elem) {
            elem.scrollIntoView({
              behavior: 'smooth',
            });
          }
        } catch (error) {
          console.error('Failed to scroll to ' + `#${target}`);
        }
      }}
      className="link"
    >
      {children}
    </Anchor>
  );
}
const Anchor = styled.span`
  transition: all 0.25s ease-in-out;
  color: ${({ theme }) => theme.colors.eloDarkGrey};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.eloBlack};
  }
`;
