import * as React from 'react';
import styled from 'styled-components';
import { getImage } from 'gatsby-plugin-image';
import { SectionProps } from 'types';
import { SectionContainer, BackgroundImage, Button, Image, Typography } from 'ui';

const ImageContainer = styled.div<{ mobile: boolean }>(
  ({ theme, mobile = false }) => `
  display: ${mobile ? 'block' : 'none'} !important;
  width: 100px;
  padding: 0;
  margin-top: 10px;
  ${theme.breakpoints.small} {
    display: ${mobile ? 'none' : 'block'} !important;
    width: 150px;
    margin-top: auto;
  }
  ${theme.breakpoints.wide} {
    width: 180px;
  }
`
);

const FirstSection = styled.div(
  ({ theme }) => `    
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 100px 20px 0 20px;  

  ${theme.breakpoints.small} {
    height: 100%;
    padding: 144px ${theme.padding.horizontal.tablet}px ${theme.padding.horizontal.tablet}px ${theme.padding.horizontal.tablet}px;
    align-items: flex-start;
    text-align: left;
    
  }
  ${theme.breakpoints.desktop} {
    padding: 144px ${theme.padding.horizontal.desktop}px ${theme.padding.horizontal.desktop}px ${theme.padding.horizontal.desktop}px;
  }
  ${theme.breakpoints.wide} {
    padding: 111px ${theme.padding.horizontal.wide}px ${theme.padding.horizontal.wide}px ${theme.padding.horizontal.wide}px;
  }

  h1 {
    font-family: "Everett Light";
    color: ${theme.colors.eloBlack};
    margin-bottom: 10px;
    text-align: center;
    font-size: 38px;
    line-height: 43px;
    letter-spacing: -1px;
    max-width: 300px;
    ${theme.breakpoints.desktop} {
      text-align: left;
      font-size: 85px;
      line-height: 80px;
      margin-top: 100px;
      max-width: 650px;
      margin-left: -6px;
    }
    ${theme.breakpoints.wide} {
      margin-top: 150px;
    }
  }
  h2 {
    font-weight: 400;
    font-size: 15px;
    line-height: 20.25px;
    font-family: 'Everett Regular';
    color: ${theme.colors.eloBlack};
    margin-top: auto;

    padding: 0.5em 0;
    text-align: center;

    ${theme.breakpoints.small} {
      max-width: 450px;
      margin: 0;
      text-align: left;
      font-size: 20px;
      line-height: 27px;
    }
    ${theme.breakpoints.wide} {
      max-width: 650px;
    }
  }

`
);

const CtaButtonContainer = styled.div<{ mobile: boolean }>(
  ({ theme, mobile }) => `
  display: ${mobile ? 'block' : 'none'};
  margin-top: 1rem;
  margin-bottom: -20px;
  z-index: 1;
  button {
  }
  ${theme.breakpoints.small} {
    display: ${mobile ? 'none' : 'block'};
    margin-bottom: 0px;
    button {
      margin-bottom: 0;
      width: 150px;
      height: 48px;
    }
  }
  ${theme.breakpoints.desktop} {
    button {
      width: 180px;
      height: 48px;
    }
  }
`
);

export default function ({
  slug,
  headline,
  subHeading,
  body,
  ctaButton,
  background,
  mobileBackground,
  theme,
  images,
  data,
}: SectionProps): JSX.Element {
  const isLandingPage = data.slug === 'index';

  const image = images ? getImage(images[0]?.desktop) : null;

  return (
    <>
      <SectionContainer id={slug} className={isLandingPage ? 'landingpage' : ''}>
        <BackgroundImage
          desktopImage={background}
          mobileImage={mobileBackground}
          backgroundColor={'white'}
        >
          <FirstSection>
            {/* {headline && <h1>{headline}</h1>} */}
            {headline && <Typography variant='h1'>{headline}</Typography>}
            {image && (
              <ImageContainer mobile={true}>
                <Image gatsbyImageData={image} alt=""></Image>
              </ImageContainer>
            )}
            {subHeading && <h2>{subHeading}</h2>}
            <CtaButtonContainer mobile={false}>                        
              <Button 
              ctaButton={ctaButton} 
              sx={{ paddingInline: '150px', marginBottom: '1rem' }}
              />
            </CtaButtonContainer>
            {image && (
              <ImageContainer mobile={false}>
                <Image gatsbyImageData={image} alt=""></Image>
              </ImageContainer>
            )}
            <CtaButtonContainer mobile={true}>
              <Button 
              ctaButton={ctaButton}               
              />
            </CtaButtonContainer>
          </FirstSection>
        </BackgroundImage>
      </SectionContainer>
    </>
  );
}
