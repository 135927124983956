/***************************************************************
 * SVG Circle
 * -------------------------------------------------------------
 * Circle Icon with Params
 ***************************************************************/

import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

const Circle = ({
  stroke = '#333333',
  strokeWidth = '1.25',
  fill = 'none', // Default fill color is 'none'
  width = '21',
  height = '21',
  viewBox = '0 0 20 21',
}: SVGIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="10.6309"
      cy="10"
      rx="9.77157"
      ry="10"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      fill={fill} // Use the fill prop here
    />
  </svg>
);

export default Circle;

