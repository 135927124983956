import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

export const Flavor = ({
  stroke = '#1A1919',
  strokeWidth = '1.25',
  width = '16',
  height = '21',
  viewBox = '0 0 16 21',  
}: SVGIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.07698 20C11.9855 20 15.154 16.8316 15.154 12.923C15.154 9.01454 11.9855 5.84607 8.07698 5.84607C4.16847 5.84607 1 9.01454 1 12.923C1 16.8316 4.16847 20 8.07698 20Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M11.2468 1H12.9237V2.6769C12.9237 4.42768 11.5054 5.85342 9.74722 5.85342H8.07031V4.17651C8.07031 2.42574 9.48866 1 11.2468 1Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M8.0769 5.85342V4.17651C8.0769 2.42574 6.65855 1 4.90039 1"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M10.3664 11.2548C11.011 11.2548 11.5336 10.7322 11.5336 10.0876C11.5336 9.44298 11.011 8.92041 10.3664 8.92041C9.72178 8.92041 9.19922 9.44298 9.19922 10.0876C9.19922 10.7322 9.72178 11.2548 10.3664 11.2548Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M4.8957 14.0163C5.54032 14.0163 6.06288 13.4937 6.06288 12.8491C6.06288 12.2045 5.54032 11.6819 4.8957 11.6819C4.25108 11.6819 3.72852 12.2045 3.72852 12.8491C3.72852 13.4937 4.25108 14.0163 4.8957 14.0163Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M8.86836 17.2063C9.51297 17.2063 10.0355 16.6838 10.0355 16.0391C10.0355 15.3945 9.51297 14.8719 8.86836 14.8719C8.22374 14.8719 7.70117 15.3945 7.70117 16.0391C7.70117 16.6838 8.22374 17.2063 8.86836 17.2063Z"      
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
  </svg>
);

export default Flavor;
