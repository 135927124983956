import React, { useEffect, useState } from 'react';
import { SmartCTA as SmartCTAType } from 'src/types/smartCTA';
import { Container, Box } from 'ui';
import styled, { css } from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BasicBlockRichTextRenderOptions } from 'src/utils/render';
import { useViewport } from 'src/hooks';

interface ISmartCTA {
  smartCTA: SmartCTAType | null;
}

const SmartCTA = ({ smartCTA }: ISmartCTA) => {
  const [isVisible, setIsVisible] = useState(false);

  const { isMobile } = useViewport();

  const getOffset = () => {
    const offset = smartCTA?.mobileScrollOffset || 50;    
    return offset;
  }

  useEffect(() => {
    
    const handleScroll = () => {
      const shouldShow = window.scrollY > getOffset();
      setIsVisible(shouldShow);
    };

    // Listen for scroll events
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!smartCTA) return null;

  /**********************************************
   * Clone the block body data before you pass it
   * to the renderRichText function. This is because
   * the renderRichText function will mutate the data
   * and we don't want to mutate the original data.
   * If you don't do this, you will get an error.
   * ********************************************/
  const clone = JSON.parse(JSON.stringify(smartCTA.cta));

  /**************************************************
   * HANDLE DESKTOP
   * -----------------------------------------------
   * If the viewport is not mobile, and smartCTA.displayInBreakpoints
   * does not include 'desktop', then return null.
   **************************************************/
  if (!isMobile && !smartCTA.displayInBreakpoints.includes('desktop')) return null;

  /**************************************************
   * HANDLE MOBILE
   * -----------------------------------------------
   * If the viewport is mobile, and smartCTA.displayInBreakpoints
   * does not include 'mobile', then return null.   
   **************************************************/
  if (isMobile && !smartCTA.displayInBreakpoints.includes('mobile')) return null;

  return (
    <>
      <CTAContainer isVisible={isVisible}>
        <Box background={'#FFF'} width={'100%'}>
          <Container>
            <Box
              //   border={'1px dashed cyan'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              textAlign={'center'}
              pt={'2px'}
              mb={'-30px'}          
            >
              {renderRichText(clone, BasicBlockRichTextRenderOptions)}
            </Box>
          </Container>
        </Box>
      </CTAContainer>
    </>
  );
};

export default SmartCTA;

// Define an interface for the CTAContainer props
interface CTAContainerProps {
  isVisible: boolean;
}

const CTAContainer = styled.div<CTAContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 500ms linear;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2); // Adding light drop shadow

  ${({ isVisible }) =>
    isVisible
      ? css`
          transform: translateY(0);
        `
      : css`
          transform: translateY(100%);
        `}
`;
