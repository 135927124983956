import React from 'react';
import { Box } from 'ui';
import styled from 'styled-components';
import { ContentfulVideoAsset } from 'src/types/index';
import { responsiveV2 } from 'src/utils';

interface VideoAssetProps {
  url: string;
  data: ContentfulVideoAsset;
}

const VideoAsset = ({ url, data }: VideoAssetProps) => {
  const videoUrl = url ? url : data?.url ? data.url : data.videoFile.url;

  return (
    <VideoContainer>
      <Video
        autoPlay
        loop
        muted
        playsInline
        horizontalPosition={data.horizontalPosition}
        verticalPosition={data.verticalPosition}
        scale={data.scale}
      >
        <source src={videoUrl} type="video/mp4" />
      </Video>
    </VideoContainer>
  );
};

export default VideoAsset;

interface VideoProps {
  horizontalPosition?: string | string[];
  verticalPosition?: string | string[];  
  scale?: string | string[];
}

const VideoContainer = styled.div`
  position: relative;
  display: flex;  
  width: 100%;
  /* border: 1px solid red; */
  overflow: hidden;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;  
  /* position: absolute; */
  ${({ horizontalPosition = ['0'], verticalPosition = ['0'], scale = ['1'] }: VideoProps) =>
    responsiveV2({
      cssPropsVals: {
        scale: Array.isArray(scale) ? scale : [scale],        
        'margin-left': Array.isArray(horizontalPosition) ? horizontalPosition : [horizontalPosition],
        'margin-top': Array.isArray(verticalPosition) ? verticalPosition : [verticalPosition],        
      },
    })}
`;
