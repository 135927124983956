import React from 'react';
import styled from 'styled-components';
import { Block } from 'types';
import { Image } from 'ui';
import { CTALink } from 'src/features/ui/link/Link';

type Props = Block & { right: boolean };

const FeatureItemLeft = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  > div {
    flex-basis: 100%;
  }
  ${({ theme }) => theme.breakpoints.small} {
    flex-flow: row wrap;
    align-items: center;
    > div {
      flex-basis: 40%;
    }
  }
`;
const FeatureItemRight = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
  > div {
    flex-basis: 100%;
  }
  ${({ theme }) => theme.breakpoints.small} {
    flex-flow: row-reverse wrap;
    align-items: center;
    > div {
      flex-basis: 40%;
    }
  }
`;
const Content = styled.div`
  margin: 0 0px;
  max-width: 420px;
  h3 {
      margin-top: 1rem;
      font-family: "Everett Medium" !important;
      font-size: 15px !important;
      line-height: 20.25px !important;
      color: ${({ theme }) => theme.colors.eloBlack} !important;
  }
  p {
    font-family: "Everett Regular" !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: ${({ theme }) => theme.colors.eloDarkGrey}; !important;
  }
  a {
    font-size: 13px !important;
    line-height: 18.2px !important;
    color: ${({ theme }) => theme.colors.eloBlack} !important;
    display: flex;
    align-items: center;
    svg {
      margin-left: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.small} {
    margin: 0 47px;
    h3 {
      font-size: 18px !important;
      line-height: 21.6px !important;
  }
  p {
    font-size: 16px !important;
    line-height: 21.6px !important;
  }
  a {
    font-size: 16px !important;
    line-height: 21.6px !important;
    transition: all 0.25s ease-in-out;
    svg * {
      transition: all 0.25s ease-in-out;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.primary} !important;
      svg * {
        fill: ${({ theme }) => theme.colors.primary} !important;
      }
    }
  }
  }
`;

const Feature = (props: Props): JSX.Element =>
  props.right ? (
    <FeatureItemRight>
      <FeatureContent {...props} />
    </FeatureItemRight>
  ) : (
    <FeatureItemLeft>
      <FeatureContent {...props} />
    </FeatureItemLeft>
  );

function FeatureContent(props: Props) {
  return (
    <>
      <Image {...props.thumbnail} loading="lazy" alt="" />
      <Content>
        <h3>{props.headline}</h3>
        {props.shortBody && <p>{props.shortBody.shortBody}</p>}
        {props.ctaButton && <CTALink {...props.ctaButton} />}
      </Content>
    </>
  );
}

export default Feature;
