/********************************************************************************
 * UTILS: DATE MINUS
 * ----------------------------------------------------------------------------
 * This utility function will remove days from a date and return the new date.
 * ******************************************************************************/

export const dateMinusDays = (date: string, days: number):string => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - days);
    return newDate.toISOString();
}

export default dateMinusDays;