import React from 'react';
import { Modal, Box, Typography, IconButton, Stack } from '@mui/material';
import { Product } from 'types';
import { Image } from 'ui';
import { useAssessmentStore } from 'src/features/assessment-summary/store';
import CloseIcon from '@mui/icons-material/Close';

interface ModalSupplementFactsProps {
  open: boolean;
  onClose: (open: boolean) => void;
}

const ModalSupplementFacts = ({ open, onClose }: ModalSupplementFactsProps) => {
  const storeProduct = useAssessmentStore(state => state.storeProduct);

  const imageData = storeProduct?.ingredients?.supplementFactsLabel?.gatsbyImageData ?? null;
  const svgData = storeProduct?.ingredients?.supplementFactsLabel?.svg ?? null;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #AAA',
    boxShadow: 24,
    pt: 1,
    px: 2,
    pb: 2,
    borderRadius: 2,
  };

  const handleClose = () => {
    onClose(false);
  };

  const getGradient = () => {
    switch (storeProduct?.slug) {
      case 'luminary-gummies-create':
        return 'linear-gradient(180deg, #C1C19B 0%, #FFE2DE 100%)';
        break;
      case 'luminary-gummies-form':
        return 'linear-gradient(180deg, #F7CA95 0%, #CFE7F8 100%)';
        break;
      case 'luminary-gummies-flourish':
        return 'linear-gradient(180deg, #FFDFDB 0%, #F9C88F 100%)';
        break;
      case 'luminary-gummies-nurture':
        return 'linear-gradient(180deg, #CFE7F8 0%, #F7E095 100%)';
        break;
      default:
        return 'linear-gradient(180deg, #F7CA95 0%, #CFE7F8 100%)';
    }
  };

  return (
    <Modal open={open}>
      <Box
        position={'relative'}
        width={['94vw', '520px', '520px']}
        minHeight={['440px']}
        maxHeight={['90vh']}
        sx={{
          ...style,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto', // This ensures that the content area can scroll if needed
          //   background: 'linear-gradient(180deg, #C1C19B 0%, #FFE2DE 100%)',
          background: getGradient(),
        }}
      >
        <Box position={'absolute'} right={['2px', '10px']} top={['2px', '6px']}>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon sx={{ color: 'black' }} fontSize="small" />
          </IconButton>
        </Box>
        <Stack spacing={2} px={['0px', '1.47rem']} pt={['25px']} pb={['10px']}>
          <Image
            svg={svgData}
            gatsbyImageData={imageData}
            alt="Supplement Facts"
          />
        </Stack>
      </Box>
    </Modal>
  );
};

export default ModalSupplementFacts;
