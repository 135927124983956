import * as React from 'react';
import styled from 'styled-components';
import head from 'lodash/head';
import get from 'lodash/get';
import nth from 'lodash/nth';
import Marquee from 'react-fast-marquee';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { DefaultRichTextRenderOptions } from '../../../../utils/render';
import { SectionProps } from 'types';
import SectionContainer from '../../SectionContainer';
import { ImageCarousel, Icon } from 'ui';
import { flatten } from 'lodash';

export default function TwoColumnLiftTicker({
  slug,
  body,
  images,
  blocks,
}: SectionProps): JSX.Element {  
  const reasons = get(head(blocks), 'blocks') || [];
  const ticker = get(nth(blocks, 1), 'blocks') || [];
  return (
    <SectionContainer id={slug} usePadding contentBackground={'#F8F9F5'}>
      <Container className="two-column-lift-ticker">
        {/* IMAGE CAROUSEL */}
        <ImageCarousel
          //IMAGES
          images={images}
          
          // ANIMATION PROPERTIES
          enter={{opacity: 0, scale: 1}}
          active={{opacity: 1, scale: 1}}
          exit={{opacity: 0, scale: 1}}
          transition={{duration: 1, ease:'easeInOut'}}
          
          // STYLE PROPERTIES
          className="image-carousel"
          rootStyles={{}}
          buttonContainerStyles={{}}
          buttonStyles={{
            background:'#E1E2D2',            
          }}
          
          // BUTTON PROPERTIES
          // BACK BUTTON
          useIconBack={true}          
          iconBack={'arrow-left-black'}
          // buttonTextBack='Back'
          
          // NEXT BUTTON
          useIconNext={true}
          iconNext={'arrow-right-black'}
          // buttonTextNext='Next'
          
          // DEV MODE
          // devMode={true}
        />

        {/* CONTENT BLOCK */}
        <div className="two-column-lift-content">
          <h2>{body && renderRichText(body, DefaultRichTextRenderOptions)}</h2>
          <div className="blocks">
            {reasons?.map((r) => {
              return (
                <div key={r.headline}>
                  <h3>
                    <Icon id="checkmark-green" />
                    {r.headline}
                  </h3>
                  {r.body && renderRichText(r.body, DefaultRichTextRenderOptions)}
                </div>
              );
            })}
          </div>
        </div>

        {/* MARQUEE */}
        <div className="ticker">
          <Marquee speed={25} gradient={false} play={true} delay={1}>
            {flatten([...ticker, ...ticker]).map((r, key) => (
              <React.Fragment key={r.headline + '__' + key}>
                <p key={r.headline}>{r.headline}</p>
                <p key={r.headline + '_'}>•</p>
              </React.Fragment>
            ))}
          </Marquee>
        </div>
      </Container>
    </SectionContainer>
  );
}

const Container = styled.div`
  /***** CONTAINER PARENT STYLES ********************/

  //MOBILE
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'image' 'content' 'ticker';
  grid-row-gap: 0px;
  grid-column-gap: 20px;
  align-items: end;
  /* gap: 20px; */

  // DESKTOP
  ${({ theme }) => theme.breakpoints.small} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'image content'
      'ticker ticker';
    grid-row-gap: 0px;    
  }

  /**************************************************/
  /* IMAGE CAROUSEL
/**************************************************/

  .image-carousel {
    // GLOBAL
    grid-area: image;

    // MOBILE
    margin-top: -260px;
    padding-bottom: 44px;

    // DESKTOP
    ${({ theme }) => theme.breakpoints.small} {
      margin-top: -260px;
      padding-bottom: 44px;
    }
  }

  /**************************************************/
  /* CONTENT BLOCK                               
/**************************************************/

  .two-column-lift-content {
    /******************************
  /* GLOBAL
  /----------------------------*/
    grid-area: content;
    align-self: flex-start;

    /******************************
  /* MOBILE
  /----------------------------*/
    h2 .typography-p {
      font-family: 'Everett Light';
      color: ${({ theme }) => theme.colors.eloDarkGreen};
      font-size: 29px;
      line-height: 32px;
      color: ${({ theme }) => theme.colors.eloDarkGreen};
      margin-bottom: 20px;
    }

    div.blocks {
      margin-top: 15px;
      margin-bottom: 40px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-center;
      flex-flow: column;
      div {
        margin-top: 10px;
        img {
          width: 71px;
          margin-bottom: 10px;
        }
        h3 {
          font-size: 18px;
          line-height: 20px;
          margin-bottom: 0;
          color: ${({ theme }) => theme.colors.eloDarkGreen};
          svg {
            margin-right: 10px;
          }
        }
        .typography-p {
          margin-top: 10px;
          font-size: 12.5px;
          line-height: 17.5px;
          color: ${({ theme }) => theme.colors.eloDarkGrey};
          font-family: 'Everett Regular';
        }
      }
    }

    /******************************
  /* DESKTOP
  /----------------------------*/
    ${({ theme }) => theme.breakpoints.small} {
      h2 .typography-p {
        font-size: 51px;
        line-height: 56px;
      }

      > div.blocks {
        margin-top: 27px;
        div {
          margin-top: 16px;
          h3 {
            font-size: 28px;
            line-height: 33px;
            display: flex;
            align-items: center;
            svg {
              height: 15px;
              width: 21px;
              margin-right: 10px;
            }
          }

          .typography-p {
            font-size: 15px;
            line-height: 21px;
          }
        }
      }
    }
  }

  /**************************************************/
  /* TICKER
/* The ticker is currently the same in mobile
/* as it is in Desktop
/**************************************************/

  .ticker {
    grid-area: ticker;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-top: solid 1px ${({ theme }) => theme.colors.eloDarkGreen};
    display: flex;
    align-content: center;
    .marquee {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &:last-child {
        margin-left: 10px;
        ${({ theme }) => theme.breakpoints.tablet} {
          margin-left: 5px;
        }
      }
      > p {
        margin: 0 10px;
        font-family: 'Everett Mono Medium';
      }
    }
    > div {
      p {
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
  }
`;
