import React from 'react';
import styled, { keyframes } from 'styled-components';

const waveAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

interface SkeletonBaseProps {
  width?: string;
  height?: string;
}

const SkeletonBase = styled.div<SkeletonBaseProps>`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  background: linear-gradient(90deg, #ddd 25%, #efefef 50%, #ddd 75%);
  background-size: 200% 100%;
  animation: ${waveAnimation} 4s ease-in-out infinite;
`;

export type SkeletonProps = {
  width?: string;
  height?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({ width, height }) => {
  return <SkeletonBase width={width} height={height} />;
};

export default Skeleton;
