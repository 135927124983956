/********************************************************************************
 * USER INVOICES
 * ----------------------------------------------------------------------------
 * This component will display the user's invoices. Data for this component is
 * loaded from the central store which is updated when a user logs in.
 *
 * * NOTE:
 * The invoice data that this component uses is not directly available from the
 * ELO API. Instead, the data is stitched together from various sources and then
 * loaded into the central store. This is intially done in the useLoginEvents hook.
 *
 * You can reload the data if you need to by calling the initializeInvoices data
 * function.
 * *****************************************************************************/
import React from 'react';

// Use to initialize the invoices data if you need to reload it
// import { initializeInvoices } from '../../data';
import { useAccountStore } from '../../store';
import { Typography, Chip, Box, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { formatDate, formatCurrency } from '../../utils';
import { Currency } from '../../../API';

const UserInvoices = () => {
  const invoices = useAccountStore(state => state.invoices);
  
  
  if (!invoices) return <>Loading or no invvoices to display.</>;
  const filteredInvoices = invoices.filter(invoice => invoice !== null);  
  
  // Define the columns for the data grid
  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      width: 95,
      renderHeader: params => <Typography variant="tiny2">{params.colDef.headerName}</Typography>,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="tiny2">{params.value}</Typography>
      ),
    },
    {
      field: 'title',
      headerName: 'Product',
      minWidth: 120,
      flex: 1,
      renderHeader: params => <Typography variant="tiny2">{params.colDef.headerName}</Typography>,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="tiny2">{params.value}</Typography>
      ),
    },
    {
      field: 'status',
      headerName: '',
      width: 100,
      renderHeader: params => <Typography variant="tiny2">{params.colDef.headerName}</Typography>,
      renderCell: (params: GridRenderCellParams) =>
        params.value == 'Upcoming' ? (
          <Chip label={<Typography variant="tiny2">{params.value}</Typography>} size="small" />
        ) : (
          <Typography variant="tiny2">{params.value}</Typography>
        ),
    },
    {
      field: 'amount',
      headerName: '',
      width: 65,
      renderHeader: params => <Typography variant="tiny2">{params.colDef.headerName}</Typography>,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="tiny2">{formatCurrency(params.value, Currency.USD)}</Typography>
      ),
    },
  ];

  const getStatus = (status: string) => {
    switch (status) {
      case 'paid':
        return '';
      case 'draft':
        return 'Upcoming';
      default:
        return '';
    }
  };
  const rows = filteredInvoices.map((invoice, index) => {
    return {
      id: index,
      date: invoice?.paidAt && formatDate(invoice.paidAt, 'MMM dd, yyyy'),
      title: invoice?.title,
      status: invoice?.status && getStatus(invoice.status),
      amount: invoice?.total,
    };
  });

  return (
    <Stack spacing={2}>
      <Typography variant="h4">Receipts</Typography>
      <Box height={'400px'} width={'100%'}>
        <DataGrid
          rows={rows} // Cast to 'Invoice[]' if you're sure about the structure
          columns={columns}
          autoPageSize
          sx={{
            '& .MuiTablePagination-displayedRows, & .MuiButtonBase-root': {
              fontSize: '0.75rem', // Adjust font size as needed
            },
          }}
        />
      </Box>
    </Stack>
  );
};

export default UserInvoices;
