/******************************************************************************************
 * RESPONSIVE SECTION
 * ---------------------------------------------------------------------------------------
 * Responsive section will become the default section type. It will be able to handle
 * all the different types of sections we have now. It will be able to handle the
 * different types of blocks we have now. It will be able to handle the different
 * types of backgrounds we have now, etc.
 *
 * This section will use the new "BasicBlocks" component to render the blocks rather than
 * all of the deterministic blocks we are rendering now. The blocks that this section will
 * render are found in src/features/blocks
 * ****************************************************************************************/

import * as React from 'react';
import styled from 'styled-components';
import { Block, SectionProps } from 'types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {
  DefaultRichTextRenderOptions,
  BasicBlockRichTextRenderOptions,
} from '../../../../utils/render';
import { RichTextRenderOptions } from './render';
import { FullscreenBackgroundImage, SectionContainer2 } from 'ui';

import { Container, Box, Typography } from 'ui';
import Blocks from './BasicBlocks';

const PageSectionResponsive = ({
  slug,
  headline,
  subHeading,
  body,
  blocks,
  basicBlocks,
  background,
  mobileBackground,
  theme,
  designNodes,
  sectionType,
}: SectionProps) => {
  
  const clonedData = JSON.parse(JSON.stringify(body));


  return (
    <SectionContainer2 designNodes={designNodes} sectionType={sectionType}>      
      <Container>
        {headline && (
          <Box>
            <Typography variant="h2">{headline}</Typography>
          </Box>
        )}

        {subHeading && (
          <Box>
            <Typography variant="h3">{subHeading}</Typography>
          </Box>
        )}

        {body && (
          <Box className="responsive-section-body">
            {renderRichText(clonedData, DefaultRichTextRenderOptions)}
            {/* {renderRichText(clonedData, BasicBlockRichTextRenderOptions)} */}
          </Box>
        )}

        {basicBlocks && (
          <Box>
            <Blocks basicBlocks={basicBlocks} />
          </Box>
        )}
      </Container>
    </SectionContainer2>
  );
};

export default PageSectionResponsive;

/******************************************************************************************
 * SECTION
 * ---------------------------------------------------------------------------------------
 * The section container establishes the width and behavior of the section content container.
 * This will stretch the full width of the screen. It allows a full width background image,
 * image, etc.
 * ****************************************************************************************/
const Section = styled.div`
  /* border: 2px solid tomato; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

/******************************************************************************************
 * SECTION CONTAINER
 * ---------------------------------------------------------------------------------------
 * The section container establishes the breakpoint widths within the section container.
 * ****************************************************************************************/
const SectionContainer = styled.div`
  /* border: 1px solid purple; */
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 100%; // Default for mobile (xs)
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: 600px) {
    max-width: 600px; // sm breakpoint
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (min-width: 960px) {
    max-width: 960px; // md breakpoint
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media (min-width: 1280px) {
    max-width: 1280px; // lg breakpoint
  }

  @media (min-width: 1920px) {
    max-width: 1920px; // xl breakpoint
  }
`;
