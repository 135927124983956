import * as React from 'react';
import { Link } from 'gatsby';
import { Breadcrumb } from 'antd';
import JsonLDScript from '../jsonld/JsonLDScript';
import { Icon } from 'ui';

type AnswerBreadcrumbProps = {
  title?: string;
};

export const HTML = ({ title }: AnswerBreadcrumbProps): JSX.Element => {
  return (
    <Breadcrumb separator={<Icon id="right" />}>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        {title ? <Link to="/answers/">Answers</Link> : <>Answers</>}
      </Breadcrumb.Item>
      {title ? <Breadcrumb.Item>{title}</Breadcrumb.Item> : <></>}
    </Breadcrumb>
  );
};

// https://developers.google.com/search/docs/data-types/breadcrumb#json-ld
export const JsonLD = ({ title }: AnswerBreadcrumbProps): JSX.Element => {
  const homeItem = {
    '@type': 'ListItem',
    position: 1,
    name: 'Home',
    item: 'https://www.elo.health/',
  };
  const answersLink = {
    '@type': 'ListItem',
    position: 2,
    name: 'Answers',
    item: 'https://www.elo.health/answers/',
  };
  const answersNoLink = {
    '@type': 'ListItem',
    position: 2,
    name: 'Answers',
  };
  const titleItem = {
    '@type': 'ListItem',
    position: 3,
    name: title,
  };
  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: title ? [homeItem, answersLink, titleItem] : [homeItem, answersNoLink],
  };
  return <JsonLDScript jsonld={jsonld} />;
};

const AnswersBreadcrumb = ({ title }: AnswerBreadcrumbProps): JSX.Element => {
  return (
    <div className="page-section-breadcrumb">
      <HTML title={title} />
      <JsonLD title={title} />
    </div>
  );
};

export default AnswersBreadcrumb;
