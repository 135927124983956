import * as React from 'react';
import styled from 'styled-components';

interface Props {
  timeToRead: number;
}

export default function TimeToRead({ timeToRead }: Props) {
  return <TimeToReadContainer>{timeToRead} mins</TimeToReadContainer>;
}

const TimeToReadContainer = styled.div`
  font-size: 14px;
`;
