import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Link } from '../link/Link';
import EloLogoLight from '../../../images/EloLogoLight.svg';

interface IProps {
  position?:
    | 'relative'
    | 'absolute'
    | 'fixed'
    | 'sticky'
    | 'static'
    | 'inherit'
    | 'initial'
    | 'unset'
    | undefined;
  positionMobile?:
    | 'relative'
    | 'absolute'
    | 'fixed'
    | 'sticky'
    | 'static'
    | 'inherit'
    | 'initial'
    | 'unset'
    | undefined;
  background?: string | undefined;
  noLink?: boolean | undefined;
}

const BareNav = ({
  position = 'absolute',
  positionMobile = 'relative',
  background = undefined,
  noLink = false,
}: IProps): JSX.Element => (
  <LogoContainer position={position} background={background} positionMobile={positionMobile}>
    {noLink ? (
      <EloLogoLight />
    ) : (
      <Link to="/">
        <EloLogoLight />
      </Link>
    )}
  </LogoContainer>
);
export default BareNav;

interface LogoContainerProps {
  position?: IProps['position'];
  background?: IProps['background'];
  positionMobile?: IProps['positionMobile'];
}

const LogoContainer = styled.div<LogoContainerProps>(
  ({ theme, position, background, positionMobile }) => `    
    position: ${positionMobile};  
      background: ${background ?? undefined};  
      top: 0;
      left: 0;
      z-index: 99;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    margin-bottom: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    svg {
      width: 68px;
      height: 24px;
    }
    ${theme.breakpoints.tablet} {
      svg {
        width: 78.68px;
        height: 27px;
      }
      position: ${position ?? 'absolute'};
      background: ${background ?? undefined};  
      top: 0;
      left: 0;
      z-index: 99;
    }
  `
);
