import * as React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Marquee from 'react-fast-marquee';
import { SectionProps, ResponsiveImage } from 'types';
import { SectionContainer, Image } from 'ui';

const HighlightsContainer = styled.div(
  ({ theme }) => `
  width: 100%;
  background: white;
  border-bottom: 1px solid ${theme.colors.eloGreyge};
  padding: 0;
  overflow: hidden;
  ${theme.breakpoints.mobile} {

  }
  ${theme.breakpoints.tablet} {

    padding: 0 ${theme.padding.horizontal.tablet}px 0 ${theme.padding.horizontal.tablet}px;
  }
  ${theme.breakpoints.desktop} {
    padding: 0 ${theme.padding.horizontal.desktop}px 0 ${theme.padding.horizontal.desktop}px;
  }
  ${theme.breakpoints.wide} {
    padding: 0 ${theme.padding.horizontal.wide}px 0 ${theme.padding.horizontal.wide}px;
  }


  > div > div {
    justify-content: space-between;
  }

  .highlight-image-container {
    width: 88px;
    margin: 30px 17px;
  }
  .highlight-image {
    img {
      margin: 0 auto;
    }
    &.mobile {
      display: none;
    }
  }
  ${theme.breakpoints.mobile} {
    .highlight-image.mobile {
      display: block;
    }
    .highlight-image:not(.mobile) {
      display: none;
    }
  }
  ${theme.breakpoints.tablet} {
    .highlight-image-container {
      width: 111px;
      margin: 38px 0px;
    }
    .highlight-image:not(.mobile) {
      display: block;
    }
  }
}
  
`
);



const HighlightsBar = ({ images }: SectionProps): JSX.Element => {
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true);
  React.useEffect(() => {
    setIsFirstRender(false);
  }, []);

  if (isFirstRender) return <></>;

  return (
    <SectionContainer id="" contentBackground={'#fff'}>
      <HighlightsContainer>
        <Marquee gradient={false} play={isMobile} delay={0} speed={30}>
          {renderBlocks(images)}
        </Marquee>
      </HighlightsContainer>
    </SectionContainer>
  );
};
export default HighlightsBar;

function renderBlocks(images: ResponsiveImage[]) {
  return (images || []).map((img: ResponsiveImage, key: number) => (
    <div key={img.desktop.file.url + key} className="highlight-image-container">
      <Image {...img.mobile} className="highlight-image mobile" loading="eager" />
      <Image {...img.desktop} className="highlight-image" loading="eager" />
    </div>
  ));
}
