import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

const PlantBased = ({
  stroke = '#333333',
  strokeWidth = '1.25',
  width = '42',
  height = '46',
  viewBox = '0 0 42 46',
}: SVGIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.3757 44.9224H0.948242"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M8.09961 30.7964C15.311 30.7964 21.1584 37.1216 21.1584 44.9225"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M21.1572 44.9225C21.1572 37.1216 27.0046 30.7964 34.216 30.7964"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M33.6781 9.875C33.6781 9.875 18.5252 18.4749 21.1578 44.9226"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M22.4083 22.7416C18.6983 18.719 16.6825 12.6757 22.7471 6.5102C28.299 0.86164 40.767 1.19059 40.767 1.19059C40.767 1.19059 42.0877 14.6024 37.3438 21.0498C32.148 28.0988 26.4223 26.4165 22.4169 22.7416H22.4083Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
  </svg>
);

export default PlantBased;
