
const breakpoints = [0, 600, 900, 1200, 1536];

const responsive = (cssProp: string, cssVal: string[]) => {
  if(!cssProp || !cssVal || !Array.isArray(cssVal)) {
    return cssProp ? `${cssProp}: initial;` : '';
  }

  return cssVal
    .map((value, index) => {
      if (index === 0) {
        return `${cssProp}: ${value};`;
      } else if (index < breakpoints.length) {
        return `@media screen and (min-width: ${breakpoints[index]}px) {
          ${cssProp}: ${value};
        }`;
      }
    })
    .filter(Boolean)
    .join(' ');
};

export default responsive;
