import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';



const Avatar = ({ 
    stroke = '#333333',
    strokeWidth = '1.25',
    width = '50', 
    height = '50', 
    viewBox = '0 0 50 50'
  }: SVGIcon) => (
    <svg 
    width={width}
    height={height}
    viewBox={viewBox} 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M1 22.056C1 16.2558 5.69982 11.556 11.5 11.556C17.3002 11.556 22 16.2558 22 22.056"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M11.5013 11.5559C14.4317 11.5559 16.8072 9.18038 16.8072 6.24998C16.8072 3.31959 14.4317 0.944031 11.5013 0.944031C8.57087 0.944031 6.19531 3.31959 6.19531 6.24998C6.19531 9.18038 8.57087 11.5559 11.5013 11.5559Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
  </svg>
);

export default Avatar;
