/********************************************************************************
 * * UPDATE STAGE
 * -----------------------------------------------------------------------------
 * This function updates the pregnancy stage of the user. This is isolated to
 * avoid creating any side effects when updating the user. The update stage
 * actually needs to perform a few tasks:
 * - update user data
 * TODO: Get new user configuration
 * TODO: Charge customers CC
 * - update user account state
 *
 * These things need to happen in sequence
 * *****************************************************************************/

import { getUserConfiguration } from './getUserConfiguration';

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/**********************************************************************
 * * IMPORT GRAPHQL MUTATION, TYPES, and ENUMS
 * ********************************************************************/
import { UserData } from '../types';
import { updateUser } from './graphql/mutations';
import { PregnancyStage, GummyFlavor } from '../types/API';

/******************************************************************************************
 * * IMPORT ACCOUNT STORE
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { useAccountStore } from '../store';

export const updateStage = async (stage: PregnancyStage) => {
  /**********************************************************************
   * * SUBSCRIBE TO STORE DATA AND GET UPDATE FUNCTION
   * ********************************************************************/
  const updateAccountState = useAccountStore.getState().updateAccountState;
  const newUserData = useAccountStore.getState().newUserData;
  const userAccount = useAccountStore.getState().userAccount;
  const flavor = newUserData?.preferences?.gummyFlavor ?? userAccount?.preferences?.gummyFlavor;

  try {
    if (!stage) {
      throw new Error('Pregnancy stage is required');
    }

    /**********************************************************************
     * * BUILD UPDATE MUTATION
     * ********************************************************************/
    const buildMutation = (): GraphQLOptions => {
      const mutation = updateUser;
      const authMode = 'userPool';
      const variables = {
        input: {
          preferences: {
            pregnancyStage: stage,
            gummyFlavor: flavor as GummyFlavor,
          },
        },
      };
      return {
        query: mutation,
        authMode,
        variables,
      };
    };

    /**********************************************************************
     * * UPDATE USER
     * ********************************************************************/
    interface UpdateUserResponse {
      updateUser: UserData;
    }

    const handleUpdateUser = async () => {
      const query = buildMutation();
      const response = (await client.graphql(query)) as GraphQLResult<UpdateUserResponse>;
      const data = response.data?.updateUser;
      if (!data) {
        throw new Error('Failed to update pregnancy stage, no data was returned');
      }

      // * UPDATE STORE
      updateAccountState(state => {
        if (!state?.userAccount?.preferences?.pregnancyStage) {
          throw new Error('Pregnancy stage was not found in store data');
        }
        state.userAccount.preferences = data.preferences;
      });

      // * RETURN DATA
      return data;
    };

    const updatedUser = await handleUpdateUser();
    await getUserConfiguration();

  } catch (error) {
    if (error instanceof Error) {
      return {
        error: error.message,
      };
    }
  }
};

export default updateStage;
