import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { ImageFile, LinkBlock, LinkType, DesignOptions } from 'types';
import { SectionContainer2, Container, Box, Typography } from 'ui';

import EmailSubscription from './components/EmailSubscription';
import { Link } from '../link/Link';
import Icon, { IconId } from '../icon/Icon';
import Image from '../image/Image';

type PageFooterProps = {
  slug: string;
  footerNavLinks: LinkBlock;
  contactLink: LinkBlock;
  socialLinks: LinkBlock;
  legalLinks: LinkBlock;
  logo: ImageFile;
  designNodes: DesignOptions[];
};

const paymentIcons: IconId[] = [
  'payment-apple',
  'payment-paypal',
  'payment-mastercard',
  'payment-visa',
];
const Footer = ({
  logo,
  footerNavLinks,
  contactLink,
  socialLinks,
  legalLinks,
  designNodes,
}: PageFooterProps): JSX.Element => {
  const nav = footerNavLinks;
  const contact = contactLink;
  const social = socialLinks;
  const legal = legalLinks;

  const theme = useContext(ThemeContext);

  return (
    <>
      <SectionContainer2 designNodes={designNodes} backgroundColor={theme.colors.eloGreyge}>
        <Container>
          <Grid>
            {/* <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
            >
              <EmailSubscription new />
            </Box> */}
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'flex-start'}
              sx={{ gap: '.47rem' }}
            >
              <Typography variant="body1">Support</Typography>
              {/* <Typography variant="body2">Help Center</Typography> */}

              <Typography variant="body2" sx={{ textDecoration: 'none', color:'#333' }}>
                <a
                  href="mailto:help@luminaryvitamins.com"
                  style={{ color: 'black', textDecoration: 'none' }}
                >
                  help@luminaryvitamins.com
                </a>
              </Typography>

              {/* {contact?.links?.map(link => (
                <Link {...link} key={link.slug} />
              ))} */}
            </Box>
            <Box>{''}</Box>
            <Box>              
                <Typography variant="tiny1">
                <a href="/terms-and-privacy" style={{ textDecoration: 'none', color:'#333' }}>Terms & Conditions - Privacy Policy</a></Typography>
              
              
                <Typography variant="tiny1">
                  Copyright © {new Date().getFullYear()} Luminary Vitamins. All rights reserved.                
                </Typography>
              
            </Box>

            <Box>
              <Typography variant="tiny2">
                * These statements have not been evaluated by the Food and Drug Administration. This
                product is not intended to diagnose, treat, cure, or prevent any disease.
              </Typography>
            </Box>
          </Grid>
        </Container>
      </SectionContainer2>
      <GradientContainer />
    </>
  );
};

export default Footer;

// export function renderSocialLinks(links: LinkType[]) {
//   const supportedSlugs = {
//     'facebook-link': 'social-facebook',
//     'elo-linkedin': 'social-linkedin',
//     instagram: 'social-instagram',
//     twitter: 'social-twitter',
//   };
//   const resolveIconId = (slug: string): IconId => {
//     const iconId = supportedSlugs[slug];
//     if (!iconId) {
//       throw new Error(`Unsupported social link slug ${slug}`);
//     }
//     return iconId;
//   };
//   return links?.map(link => (
//     <a href={link.url} key={link.url}>
//       <SocialIcon>
//         <Icon id={resolveIconId(link.slug)} />
//       </SocialIcon>
//     </a>
//   ));
// }

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
  }
`;

const GradientContainer = styled.div`
  background: ${({ theme }) => theme.colors.luminaryOrange};
  height: 16px;
`;

const SocialIcon = styled.div(
  ({ theme }) => `
  width: 20px;
  height: 20px;
  margin-right: 10px;
	display: flex;
	justify-content: center;
  margin-left: 5px;
	${theme.breakpoints.tablet} {
		width: 30px;
		height: 30px;
		margin-right: 30px;
	}
`
);
