/**
 * Component Level Utililities Object Literal
 * To make the component code more readable, I've added an 
 * object literal that containes one or more utility functions.
 */

import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ResponsiveImage } from 'src/types';

interface iGetImage {
  // Array of gatsby image objects
  images: ResponsiveImage[];
  // Current image index to display
  index: number;
  // Is the current viewport mobile
  isMobile: boolean;
}

/**
 * getImage
 * Return the best possible image object. Defaults to a backup. Return null if
 * the primary image or backup image does not exist.
 */
const getImage = ({ images, index = 0, isMobile = false }: iGetImage): IGatsbyImageData | null => {
  // If there is not images array or the length is less than 1 return null
  if (!images || images.length < 1) {
    throw new Error('No Images provided to the getImage utility function.');
  }

  // Set the current device based on isMobile
  // This should make the default desktop if it's not mobile.
  const device: 'desktop' | 'mobile' | 'slug' = isMobile ? 'mobile' : 'desktop';

  // Apply some logic to enable device defaults
  // this should account for cases where
  // Device is mobile or desktop
  // The preferred image file is used, defaults to the other image
  // or returns null if an error if neither image exists.

  switch (device) {
    case 'desktop':
      if (images[index].desktop) {
        return images[index].desktop.gatsbyImageData ?? null;
      } else if (images[index].mobile) {
        return images[index].mobile.gatsbyImageData ?? null;
      } else {
        return null        
      }
    case 'mobile': {
      if (images[index].mobile) {
        return images[index].mobile.gatsbyImageData ?? null;
      } else if (images[index].desktop) {
        return images[index].desktop.gatsbyImageData ?? null;
      } else {
        return null        
      }
    }
    default: {
        return null      
    }
  }
};


export const utils = {
  getImage,
};
