import * as React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import times from 'lodash/times';
import { isMobile } from 'react-device-detect';
import { Block, SectionProps } from 'types';
import {Icon, Image} from 'ui';

import SectionContainer from '../../SectionContainer';
import flatten from 'lodash/flatten';

const CarouselContainer = styled.div(
  ({ theme }) => `
    position: relative;
    padding-top: 60px;
    padding-bottom: 67px; 
    .slick-slide {
        transition: all 0.25s ease-in-out;
        &:not(.slick-current) {
            opacity: .25;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            transition: all 0.25s ease-in-out;
            p {
                transition: all 0.25s ease-in-out;
                opacity: 1;
            }
            &:not(.slick-current) {
                opacity: .25;
                p {
                    opacity: 0;
                }
            }
        }
    }
    ${theme.breakpoints.tablet} {
        padding-top: 115px;
        padding-bottom: 100px; 
    }
`
);
const BlockContainer = styled.div`
  display: flex !important;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 600px;
  gap: 10px;
`;

const CarouselBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 0;
  color: ${({ theme }) => theme.colors.eloBlack};
  max-width: 650px;
  ${({ theme }) => theme.breakpoints.tablet} {
    padding: 20px 0px 0;
  }
`;
const Quote = styled.p`
  color: ${({ theme }) => theme.colors.eloBlack};
  font-family: 'Everett Regular';
  font-size: 18px;
  line-height: 22.25px;
  text-align: center;
  width: calc(100% + 50px);
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 28px;
    line-height: 33px;
    width: 100%;
  }
`;

const Author = styled.p`
  font-size: 12.5px;
  line-height: 17.5px;
  text-align: center;
  font-family: 'Everett Regular';
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 15px;
    line-height: 21px;
  }
`;

const ReviewImageContainer = styled.div`
  max-width: 280px;
  ${({ theme }) => theme.breakpoints.tablet} {
    margin: 0 auto;
    max-width: 380px;
  }
`;

const StarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  svg {
    margin-right: 0.5rem;
    path {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export default function Carousel({ slug, headline, body, blocks }: SectionProps): JSX.Element {
  const settings = {
    infinite: true,
    speed: 500,
    arrows: false,
    centerMode: true,
    slidesToShow: 1,
    centerPadding: '28%',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 751,
        settings: {
          centerPadding: '50px',
        },
      },
    ],
  };

  return (
    <SectionContainer id={slug} classes="reviews" contentBackgroundColor="#fff">
      <CarouselContainer>
        <Slider {...settings}>{renderBlocks(flatten([...blocks, ...blocks]))}</Slider>
      </CarouselContainer>
    </SectionContainer>
  );
}
function renderBlocks(blocks: Block[]): JSX.Element[] {
  const getImage = (a: Block) =>
    isMobile ? a?.mobileBackground?.gatsbyImageData : a?.background?.gatsbyImageData;
  return blocks.map((block) => {
    return (
      <BlockContainer key={`carousel-${block.slug}`}>
        <ReviewImageContainer>
          <Image gatsbyImageData={getImage(block)} alt="" />
        </ReviewImageContainer>

        <CarouselBlock className="slide-content">
          {block.rating && (
            <StarContainer>
              {times(block.rating, (i) => (
                <Icon id="star" key={`${block.slug}-start-${i}`} />
              ))}
            </StarContainer>
          )}
          <Quote>{block?.shortBody?.shortBody}</Quote>
          <Author>
            {block.headline}
            <br />
            {block.subHeading}
          </Author>
        </CarouselBlock>
      </BlockContainer>
    );
  });
}
