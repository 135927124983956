import * as React from 'react';
import { SectionProps } from 'types';
import { PageSectionListing } from 'ui';
import { createListingData } from 'src/features/ui/pagesectionlisting/PageSectionListing';

type PageSectionCategoryRoundupProps = SectionProps & {
  data: any;
};

const PageSectionCategoryRoundup = ({
  slug,
  headline,
  listingObjectType,
  data,
}: PageSectionCategoryRoundupProps) => {
  const listingData = createListingData(data[listingObjectType], listingObjectType);
  const items = listingData.filter((item) => item.tags?.includes(headline)).slice(0, 3);

  return (
    <PageSectionListing
      slug={slug}
      title={headline}
      items={items}
      titleLink={true}
      className="category-roundup"
    />
  );
};

export default PageSectionCategoryRoundup;
