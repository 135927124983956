import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from 'src/features/ui/typography/Typography';
import { responsive } from 'src/utils';

interface IProps {
  checkoutButtonVisible: boolean;
}

const TopNav = ({ checkoutButtonVisible }: IProps) => {
  
  const [isNavVisible, setIsNavVisible] = useState<boolean>(true);
  
  const scrollToAnchor = (anchorId:string) => {
    const element = document.getElementById(anchorId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  useEffect(() => {
    if (checkoutButtonVisible) {
      setIsNavVisible(false);
    } else {
      setIsNavVisible(true);
    }
  }, [checkoutButtonVisible]);

  const handleCheckoutClick = () => {
    scrollToAnchor('price-summary');
    setIsNavVisible(false);
  };

  return (
    <TopNavContainer
    isVisible={isNavVisible} 
    checkoutButtonVisible={checkoutButtonVisible}
    onClick={handleCheckoutClick}
    >      
      <Typography variant="body1" sx={{textDecoration:'uderline'}}>
        See membership plans
      </Typography>
    </TopNavContainer>
  );
};

export default TopNav;

interface ITopNavContainer {
  isVisible: boolean;
  checkoutButtonVisible: boolean;
}

const TopNavContainer = styled.div<ITopNavContainer>`
  height: 30px;
  position: fixed;
  background-color: #bddc04;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 14px;
  margin-bottom: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  p {
    margin: unset;
  }
  svg {
    ${responsive('width', ['78.68px', '78.68px', '68px'])}
    ${responsive('height', ['24px', '24px', '27px'])}
  }
  button {
    max-height: 36px;
    min-height: 36px;
  }
  transition: transform 0.3s ease-in-out;
  transform: ${({ isVisible, checkoutButtonVisible }) =>
    isVisible && !checkoutButtonVisible ? 'translateY(0)' : 'translateY(-100%)'};
`;
