import React from 'react';
import styled from 'styled-components';

type Props = {
  id: 'dots';
};

export default function Spinner({ id }: Props): JSX.Element {
  switch (id) {
    case 'dots':
      return <DotsSpinner />;
  }
}

const DotsSpinner = (): JSX.Element => {
  return (
    <SpinnerContainer>
      <Bounce1></Bounce1>
      <Bounce2></Bounce2>
      <Bounce3></Bounce3>
    </SpinnerContainer>
  );
};

// slightly modified from https://tobiasahlin.com/spinkit/

const SpinnerContainer = styled.div`
  width: 30px;
  text-align: center;

  & > div {
    width: 8px;
    height: 8px;
    background-color: #1a1919;
    margin-right: 1px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const Bounce1 = styled.div`
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
`;

const Bounce2 = styled.div`
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
`;

const Bounce3 = styled.div``;
