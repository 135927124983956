import * as React from 'react';
import styled from 'styled-components';
import { Context } from '../../../store/EloStore.Provider';
import ProductCard from '../../../product/Product.Card';
import { SectionContainer2, HeadlineContainer, Container, Box } from 'ui';
import { SectionProps } from 'types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { DefaultRichTextRenderOptions } from 'src/utils/render';

const ProductListing = (props: SectionProps): JSX.Element => {
  const { store } = React.useContext(Context);

  const { body = undefined, products = [], designNodes = undefined, sectionType } = props;
  // const products = props.products || [];

  /*
  React.useEffect(() => {
    try {
      const items : EcommerceItem[] = Object.values(store.products).map(item => {
        const subs = Object.values(item.subscriptionModels);

        return {
            item_name: item.title ?? 'N/A',
            item_id: subs.length > 0 ? subs[0].stripePriceId ?? 'N/A' : 'N/A'
        }
      });

      analytics.viewItemList({ items }).finally(/* NOP );
    } catch (err) {
      console.debug("analytics action failed");
    }
  }, [store.products]);
*/
  return (
    <SectionContainer2 designNodes={designNodes} sectionType={sectionType}>
      <Container>
        {/* {props.body && (
          <Headline>
            <HeadlineContainer
              headline={props.headline}
              body={props.body}
              className="productlisting-headline"
            />
          </Headline>
        )} */}
        <Box>{body && renderRichText(body, DefaultRichTextRenderOptions)}</Box>
        <Grid>
          {products.map(p => (
            <ProductCard key={p.slug} product={p} />
          ))}
        </Grid>
      </Container>
    </SectionContainer2>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: 48px;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  flex-flow: wrap column;
  align-items: stretch;
  justify-content: space-between;
  gap: 1rem;
  .product-card {
    margin-bottom: 1rem;
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    flex-flow: row;
    .product-card {
      width: 50%;
    }
  }
`;

const Headline = styled.div`
  margin-top: 5rem;
  .productlisting-headline {
    .typography-p {
      max-width: 1120px;
      ${({ theme }) => theme.breakpoints.desktop} {
        font-size: 58px !important;
        line-height: 63.8px !important;
      }
    }
  }
`;

export default ProductListing;
