import * as React from 'react';
import { current, castDraft, Immutable, Draft } from 'immer';
import { useImmerReducer } from 'use-immer';
import { SessionStorage as storage } from '../storage/Storage';

// ELO Library Imports
import { Theme } from 'src/elo';

/********************************************************************************
 * COGNITO PROVIDER
 * ----------------------------------------------------------------------------
 * importing authenticator provider and wrapping the context provider with it
 * in this location to cover it's use by gatsby-ssr and gatsby-browser
 ********************************************************************************/
import { Authenticator } from '@aws-amplify/ui-react';
import { generateClient } from 'aws-amplify/api';
import { graphql, useStaticQuery } from 'gatsby';

const client = generateClient();

import { ThemeProvider } from 'styled-components';
import { BaseStyles, TypographyStyles } from '../../theme/GlobalStyles';
import { base } from '../../theme/Theme';
import { Product, SubscriptionModel } from '../product/Product.types';
import { Cart, Coupon, LineItem, Promotion, UTMData } from './EloStore.types';

type Mode = 'stub' | 'content-loaded' | 'ready';

export type State = Immutable<{
  key: 'elo-health-store';
  mode: Mode;
  modified: Date;
  promotions: Promotion[];
  products: {
    [slug: string]: Product;
  };
  layout: {
    theme: 'dark' | 'light';
    cartWidged: {
      shown: boolean;
    };
    loader: {
      shown: boolean;
    };
  };
  cart: Cart;
  analytics: {
    utmData?: UTMData;
  };
}>;

const initialState: State = {
  key: 'elo-health-store',
  mode: 'stub',
  modified: new Date(),
  promotions: [],
  products: {},
  layout: {
    theme: 'light',
    cartWidged: {
      shown: false,
    },
    loader: {
      shown: false,
    },
  },
  cart: {
    loaded: false,
    saved: null,
    owner: null,
    items: {},
    discount: { mode: 'stub' },
  },
  analytics: {},
};

type Action =
  | { type: 'store-ready' }
  | { type: 'cart-clear' }
  | { type: 'cart-saved' }
  | { type: 'cart-restore'; payload: { cart: Cart } }
  | { type: 'cart-add-or-edit-product'; payload: LineItem }
  | {
      type: 'cart-add-or-edit-discount';
      payload: { code: string; coupon: Coupon | null; isValid: boolean };
    }
  | { type: 'cart-remove-product'; payload: { productSlug: string } }
  | { type: 'layout-cart-widget-set-shown'; payload: { shown: boolean } }
  | { type: 'layout-loader-set-shown'; payload: { shown: boolean } }
  | { type: 'layout-set-theme'; payload: { theme: 'light' | 'dark' } }
  | { type: 'products-populate'; payload: { contentfulData: Queries.ProductsQuery } }
  | {
      type: 'analytics-store-utm-data';
      payload: {
        utm_source?: string;
        utm_medium?: string;
        utm_campaign?: string;
        utm_content?: string;
        utm_term?: string;
      };
    };

const reducer = (draft: Draft<State>, action: Action) => {
  // console.debug(`EloStore: [${action.type}]`, action, current(draft));

  switch (action.type) {
    case 'store-ready': {
      draft.mode = 'ready';
      if (!draft.cart.owner) {
        draft.cart.owner = crypto ? crypto.randomUUID() : null;
      }

      break;
    }
    case 'cart-saved': {
      draft.cart.saved = new Date();
      break;
    }
    case 'cart-clear': {
      draft.cart = {
        ...castDraft(initialState.cart),
        owner: draft.cart.owner,
      };

      break;
    }
    case 'cart-restore': {
      draft.cart = castDraft({ ...draft.cart, ...action.payload.cart });
      if (!draft.cart.owner) {
        draft.cart.owner = crypto ? crypto.randomUUID() : null;
      }

      break;
    }
    case 'cart-add-or-edit-product': {
      const items: { [id: string]: LineItem } = {};
      items[action.payload.productSlug] = {
        productSlug: action.payload.productSlug,
        subscriptionSlug: action.payload.subscriptionSlug,
        variantSlugs: action.payload.variantSlugs,
      };

      draft.cart.items = items;

      break;
    }
    case 'cart-add-or-edit-discount': {
      draft.cart.discount.code = action.payload.code;
      draft.cart.discount.mode = action.payload.isValid ? 'valid' : 'invalid';

      if (action.payload.isValid && action.payload.coupon) {
        draft.cart.discount.coupon = castDraft(action.payload.coupon);
      }

      break;
    }
    case 'cart-remove-product': {
      delete draft.cart.items[action.payload.productSlug];
      break;
    }
    case 'layout-cart-widget-set-shown': {
      draft.layout.cartWidged.shown = castDraft(action.payload.shown);
      break;
    }
    case 'layout-loader-set-shown': {
      draft.layout.loader.shown = castDraft(action.payload.shown);
      break;
    }
    case 'layout-set-theme': {
      draft.layout.theme = action.payload.theme;
      break;
    }
    case 'products-populate': {
      const productModels = action.payload.contentfulData.allContentfulProduct.nodes;

      const products = productModels.map((productModel: any) => {
        const product: Product = {
          ...(productModel as Product),
          subscriptionModels: {},
        };

        if (productModel.subscriptionModels) {
          Object.values(productModel.subscriptionModels).forEach((price: any) => {
            if (price && price.slug) {
              product.subscriptionModels[price.slug] = {
                ...(price as SubscriptionModel),
                source: 'contenful',
              };
            }
          });
        }

        return product;
      });

      products.forEach(p => (draft.products[p.slug] = castDraft(p)));
      draft.mode = 'content-loaded';

      break;
    }
    case 'analytics-store-utm-data': {
      draft.analytics.utmData = action.payload;
      break;
    }
  }
};

interface ContextInteface {
  store: State;
  dispatch: React.Dispatch<Action>;
  saveCartContents: () => Promise<void>;
}

const Context = React.createContext<ContextInteface>({
  store: initialState,
  dispatch: action =>
    console.error('Dispatched action outside of an ShoppingCart Context provider', action),
  saveCartContents: async () => {
    return;
  },
});

type Props = {
  children?: React.ReactNode;
  dummyMode?: boolean;
};

function Provider({ children, dummyMode = false }: Props): JSX.Element {
  const [store, dispatch] = useImmerReducer(reducer, initialState);

  const saveCartContents = React.useCallback(async () => {
    await storage.setItem(store.key, JSON.stringify(store.cart));
  }, [store, storage]);

  const productsModels = dummyMode ? null : useStaticQuery<Queries.ProductsQuery>(productsQuery);

  React.useEffect(() => {
    switch (store.mode) {
      case 'stub': {
        if (productsModels) {
          // console.debug('Adding products to context.', productsModels);
          dispatch({ type: 'products-populate', payload: { contentfulData: productsModels } });
        }

        (async () => {
          const data = await storage.getItem(store.key);
          let cart;
          if (data) {
            // console.debug('Restoring previously stored cart.', data);
            cart = JSON.parse(data);
          } else {
            console.debug('Nothing to restore / No previous shopping cart exists.');
          }
          dispatch({
            type: 'cart-restore',
            payload: { cart: { ...cart, loaded: true } as Cart },
          });
        })();

        break;
      }
      case 'content-loaded': {
        // console.debug('Initial render done, loading product data from Stripe.');
        (async () => {
          dispatch({ type: 'store-ready' });
        })();

        break;
      }
    }
  }, [store.mode]);

  React.useEffect(() => {
    saveCartContents().then(() => dispatch({ type: 'cart-saved' }));
  }, [store.cart.items, store.cart.discount]);
  return (
    <>
      {/********************************************************************************
       * COGNITO GLOBAL AUTH CONTEXT
       * ----------------------------------------------------------------------------
       * Wrapping your entire app with <Authenticator.Provider> is how we manage authentication
       * state across the app. This setup ensures that authentication state is accessible
       * throughout the application, enabling us to trigger the login modal contextually
       * or enforce authentication state checks at any level of the component hierarchy.
       * The Amplify UI components are designed to work seamlessly with AWS Cognito,
       * providing a good solution for handling user authentication and session management.
       ********************************************************************************/}
      <Authenticator.Provider>
        {/********************************************************************************
         * AUTH MODAL CONTEXT
         * ----------------------------------------------------------------------------
         ********************************************************************************/}

        <Context.Provider value={{ store, dispatch, saveCartContents }}>
          <ThemeProvider theme={{ ...base, colorScheme: store.layout.theme }}>
            <BaseStyles />
            <TypographyStyles />
            <Theme>{children}</Theme>
          </ThemeProvider>
        </Context.Provider>
      </Authenticator.Provider>
    </>
  );
}

export const productsQuery = graphql`
  query Products {
    allContentfulProduct {
      nodes {
        ...ProductFragment
      }
    }
  }
`;

export { Provider, Context };
