/******************************************************************************************
 * * USE CUSTOMER DATA HOOK
 * ---------------------------------------------------------------------------------------
 * The useCustomerData hook surfaces some convenient data that can be used by the app that
 * is integrating the ELO Partner Library.
 *
 * * exports
 * - useCustomerData
 * - isActiveCustoemr
 * ***************************************************************************************/

import { useEffect } from 'react';
import { useAccountStore } from '../store';
import { UserData } from '../types';

export const useCustomerData = () => {
  /********************************************************************************
   * GET USER ACCOUNT STORE
   * ------------------------------------------------------------------------------
   * *****************************************************************************/
  const userAccount = useAccountStore(state => state.userAccount);

  const isActiveCustomer = () => {
    // const active = userAccount?.subscriptions?.items[0]?.status === 'active';
    const isActive = userAccount?.subscriptions?.items.some(item => item && item.status === 'active');
    return isActive;    
  };

  const isActive = isActiveCustomer();

  return { isActiveCustomer, isActive}
};

export default useCustomerData;
