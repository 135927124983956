// TODO: Update this to use the products.products array rather than the products.ingredients object

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Skeleton } from 'src/features/ui';
import { PreliminaryPlan, Ingredient } from 'src/API';
import { CategoryEdge, CategoryNode } from 'src/types/categories';
import Layer from './Layer';

interface iLayersProps {
  formula: PreliminaryPlan | null;
  categories: CategoryEdge | null;
}

const Layers = ({ formula, categories }: iLayersProps) => {
  const [loaded, setLoaded] = useState(false);
  const [cmsIngredients, setCmsIngredients] = useState<Ingredient[] | null | undefined>(null);
  const [formulaIngredients, setFormulaIngredients] = useState<Ingredient[] | null>(null);

  // console.log('LAYERS STATE VARS', loaded, cmsIngredients, formulaIngredients);
  // console.log('CMS INGREDIENTS', cmsIngredients);

  /*************************************************************************
   * GUMMY CATEGORY and INGREDIENTS
   * -----------------------------------------------------------------------
   * The gummy category contains all possible ingredients for gummies. This
   * is later used as a reference for the formula ingredients.
   * 1. Get the gummy category
   * 2. Extract the ingredients from the gummy category
   * 3. Return the CMS Ingredients or Null if not found
   *************************************************************************/
  const getGummyCategory = () => {
    // 1. Get the gummy category
    const foundCategory = categories?.edges.find(
      (category: CategoryNode) => category?.node?.slug === 'gummy-layer'
    );

    if (foundCategory && foundCategory.node) {
      const { node: gummyCategory } = foundCategory;
      const { ingredient: cmsIngredients } = gummyCategory;
      return cmsIngredients;
    } else {
      // Handle the case where no matching category was found
      console.error('gummy-category not found in CMS, check that.');
      return null;
    }
  };

  /*************************************************************************
   * GET FORULA INGREDIENTS
   * -----------------------------------------------------------------------
   * This function will set the formula ingredients once the formula is loaded.
   * It will return an array of ingredienets or it will return null. This is
   * the function that puts together the formula in a usable way by combining
   * the formula ingredients data in a more usable way.
   *************************************************************************/

  const getFormulaIngredients = () => {
    // If there is no formula or formula.product then exit early
    if (!formula || !formula.products) return null;

    /*************************************************************************
     * FORMULA INGREDIENTS
     * -----------------------------------------------------------------------
     * The formula has an array of all ingredients used in the formula. This
     * is the products[0].ingredients array from the formula. Steps to get:
     * 1. Get the the ingredients array
     * 2. Filter out any null values from the array
     * 3. Exit early if there are not ingredients
     *************************************************************************/
    let formulaIngredients = formula.products[0]?.ingredients ?? [];
    formulaIngredients.filter((ingredient): ingredient is Ingredient => ingredient !== null);
    if (formulaIngredients.length < 1) return null;

    /*************************************************************************
     * PRODUCTS
     * -----------------------------------------------------------------------
     * The formula has an array of products. This is the products[0].products
     * Isolate the products array so we can use it to map over the ingredients
     *************************************************************************/
    const allProducts = formula?.products[0]?.products;
    // console.log('allProducts', allProducts);

    /*************************************************************************
     * MAP OVER THE INGREDIENTS AND ADD PRODUCT DATA KEY ATTRIBUTES
     * -----------------------------------------------------------------------
     * At this point we have the
     * - formulaIngredients array
     * - allProducts array
     *
     * We need to add the product data to the formulaIngredients array so we
     * can use it to render the layers.
     *
     * Steps:
     * 1. Look at every ingredient in the formula.
     * 2. For each ingredient, look at each product in the products array
     * 2.A. For each product, see if it matched the current ingredient by comparing the ingredient slug to the product ingredients
     * 3. Get the product assessments
     * 4. Return an object that merges ingredients, product, and assessments data
     */

    // 1. Look at every ingredient in the formula.
    const ingredientsWithProductData = formulaIngredients.map(ingredient => {
      // 2. For the current ingredient look at each product in the products array
      const product = allProducts?.find(product => {
        // 2.A. For each product, see if it matched the current ingredient by comparing the ingredient slug to the product ingredients
        return product?.ingredients?.some(
          (ingredientItem: any) => ingredientItem.slug === ingredient.slug
        );
      });

      // 3. Get assessment data
      const assements = product?.assessments ?? null;

      // 4. Return an object that merges ingredients, product, and assessments data
      return {
        ...ingredient, // spread the ingredient data
        assessments: assements, // add the assessments data
        layerCount: product?.serving?.quantity ?? 0, // add the layer count for rendering layers more than once if necessary
      };
    });

    // console.log('INGREDIENTS WITH PRODUCT DATA', ingredientsWithProductData);
    return ingredientsWithProductData;
  };

  /*************************************************************************
   * SET CMS INGREDIENTS and FORMULA INGREDIENTS to STATE
   * -----------------------------------------------------------------------
   * This will set the CMS ingredients and the formula ingredients to state
   * only when the formula is loaded.
   *************************************************************************/
  useEffect(() => {
    if (formula) {
      setCmsIngredients(getGummyCategory());
      setFormulaIngredients(getFormulaIngredients());
    }
  }, [formula]);

  /*************************************************************************
   * SET LOADED STATE to TRUE
   * -----------------------------------------------------------------------
   * Only set the loaded state to true when the formula ingredients and the
   * CMS ingredients are loaded.
   *************************************************************************/
  useEffect(() => {
    if (cmsIngredients && formulaIngredients) {
      setLoaded(true);
    }
  }, [cmsIngredients, formulaIngredients]);

  // console.log('loaded', loaded);
  // console.log('formulaIngredients', formulaIngredients);
  // console.log('cmsIngredients', cmsIngredients);

  /*************************************************************************
   * GET CMS INGREDIENT DETAILS
   * -----------------------------------------------------------------------
   * This function will use the formula ingredient slug as a key to find the
   * ingredient details from the CMS. This will be used to render the
   * ingredient details in the UI.
   *************************************************************************/
  const getCmsIngredientDetails = (slug: string | undefined) => {
    // console.log('Looking for slug ', slug)
    if (!slug || !cmsIngredients) return null;
    const ingredient = cmsIngredients.find((ing: any) => ing.eloProductSlug === slug);
    return ingredient;
  };

  /*************************************************************************
   * RENDERING THE INGREDIENTS
   * To render the ingredients we loop through the formula ingredients.
   * The reason we use the formula ingredients is because it is an object that
   * contains the ingredient data, the product data, and the assessments data.
   * This JSON data object was created in the getFormulaIngredients function.
   * It contains data attributes that are not structured in a usable way in the
   * raw formula data returned from the API.
   *
   * To render the layers:
   * 1. Loop through the formulaIngredients array
   * 2. For each ingredient, get the ingredient details from the CMS Data
   * 
   * Loop through the formula ingredients and render the ingredients from the CMS
   * We may need to add some logic to re-order ingredients based on marketing requirements
   * For this or for future itterations we may need to expand the CMS ingredients data model and add data
   */

  // console.log('FORMULA INGREDIENTS', formulaIngredients);

  return (
    <>
      {!loaded && <Skeleton width="100%" height="100%" />}

      {loaded && (
        <div>
          {
            // 1. Loop through the formulaIngredients
            formulaIngredients?.map((ingredient, index) => {
              // 2. For each ingredient, get the ingredient details from the CMS Data
              // console.log('Looking for ingredient.slug', ingredient?.slug)
              const cmsIngredient = getCmsIngredientDetails(ingredient?.slug);
              // 2.A. Exit early if there is no ingredient data in the cms
              if (!cmsIngredient) return null;

              // return (
              //   <Layer
              //     key={index}
              //     ingredientIndex={index}
              //     // layerIndex={layerIndex}
              //     dose={ingredient?.dose}
              //     cmsIngredient={cmsIngredient}
              //     assessments={ingredient?.assessments}
              //   />
              // );

              {
                /**********************************************
                 * REPEAT LAYERS BASED ON LAYER COUNT VALUE
                 * --------------------------------------------
                 * Layer Count is being added to the formulaIngredients
                 * array in the getFormulaIngredients function.
                 */
              }

              // Get and/or set the layer count
              let layerCount = ingredient.layerCount;
              if (!layerCount || layerCount <= 0) layerCount = 1;

              // When you return the layer component, repeat it based on the layerCount
              return (
                <>
                  {/* Layer Count {layerCount} */}
                  {[...Array(layerCount)].map((_, layerIndex) => {
                    return (
                      <Layer
                        key={layerIndex}
                        ingredientIndex={index}
                        layerIndex={layerIndex}
                        dose={ingredient?.dose}
                        cmsIngredient={cmsIngredient}
                        assessments={ingredient?.assessments}
                      />
                    );
                  })}
                  {/* <Layer key={index} cmsIngredient={cmsIngredient} /> */}
                </>
              );
            })
          }
        </div>
      )}
    </>
  );
};

export default Layers;

const Container = styled.div`
  width: 100%;
  padding-inline: 20px;
`;

const Title = styled.div`
  padding: 20px 0;
`;
