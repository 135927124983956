import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Author from '../author/Author2';
import TimeToRead from '../timetoread/TimeToRead';
import { AuthorProps } from '../author/Author';
import { ImageFile } from '../types';
import Image from '../image/Image';

interface Props {
  title: string;
  author: AuthorProps;
  timeToRead: number;
  url: string;
  thumbnail: ImageFile;
}

const Thumbnail = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: 'Everett Medium';
  font-size: 18px;
  margin: 10px 0;
`;

const Cell = styled.div`
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0.5rem;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.tablet} {
    flex: 0 0 33%;

    &:before {
      content: '';
      display: table;
      padding-top: 100%;
    }
  }
`;

const Item = styled.div`
  flex-grow: 1;
  font-size: 13px;

  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: rgba(0, 0, 0, 0.85);
  }
  a:hover {
    color: rgba(0, 0, 0, 0.85);
  }
`;

export const BiomarkerBlocksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;
  gap: 20px 0;
`;

export default function BiomarkerBlock({
  title,
  author,
  timeToRead,
  url,
  thumbnail,
}: Props): JSX.Element {
  return (
    <Cell>
      <Item>
        <Link to={url}>
          <Thumbnail>
            <Image gatsbyImageData={thumbnail?.gatsbyImageData} />
          </Thumbnail>
          <Title>{title}</Title>
          <Author author={author} />
          <TimeToRead timeToRead={timeToRead} />
        </Link>
      </Item>
    </Cell>
  );
}
