import React, { useState } from 'react';
import { Box, Typography, Button, Stack, Checkbox, FormControlLabel } from '@mui/material';
import { refreshAccountStore } from '../../../../data';
import { deleteUserAccount } from '../../../../actions';
import DeleteLoading from './DeleteLoading';

interface DeleteModalProps {
  onClose: () => void;
}

const DeleteModal = ({ onClose }: DeleteModalProps) => {
  /**********************************************************************
   * STATE
   * ********************************************************************/
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  /**********************************************************************
   * MODAL STYLES
   * ********************************************************************/
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #AAA',
    boxShadow: 24,
    pt: 1,
    px: 2,
    pb: 2,
    borderRadius: 2,
  };

  /**********************************************************************
   * HANDLE CHECKBOX CONFIRMATION
   * ********************************************************************/
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  /**********************************************************************
   * HANDLE REFRESH ACCOUNT STORE
   * ********************************************************************/
  // * REPEAT FUNCTION DELAY
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const handleRefreshAccountStore = async () => {
    // Run in a loop
    for (let attempt = 1; attempt <= 5; attempt++) {
      const account = await refreshAccountStore();
      // Verify that the account now has the new form subscription

      const deleteScheduled = account?.deleteScheduledAfter ?? null;
      if (deleteScheduled) {
    
        return;
      } else {
    
        await delay(3000);
      }
    }

    
    return;
  };

  /**********************************************************************
   * HANDLE DELETE ACCOUNT
   * ********************************************************************/
  const handleDeleteAccount = async () => {
    // * STEP: Set loading to true
    setLoading(true);
    // * STEP: Call the delete account function
    // console.log('Deleting Account');
    await deleteUserAccount({ reason: 'User Requested Account Deletion' });
    // STEP: Update Account Stage
    // console.log('Refreshing Account Store');
    await handleRefreshAccountStore();

    onClose();
  };

  return (
    <>
      <Box
        position={'relative'}
        width={['94vw', '420px', '480px']}
        minHeight={['440px']}
        maxHeight={['94vh']}
        sx={{
          ...style,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto', // This ensures that the content area can scroll if needed
        }}
      >
        {loading && <DeleteLoading />}
        <Typography variant="h4" sx={{ my: '.47rem' }}>
          Are you sure?
        </Typography>
        <Box
          width={'100%'}
          border={'1px solid red'}
          p={'.47rem'}
          my={'1.47rem'}
          sx={{ borderRadius: 1 }}
        >
          <Typography variant="h4" sx={{ my: '.47rem' }} color="red">
            WARNING
          </Typography>
          <Typography variant="body2" gutterBottom>
            This action cannot be undone. Deleting your account will erase all personal information.
            You may still be responsible for a balance due for the last order that was prepared or
            shipped.
          </Typography>
        </Box>
        <Stack spacing={2}>
          <FormControlLabel
            label="Check to confirm"
            control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{ color: 'red', '&.Mui-checked': { color: 'red' } }} // Apply styles directly
              />
            }
          />
          <Button
            variant="contained"
            onClick={handleDeleteAccount}
            sx={{
              background: 'red',
              color: 'white',
              '&:hover': {
                background: 'red',
                color: 'white',
              },
            }}
            disabled={!checked}
          >
            DELETE MY ACCOUNT
          </Button>
          <Button variant="outlined" onClick={onClose}>
            CANCEL
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default DeleteModal;
