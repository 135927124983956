export enum Currency {
  USD = 'usd',
}

export const formatPrice = (pennies: number, currency: Currency = Currency.USD): string => {
  if (typeof pennies !== 'number' || isNaN(pennies)) {
    throw new Error('Invalid input: pennies must be a number.');
  }

  const dollars = pennies / 100;

  // Format the number with commas and two decimal places
  const formattedDollars = dollars.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  switch (currency) {
    case Currency.USD:
      return `$${formattedDollars}`;
    default:
      throw new Error('Unsupported currency');
  }
};

export default formatPrice;
