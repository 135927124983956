
/**
 * Responsive V2 - Responsive utility function
 * Version 2 is designed to minimize the amount of generated CSS code
 * by combining all values provided by a responsive component into combined media queries.
 * In version 1, each CSS property and value pair would generate a a separate media query.
 * This refactor is designed to reduce the amount of CSS code generated by the responsive utility function.
 */

const breakpoints = [0, 600, 900, 1200, 1536];
// const breakpoints = [0, 750, 821, 1200, 1800];

interface ResponsiveV2 {
  cssPropsVals: Record<string, Array<string | number>>;

}



const responsiveV2 = ({ cssPropsVals }: ResponsiveV2) => {
  if (!cssPropsVals) {
    return '';
  }

  const mediaQueries = breakpoints
    .map((breakpoint, index) => {
      const rules = Object.entries(cssPropsVals)
        .map(([prop, values]) => {
          const value = values[index] || values[values.length - 1];
          return `${prop}: ${value};`;
        })
        .join(' ');

      if (index === 0) {
        return rules;
      }

      return `@media screen and (min-width: ${breakpoint}px) { ${rules} }`;
    })
    .filter(Boolean)
    .join(' ');

  return mediaQueries;
};

export default responsiveV2;
