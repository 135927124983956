import * as React from 'react';
import { Block, SectionProps } from 'types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Layout, Col, Row, Typography } from 'antd';
import { DefaultRichTextRenderOptions } from '../../../../utils/render';
import { FullscreenBackgroundImage } from 'ui';
import EmbeddedBlock from 'src/features/ui/embeddedblock/EmbeddedBlock';
const { Title } = Typography;

const blockTypes = {
  Embedded: EmbeddedBlock, // we just support EmbeddedBlocks here in the basic section
};

const PageSectionBasic = ({
  slug,
  headline,
  subHeading,
  body,
  blocks,
  background,
  mobileBackground,
  theme,
}: SectionProps) => {
  function renderBlocks(blocks: Block[]): JSX.Element {
    const sectionBlocks = blocks.map((block) => {
      const SpecificBlock = blockTypes[block.type];
      return {
        block: <SpecificBlock className={`${block.type.toLowerCase()}`} block={block} />,
        size: block.size,
      };
    });

    if (sectionBlocks.length > 0) {
      return (
        <Row key={`${slug}-blockrow`} gutter={[16, 16]} justify="center">
          {sectionBlocks.map((item, index) => {
            return (
              <Col key={`${slug}-block-${index}`} className={`block ${item.size}`} span={6}>
                {item.block}
              </Col>
            );
          })}
        </Row>
      );
    }

    return <></>;
  }

  return (
    <Layout className={'article-layout'}>
      <FullscreenBackgroundImage
        // ref={ref}
        fluid={background?.fluid}
        mobileFluid={mobileBackground?.fluid}
        className={'page-section-basic page-section-background ' + slug}
      >
        {headline && (
          <Title className="headline" level={1}>
            {headline}
          </Title>
        )}
        {subHeading && (
          <Title className="subheading" level={2}>
            {subHeading}
          </Title>
        )}
        {body && <div className="body">{renderRichText(body, DefaultRichTextRenderOptions)}</div>}
        {blocks && <div className="blocks">{renderBlocks(blocks)}</div>}
      </FullscreenBackgroundImage>
    </Layout>
  );
};

export default PageSectionBasic;
