import * as React from 'react';
import get from 'lodash/get';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';
import Video from '../video/Video';

export interface ImageProps {
  readonly title?: string | null;
  readonly className?: string | null;
  readonly svg?: {
    readonly content: string | null;
  } | null;
  readonly gatsbyImageData?: IGatsbyImageData | Record<string, unknown> | null;
  readonly file?: {
    readonly contentType: string | null;
    readonly url: string | null;
  } | null;
  readonly alt?: string;
  loading?: 'eager' | 'lazy' | undefined;
  onLoad?: () => void;
  debug?: boolean;
}

// Render inline SVG with fallback non-svg images
const Image = ({
  className = null,
  svg = null,
  gatsbyImageData = null,
  file = null,
  alt = '',
  loading = 'lazy',
  onLoad,
  debug = false,
}: ImageProps): JSX.Element => {
  const contentType = get(file, 'contentType');

  const isVideo = contentType === 'video/quicktime' || contentType === 'video/mp4';

  // Gatsby images
  if (!isVideo && gatsbyImageData !== null) {
    if (className === 'avatar') {
      return (
        <FixedCircularGatsbyImage
          image={gatsbyImageData as IGatsbyImageData}
          className={className ?? undefined}
          alt={alt}
        />
      );
    }

    return (
      <GatsbyImage
        image={gatsbyImageData as IGatsbyImageData}
        className={className ?? undefined}
        alt={alt}
        loading={loading}
        onLoad={onLoad}
      />
    );
  }

  if (isVideo) {
    return <Video videoSrcURL={file?.url} />;
  }

  // Inlined SVGs
  if (svg && svg.content) {
    
    const dimensions = get(file, 'details.image');
    const style = {
      width: get(dimensions, 'width'),
    };
    return (
      <div
        style={style}
        className={className ?? undefined}
        dangerouslySetInnerHTML={{ __html: svg.content }}
      />
    );
  }

  return file && file.url ? (
    <img className={className ?? undefined} src={file.url} alt={alt} />
  ) : (
    <></>
  );
};

const FixedCircularGatsbyImage = styled(GatsbyImage)`
  -webkit-mask-image: -webkit-gradient(white, black);
  border-radius: 50%;
  img {
    -webkit-mask-image: -webkit-gradient(white, black);
    border-radius: 50%;
  }
`;

export default Image;
