import { renderRichText } from 'gatsby-source-contentful/rich-text';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { AnswerSchemaRichTextRenderOptions, DefaultRichTextRenderOptions } from '../../../utils/render';
import JsonLDScript from '../jsonld/JsonLDScript';

type AnswerSchemaProps = {
  answer: any;
};

const AnswerSchema = ({ answer }: AnswerSchemaProps): JSX.Element => {
  // const answerRichText = renderRichText(answer?.answer, AnswerSchemaRichTextRenderOptions);
  const answerRichText = renderRichText(answer?.answer, DefaultRichTextRenderOptions);
  const text = ReactDOMServer.renderToString(<>{answerRichText}</>);
  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: answer?.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: text,
        },
      },
    ],
  };
  return <JsonLDScript jsonld={jsonld} />;
};

export default AnswerSchema;
