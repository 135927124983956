import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';
import { Image } from 'ui';
import { Product } from './Product.types';
import ProductCardFooter from './components/ProductCardFooter';
import { Typography } from 'ui';

type Props = {
  readonly product: Product;
};

const ProductCard = ({ product }: Props): JSX.Element => {
  const myStyle = 'linear-gradient(0deg, #C1C19B 0%, #FFE2DE 100.01%)';
  const myStyles = [
    {
      background: 'linear-gradient(0deg, #C1C19B 0%, #FFE2DE 100.01%)',
    },
  ];

  const myCustomStyles = JSON.parse(product.customStyles?.internal?.content || '{}');

  const link = product?.productCardCta?.link || `/products/${product.slug}`;
  const image =
    product.productCardImage ?? product.featuredImageResponsive ?? product.featuredImage;
  const responsiveImage = isMobile ? image.mobile ?? image : image.desktop ?? image;

  return (
    <ProductContainer className="product-card" myStyles={myCustomStyles}>
      <TitleContainer>
        <Typography variant="h3" component="div" sx={{ paddingBlock: '.47rem' }}>
          {product.title}
        </Typography>
        {product.productCardTag && (
          <Tag>
            <Typography variant="body2">{product.productCardTag}</Typography>
          </Tag>
        )}
      </TitleContainer>

      {/* <Link to={link} className="product-img-link"> */}
      <ProductImage>
        <Image key="featured_image" {...responsiveImage} className="product_image" />
      </ProductImage>
      {/* </Link> */}
      <ProductCardFooter product={product} includes={product?.includedProductCard || []} />
    </ProductContainer>
  );
};

// background: linear-gradient(0deg, #C1C19B 0%, #FFE2DE 100.01%);

// background: ${myStyle};

const ProductContainer = styled.div(({ theme, myStyles }) => {
  // Convert myStyles object to a string of CSS rules
  const styleString = Object.entries(myStyles).reduce((styleString, [key, value]) => {
    return `${styleString} ${key}: ${value};`;
  }, '');

  return `
      ${styleString} /* Spread the CSS rules from myStyles */
      border-radius: 3px;
      padding: 20px 22px 20px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      h2 {
        margin: 0;
        text-align: left;
      }
      ${theme.breakpoints.desktop} {
        padding: 20px 30px;
      }
      a.product-img-link {
        width: 100%;
      }
      .product_image {
        width: 100%;
        img { 
          transition: all 0.25s ease-in-out;
          transform: scale(1); 
        }
      }
      
      &:hover {
        .product_image {
          img { transform: scale(1.01); }
        }
      }
    `;
});

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  text-align: center;
  h2 {
    text-align: center;
    margin-bottom: 10px;
    letter-spacing: -0.5px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    flex-flow: row wrap;
    justify-content: space-between;
    h2 {
      text-align: left;
      margin-bottom: 1rem;
    }
  }
`;

const Tag = styled.span`
  background: transparent;
  padding: 5px 10px;
  margin-bottom: 1rem;
  font-size: 11px;
  line-height: 10.67px;
  ${({ theme }) => theme.breakpoints.tablet} {
    margin-bottom: 0rem;
    font-size: 14px;
    line-height: 13.58px;
  }
`;

const ProductImage = styled.div`
  width: 100%;
  // margin: 10px 0;
`;

export default ProductCard;
