/* eslint react/display-name: "off" */

import * as React from 'react';
import styled from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import { BLOCKS } from '@contentful/rich-text-types';
import SectionContainer from '../../SectionContainer';

import { SectionProps } from 'types';

import { Author, HeadlineGroup } from 'ui';

const InlineVideoContainer = styled.div`
  max-width: 650px;
  margin: auto;
  video {
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    display: none;
  }
`;
const SideVideoContainer = styled.div`
  display: none;
  ${({ theme }) => theme.breakpoints.desktop} {
    display: initial;
    padding-left: 50px;
    video {
      margin-top: 25px;
      max-width: 400px;
    }
  }
  ${({ theme }) => theme.breakpoints.wide} {
    padding-left: 100px;
    video {
      margin-top: 50px;
      max-width: 600px;
    }
  }
`;

const TextContent = styled.div`
  padding-top: 35px;
  max-width: 1080px;
`;

const FlexContainer = styled.div`
  ${({ theme }) => theme.breakpoints.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

/**
 * HACK ALERT
 * We need to create our own video component because React behaves strangely with the video tag.
 * The problem is that React removes (at least) attributes `muted` and `playsinline` when rendering
 * so we need to explicitly set the tag.
 *
 * - We need `muted` because otherwise browsers won't autoplay the video.
 * - We need `playsinline` because otherwise mobile Safari will fullscreen the video.
 * - `webkit-playsinline` is a deprecated way of defining the above, we include it just in case.
 *
 * Ref: https://stackoverflow.com/questions/59404642/react-html-video-tag-wont-autoplay-on-mobile-devices
 */
const Video = ({ src }: { src: string }): JSX.Element => {
  const innerHtml = {
    __html: `
            <video
                src=${src}
                webkit-playsinline
                playsinline
                autoplay
                loop
                muted
            />
        `,
  };
  return <div dangerouslySetInnerHTML={innerHtml}></div>;
};

export default function PageSectionCoaches({
  headline,
  subHeading,
  body,
  images,
  slug,
}: SectionProps) {
  const richText = renderRichText(body, renderOptions);
  return (
    <SectionContainer id={slug} usePadding contentBackgroundColor={'#E2E2E2'}>
      <FlexContainer>
        <div>
          <HeadlineGroup headline={headline} subHeading={subHeading} />
          {images && images.length > 0 && (
            <InlineVideoContainer>
              <Video src={images[0].desktop.file.url} />
            </InlineVideoContainer>
          )}
          <TextContent>{richText}</TextContent>
        </div>
        <div>
          {images && images.length > 0 && (
            <SideVideoContainer>
              <Video src={images[0].desktop.file.url} />
            </SideVideoContainer>
          )}
        </div>
      </FlexContainer>
    </SectionContainer>
  );
}

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => <p>{children}</p>,
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const embed = node?.data?.target;
      if (embed?.__typename === 'ContentfulAuthor') {
        return <Author {...embed} />;
      }
    },
  },
};
