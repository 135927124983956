/******************************************************************************************
 * HOOK useAuthStatus
 * ---------------------------------------------------------------------------------------
 * This hook is used to provide the current authentication status of the user. This is
 * useful for determining if the user is logged in or not. This is important for operations
 * where a user may or may not be logged in when performing graphql queries that require
 * an authMode to be set. For example: getPreliminaryPlan query requires an authMode of
 * "userPool" to be set if a user is logged in and "iam" if the user is not logged in.
 * ****************************************************************************************/

import { useAccountStore } from '../store';

export const useAuthStatus = () => {
    
  const userAccount = useAccountStore(state => state.userAccount);

  const handleCheckAuth = () => {    
    return userAccount?.id ? true : false;
  };

  const isAuthenticated = handleCheckAuth();  

  return { isAuthenticated };
};