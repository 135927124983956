import * as React from 'react';
import styled from 'styled-components';
import { SectionProps } from 'types';

import {SectionContainer, HeadlineContainer} from 'ui';

import Feature from './components/Feature';

export default function PageSectionFeatures({ headline, body, blocks }: SectionProps): JSX.Element {
  return (
    <SectionContainer id="features" usePadding contentBackground={'#fff'}>
      <Headline>
        <HeadlineContainer headline={headline} body={body} className="features-headline-container" />
      </Headline>
      {blocks.map((b, index) => (
        <Feature {...b} right={index % 2 !== 0} key={b.slug} />
      ))}
    </SectionContainer>
  );
}
const Headline = styled.div`
  .typography-p {
    ${({ theme }) => theme.breakpoints.tablet} {
      max-width: 500px;
    }
  }
`;
