import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Typography, Icon } from 'ui';

export type CollapseProps = {
  // question: any;
  title: string;
  isOpen?: boolean;
  icon?: string;
  iconColor?: string;  
  onClick?: () => void;
};

const Collapse = ({
  title,
  isOpen = false,
  onClick,
  children,
  icon,
  iconColor,  
}: React.PropsWithChildren<CollapseProps>) => {

  /******************************************************
   * STATE VARIABLES
   * ----------------------------------------------------
   * open, setOpen ; Collapse Open State
   * ***************************************************/
  const [open, setOpen] = useState(isOpen);

  useEffect(() => setOpen(isOpen), [isOpen]);
  
  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const contentHeight = contentRef.current ? contentRef.current.scrollHeight : 0;

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      setOpen(!open);
    }
  };

  return (
    <Container className={open ? 'open' : ''}>
      <Box>
        <button onClick={() => handleClick()} type="button">
          <Box width={'100%'} display={'flex'} alignItems={'center'}>
            {icon && (
              <Box display={'flex'} alignItems={'center'} pr={['1rem']} sx={{ flexShrink: 0 }}>
                <Icon id={icon} stroke="none" fill={iconColor} />
              </Box>
            )}

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-start'}
              sx={{ flexGrow: 1 }}
            >
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
            </Box>

            <Box display={'flex'} alignItems={'center'} sx={{ flexShrink: 0 }}>
              <Toggle className={open ? '' : 'open'} />
            </Box>
          </Box>
        </button>
        <div ref={contentRef} style={{ height: `${contentHeight}px` }}>
          <Box>
            <Typography variant="body2">{children}</Typography>
          </Box>
        </div>
      </Box>
    </Container>
  );
};
export default Collapse;

const Container = styled.div(
  ({ theme }) => `
    border: 1px solid #D7D7D5;
    border-radius: 5px;
    margin-bottom: 13px;
    position: relative;
    > div {
        border: 2px solid transparent;
        border-radius: 5px;
        transition: all 0.25s ease-in-out;
        margin: -2px;
    }
    &.open {
        border: 1px solid ${theme.colors.primary};
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        > div {
            border: 2px solid ${theme.colors.primary};
        }
    }
    > div > button {
        background: transparent;
        border: none;
        padding: 24px;
        color: ${theme.colors.eloBlack};
        font-size: 19px;
        line-height: 25.65px;
        font-family: 'Everett Medium';
        width: 100%;
        text-align: left;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            max-width: 80%;
        }
    }
    &:not(.open) {
        > div > div {
            height: 0 !important;
            opacity: 0;
        }
    }
    > div > div {
        overflow: hidden;
        transition: all 0.25s ease-in-out;

        opacity: 1;
    }
    > div > div > div {
        padding: 0 24px 24px;
        > * {
            font-size: 15px;
            line-height:  25.75px;
            color: ${theme.colors.eloDarkGrey};
        }
        strong {
            font-weight: 500;
        }
        a,a:hover {
            color: ${theme.colors.eloDarkGreen};
        }
    }
  
`
);
export const Toggle = styled.div(
  ({ theme }) => `

    width: 18px;
    height: 2px;
    background: ${theme.colors.eloBlack};
    position: relative;
    transition: .25s all cubic-bezier(.17,.67,.09,.97);
    
    &.open::after {
      transform: rotate(90deg);
      opacity: 1;
    }
    
    &.open {
      transform: rotate(90deg);
    }
    
    &::after {
        content: "";
        width: 18px;
        transition: .25s all cubic-bezier(.17,.67,.09,.97);
        transition-delay: .1s;
        height: 2px;
        position: absolute;
        opacity: 0;
        background: ${theme.colors.eloBlack};
      }
  
`
);
