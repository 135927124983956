/******************************************************************************************
 * UPDATE ACCOUNT STORE
 * ---------------------------------------------------------------------------------------
 * This function is used to update the account store with new data. It is useful when you 
 * need to refresh the store after a user has upgraded their subscription.
 * ***************************************************************************************/
 
import { getUserAccount, getUserConfiguration, initializeInvoices } from './';

/************************************************************************
 * ACCOUNT STORE
 * -----------------------------------------------------------------------
 * **********************************************************************/
import { useAccountStore } from '../store';

export const refreshAccountStore = async () => {

    /**********************************************************************
     * * SUBSCRIBE TO STORE DATA AND GET UPDATE FUNCTION
     * ********************************************************************/
    const updateAccountState = useAccountStore.getState().updateAccountState;
    
    /**********************************************************************
     * * SET LOADING DATA TO TRUE
     * ********************************************************************/
    // updateAccountState(state => {
    //     state.loadingData = true;
    // });
    
    /**********************************************************************
     * * OPTIMIZE THE DATA RETRIEVAL BY USING PROMISE.ALL
     * ********************************************************************/    
    const [
        userConfiguration,
        userAccount
    ] = await Promise.all([
        getUserConfiguration(), // * GET USER CONFIGURATION
        getUserAccount(), // * GET USER ACCOUNT        
    ]);

    /**********************************************************************
     * * UPDATE THE STORE
     * ********************************************************************/
    updateAccountState(state => {
        state.userAccount = userAccount ?? null;
        state.userConfiguration = userConfiguration?.userConfiguration ?? null;        
        // state.loadingData = false; // * SET LOADING DATA TO FALSE
    });    

    
    /**********************************************************************
     * * POST PROCESSING AFTER DATA HAS BEEN LOADED
     * --------------------------------------------------------------------
     * Note that initializeInvoices does not call the API. It gets the
     * data from the central store and updates the invoices in the central
     * store. This is because the invoices query in the ELO API does not
     * return the data necessary to organize and display the invoices in
     * the way that we need to. Specifically, it doesn't return attributes
     * that allow us to identify which product the invoice was for. Therefore
     * we need to stitch the data together ourselves from various sources.
     * 
     * The initializeInvoices function encapsulates this process.
     * 
     * It also handles updating the central state with the new data.
     * ********************************************************************/    
    await initializeInvoices(); // * INITIALIZE INVOICES
    
    return userAccount

}

export default refreshAccountStore;