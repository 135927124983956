/********************************************************************************
 * DATA FUNCTION: getUserAccount
 * ----------------------------------------------------------------------------
 * This function is used to get the user account from the API. It is used in the
 * useAuthListener hook to get the user account data once a user is logged in.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/**********************************************************************
 * * GRAPHQL QUERY and Types
 * --------------------------------------------------------------------
 * * IMPORTANT
 * It's critically important that the query and types are explicitly
 * defined within the component. This is because the AWS Amplify client
 * component does not resolve the promise if there is a Partial return
 * of the data. The result is that the network request is successful,
 * the data is not returned, and the try catch block simply ends without
 * data or an error being returned.
 *
 * TODO: Move Data Queries to a separate utility component.
 *
 * ********************************************************************/
import { me } from './graphql/query.me';
import { UserData } from '../types';

/**********************************************************************
 * * DEFINE GRAPHQL QUERY RESULT TYPE
 * --------------------------------------------------------------------
 * The API response includes me: {}
 * We are removing me in our types so we need to add it here to type
 * the response properly. However, the UserData type does not contain
 * the 'me' property. So we use this for the response but we don't
 * include it in the state update.
 * ********************************************************************/
type UserDataResult = {
  me: UserData;
};


/**********************************************************************
 * * BUILD USER ACCOUNT QUERY
 * --------------------------------------------------------------------
 * ********************************************************************/
const buildQuery = (): GraphQLOptions => {
  const query = me;
  const authMode = 'userPool';
  const variables = { input: {} } as any;
  
  return {
    query: query,
    authMode: authMode,
    variables,
  };
};

/**********************************************************************
 * * GET USER ACCOUNT
 * --------------------------------------------------------------------
 * ********************************************************************/
export const getUserAccount = async () => {    
  try {
    const query = buildQuery();     
    const result = (await client.graphql(query)) as GraphQLResult<UserDataResult>;    
    if (result.data) {
      const userAccount = result.data.me;              
      return userAccount;
    }
  } catch (error) {
    console.error('Error getting user account:', error);
  }
};

export default getUserAccount;
