import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';

import SectionContainer from '../../SectionContainer';
import { CtaLink, Image } from 'ui';
import { SectionProps } from 'types';

export default function CuratedArticles({ headline, body, slug }: SectionProps) {
  const content = renderRichText(body, renderOptions);
  return (
    <SectionContainer id={slug} usePadding contentBackgroundColor={'#E2E2E2'}>
      <StyledTitle>{headline}</StyledTitle>
      <HorizontalScroll>{content}</HorizontalScroll>
      <LinkContainer>
        <CtaLink label="See all articles" to="/articles/" />
      </LinkContainer>
    </SectionContainer>
  );
}

type ArticleBoxProps = {
  slug: string;
  title: string;
  thumbnail: {
    gatsbyImageData: any;
  };
  cardThumbnail: {
    gatsbyImageData: any;
  };
  blurb: {
    blurb: string;
  };
  publishedAt: string;
};

const HorizontalScroll = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  white-space: nowrap;
`;

const StyledTitle = styled.h1`
  margin-left: 20px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 40px 0;
`;

const cardWidth = {
  mobile: 316,
  tablet: 373,
  desktop: 373,
  wide: 614,
};
const cardHeight = {
  mobile: 558,
  tablet: 565,
  desktop: 565,
  wide: 888,
};
const imageWidth = {
  mobile: cardWidth.mobile,
  tablet: cardWidth.tablet,
  desktop: cardWidth.desktop,
  wide: cardWidth.wide,
};
const imageHeight = {
  mobile: 318,
  tablet: 338,
  desktop: 338,
  wide: 499,
};

const Box = styled.div`
  width: ${cardWidth.mobile}px;
  min-width: ${cardWidth.mobile}px;
  min-height: ${cardHeight.mobile}px;
  ${({ theme }) => theme.breakpoints.tablet} {
    width: ${cardWidth.tablet}px;
    min-width: ${cardWidth.tablet}px;
    min-height: ${cardHeight.tablet}px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    width: ${cardWidth.desktop}px;
    min-width: ${cardWidth.desktop}px;
    min-height: ${cardHeight.desktop}px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    width: ${cardWidth.wide}px;
    min-width: ${cardWidth.wide}px;
    min-height: ${cardHeight.wide}px;
  }
  margin: 10px;
  white-space: normal;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ImageContainer = styled.div`
  img {
    object-fit: cover;
    width: ${imageWidth.mobile}px;
    height: ${imageHeight.mobile}px;
    ${({ theme }) => theme.breakpoints.tablet} {
      width: ${imageWidth.tablet}px;
      height: ${imageHeight.tablet}px;
    }
    ${({ theme }) => theme.breakpoints.desktop} {
      width: ${imageWidth.desktop}px;
      height: ${imageHeight.desktop}px;
    }
    ${({ theme }) => theme.breakpoints.wide} {
      width: ${imageWidth.wide}px;
      height: ${imageHeight.wide}px;
    }
  }
`;

const ArticleContent = styled.div`
  padding: 14px 16px;
  ${({ theme }) => theme.breakpoints.wide} {
    padding: 18px 25px;
  }
`;

const Small = styled.small`
  font-size: 14px;
  line-height: 17px;
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
    line-height: 20px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    font-size: 23px;
    line-height: 28px;
  }
`;

const PublishedAt = styled(Small)`
  opacity: 0.4;
`;

const ArticleTitle = styled.div`
  font-family: 'Everett Medium';
  margin: 10px 0;
  font-size: 19px;
  line-height: 23px;
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 23px;
    line-height: 26px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    font-size: 31px;
    line-height: 36px;
  }
`;

const Blurb = styled(Small)`
  margin-bottom: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const ReadMoreLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: underline;
  font-size: 14px;
  line-height: 17px;
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 28px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const ArticleBox = ({
  slug,
  publishedAt,
  title,
  thumbnail,
  cardThumbnail,
  blurb,
}: ArticleBoxProps): JSX.Element => {
  return (
    <Box>
      <ImageContainer>
        {cardThumbnail && <Image gatsbyImageData={cardThumbnail.gatsbyImageData} alt="" />}
        {!cardThumbnail && thumbnail && (
          <Image gatsbyImageData={thumbnail.gatsbyImageData} alt="" />
        )}
      </ImageContainer>
      <ArticleContent>
        <PublishedAt>{new Date(publishedAt).toDateString()}</PublishedAt>
        <ArticleTitle>{title}</ArticleTitle>
        <Blurb>{blurb.blurb}</Blurb>
        <ReadMoreLink style={{}} to={`/articles/${slug}`}>
          Read more
        </ReadMoreLink>
      </ArticleContent>
    </Box>
  );
};

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const embed = node?.data?.target;
      if (embed?.__typename === 'ContentfulArticle') {
        return <ArticleBox {...embed} />;
      }
    },
  },
};
