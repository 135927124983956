import * as React from 'react';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import styled, { useTheme } from 'styled-components';
import times from 'lodash/times';
import { isMobile } from 'react-device-detect';
import { Block, SectionProps } from 'types';
import { SectionContainer, Icon, Image, HeadlineContainer } from 'ui';

const Headline = styled.div(
  ({ theme }) => `
  .reviews-headline-container {
    ${theme.breakpoints.mobile} {
      padding: ${theme.padding.vertical.mobile}px ${theme.padding.horizontal.mobile}px 0;
    }
    .typography-p {
      
      ${theme.breakpoints.mobile} {
        max-width: 85%;
        margin-bottom: 0;
      }
      ${theme.breakpoints.tablet} {
        max-width: 85%;

      }
      ${theme.breakpoints.desktop} {
        max-width: 600px;
      }
    }
  }
`
);

const CarouselContainer = styled.div(
  ({ theme }) => `
  background: ${theme.colors.eloBlack};
  position: relative;

  ${theme.breakpoints.tablet} {
    margin: 0 -140px;
    padding-left: 140px;
  }
  .slick-track {
    ${theme.breakpoints.tablet} {
    }
  }
  ${theme.breakpoints.mobile} {
    margin-left: -79px;
    padding: ${theme.padding.vertical.mobile}px 0 ${theme.padding.vertical.mobile}px ${theme.padding.horizontal.mobile}px;
  }
`
);
const BlockContainer = styled.div`
  display: flex !important;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  ${({ theme }) => theme.breakpoints.tablet} {
    flex-flow: row;
  }  
  ${({ theme }) => theme.breakpoints.mobile} {
    width: 600px;
    margin: 1rem;
  }  
`;

const CarouselBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  color: #fff;
  ${({ theme }) => theme.breakpoints.mobile} {
    width: 267px;
  }
  ${({ theme }) => theme.breakpoints.tablet} {
    max-width: 330px;
    padding-right: 30px;
  }
`;
const Quote = styled.p`
  color: #fff;
  font-family: 'Everett Light';
  font-size: 15px;
  line-height: 20.25px;
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 20px;
    line-height: 27px;
  }
`;

const Author = styled.p`
  font-family: 'Everett Light';
  font-size: 11px;
  line-height: 15px;
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 12px;
    line-height: 16.2px;
  }
`;
const ReadMoreLink = styled(Link)`
  font-size: 14px;
  line-height: 17px;
`;

const ReviewImageContainer = styled.div`
  max-width: 270px;
  ${({ theme }) => theme.breakpoints.tablet} {
    margin-right: 15px;
    max-width: 350px;
  }
`;
const CarouselButton = styled.button<{ right?: boolean }>`
  background: transparent !important;
  border: none !important;
  padding: 10px !important;
  cursor: pointer !important;
  width: auto !important;
  height: auto !important;
  position: absolute;
  top: -41px !important;
  right: ${(p) => (p.right ? '6px' : '45px')} !important;
  left: unset !important;
  ${({ theme }) => theme.breakpoints.tablet} {
    right: ${(p) => (p.right ? '150px' : '190px')} !important;
  }
  &:before {
    content: '' !important;
  }
`;
const StarContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  svg {
    margin-right: 0.5rem;
  }
`;

export default function PageSectionTestimonials({
  slug,
  headline,
  body,
  blocks,
}: SectionProps): JSX.Element {
  const theme = useTheme();
  const settings = {
    infinite: true,
    speed: 500,
    variableWidth: !isMobile,
    nextArrow: (
      <CarouselButton className="slider-arrow" right>
        <Icon id="arrow-right" />
      </CarouselButton>
    ),
    prevArrow: (
      <CarouselButton className="slider-arrow">
        <Icon id="arrow-left" />
      </CarouselButton>
    ),
    responsive: [
      {
        breakpoint: 751,
        settings: {
          centerMode: true,
          centerPadding: '79px',
        },
      },
    ],
  };
  return (
    <SectionContainer
      id={slug}
      classes="reviews"
      usePaddingNoMobile
      contentBackgroundColor={theme.colors.eloBlack}
    >
      <Headline>
        <HeadlineContainer
          headline={headline}
          body={body}
          darkbg
          className="reviews-headline-container"
        />
      </Headline>
      <CarouselContainer>
        <Slider {...settings}>{renderBlocks(blocks)}</Slider>
      </CarouselContainer>
    </SectionContainer>
  );
}
function renderBlocks(blocks: Block[]): JSX.Element[] {
  const getImage = (a: Block) =>
    isMobile ? a?.mobileBackground?.gatsbyImageData : a?.background?.gatsbyImageData;
  return blocks.map((block) => {
    return (
      // <BlockContainer key={`carousel-${block.slug}`} style={{ width: !isMobile ? 692 : '100%' }}>
      <BlockContainer key={`carousel-${block.slug}`}>
        <ReviewImageContainer>
          <Image gatsbyImageData={getImage(block)} alt="" />
        </ReviewImageContainer>

        <CarouselBlock className="slide-content">
          {block.rating && (
            <StarContainer>
              {times(block.rating, (i) => (
                <Icon id="star" key={`${block.slug}-start-${i}`} />
              ))}
            </StarContainer>
          )}
          <Quote>{block?.shortBody?.shortBody}</Quote>
          <Author>{block.subHeading}</Author>
        </CarouselBlock>
      </BlockContainer>
    );
  });
}
