import React from 'react';
import styled from 'styled-components';
import { responsive } from 'src/utils';

interface SpacerProps {
  target: {
    height?: string[];
  };
}

const Spacer: React.FC<SpacerProps> = ({ target }) => {
  const { height = [] } = target;
  return <StyledSpacer height={height} />
};

interface StyledSpacerProps {
  height?: string[];
}

const StyledSpacer = styled.div<StyledSpacerProps>`
  content: ' ';
  ${({ height = [] }) => responsive('height', height)};
`;

export default Spacer;
