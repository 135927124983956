export const getPreliminaryPlan = /* GraphQL */ `
  query GetPreliminaryPlan($id: ID!) {
    getPreliminaryPlan(id: $id) {
      id
      createdAt
      updatedAt
      expiresAt
      email
      member {
        anonymousId
        firstName
        lastName
        name
        email
        affiliations
        age
        sex
        diets
        sports
        goals
        medicalConditions
        nutrientDeficiencies
        targeting {
          utmCampaign
          utmSource
          utmMedium
          utmContent
          utmTerm
          __typename
        }
        __typename
      }
      products {
        id
        slug
        name
        category
        features
        pricing {
          id
          type
          currency
          billingScheme
          unitAmount
          __typename
        }
        shortDescription
        longDescription
        serving {
          unit
          quantity
          __typename
        }        
        products {
          id
          slug
          name
          category
          features
          shortDescription
          longDescription
          serving {
            unit
            quantity
            __typename
          }        
          servingCount
          ingredients {
            id
            slug
            name
            category
            shortDescription
            longDescription
            __typename
            dose {
              unit
              quantity
              __typename
            }
            assessments {
              type
              name
              slug
            }            
          }
          __typename
        }
        ingredients {
          id
          slug
          name
          category
          shortDescription
          longDescription
          __typename
        }
        assessments {
          type
          name
          slug
          __typename
        }
        __typename
      }
      builds {
        buildId
        startAt
        endAt
        initBy
        error
        errors
        typeformEventPayloadArn
        questionnaireDataPayloadArn
        userDataPayloadArn
        assessmentRecommendationPayloadArn
        ruleRecommendationPayloadArn
        dosageRecommendationPayloadArn
        productRecommendationPayloadArn
        __typename
      }
      status
      error
      errorMessages
      __typename
    }
  }
`;
