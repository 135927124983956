import React from 'react';
import styled from 'styled-components';
import { statesOfUSA } from '../../../utils/const';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'ui';

const form = ({ onSubmit, register, errors, submitting, questions }: any): JSX.Element => {
  return (
    <Form onSubmit={onSubmit} id="qualification-form">
      <InputBlock>
        <input type="email" {...register('email')} placeholder={questions.email} />
        {errors?.email?.message && <ValidationMessage>{errors.email.message}</ValidationMessage>}
      </InputBlock>
      <InputBlock className="select">
        <select defaultValue="" {...register('referrer')}>
          <option disabled={true} value="" hidden={true}>
            {questions.referrer}
          </option>
          <option value="web_search">Podcast</option>
          <option value="web_search">Web Search</option>
          <option value="instagram">Instagram</option>
          <option value="facebook">Facebook</option>
          <option value="twitter">Twitter</option>
          <option value="linkedin">LinkedIn</option>
          <option value="youtube">YouTube</option>
          <option value="email">Email</option>
          <option value="news_article">News Article</option>
          <option value="friend_or_family">Friend / Family</option>
        </select>
        {errors?.referrer?.message && (
          <ValidationMessage>{errors.referrer.message}</ValidationMessage>
        )}
      </InputBlock>
      <InputBlock className="select">
        <select defaultValue="" {...register('primaryGoal')}>
          <option disabled={true} value="" hidden={true}>
            {questions.primaryGoal}
          </option>
          <option value="track_biomarkers">Track biomarkers</option>
          <option value="optimize_nutrition">Optimize nutrition</option>
          <option value="lose_weight">Lose weight</option>
          <option value="improve_performance">Improve performance</option>
          <option value="improve_sleep">Improve sleep</option>
          <option value="improve_focus">Improve focus</option>
          <option value="increase_longevity">Increase longevity</option>
        </select>
        {errors?.primaryGoal?.message && (
          <ValidationMessage>{errors.primaryGoal.message}</ValidationMessage>
        )}
      </InputBlock>
      <InputBlock className="select">
        <select defaultValue="" {...register('phoneType')}>
          <option disabled={true} value="" hidden={true}>
            {questions.phoneType}
          </option>
          <option value="iphone">iPhone</option>
          <option value="android">Android</option>

          <option value="other">Other</option>
        </select>
        {errors?.phoneType?.message && (
          <ValidationMessage>{errors.phoneType.message}</ValidationMessage>
        )}
      </InputBlock>
      <InputBlock className="select">
        <select defaultValue="" {...register('state')}>
          <option disabled={true} value="" hidden={true}>
            {questions.state}
          </option>
          {Object.keys(statesOfUSA).map((k) => (
            <option key={k} value={k}>
              {statesOfUSA[k]}
            </option>
          ))}
        </select>
        {errors?.state?.message && <ValidationMessage>{errors.state.message}</ValidationMessage>}
      </InputBlock>
      <InputBlock>
        <label>
          <input type="checkbox" {...register('over18')} />
          &nbsp;{questions.over18}
        </label>
        {errors?.over18?.message && <ValidationMessage>{errors.over18.message}</ValidationMessage>}
      </InputBlock>

      <ButtonContainer className="qualification-form-control">
        <Button onClick={() => {}} type="submit" form="qualification-form">
          {submitting && <LoadingOutlined />}
          {!submitting && <>Proceed to checkout</>}
        </Button>
      </ButtonContainer>
    </Form>
  );
};
export default form;
const svgDownArrow = `<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>`;

const checkmark = `<svg width='17' height='15' viewBox='0 0 17 15' fill='black' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M16.2127 1.88219L7.31739 14.1133L0.0996094 8.69993L1.89961 6.29993L6.68183 9.8866L13.7865 0.117676L16.2127 1.88219Z' fill='black'/></svg>`;
const Form = styled.form(
  ({ theme }) => `
    display: grid;
    grid-template-columns: auto;
    gap: 1em;
    
    margin-top: 1.5rem;
  
    padding-bottom: 5rem;
    ${theme.breakpoints.mobile} {
      font-size: 20px;
      line-height: 25px;
    }
    
    ${theme.breakpoints.tablet} {
      font-size: 31px;
      line-height: 35px;

    }
  `
);
const InputBlock = styled.div(
  ({ theme }) => `
        width: 100%;
        color: ${theme.colors.eloDarkGrey};
        border-bottom: 1px dashed ${theme.colors.eloBlack};
        position: relative;

        :focus-within {
            border-bottom: 1px solid ${theme.colors.primary};
        }
    
        input::placeholder {
            color: ${theme.colors.eloDarkGrey};
            opacity: 100%;
        }
    
        input, select {
            -webkit-appearance:none;
            appearance: none;
            border: none;
            padding: 10px 0;
            font: inherit;
            color: currentColor;
            padding-bottom: 7px;
    
            background-color: ${theme.colors.white};
            width: 100%;
    
            :focus-within, :focus {
                border: none;
                outline: none;
    
                ::placeholder {
                    color: transparent;
                }
            }
        }
    
        select {
            background:url("data:image/svg+xml;utf8,${svgDownArrow}") no-repeat scroll 99% 60% transparent;
            cursor: pointer;
        }
    
        label {
          display: grid;
          grid-template-columns: 16px auto;
          gap: 16px;
          align-items: center;
          
          padding: 10px 0;
      }
        input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            background-color: ${theme.colors.white};
            margin: 0;
            width: 20px;
            height: 20px;
            border: 1px solid ${theme.colors.eloMidGrey};
            border-radius: 3px;
            //transform: translateY(-0.075em);
            display: grid;
            place-content: center;
            
            ${theme.breakpoints.tablet} {
              width: 23px;
              height: 23px;
            }
            
            :checked {
              background-image: url("data:image/svg+xml;utf8,${checkmark}");
              background-repeat: no-repeat;
              background-size: 90% 90%;
              background-position: center;
            }
        }
    `
);
const ValidationMessage = styled.p(
  ({ theme }) => `
        color: ${theme.colors.error};
        font-size: 1rem;
        line-height: 1rem;
        margin: 5px 0;
        ${theme.breakpoints.desktop} {
            position: absolute;
            top: 20px;
            left: 600px;
            margin: 10px 0 0 0;
        }
    `
);

const ButtonContainer = styled.div(
  ({ theme }) => `
  margin-top: 1rem;
  button {
  width: 100%;
  ${theme.breakpoints.tablet} {
    width: auto;
  }
}
`
);
