// AMPLIFY V5 to V6 UPGRADE
// import { API } from 'aws-amplify';
// import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';

import { generateClient } from 'aws-amplify/api';
const client = generateClient();

import { GraphQLQuery } from '@aws-amplify/api';
import {
  UpdateQuestionnaireStatusInput,
  UpdateQuestionnaireStatusMutation,
  UpdateQuestionnaireStatusMutationVariables,
} from 'src/graphql/elomapi';
import { updateQuestionnaireStatus } from '../../../graphql/mutations';

/********************************************************************************
 * IS AUTHENTICATED
 * ------------------------------------------------------------------------------
 * Import the isAuthenticated function from the ELO package to determine the
 * authentication mode that is necessary to use. The isAuthenticated function
 * simplifies the process of determining the authentication mode by checking the
 * state of the user.
 * *****************************************************************************/
import { isAuthenticated } from 'src/elo';

export const updateStatus = async ({
  formId,
  anonymousId,
  responseId = null,
  startedAt = null,
  updatedAt = null,
  completedAt = null,
  currentQuestion = null,
  targeting = null,
  error = null, // Call if error
  errorAt = null, // Call if error
  errorMessages = null, // Call if error
}: UpdateQuestionnaireStatusInput): Promise<boolean> => {
  if (!formId || !anonymousId) {
    console.error('Missing formId or anonymousId');
    return false;
  }

  // Set the auth mode
  const authMode = isAuthenticated() ? 'userPool' : ('iam' as 'userPool' | 'iam');

  const variables: UpdateQuestionnaireStatusMutationVariables = {
    input: {
      formId: formId,
      anonymousId: anonymousId,
      email: null, // We do not know the email
      responseId: responseId,
      targeting: targeting,
      startedAt: startedAt,
      updatedAt: updatedAt,
      completedAt: completedAt,
      currentQuestion: currentQuestion,
      error: error,
      errorAt: errorAt,
      errorMessages: errorMessages,
    },
  };

  try {
    const response = await client.graphql<GraphQLQuery<UpdateQuestionnaireStatusMutation>>({
      query: updateQuestionnaireStatus,
      variables,
      authMode: authMode,
    });
    console.debug('updateQuestionnaireStatus API response', response);
    return response.data?.updateQuestionnaireStatus ?? false;
  } catch (error) {
    console.error('failed to update questionnaire status', error);
    return Promise.resolve(false);
  }
};

export default updateStatus;
