// Generic Re-Usabel Accordian

import React from 'react';
import { Accordion as MuiAccordion } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { TypographyProps } from '@mui/material/Typography';

interface AccordianProps {
  children: React.ReactNode;
  title: string;
  expanded?: boolean;
  variant?: TypographyProps['variant'];
}

const Accordian = ({ title, children, expanded, variant }: AccordianProps) => {
  return (
    <MuiAccordion defaultExpanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography
          // variant="h4" is default
          variant={variant ? variant : 'h4'}
          component={'div'} // present as a div
          sx={{ my: '.47rem' }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordian;
