import * as React from 'react';

type JsonLDScriptProps = {
  jsonld: any;
};

export const JsonLDScript = ({ jsonld }: JsonLDScriptProps): JSX.Element => {
  const innerHTML = { __html: JSON.stringify(jsonld) };
  return <script type="application/ld+json" dangerouslySetInnerHTML={innerHTML} />;
};

export default JsonLDScript;
