import { SiteMetadata } from 'types';
import { Buffer } from 'buffer';

const salt = 'EloHealth';

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events
export enum GA4Action {
  begin_checkout = 'begin_checkout',
  purchase = 'purchase',
}

export enum CustomAction {
  cta_button_click = 'cta_button_click',
}

export interface PlainEvent {
  event: string;
  user_id?: string;
}
export interface TrackingEvent extends PlainEvent {
  event: GA4Action | CustomAction;
  ecommerce: EcommerceEvent | null;
  custom: CTAEvent | null;
}

interface EcommerceEvent {
  items: EcommerceItem[];
}

export interface CTAEvent {
  target: string;
}

export interface BeginCheckoutEvent extends EcommerceEvent {
  currency?: string;
  value: number;
}

export interface PurchaseEvent extends EcommerceEvent {
  transaction_id: string;
  currency?: string;
  value: number;
}

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase_item
export type EcommerceItem = {
  item_name: string;
  item_id: string;
  quantity: number;
};

export function buildAnalyticsPageUrl(meta: SiteMetadata, page: any): string {
  if (meta && page.slug) {
    return `${meta.siteUrl}${page.path || '/'}${page.slug}`;
  }
  return `/${page.slug}`;
}

const encoder = new TextEncoder();
const pushToDataLayer = async (
  eventData: TrackingEvent | PlainEvent,
  user_email?: string | null
) => {
  let digest: string | null = null;
  if (crypto && crypto.subtle && encoder && user_email && user_email.length) {
    const hash = await crypto.subtle.digest('SHA-256', encoder.encode(user_email + salt));
    digest = Buffer.from(hash).toString('base64');
  }
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    digest && window.dataLayer.push({ user_id: digest, crm_id: digest });
    window.dataLayer.push(eventData);
  }
};

export default {
  ctaClicked: (eventData: CTAEvent) =>
    pushToDataLayer({
      event: CustomAction.cta_button_click,
      ecommerce: null,
      custom: eventData,
    }),
  beginCheckout: (eventData: BeginCheckoutEvent, customData = null, user_email: string) =>
    pushToDataLayer(
      {
        event: GA4Action.begin_checkout,
        ecommerce: eventData,
        custom: customData,
      },
      user_email
    ),
  purchase: (eventData: PurchaseEvent, user_email?: string | null) =>
    pushToDataLayer(
      {
        event: GA4Action.purchase,
        ecommerce: eventData,
        custom: null,
      },
      user_email
    ),
  activateGoogleOptimize: () =>
    pushToDataLayer({
      event: 'optimize.activate',
    }),
};
