import * as React from 'react';
import styled from 'styled-components';
import Image from '../image/Image';
import { Link } from 'gatsby';

/**
 * HACK ALERT
 * Weird and magical -webkit-mask-image is needed to get rounded corners on Safari🤷‍♂️
 * See https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
 */
const RoundAvatar = styled(Image)`
  vertical-align: middle;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  mask-image: -webkit-radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  margin-bottom: 20px;
  margin-right: 1.5em;
`;

const NameTitle = styled.p`
  font-family: 'Everett Medium';
  font-weight: bold;
  padding: 0.5em 0 0 0;
  margin: 0;
  font-size: 19px;
  line-height: 23px;
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 23px;
    line-height: 26px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    font-size: 31px;
    line-height: 36px;
  }
`;

const ReadMore = styled.span`
  a {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
    font-size: 14px;
    line-height: 17px;
    ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 18px;
      line-height: 20px;
    }
    ${({ theme }) => theme.breakpoints.wide} {
      font-size: 24px;
      line-height: 28px;
    }
  }
`;

const AuthorCard = styled.div`
  display: flex;
`;

export type AuthorProps = {
  name: string;
  title?: string;
  avatar: {
    gatsbyImageData: any;
  };
  bio?: {
    slug: string;
    title: string;
  };
};

export default function Author({ name, title, avatar, bio }: AuthorProps): JSX.Element {
  return (
    <AuthorCard>
      <div>
        <RoundAvatar gatsbyImageData={avatar.gatsbyImageData} alt="" />
      </div>
      <div>
        <NameTitle>
          {name}
          {title && `, ${title}`}
        </NameTitle>
        {bio && (
          <ReadMore>
            <Link to={`/articles/${bio.slug}`} title={bio.title}>
              Read more
            </Link>
          </ReadMore>
        )}
      </div>
    </AuthorCard>
  );
}
