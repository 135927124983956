/* eslint react/display-name: "off" */

import * as React from 'react';
import parse, { domToReact } from 'html-react-parser';
import { Colors } from '../theme/Theme';

enum Tag {
  Green = 'green',
  LineBreak = 'br',
}

const replaceRules = {
  [Tag.Green]: (children) => <span style={{ color: Colors.primary }}>{children}</span>,
  [Tag.LineBreak]: () => <br />,
};

const options = {
  replace: ({ name, children }) => {
    const ruleFn = replaceRules[name];
    return ruleFn && ruleFn(domToReact(children, options));
  },
};

export const applyRules = (message: string): JSX.Element => {
  return parse(message, options);
};
