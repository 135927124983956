import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Container, Typography } from 'ui';
// import Typography from 'src/features/ui/typography/Typography';
import { Icon } from 'ui';
import { useAssessmentStore } from 'src/features/assessment-summary/store';

const Member = () => {

  const storeProduct = useAssessmentStore(state => state.storeProduct);
  const storeMember = useAssessmentStore(state => state.storeMember);
  const storeFormula = useAssessmentStore(state => state.storeFormula);

  /**************************************************************
   * GET FLAVOR FROM FORMULA
   * ------------------------------------------------------------
   * Formula is passed into the member component. The formula
   * contains the products array. The first entry in the formula.products
   * array is an object that contains a products array.
   *
   * The products[0].products array contains an object with a key 'features'
   * which is itself an array of strings. If that array of strings contains
   * the string 'gummyFlavor' then that product array entry is the flavor of
   * the gummy.
   *
   * Soooo.
   * Get that object and use the name property as the flavor.
   **************************************************************/
  const [flavor, setFlavor] = useState<string | null>(null);

  useEffect(() => {
    // const getFlavor = (formula: PreliminaryPlan) => {
    const getFlavor = () => {
      // Get the products array from the formula
      // const products = formula?.products[0]?.products;
      if (!storeFormula?.products) return null; // Bail if there is no formula to avoid an error
      const products = storeFormula?.products[0]?.products ?? null;

      // Get the flavor object from the products array
      const flavorObject = products?.find(product => product?.features?.includes('gummyFlavor'));
      if (!flavorObject) return null; // Bail if there is no flavor object

      // Get the name property from the flavor object
      const gummyFlavor = flavorObject?.name ?? null;

      // Set the flavor state
      setFlavor(gummyFlavor);
    };

    // if (storeFormula) getFlavor(storeFormula);
    if (storeFormula) getFlavor();

  }, [storeFormula]);

  if (!storeMember) return null;

  const getGenderLabel = (gender: string) => {
    if (gender.toLocaleLowerCase() === 'male') {
      return 'male';
    } else if (gender.toLocaleLowerCase() === 'female') {
      return 'female';
    } else if (gender.toLocaleLowerCase() === 'intersex') {
      return 'intersex';
    } else if (gender.toLocaleLowerCase() === 'prefer not to say') {
      return '';
    } else {
      return '';
    }
  };

  // const getDietaryRestrictions = (diets: string[]) => {};

  return (
    <>
      <Container>
        {/* <MemberNameContainer> */}

        <Box mb={'3rem'}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h1">
              {/* {storeProduct?.title ?? 'Gummy'} for <MemberNameWrapper>{member?.firstName}</MemberNameWrapper> */}
              {storeProduct?.title ?? 'Gummy'} for <MemberNameWrapper>{storeMember?.firstName}</MemberNameWrapper>
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body1">
              {storeProduct?.pdpTitleDescription}              
            </Typography>
          </Box>
        </Box>

        {/* </MemberNameContainer> */}

        <MemberContainer>
          {/****************************************
           * GENDER AND AGE
           */}

          {storeMember?.age && (
            <MemberWrapper>
              <MemberCategoryType>
                <Icon id="avatar" stroke="#1A1919" width="20px" height="22px" viewBox="0 0 22 22" />
              </MemberCategoryType>

              <Typography variant="body3">
                {/* {member.age && `${member.age} | `} {getGenderLabel(member.sex ?? '')} */}
                {storeMember?.age && `${storeMember.age} | `} {getGenderLabel(storeMember?.sex ?? '')}
              </Typography>
            </MemberWrapper>
          )}

          {/****************************************
           * ADD FLAVOR
           */}

          {flavor && (
            <MemberWrapper>
              <MemberCategoryType>
                <Icon id="flavor" stroke="#1A1919" width="16px" height="21px" viewBox="0 0 16 21" />
              </MemberCategoryType>
              <Typography variant="body3">{flavor}</Typography>
            </MemberWrapper>
          )}

          <MemberWrapper>
            <MemberCategoryType>
              <Icon
                id="silverware"
                stroke="#1A1919"
                width="21px"
                height="20px"
                viewBox="0 0 21 20"
              />
            </MemberCategoryType>
            <Typography variant="body3">{storeMember?.diets && storeMember.diets?.join(', ')}</Typography>
          </MemberWrapper>
        </MemberContainer>
      </Container>
    </>
  );
};

export default Member;

const MemberContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: -1.5rem;
  > * {
    flex: 1; /* Equal width for all child elements */
  }
`;

const MemberCategoryType = styled.span`
  font-family: 'Everett Medium';
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.colors.eloBlack};
  margin-right: 0.47rem;
`;

const MemberWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Everett Light';
  font-weight: 400;
  font-size: 14px;
  color: ${props => props.theme.colors.eloDarkGrey};
  align-items: center;
  margin-top: 0.25rem;
  line-height: 1.47;
`;

const MemberGoal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2.5rem;
`;

const MemberNameContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MemberNameWrapper = styled.span`
  color: ${props => props.theme.colors.luminaryOrange};
`;

const IconWrapper = styled.div`
  width: 21px;
  height: 21px;
  /* border: 1px solid #efefef; */
`;

const MemberContent = styled.span`
  font-family: 'Everett Light';
  font-weight: 400;
  font-size: 14px;
  /* padding-left: 4px; */
  color: ${props => props.theme.colors.eloDarkGrey};
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
`;
