import React, { useState } from 'react';
import { AccountSettings } from '@aws-amplify/ui-react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Modal, Button, Typography } from '@mui/material';
import DeleteModal from './DeleteModal';

const DeleteUser = () => {
  const handleSuccess = () => {
    alert('user has been successfully deleted');
  };

  const [open, setOpen] = useState(false);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <>
          <DeleteModal onClose={() => setOpen(false)} />
        </>
      </Modal>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h4" sx={{ my: '.47rem' }}>
            Delete Account
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" gutterBottom sx={{ mb: '1.47rem' }}>
            Deleting your account will erase all personal information. You may still be charged any
            pending payments even if you delete your account.
          </Typography>
          <Box>
            <Button variant="contained" onClick={() => setOpen(true)}>
              Delete Account
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DeleteUser;
