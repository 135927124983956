/******************************************************************************************
 * * COMPONENT: LOGIN
 * ---------------------------------------------------------------------------------------
 * Just in case the user is not logged in, we need to provide a way for them to log in.
 * This will provide a nice way to alert the user that they need to log in to see this
 * component. It provides a fallback in case the main route is not explicitly protected.
 * ****************************************************************************************/

import React, { useEffect } from 'react';
import { Box, Stack, Typography, Button } from '@mui/material';
import { useAccountStore } from 'src/elo/store';

const Login = () => {
  
  const updateAccountState = useAccountStore(state => state.updateAccountState);

  useEffect(() => {
    updateAccountState(state => {
      state.showLoginModal = true;
    });
  }, []);

  const handleLogin = () => {
    updateAccountState(state => {
      state.showLoginModal = true;
    });
  };

  return (
    <Box
      sx={{
        minHeight: '70vh',
      }}
      display={'flex'}
      justifyContent={'center'}
    >
      {/* <Stack justifyContent={'center'}>
        <Button variant="contained" onClick={handleLogin}>
          Login
        </Button>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            marginTop: 2,
          }}
        >
          Login Required
        </Typography>
      </Stack> */}
    </Box>
  );
};

export default Login;
