/********************************************************************************
 * DATA FUNCTION: getLineItems
 * ----------------------------------------------------------------------------
 * This function get's all available line items from the ELO API and loads them
 * into the central store when the user logs in making the data available for all
 * components in the app. This typicially occurs when a user logs in.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';
import { getLineItems as getLineItemsQuery } from './graphql/queries';
import { LineItem, GetLineItemsQuery } from '../types/API';

/**********************************************************************
 * * BUILD LINE ITEMS QUERY
 * --------------------------------------------------------------------
 * ********************************************************************/

const buildQuery = (): GraphQLOptions => {
  const query = getLineItemsQuery;
  const authMode = 'userPool';
  const variables = { input: {} } as any;

  return {
    query,
    authMode: authMode,
    variables,
  };
};

/**********************************************************************
 * * GET LINE ITEMS
 * --------------------------------------------------------------------
 * ********************************************************************/

export type LineItemsResponse = LineItem[] | null | undefined;

export const getLineItems = async (): Promise<LineItemsResponse> => {
  // console.log('Getting Line Items Data');

  try {
    const query = buildQuery();
    // const result = await client.graphql(query) as GraphQLResult<{ getLineItems: LineItemsResponse }>;    
    const result = await client.graphql(query) as GraphQLResult<GetLineItemsQuery>;    
    // console.log('Line Items Result: ', result);
    return result.data.getLineItems
  } catch (error) {
    console.error('Error getting line items', error);
    return null;
  }
};

export default getLineItems;
