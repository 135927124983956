import * as React from 'react';
import { SectionProps } from 'types';
import { Link } from 'gatsby';
import { Tag } from 'antd';
import { kebabCase } from 'lodash';

type PageSectionArticleTagsProps = SectionProps & {
  data: any;
};

const ArticleTags = ({ slug, headline }: PageSectionArticleTagsProps) => {
  const tags = headline?.split(',').map((tag) => tag.trim()) || [];

  function href(tag: string): string {
    return tag ? '/articles/' + kebabCase(tag) + '/' : '';
  }

  return (
    <div className={'page-section-tags ' + slug}>
      {tags.map((tag) => (
        <Tag key={tag}>
          <Link to={href(tag)}>{tag.toUpperCase()}</Link>
        </Tag>
      ))}
    </div>
  );
};

export default ArticleTags;
