import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Begin from './Begin';
import SelectFlavor from './SelectFlavor';
import Confirm from './Confirm';
import Update from './Update';
import { refreshAccountStore, initializeInvoices } from '../../../../../data';
import { checkout } from '../../../../../actions';
import { useAccountStore } from '../../../../../store';

interface ToPrenatalProps {
  cancel: (value: boolean) => void;
}

const buttonLabel = ['Switch to Form (early pregnancy)', 'Confirm Flavor', 'Subscribe for $69/mo'];

const ToPrenatal = ({ cancel }: ToPrenatalProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  /********************************************************************************
   * GET STORE VALUES
   * -----------------------------------------------------------------------------
   * *********************************************************************************/
  // const newUserData = useAccountStore(state => state.newUserData);
  const userAccount = useAccountStore(state => state.userAccount);
  const lineItems = useAccountStore(state => state.lineItems);
  const invoices = useAccountStore(state => state.invoices);

  // Exit Early if the userAccount or lineItems are not available
  if (!userAccount || !lineItems) return null;

  /********************************************************************************
   * GET RELEVANT SUBSCRIPTION VALUES
   * -----------------------------------------------------------------------------
   * We need values necessary for:
   * - Cancelling the Create Subscription
   * - Checking out with the new subscription
   * *********************************************************************************/
  const getCancelSubscription = () => {
    const createSubscription = userAccount?.subscriptions?.items?.find(item =>
      item?.title?.toLowerCase().includes('create')
    );
    if (!createSubscription) return null;
    return createSubscription.id ?? null;
  };

  /********************************************************************************
   * HANDLE REFRSH ACCOUNT STORE
   * -----------------------------------------------------------------------------
   * *****************************************************************************/
  // * REPEAT FUNCTION DELAY
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const handleRefreshAccountStore = async () => {
    // Run in a loop
    for (let attempt = 1; attempt <= 5; attempt++) {
      const account = await refreshAccountStore();
      // Verify that the account now has the new form subscription
      const isUpdated = account?.subscriptions?.items?.find(item =>
        item?.title?.toLowerCase().includes('form')
      );
      if (isUpdated) {
        return;
      } else {
        await delay(3000);
      }
    }
    return;
  };

  const handleInitializeInvoices = async () => {
    await delay(3000);
    await refreshAccountStore();
    await initializeInvoices();
  };

  /********************************************************************************
   * GET RELEVANT LINE ITEM VALUES NECESSARY FOR CHECKOUT
   * -----------------------------------------------------------------------------
   * We need values necessary for:
   * - Checking out with the new subscription
   * *********************************************************************************/
  const lineItem = lineItems?.find(item =>
    item?.purchasableSubscriptionSlug?.toLowerCase().includes('form')
  );
  const purchasableSubscriptionId = lineItem?.purchasableSubscriptionId ?? null;
  const productId = lineItem?.productId ?? null;
  const priceId = lineItem?.priceId ?? null;
  const customerEmail = userAccount?.email ?? null;
  const subscriptionId = getCancelSubscription();

  if (!productId || !priceId || !customerEmail || !subscriptionId || !purchasableSubscriptionId) return null;

  const handleMutateStage = async () => {
    setLoading(true);
    try {
      await checkout({
        productId,
        priceId,
        purchasableSubscriptionId,
        customerEmail,
      });

      /**********************************************************************
       * * REFRESH THE ACCOUNT STORE
       * --------------------------------------------------------------------
       * The refreshAccountStore function is used to update the account store
       * with new data. The function will run in a loop until the account store
       * has been updated with the new form subscription. It will run up
       * to 5 times with a 3 second delay between each attempt.
       * ********************************************************************/

      await handleRefreshAccountStore();
      await handleInitializeInvoices();

      setLoading(false);
    } catch (error) {
      console.error('Error updating pregnancy stage', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  /********************************************************************************
   * HANDLE NEXT BUTTON CLICK
   * -----------------------------------------------------------------------------
   * 1. If the activeStep is greater than 1, then the user is on the last step
   *   and the cancel function should be called with a value of false.
   *
   * This is where the logic for saving the flavor and calling the update product
   * function would go.
   * *****************************************************************************/
  const handleNext = async () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    if (activeStep === 2) {
      await handleMutateStage();
      cancel(false);
    }
  };

  const handleCancel = () => {
    cancel(false);
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <Begin />;
      case 1:
        return <SelectFlavor />;
      case 2:
        return <Confirm />;
      case 3:
        return <Update />;
      default:
        return 'Unknown step';
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #AAA',
    boxShadow: 24,
    pt: 1,
    px: 2,
    pb: 2,
    borderRadius: 2,
  };

  return (
    <>
      <Box
        width={['94vw', '420px', '480px']}
        minHeight={['440px']}
        maxHeight={['94vh']}
        sx={{
          ...style,
          display: 'flex',
          flexDirection: 'column',
          // minHeight: '400px',
          overflow: 'auto', // This ensures that the content area can scroll if needed
        }}
      >
        {/* Content to fill the middle with potential for scrolling */}
        <Box
          // border={'1px solid #AAA'}
          display={'flex'}
          flexDirection={'column'}
          mt={2}
          mb={1}
          sx={{
            flexGrow: 1,
            overflowY: 'auto', // Allows the content area to scroll independently
            p: 1, // Provides some padding around the content
            // border: '1px solid tomato',
          }}
        >
          {getStepContent(activeStep)}

          {/* Footer Buttons - Always visible */}

          {/* Footer Button */}
        </Box>

        {/* ONLY SHOW FOOTER IF THE ACTIVE STEP IS LESS THAN 3 */}
        {activeStep < 3 && (
          <Box position={'static'} sx={{ background: 'white' }}>
            <Stack spacing={1}>
              <Button variant="contained" onClick={handleNext}>
                {buttonLabel[activeStep]}
              </Button>

              <Button variant="text" onClick={handleCancel}>
                Stay on Create (prenatal)
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ToPrenatal;
