export default {
  palette: {
    primary: {
      main: '#f69b32',               
      // main: '#FF0000',               
      light: '#ffac62',
      dark: '#c57012',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    htmlFontSize: 16,
    h1: {
      fontFamily: 'Meno Display Regular, Arial',
      fontWeight: 400,
      fontSize: '70px',
      lineHeight: 1.05, // equivalent to 105%
      letterSpacing: '-0.7px',
    },
    h2: {
      fontFamily: 'Meno Display Regular, Arial',
      fontWeight: 300,
      fontSize: '50px',
      lineHeight: 1.15, // equivalent to 115%
      letterSpacing: '-1px',
    },
    h3: {
      fontFamily: 'Meno Display Regular, Arial',
      fontWeight: 400,
      fontSize: '38px',
      lineHeight: 1.15, // equivalent to 115%
      letterSpacing: '-0.72px',
    },
    h4: {
      fontFamily: 'Meno Display Regular, Arial',
      fontWeight: 300,
      fontSize: '28px',
      lineHeight: 1.15, // equivalent to 115%
      letterSpacing: '-0.72px',
    },
    h5: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 400,
      fontSize: '26px',
      lineHeight: 1.15, // equivalent to 115%
      letterSpacing: '-0.72px',
    },
    h6: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 300,
      fontSize: '24px',
      lineHeight: 1.15, // equivalent to 115%
      letterSpacing: '-0.72px',
    },
    subtitle1: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 300,
      fontSize: '18px',
      lineHeight: 1.15, // equivalent to 115%
      letterSpacing: '-0.72px',
    },
    subtitle2: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: 1.15, // equivalent to 115%
      letterSpacing: '-0.72px',
    },
    body1: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: 1.48, // equivalent to 148%
      letterSpacing: '-0.2px',
    },
    body2: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: 1.4, // equivalent to 140%
      letterSpacing: '-0.2px',
    },
    body3: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 0.97, // equivalent to 97%
    },
    caption: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.3, // equivalent to 130%
    },
    button: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: 1.35, // equivalent to 140%
    },
    overline: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: 1.45, // equivalent to 145%
    },
    tiny1: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: 1.45, // equivalent to 145%
    },
    tiny2: {
      fontFamily: 'Montserrat, Arial',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: 1.45, // equivalent to 145%
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '50px', // High value to create a pill shape
          textTransform: 'none' as any, // Remove text transformation
        },
        outlined: {
          borderWidth: '2px', // Set border width for outlined variant
          '&:hover': {
            borderWidth: '2px', // Ensure the border width stays the same on hover
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          // Apply body2 styles to the input text
          fontSize: '0.875rem', // Example for body2 font size
          '&::placeholder': {
            fontSize: '0.875rem', // Ensuring placeholder matches body2 font size
            opacity: 1, // Optional: Adjust placeholder opacity
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Apply body2 styles to the label
          fontSize: '0.875rem', // Default font size for body2
          '&.Mui-focused': {
            fontSize: '0.875rem', // Maintain font size when focused
          },
          '&.MuiInputLabel-shrink': {
            // Specifically targeting the shrunken label
            transform: 'translate(14px, -6px) scale(0.75)', // Adjust scale to control size
            fontSize: '0.875rem', // Ensure it matches your desired size for shrunken state
          },
          '@media (min-width:600px)': {
            fontSize: '1rem', // Font size set to 1rem for viewport widths over 900px
            '&.Mui-focused': {
              fontSize: '0.875rem', // Ensure focused state also uses 1rem font size at this breakpoint
            },
            '&.MuiInputLabel-shrink': {
              fontSize: '0.875rem', // Ensure shrunken label uses 1rem font size at this breakpoint
              transform: 'translate(14px, -10px) scale(1)', // Adjust transform scale for consistency
            },
          },
        },
      },
    },
    // Optionally adjust FormHelperText if needed to match body2 size
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem', // Align with body2 for helper text
        },
      },
    },
  },
};
