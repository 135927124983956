import React from 'react';
import styled from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { DefaultRichTextRenderOptions } from '../../../utils/render';

const Headline = (props: any): JSX.Element => {
  return (
    <HeadlineContainer darkbg={props.darkbg} className={props.className}>
      <h2>{props.headline}</h2>
      {props.body && renderRichText(props.body, DefaultRichTextRenderOptions)}
    </HeadlineContainer>
  );
};
export default Headline;
const HeadlineContainer = styled.div<{ darkbg: boolean }>`
  h2 {
    font-family: 'Everett Medium';
    color: ${({ darkbg, theme }) => (darkbg ? theme.colors.white : theme.colors.eloBlack)};
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    margin-bottom: 20px;
    ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 14px;
      line-height: 14.7px;
    }
  }
  > p {
    font-family: 'Everett Light';
    color: ${({ darkbg, theme }) => (darkbg ? theme.colors.white : theme.colors.eloBlack)};
    font-weight: 300;
    font-size: 25px;
    line-height: 28.75px;

    ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 37px;
      line-height: 42px;
    }
  }
`;
