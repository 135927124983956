import React, { useState } from 'react';
import {
  SubscriptionStatus as ENUMSubscriptionStatus,
  UserSubscription,
} from '../../../../types/API';
import {
  Box,
  Typography,
  Button,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  Modal,
} from '@mui/material';
import CancelModal from './CancelModal';

import { formatDate } from '../../../../utils';
// * Import Account Store
// import { useAccountStore } from '../../../../store';
// import { sub } from 'date-fns';

interface SubscriptionStatusProps {
  subscription: UserSubscription;
}

const SubscriptionStatus = ({ subscription }: SubscriptionStatusProps) => {
  const [value, setValue] = useState<ENUMSubscriptionStatus | undefined | null>(
    subscription.status
  );
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as ENUMSubscriptionStatus);
  };

  const handleCancleSubscription = () => {
    setCancelModalOpen(true);
    if (value === ENUMSubscriptionStatus.canceled) {
      setCancelModalOpen(true);
    }
  };

  if (cancelModalOpen) {
    return (
      <>
        <Modal open={cancelModalOpen} onClose={() => setCancelModalOpen(false)}>
          <CancelModal
            cancel={setCancelModalOpen}
            subscriptionId={subscription.id}
            subscription={subscription}
          />
        </Modal>
      </>
    );
  }

  if (subscription.cancellationRequestedAt) {
    return (
      <>
        <Box>
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            Your subscription has been canceled. However, you will continue to be billed for the
            remainder of your subscription period which ends on{' '}
            {subscription.committedUntil && formatDate(subscription.committedUntil, 'MMM dd, yyyy')}
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box>
        <Stack spacing={2}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={ENUMSubscriptionStatus.active}
              control={<Radio />}
              label={
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  Active
                </Typography>
              }
              sx={{ alignItems: 'flex-start' }} // Align items to start at the top
            />
            <FormControlLabel
              value={ENUMSubscriptionStatus.canceled}
              control={<Radio />}
              label={
                <>
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    Cancel my subscription
                  </Typography>
                  <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
                    You may still be billed for a balance due on the last order that was prepared or
                    shipped.
                  </Typography>
                </>
              }
              sx={{ alignItems: 'flex-start' }} // Align items to start at the top
            />
          </RadioGroup>

          <Box pt={['1.47rem']}>
            <Button variant="contained" color="primary" onClick={handleCancleSubscription}>
              Update
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default SubscriptionStatus;
