/**
 * FUTURE SELF AND OTHER DEVELOPERS:
 * This component is used by the product page for Smart Supplement and Smart Protein.
 * If changes are made to this page, both product pages must be checked.
 * 
 * TODO: Verify product variant removal
 * The product variant functionality has been removed by commenting it out. We need to
 * verify that this is not needed for the smart protein or smart supplement product pages.
 * If it is, add it back in and make sure it works. If it's not, then remove it and also remove
 * it from the data model in contentful.
 */
import * as React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';
import times from 'lodash/times';
import uniqBy from 'lodash/uniqBy';
import { useEloStore } from '../../../store/EloStore.hooks';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { DefaultRichTextRenderOptions } from '../../../../utils/render';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { SectionProps } from 'types';
import SectionContainer from '../../SectionContainer';
import { Button, Icon, Image } from 'ui';

import { displayRecurringPrice, getSubscription, getPricing } from '../../../product/utils';
// Verify that this is not needed for the smart protein or smart supplement product pages before removing
// import ProductVariants from './components/ProductVariants';
import analytics from '../../../../utils/analytics';
import { Product } from '../../../product/Product.types';
import { navigate } from 'gatsby';

const BuyProduct = (props: SectionProps): JSX.Element => {
  const { video, mobileVideo, products } = props;
  const { store, cart } = useEloStore();
  if (!products || !products[0]) return <></>;
  const product = products && products[0];
  const contextProduct = store.products[product.slug] || product;

  const isSupplements = contextProduct.slug.indexOf('supplements') >= 0;
  const mobileImages = (contextProduct.productImagesResponsive || []).map(img => img.mobile);
  const desktopImages = (contextProduct.productImagesResponsive || []).map(img => img.desktop);
  const mobileFeaturedImage =
    contextProduct?.featuredImageResponsive?.mobile ?? contextProduct?.featuredImage;
  const desktopFeaturedImage =
    contextProduct?.featuredImageResponsive?.desktop ?? contextProduct?.featuredImage;
  const productImages = isMobile
    ? [mobileFeaturedImage, ...mobileImages]
    : [desktopFeaturedImage, ...desktopImages];

  const selectedSubscription = getSubscription(contextProduct as Product);
  const subscriptionPrice = getPricing(selectedSubscription);

  const [selectedVariants, setSelectedVariants] = React.useState<Record<string, string>>({});
  const [error, toggleError] = React.useState<boolean>(false);
  const selectVariant = (slug: string) => {
    const variant = product.variants.find(variant => slug === variant.slug);

    if (variant && variant.type) {
      const updatedVariants: Record<string, string> = {
        ...selectedVariants,
        [variant.type]: variant.slug,
      };

      setSelectedVariants(updatedVariants);
    }
  };

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      const nav = document.getElementById('header');
      if (nav && !!video) {
        nav.classList.add('pdp-video');
      } else {
        if (nav) {
          nav.classList.remove('pdp-video');
        }
      }
      if (nav && product.coupon?.ribbon) {
        nav.classList.add('discount-ribbon');
      } else {
        if (nav) {
          nav.classList.remove('discount-ribbon');
        }
      }
    }
  }, [product.coupon?.ribbon, video]);

  React.useEffect(() => {
    try {
      analytics
        .viewItem({
          items: [
            {
              item_name: product.title,
              item_id: product.stripeProductId ?? 'N/A',
              quantity: 1,
            },
          ],

          value: subscriptionPrice?.price ?? 0,
          currency: subscriptionPrice
            ? subscriptionPrice.currency === '$'
              ? 'USD'
              : subscriptionPrice.currency
            : 'USD',
        })
        .finally(/* NOP */);
    } catch (err) {
      console.debug('Analytics event failed.');
    }
  }, [product]);

  const [currentScroll, setCurrentScroll] = React.useState<number>(-1);
  React.useEffect(() => {
    const handleScroll = () => {
      const id = 'product-action-footer';
      const t = document?.getElementById(id);

      const scrolled = document?.scrollingElement?.scrollTop ?? scrollY;
      const position = t?.offsetTop ?? 276;
      const ofPos = position - window.innerHeight + (t?.offsetHeight ?? 80);
      if (!t?.classList.contains('active')) {
        setCurrentScroll(ofPos);
      }

      if (scrolled > currentScroll) {
        t?.classList.add('active');
      } else {
        t?.classList.remove('active');
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.body.addEventListener('touchmove', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.removeEventListener('touchmove', handleScroll);
    };
  }, [currentScroll]);

  // Calculate variant type amount to check if user chose from all required options.
  const variants = uniqBy(product.variants, 'type');
  const formisOk = !product.variants || Object.keys(selectedVariants).length === variants.length;

  const hasPushdownBanner =
    typeof document != 'undefined' && document?.getElementById('pushdown-banner');

  const isNewVersion = !!video;
  const headerVideo = isMobile ? mobileVideo ?? video : video;

  const settings = {
    dots: !isNewVersion,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      {headerVideo && (
        <HeaderVideo className={classnames('elo-hero-video', { pushdown: !hasPushdownBanner })}>
          <Image {...headerVideo} />
        </HeaderVideo>
      )}
      <SectionContainer
        id={product.title}
        usePaddingNoMobile
        contentBackground={'#fff'}
        nonRelative
      >
        
        {isNewVersion && product.coupon?.ribbon && (
          <CouponRibbon
            className={classnames({ pushdown: hasPushdownBanner, version2: isNewVersion })}
            onClick={() => {
              if (formisOk) {
                cart.addOrUpdateProduct(
                  product.slug,
                  selectedSubscription?.slug || '',
                  selectedVariants,
                  product.cartPath
                );
              } else {
                toggleError(true);
              }
            }}
          >
            <Image {...product.coupon?.ribbon} />
          </CouponRibbon>
        )}

        <BuyProductContainer
          id="product-container"
          className={classnames({
            version2: isNewVersion,
            'discount-ribbon': product.coupon?.ribbon,
            pushdown: hasPushdownBanner,
          })}
        >
          <ProductImageCarousel
            className={classnames({
              version2: isNewVersion,
            })}
          >
            <Slider {...settings}>
              {productImages.map((img, key) => (
                <Image
                  gatsbyImageData={img.gatsbyImageData}
                  key={`product-carousel-${key}`}
                  loading={key === 0 ? 'eager' : 'lazy'}
                />
              ))}
            </Slider>            

            {!isNewVersion && product.coupon?.ribbon && (
              <CouponRibbon
                className={classnames({ pushdown: hasPushdownBanner, version2: isNewVersion })}
                onClick={() => {
                  if (formisOk) {
                    cart.addOrUpdateProduct(
                      product.slug,
                      selectedSubscription?.slug || '',
                      selectedVariants
                    );
                  } else {
                    toggleError(true);
                  }
                }}
              >
                <Image {...product.coupon?.ribbon} />
              </CouponRibbon>
            )}
          </ProductImageCarousel>
          <ProducInformationContainer
            className={classnames({
              version2: isNewVersion,
              protein: !isSupplements,
            })}
          >
            <ProductHeader>
              {product.title} 
              {/* ASKED BY ARI TO REMOVE PRICE FROM ALL PRODUCTS */}
              {/* <Price>{displayRecurringPrice(subscriptionPrice, true)}</Price> */}
            </ProductHeader>
            {product.pdpTitleDescription && (
              <p className="title-desc">{product.pdpTitleDescription}</p>
            )}
            <StarsContainer>
              {times(Math.floor(product.rating), i => (
                <Icon id="star" className="full" key={`${product.slug}-star-${i}`} />
              ))}
              <Icon id="halfstar" className="half" key={`${product.slug}-star`} />
              {/* <span
                onClick={() => {
                  const elem = document.querySelector('.reviews');
                  if (elem && elem) {
                    elem.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }
                }}
                className="link"
              >
                See reviews
              </span> */}
            </StarsContainer>

            <Overview id="overview">
              {product.descriptionHeader && <h2>{product.descriptionHeader}</h2>}
              {product.description &&
                renderRichText(product.description, DefaultRichTextRenderOptions)}
            </Overview>
            {!isNewVersion && (
              <IncludedContainer>
                <h2>{product.includedPdpHeader}</h2>
                <div>
                  {product?.included?.map(inc => (
                    <IncludedOption key={inc.name}>
                      <div>
                        <Image {...inc.image} alt="" />
                      </div>
                      <h3>{inc.name}</h3>
                      {inc.description && <p>{inc.description.description}</p>}
                    </IncludedOption>
                  ))}
                </div>
              </IncludedContainer>
            )}

            {product.extraDescription && (
              <BioMarkersContainer>
                <h2>{product.extraDescriptionHeader}</h2>
                {renderRichText(product.extraDescription, DefaultRichTextRenderOptions)}
              </BioMarkersContainer>
            )}

            {/**
             * REMOVE PRODUCT VARIANTS
             * See TODO at top of file.
             * It's necessary to remove the product variants from being rendered.
             * It is also necessary to remove the submit button variant validation.
             */}

            {/* {product.variants && (
              <ProductVariants
                variants={product.variants}
                selectedVariants={selectedVariants}
                selectVariant={selectVariant}
                showError={error}
              />
            )} */}

            <ButtonContainer
              className={classnames({
                version2: isNewVersion,
              })}
              id="product-action-footer"
            >
              {/**
               * BUTTON DEPENDS ON PRODUCT
               * This component is currently used by Smart Supplements and Smart Recovery.
               * They need two different buttons. The button is determined by the product.
               */}

              {isSupplements ? (
                <Button
                  showLoader={formisOk}
                  onClick={() => {
                    if (formisOk) {
                      cart.addOrUpdateProduct(
                        product.slug,
                        selectedSubscription?.slug || '',
                        selectedVariants
                      );
                    } else {
                      toggleError(true);
                      const elem = document.querySelector('#product-container');
                      if (elem) {
                        elem.scrollIntoView({
                          behavior: 'smooth',
                        });
                      }
                    }
                  }}
                >
                  {product.pdpCtaText || 'Add to cart'}
                </Button>
              ) : (
                <>
                {/**
                 * SMART RECOVERY BUTTON
                 * This button is only used by Smart Recovery. It is a link to the quiz.
                 * Error checking is not necessary.
                 * Product loading is not necessary.
                 */}
                {/* <Button onClick={() => navigate('/quiz/smart-recovery/')}> */}
                <Button onClick={() => navigate(product.purchaseProductCTA?.url ?? '/')}>
                  {product.pdpCtaText || 'Add to cart'}
                </Button>
                </>
              )}

              {selectedSubscription?.cartCtaText && (
                <p className="helper-text">{selectedSubscription?.cartCtaText}</p>
              )}
            </ButtonContainer>
            {isNewVersion && (
              <IncludedContainer
                className={classnames({
                  version2: isNewVersion,
                })}
              >
                <h2>{product.includedPdpHeader}</h2>
                <div>
                  {product?.included?.map(inc => (
                    <IncludedOption
                      key={inc.name}
                      className={classnames({
                        version2: isNewVersion,
                      })}
                    >
                      <div>
                        <Image {...inc.image} alt="" />
                      </div>
                      <h3>
                        {isNewVersion && <Icon id="checkmark-green" />}
                        {inc.name}
                      </h3>
                      {inc.description && <p>{inc.description.description}</p>}
                    </IncludedOption>
                  ))}
                </div>
              </IncludedContainer>
            )}
          </ProducInformationContainer>
        </BuyProductContainer>
      </SectionContainer>
    </>
  );
};
export default BuyProduct;

const HeaderVideo = styled.div`
  max-height: 450px;
  overflow: hidden;
`;

const BuyProductContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column wrap;
  margin-top: 0;
  @media screen and (max-width: 1020px) {
    &:not(.version2) {
      &.discount-ribbon {
        margin-top: 54px;
        &.pushdown {
          margin-top: 85px;
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.tablet} {
    flex-flow: row wrap;
    align-items: flex-start;
    margin-bottom: 1rem;
    &.version2 {
      margin-top: -180px;
    }
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    margin-top: 63px;
    margin-bottom: -60px;
    &.version2 {
      margin-top: -180px;
    }
  }
  ${({ theme }) => theme.breakpoints.wide} {
    margin-bottom: -70px;
  }
`;
const ProductImageCarousel = styled.div`
  align-self: flex-start;
  width: 100%;
  background: ${({ theme }) => theme.colors.eloGreyLight};
  border-radius: 3px;
  padding-top: 7rem;
  padding-bottom: 2rem;
  position: relative;
  &.version2 {
    background: transparent;
    padding-top: 0rem;
    padding-bottom: 0rem;
    margin-top: -90px;
    ${({ theme }) => theme.breakpoints.tablet} {
      margin-top: 0;
    }
  }
  &.version2 {
    .slick-list {
      padding-bottom: 0rem;
    }
  }
  .slick-list {
    padding-bottom: 1rem;
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    .gatsby-image-wrapper {
      max-width: 73%;
      margin: 0 auto;
      img {
        margin: 0 auto;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.tablet} {
    .gatsby-image-wrapper {
      max-width: 50%;
      margin: 0 auto;
      img {
        margin: 0 auto;
      }
    }
    .slick-list {
      padding-bottom: 2rem;
    }
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    width: 46%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .gatsby-image-wrapper {
      max-width: 100%;
      img {
        margin: 0 auto;
      }
    }
  }

  .slider-arrow {
    position: absolute;
    z-index: 10;
    top: calc(50% - 10px);
    outline: 0;
    border: 0;
    background: transparent;
    color: black;
    cursor: pointer;
  }
  .slick-dots {
    ${({ theme }) => theme.breakpoints.mobile} {
      display: flex;
      justify-content: center;
      padding: 0 16px 10px;
      li {
        flex-grow: 1;
        height: 1px;
        button {
          width: 100%;
          &:before {
            width: 100%;
            height: 1px;
          }
        }
      }
    }
    li {
      width: 50px;
      ${({ theme }) => theme.breakpoints.tablet} {
        width: 110px;
      }
      &.slick-active {
        button:before  {
          background-color: ${({ theme }) => theme.colors.eloBlack};
        }
      }
    }
    button {
      width: 50px;
      height: 0px;
      display: flex;
      align-items: center;
      border-radius: 3px;
      padding: 10px;
      background-color: transparent;
      &:before {
        content: '';
        width: 50px;
        height: 3px;
        background-color: ${({ theme }) => theme.colors.eloMidGrey};
      }
      ${({ theme }) => theme.breakpoints.tablet} {
        width: 110px;
        &:before {
          width: 110px;
        }
      }
    }
  }
`;
const CouponRibbon = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 10;
  width: 105px;
  cursor: pointer;
  &.version2 {
    top: 54px;
    &.pushdown {
      top: 85px;
    }
  }

  ${({ theme }) => theme.breakpoints.tablet} {
    width: 115px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    display: block;
    width: 127px;
    &.version2 {
      top: 60px;
      &.pushdown {
        top: 93px;
      }
    }
  }
`;
const ProducInformationContainer = styled.div`
  width: 100%;
  padding: 0 1rem;
  &.protein {
    h1 {
      letter-spacing: 0px;

      ${({ theme }) => theme.breakpoints.mobile} {
        //font-size: 35px;
      }
    }
  }
  &.version2 {
    ${({ theme }) => theme.breakpoints.mobile} {
      h1 {
        margin-top: 0;
      }
    }
    ${({ theme }) => theme.breakpoints.tablet} {
      margin-top: 0px;
    }
    ${({ theme }) => theme.breakpoints.desktop} {
      margin-top: 135px;
    }
  }
  ${({ theme }) => theme.breakpoints.small} {
    margin-top: 0px;
  }
  .title-desc {
    font-size: 13px;
    line-height: 18.2px;
    color: ${({ theme }) => theme.colors.eloDarkGrey};
  }
  h2 {
    font-family: 'Everett Medium';
    font-size: 12px;
    line-height: 12.6px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.eloBlack};
  }
  .typography-p {
    font-size: 13px;
    line-height: 18.2px;
    color: ${({ theme }) => theme.colors.eloDarkGrey};
  }

  ${({ theme }) => theme.breakpoints.tablet} {
    .title-desc {
      margin-top: -1rem;
      font-size: 15px;
      line-height: 20.25px;
      color: ${({ theme }) => theme.colors.eloDarkGrey};
    }
    h2 {
      font-size: 14px;
      line-height: 15px;
    }
    .typography-p {
      font-size: 16px;
      line-height: 21.6px;
    }
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    padding-left: 55px;
    width: 54%;
  }
`;
const ProductHeader = styled.h1`
  font-family: 'Everett Light';
  font-size: 29px;
  line-height: 32px;
  letter-spacing: -1px;
  margin-bottom: 0;
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 20px;
    margin-top: 0;
  }
`;
const Price = styled.span`
  font-size: 16px;
  line-height: 22.25px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.eloDarkGrey};
  margin-top: 7px;
  ${({ theme }) => theme.breakpoints.tablet} {
    align-self: flex-end;
    margin-left: auto;
    font-size: 20px;
    line-height: 27px;
  }
`;
const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  > svg {
    &.full {
      path {
        &.full {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }
    }
    &.half {
      path {
        &.full {
          fill: ${({ theme }) => theme.colors.eloLightGrey};
        }
        &.half {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }
    }

    margin-right: 0.25rem;
  }
  color: ${({ theme }) => theme.colors.eloMidGrey};
  span {
    transition: all 0.25s ease-in-out;
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.colors.eloMidGrey};
    font-size: 12px;
    line-height: 16.2px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Overview = styled.div(
  ({ theme }) => `
  margin-top: 1rem;
  ${theme.breakpoints.tablet} {
    margin-top: 1.5rem;
  }
`
);
const IncludedContainer = styled.div(
  ({ theme }) => `
  &.version2 {
      > div {
      display: flex;
      flex-flow: column;
    }
    ${theme.breakpoints.tablet} {
      border-top: 1px solid ${theme.colors.eloGreyge};
      padding-top: 2rem;

    }
    ${theme.breakpoints.desktop} {
      > div {
        flex-flow: row wrap;
      }
    }
  }
  > div {
    display: flex;
    align-items: stretch;
    gap: 5px;
    flex-flow: row wrap;
    justify-content: space-between;
  } 
  margin-top: 2rem;
  ${theme.breakpoints.tablet} {
    margin-top: 1.5rem;
    > div {
      display: flex;
      align-items: stretch;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }

`
);
const IncludedOption = styled.div`
  flex-basis: 48%;

  > div {
    width: 100%;
    height: auto;
    // min-height: 130px;
    padding: 0;
    background: ${({ theme }) => theme.colors.eloGreyLight};
    border-radius: 3px;
    display: flex;
    align-items: flex-end;
    .gatsby-image-wrapper {
      margin: 0 auto;
    }
  }
  &.version2 {
    flex-shrink: 0;
    min-width: 36px;
    h3 {
      font-family: 'Everett Regular';
      display: flex;
      align-items: center;
      white-space: nowrap;
      flex-shrink: 0;
    }
  }
  h3 {
    font-family: 'Everett Medium';
    margin-top: 7px;
    font-size: 13px;
    line-height: 21.6px;
    color: ${({ theme }) => theme.colors.eloDarkGrey};
    margin-bottom: 2px;
    svg {
      margin-right: 5px;
      flex-shrink: 0;
    }
  }
  p {
    font-size: 11px;
    line-height: 14.85px;
    color: ${({ theme }) => theme.colors.eloDarkGrey};
    max-width: 100%;
  }
  ${({ theme }) => theme.breakpoints.tablet} {
    h3 {
      font-size: 13px;
      line-height: 21.6px;
    }
    p {
      font-size: 11px;
      line-height: 14.2px;
    }
    flex-basis: 24%;
    &.version2 {
      flex-basis: 49%;
      width: 50%;
    }
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    &.version2 {
      flex-basis: 355px;
      width: 50%;
    }
    h3 {
      font-size: 15px;
      line-height: 21.6px;
    }
    p {
      font-size: 12px;
      line-height: 14.2px;
    }
  }
`;

const BioMarkersContainer = styled.div(
  ({ theme }) => `
  margin-top: 2rem;
  ${theme.breakpoints.tablet} {
    margin-top: 1.5rem;
  }
`
);

const PrevArrowButton = styled.button`
  left: 1rem;
  ${({ theme }) => theme.breakpoints.tablet} {
    left: 1rem;
  }
`;
const PrevArrow: React.FC<{ onClick?: VoidFunction }> = function ({ onClick }) {
  return (
    <PrevArrowButton className="slider-arrow" onClick={onClick}>
      <Icon id="arrow-left-black" />
    </PrevArrowButton>
  );
};
const NextArrowButton = styled.button`
  right: 1rem;
  ${({ theme }) => theme.breakpoints.tablet} {
    right: 1rem;
  }
`;
const NextArrow: React.FC<{ onClick?: VoidFunction }> = function ({ onClick }) {
  return (
    <NextArrowButton className="slider-arrow" onClick={onClick}>
      <Icon id="arrow-right-black" />
    </NextArrowButton>
  );
};

const ButtonContainer = styled.div`
  margin-top: 2rem;
  &.version2.active {
    ${({ theme }) => theme.breakpoints.mobile} {
      z-index: 99;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: white;
      padding: 12px 1rem;
      box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.15);
    }
  }
  ${({ theme }) => theme.breakpoints.tablet} {
    width: 235px;
  }
  button {
    cursor: pointer;

    height: 48px;

    width: 100%;
  }
  .helper-text {
    color: ${({ theme }) => theme.colors.eloDarkGrey};
    font-size: 11px;
    line-height: 14.85px;
    opacity: 0.7;
    margin-top: 8px;
    margin-bottom: 5px;
    text-align: center;
    ${({ theme }) => theme.breakpoints.tablet} {
      font-size: 11px;
      line-height: 14.85px;
      display: none;
    }
  }
`;
