// AMPLIFY V5 to V6 UPGRADE

import * as React from 'react';
// import { API } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

import { Immutable } from 'immer';
import { navigate } from 'gatsby';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { CheckoutStartError, Member, StartCheckoutMutation } from '../../graphql/elomapi';
import { PreliminaryPlanMember } from 'src/API'

import { Context, State } from './EloStore.Provider';
import analytics, { createUserId, EcommerceItem } from '../../utils/analytics';
import { Product } from '../product/Product.types';
import { Cart, QualificationForm, UTMData } from './EloStore.types';
import * as transform from './EloStore.transform';
import {GraphQLQuery} from "@aws-amplify/api";
import posthog from 'posthog-js';

export type QualificationData = {
  email: string;
  channel: string;
  primary_goal: string;
  isOver18: boolean;
  phoneType: string;
  state: string;
  preliminaryPlanId?: string
};

type EloStoreHook = Immutable<{
  store: State;
  layout: {
    toggleLoader: (shown: boolean) => void;
    toggleCartWidget: () => void;
    setTheme: (theme: 'light' | 'dark') => void;
  };
  analytics: {
    storeUTM: (
      utm_source: string | null,
      utm_medium: string | null,
      utm_campaing: string | null,
      utm_content: string | null,
      utm_term: string | null
    ) => void;
  };
  cart: {
    addOrUpdateProduct: (
      productSlug: string,
      subscriptionSlug: string,
      variantSlugs: Record<string, string>,
      cartPath?: string,
      oneStep?: boolean
    ) => void;
    removeProduct: (productSlug: string) => void;
    checkout: (
      qualificationAnswers: QualificationForm,
      qualificationQuestions: object,
      relativeCancelUrlPath: string | undefined
    ) => Promise<any>;
    checkoutPersonalizedProtein: (
      // member: Member,
      member: PreliminaryPlanMember,
      preliminaryPlanId: string,
      cancelUrl?: string
    ) => Promise<any>;
  };
}>;

// Flatten nested objects/arrays to be used as metadata
const flattenForMetadata = (obj: unknown) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  return Object.keys(obj).reduce((acc: Record<string, unknown>, key: string) => {
    const value = (obj as Record<string, unknown>)[key];
    if (value !== null && (typeof value === 'object' || Array.isArray(value))) {
      acc[key] = Object.values(value).map(nestedValue => flattenForMetadata(nestedValue as Record<string, unknown>)).join(',');
    } else if(value !== null) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

const beginPersonalizedProductCheckout = async (
  member: Member,
  preliminaryPlanId: string,
  cart: Immutable<Cart>,
  availableProducts: Immutable<{ [slug: string]: Product }>,
  utmData?: UTMData,
  anonymousId?: string,
  cancelUrl?: string
) => {
  
  try {
    const email = member.email?.toLowerCase();
    let customerId;
    if(email) {
      customerId = await createUserId(email);
    }

    const gqlOptions = transform.toStartCheckoutMutation(
      customerId || null,
      email || null,
      anonymousId || null,
      cart.items,
      availableProducts,
      {
        ...(flattenForMetadata(member) as any),
        ...utmData
      },
      cancelUrl ? cancelUrl : '', // Cancel URL
      preliminaryPlanId,
      utmData,
    );

    const result = await client.graphql<GraphQLQuery<StartCheckoutMutation>>(gqlOptions);

    if (result?.data?.startCheckout?.fail) {
      if (result.data.startCheckout.fail.errorCode == CheckoutStartError.DUPLICATE_SUBSCRIPTION) {
        alert('It seems that you already have a subscription for the product(s) you are ordering.');
        await navigate('/');
        throw CheckoutStartError.DUPLICATE_SUBSCRIPTION;
      }
    } else if (result?.data?.startCheckout && result.data.startCheckout.checkoutUrl) {

      /************************************************************
       * FURTURE SELF AND DEVS
       * This is where the checkout URL which is returned
       * by the graphQL query for startCheckout is used
       * to redirect the user to the checkout page on stripe.
       ************************************************************/

      // POSTHOG EVENT: Checkout payment viewed
      posthog.capture('checkout payment viewed', {
        preliminaryPlanId: preliminaryPlanId
      });

      window.location.href = result.data.startCheckout.checkoutUrl;
      return 'SUCCESS';
    } else {
      console.error('No checkoutUrl available', result.errors);
      throw CheckoutStartError.OTHER;
    }
    throw CheckoutStartError.OTHER;
  } catch (error) {
    console.error('API call failed', error);
    throw CheckoutStartError.OTHER;
  }
};

const beginStripeCheckout = async (
  qualificationAnswers: QualificationForm,
  qualificationQuestions: object,
  cart: Immutable<Cart>,
  availableProducts: Immutable<{ [slug: string]: Product }>,
  utmData?: UTMData,
  anonymousId?: string,
  relativeCancelUrlPath = '/',
): Promise<CheckoutStartError | 'SUCCESS'> => {
  const email = qualificationAnswers.email.toLowerCase();
  let customerId;
  if(email) {
    customerId = await createUserId(email);
  }

  try {
    const gqlOptions = transform.toSendQualificationAnswersMutation(
      customerId || null,
      anonymousId || null,
      qualificationAnswers,
      qualificationQuestions
    );

    // fire and forget
    client.graphql(gqlOptions);
  } catch (err) {
    // NOP
    console.warn('Unable to store qualification answers');
  }

  try {
    const meta: QualificationData = {
      email: email,
      channel: qualificationAnswers.referrer,
      primary_goal: qualificationAnswers.primaryGoal,
      isOver18: qualificationAnswers.over18,
      phoneType: qualificationAnswers.phoneType,
      state: qualificationAnswers.state
    };

    const gqlOptions = transform.toStartCheckoutMutation(
      customerId || null,
      email || null,
      anonymousId || null,
      cart.items,
      availableProducts,
      meta,
      relativeCancelUrlPath,
      undefined,
      utmData
    );
    const result = (await client.graphql(gqlOptions)) as GraphQLResult<StartCheckoutMutation>;

    if (result?.data?.startCheckout?.fail) {
      if (result.data.startCheckout.fail.errorCode == CheckoutStartError.DUPLICATE_SUBSCRIPTION) {
        alert('It seems that you already have a subscription for the product(s) you are ordering.');
        await navigate('/');
        throw CheckoutStartError.DUPLICATE_SUBSCRIPTION;
      }
    } else if (result?.data?.startCheckout && result.data.startCheckout.checkoutUrl) {
      try {
        const items: EcommerceItem[] | undefined = result.data.startCheckout.lineItems?.flatMap(
          (li) =>
            !li
              ? []
              : {
                  item_name: li.title,
                  item_id: li.id,
                  quantity: li.quantity,
                  item_variant: undefined,
                }
        );

        await analytics.addPaymentInfoEvent(
          {
            items: items ?? ([] as EcommerceItem[]),
            value: result.data.startCheckout.totalPrice?.total ?? 0,
            currency: (result.data.startCheckout.totalPrice?.currency as string) ?? null,
          },
          null,
          email
        );
      } catch (err) {
        // Never fail on analytics
      }

      window.location.href = result.data.startCheckout.checkoutUrl;
      return 'SUCCESS';
    } else {
      console.error('No checkoutUrl available', result.errors);
      throw CheckoutStartError.OTHER;
    }
    throw CheckoutStartError.OTHER;
  } catch (error) {
    console.error('API call failed', error);
    throw CheckoutStartError.OTHER;
  }
};

export function useEloStore(): EloStoreHook {
  const { store, dispatch } = React.useContext(Context);

  const addOrUpdateProduct = React.useCallback(
    (
      productSlug: string,
      subscriptionSlug: string,
      variantSlugs: Record<string, string>,
      cartPath = '/cart',
      oneStep = false
    ) => {
      try {
        if (!(productSlug in store.cart)) {
          analytics
            .addToCart(
              transform.toCartEventData(
                productSlug,
                subscriptionSlug,
                Object.values(variantSlugs),
                store
              )
            )
            .finally();
        }
      } catch (err) {
        console.debug('Analytics event failed.');
      }

      dispatch({
        type: 'cart-add-or-edit-product',
        payload: { productSlug, subscriptionSlug, variantSlugs: Object.values(variantSlugs) },
      });

      /**
       * TODO: Make this generic
       * FUTURE SELF AND DEVS
       * This is actually a generic parameter that needs to be renamed
       * DO NOT REMOVE THIS if you intent is to simply no longer support the oneStep cart option.
       */
      if (!oneStep) {
        navigate(cartPath);
      }
    },
    []
  );
  const removeProduct = React.useCallback((productSlug: string) => {
    try {
      analytics
        .removeFromCart(
          transform.toCartEventData(
            productSlug,
            store.cart.items[productSlug].subscriptionSlug,
            store.cart.items[productSlug].variantSlugs,
            store
          )
        )
        .finally(/* NOP */);
    } catch (err) {
      console.debug('Analytics event failed.');
    }

    dispatch({
      type: 'cart-remove-product',
      payload: { productSlug },
    });
  }, []);

  const checkoutPersonalizedProtein = React.useCallback(

    async (
      member: Member,
      preliminaryPlanId: string,
      cancelUrl?: string
    ) => {

      // USE POSTHOG TO GET ANONYMOUS ID
      const getAnonymousId = () => {
        const posthogId = posthog.get_distinct_id();        
        if (posthogId) {
          return posthogId;
        } else {
          return store?.cart?.owner || undefined;
        }
      }

      const anonymousId = getAnonymousId();
      

      try {
        // POSTHOG EVENT: Checkout payment loading
        posthog.capture('checkout payment loading', {
          personalizedPlanId: preliminaryPlanId
        });

        dispatch({
          type: 'layout-loader-set-shown',
          payload: { shown: true },
        });        

        const ret = await beginPersonalizedProductCheckout(
          member,
          preliminaryPlanId,
          store.cart,
          store.products,
          store.analytics?.utmData,                    
          anonymousId,                    
          cancelUrl || undefined
        );

        if (ret !== 'SUCCESS') throw 'FAILED';
        return ret;
      } catch (error) {        
        console.error('checkout error', error)
        dispatch({
          type: 'layout-loader-set-shown',
          payload: { shown: false },
        });
      }
    },
    [store.cart]
  );

  const checkout = React.useCallback(
    async (
      qualificationAnswers: QualificationForm,
      qualificationQuestions: object,
      relativeCancelUrlPath: string | undefined
    ) => {
      try {
        dispatch({
          type: 'layout-loader-set-shown',
          payload: { shown: true },
        });

        const ret = await beginStripeCheckout(
          qualificationAnswers,
          qualificationQuestions,
          store.cart,
          store.products,
          store.analytics.utmData,
          store.cart.owner || undefined,
          relativeCancelUrlPath
      );

        if (ret !== 'SUCCESS') throw 'FAILED';
        return ret;
      } catch (error) {
        dispatch({
          type: 'layout-loader-set-shown',
          payload: { shown: false },
        });
      }
    },
    [store.cart]
  );

  const toggleCartWidget = React.useCallback(() => {
    dispatch({
      type: 'layout-cart-widget-set-shown',
      payload: { shown: !store.layout.cartWidged.shown },
    });
  }, [store.layout.cartWidged.shown]);

  const toggleLoader = React.useCallback((shown: boolean) => {
    dispatch({
      type: 'layout-loader-set-shown',
      payload: { shown },
    });
  }, []);

  const setTheme = React.useCallback(
    (theme: 'light' | 'dark') => {
      dispatch({ type: 'layout-set-theme', payload: { theme } });
    },
    [store, dispatch]
  );

  const storeUTM = React.useCallback(
    (
      utm_source: string | null,
      utm_medium: string | null,
      utm_campaign: string | null,
      utm_content: string | null,
      utm_term: string | null
    ) => {
      dispatch({
        type: 'analytics-store-utm-data',
        payload: {
          utm_source: utm_source ?? undefined,
          utm_medium: utm_medium ?? undefined,
          utm_campaign: utm_campaign ?? undefined,
          utm_content: utm_content ?? undefined,
          utm_term: utm_term ?? undefined,
        },
      });
    },
    [store, dispatch]
  );

  return {
    store,
    layout: {
      toggleLoader,
      toggleCartWidget,
      setTheme,
    },
    cart: {
      addOrUpdateProduct,
      removeProduct,
      checkout,
      checkoutPersonalizedProtein,
    },
    analytics: {
      storeUTM,
    },
  };
}
