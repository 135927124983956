import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SectionProps } from 'types';
import { Container, Box, Typography, Button, SectionContainer2 } from 'ui';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BasicBlockRichTextRenderOptions } from 'src/utils/render';
import { useViewport } from 'src/hooks';

const VideoHero = ({
  headline,
  subHeading,
  body,
  video,
  mobileVideo,
  ctaButton,
  designNodes,
  sectionType,
}: SectionProps): JSX.Element => {
  const viewport = useViewport();

  const [isLoading, setIsLoading] = useState(true);
  const [videoFile, setVideoFile] = useState(
    viewport.isMobile ? mobileVideo?.file?.url : video?.file?.url
  );

  useEffect(() => {
    const activeVideo = viewport.isMobile ? mobileVideo : video;
    setVideoFile(activeVideo?.file?.url ?? null);
  }, [viewport]);

  const handleVideoLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      <SectionContainer2 designNodes={designNodes} sectionType={sectionType}>
        <Container>
          <Grid>
            <ContentContainer>
              <Box
              zIndex={1}
              >
                <Box textAlign={['center', 'left']}>
                  {headline && <Typography variant="h1">{headline}</Typography>}
                </Box>
                <Box textAlign={['center', 'left']}>
                  {
                  subHeading && 
                  <Typography variant="body1">{subHeading}</Typography>
                  }
                </Box>
                <Box textAlign={['center', 'left']}>
                  {body && (
                    <div className="body">
                      {renderRichText(body, BasicBlockRichTextRenderOptions)}
                    </div>
                  )}
                </Box>

                <Box
                  display={'flex'}
                  width={['100%']}
                  textAlign={['center', 'left']}
                  justifyContent={['center', 'left']}
                  my={['1rem', '2rem', '3rem']}
                >
                  <Button
                    ctaButton={ctaButton}
                    sx={{
                      marginBottom: '1rem',
                      width: 'auto',
                    }}
                  />
                </Box>
              </Box>
            </ContentContainer>

            <Box
              className="hero-video"
              width={'100%'}
              height={['200px', '400px', '500px']}
              sx={{ gridArea: 'video' }}
            >
              <video
                key={videoFile} // Without the key the video will not reload on viewport change
                controls={false}
                autoPlay
                loop
                muted
                playsInline
                style={{ 
                  width: '100%', 
                  height: '100%', 
                  objectFit: 'cover', 
                  overflow: 'visible',
                  scale: viewport.isMobile ? '2.8' : '1',
                }}
                onLoadedData={handleVideoLoad} // Event listener for video load
              >
                {videoFile && <source src={videoFile} type="video/mp4"></source>}
              </video>
            </Box>
          </Grid>
        </Container>
      </SectionContainer2>
    </>
  );
};

export default VideoHero;

const Grid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  grid-template-areas:
    'video'
    'content';
  overflow: visible;
  ${({ theme }) => theme.breakpoints.tablet} {
    background: ${({ theme }) => theme.colors.white};
    grid-template-columns: 1fr 1fr; // Two columns in larger screens
    grid-gap: 20px; // Adjusted gap for larger screens
    grid-template-areas: 'content video'; // Side by side layout for larger screens
  }
`;

const ContentContainer = styled.div`
  grid-area: content; // Named grid area for layout
  display: flex; // Enable flex layout
  flex-direction: column; // Stack content vertically
  justify-content: center; // Center content vertically
  align-items: center; // Center content horizontally
  text-align: center; // Center text horizontally
`;
