import * as React from 'react';
import { ImageFile } from '../types';
import { Avatar, Space, Typography } from 'antd';
import Image from '../image/Image';
import { Link } from 'gatsby';

const { Title, Paragraph, Text } = Typography;

export interface ListItem {
  discriminator: string;
  id: string;
  title: string;
  description?: string;
  url?: string;
  thumbnail: ImageFile;
}
export interface ArticleListItem extends ListItem {
  timeToRead: number;
  author: {
    name: string;
    title: string;
    avatar: ImageFile;
  };
  tags: string[];
}

interface ListingItemProps {
  item: ListItem | ArticleListItem;
  index: number;
}

function instanceOfArticleListItem(object: any): object is ArticleListItem {
  return object.discriminator === 'ArticleListItem';
}

const ListingItem = ({ item, index }: ListingItemProps) => {
  const ItemLink = ({ children }: any): JSX.Element => {
    return (
      <Link className="url" key={`${item.id}-link-${index}`} to={`${item.url}/` || ''}>
        {children}
      </Link>
    );
  };

  if (instanceOfArticleListItem(item)) {
    return (
      <div className="listing-item">
        <ItemLink>
          <Image
            className={'thumbnail'}
            file={item.thumbnail?.file}
            gatsbyImageData={item.thumbnail?.gatsbyImageData}
          />
        </ItemLink>
        <div className="content">
          <ItemLink>
            <Title level={3} ellipsis={{ rows: 3, expandable: false }}>
              {item.title}
            </Title>
            <Paragraph
              className="description"
              ellipsis={{
                rows: 3,
                expandable: false,
                onEllipsis: undefined,
              }}
            >
              {item.description}
            </Paragraph>
          </ItemLink>
          <Space>
            <Avatar
              size={32}
              icon={
                item.author?.avatar?.file && (
                  <Image
                    className="avatar"
                    file={item.author.avatar.file}
                    gatsbyImageData={item.author.avatar.gatsbyImageData}
                  />
                )
              }
            />
            <Text className="author">
              {item?.author?.name}
              {item?.author?.title ? ', ' + item.author.title : ''}
            </Text>
          </Space>
          <Text className="time-to-read">{item?.timeToRead ? item.timeToRead + ' mins' : ''}</Text>
        </div>
      </div>
    );
  } else {
    return (
      <div className="listing-item">
        <Image
          className={'thumbnail'}
          file={item.thumbnail?.file}
          gatsbyImageData={item.thumbnail?.gatsbyImageData}
        />
        <Link key={`${item.id}-link-${index}`} to={item.url || ''}>
          <Title level={4}>{item.title}</Title>
        </Link>
        <Paragraph>{item.description}</Paragraph>
      </div>
    );
  }
};

export default ListingItem;
