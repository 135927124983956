import * as React from 'react';
import { SectionProps } from 'types';
import { PageSectionListing } from 'ui';
import { createListingData } from 'src/features/ui/pagesectionlisting/PageSectionListing';

type PageSectionListingProps = SectionProps & {
  data: any;
};

const PageSectionCategoryListing = ({
  slug,
  headline,
  listingObjectType,
  data,
}: PageSectionListingProps) => {
  const listingData = createListingData(data[listingObjectType], listingObjectType);
  const title = data?.title ? data.title : headline;

  return <PageSectionListing slug={slug} title={title} items={listingData} />;
};

export default PageSectionCategoryListing;
