import React from 'react';
import styled from 'styled-components';
import { Icon, Box, Typography } from 'ui';

const NutritionIcons = () => {
  return (
    <>
      <Box px={['1.47rem']}>
        <Typography variant="h2" sx={{ textAlign: 'center' }}>
          All nutrients are whole-food sourced, third party verified, and formulated by fertility
          doctors to support your reproductive journey.
        </Typography>
      </Box>
      <Box mt={['60px']}>
        <Grid>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'}>
            <Icon id="avatar-female" />
            <Typography variant="body2" sx={{ padding: '1rem' }}>
              Fertility doctor
              <br />
              formulated
            </Typography>
          </Box>

          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'}>
            <Icon id="plant-based" />
            <Typography variant="body2" sx={{ padding: '1rem' }}>
              Plant
              <br />
              based
            </Typography>
          </Box>

          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'}>
            <Icon id="gluten-free" />
            <Typography variant="body2" sx={{ padding: '1rem' }}>
              Gluten
              <br />
              free
            </Typography>
          </Box>

          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} textAlign={'center'}>
            <Icon id="shield" width="43" height="44" />
            <Typography variant="body2" sx={{ padding: '1rem' }}>
              3rd party
              <br />
              verified
            </Typography>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default NutritionIcons;

// TODO: add breakpoint for mobile to change the grid colums
const Grid = styled.div(({ theme }) => {
  return `display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-block: 1rem;
    align-items: flex-start;
    ${theme.breakpoints.tablet} {
      grid-template-columns: repeat(4, 1fr);
    }
    `;
});
