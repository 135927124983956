import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

import { useEloStore } from '../../../store/EloStore.hooks';

import { SectionProps } from 'types';

import SectionContainer from '../../SectionContainer';

import { Button, Skeleton } from 'ui';
import HeadlineSection from './components/HeadlineSection';
import NextStepsSection from './components/NextStepsSection';
import OrderDetailsSection from './components/OrderDetailsSection';
import CustomerInfoSection from './components/CustomerInfoSection';
import SupportSection from './components/SupportSection';
import StoryReceipt from './components/StoryReceipt';
import ProteinPlanReceipt from './components/ProteinPlanReceipt';
import GummyReceipt from './components/GummyReceipt';
import ReceiptNoApp from './components/ReceiptNoApp';
import { getCheckoutData, CheckoutData } from './util';

import analytics, { EcommerceItem } from '../../../../utils/analytics';
import { qualificationQuestions } from '../../../qualification/Qualification';
import { set } from 'lodash';
import posthog from 'posthog-js';

const firePurchaseEvent = (data: CheckoutData): void => {
  const items: EcommerceItem[] = (data.lineItems || []).flatMap((li) =>
    !li
      ? []
      : {
          item_name: li.title,
          item_id: li.id,
          quantity: li.quantity,
        }
  );
  analytics.purchase(
    {
      items,
      transaction_id: data.orderId ?? '',
      value: data.totalPrice?.total ?? 0,
      currency: data.totalPrice?.currency ?? null,
    },
    data.email
  );
};

export default function PageSectionCheckoutSuccess({
  headline,
  body,
  images,
}: SectionProps): JSX.Element {

  const {
    store: {
      layout: {
        loader: { shown },
      },
    },
    layout: { toggleLoader },
  } = useEloStore();
  
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState<CheckoutData | undefined>(undefined);
  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true);
  // const [cartFlowType, setCartFlowType] = React.useState('gummy');
  
    
  useEffect(() => {
    setIsFirstRender(false);
    // const flow = sessionStorage.getItem('elo-cart-flow');
    // if (flow) setCartFlowType(flow);
  });

  const getData = React.useCallback(async () => {
    setError(false);
    toggleLoader(true);
    try {
      const data: CheckoutData = await getCheckoutData();

      setData(data);
      firePurchaseEvent(data);

      /*********************************************************************
       * SET CART FLOW TO GUMMY IF THE PRODUCT IS ELO SMART GUMMIES
       * -------------------------------------------------------------------
       * THIS IS AN UGLY HACK
       * It's the only way to do it without refactoring the entire checkout flow.
       * TODO: Refactor the entire checkout flow.
       * 
       * data?.lineItems[0]?.id is the product subscription slug that is set in contentful.
       * This has to be a product subscription that is attached to an actual product which is
       * also configured in stripe.
       *
       * For Smart Gummies
       * In contentful there is or must be a Smart Gummy Product
       * In stripe there is a Smart Gummy Product
       * In stripe the Smart Gummy Product has a meta data attribute
       * - key: eloSlug
       * - value: elo-smart-gummies
       * 
       * Elo Smart Gummies corresponds to a product subscription that must be configured in contentful
       * and must be attached to the Smart Gummy Product in contentful as well. In Contentful
       * there can be more than one product subscription attached to a product. At least one of them
       * must be configured with the eloSlug meta data attribute that matches what is in stripe. 
       * 
       * *******************************************************************/       
      // const productSlug = data?.lineItems[0]?.id;
      
      // if (productSlug === 'elo-smart-gummies') {
      //   setCartFlowType('gummy');
      // }

      Object.keys(qualificationQuestions).map((k) => sessionStorage.removeItem(k));
      sessionStorage.removeItem('selectedVariants');
    } catch (error) {
      console.error('API call failed', error);
      setError(true);
    } finally {
      toggleLoader(false);
    }
  }, []);

  useEffect(() => {
    // POSTHOG EVENT - Checkout receipt loading
    posthog.capture('checkout receipt loading');
    
    getData().catch(console.error);
  }, [getData]);

  if (isFirstRender) {
    return <></>;
  }

  // const showBasic = !error && !shown && cartFlowType === 'basic';
  // const showStory = !error && !shown && cartFlowType === 'story';
  // const showProtein = !error && !shown && cartFlowType === 'protein';
  // const showGummy = !error && !shown && cartFlowType === 'gummy'

  if (!data) return <Skeleton width='100%' height='100vh'/>;

  return (
    <SectionContainer id="checkoutSuccess" usePadding contentBackgroundColor={'#fff'}>
      <Content>
        {error && (
          <ErrorMessage>
            <p>
              Something went wrong when fetching checkout details. Please email{' '}
              <a href="help@luminaryvitamins.com">help@luminaryvitamins.com</a> or
            </p>
            <Button onClick={() => getData()}>try again</Button>
          </ErrorMessage>
        )}
        {/* {showBasic && ( */}
          {/* <>
            <HeadlineSection headline={headline} body={body} data={data} />
            <CheckoutContainer>
              <div className="left">
                Next Steps             
                <NextStepsSection images={images} data={data} />
              </div>
              <div className="right">
                <OrderDetailsSection data={data} mobile={false} />
                <OrderDetailsSection data={data} mobile />
                <CustomerInfoSection data={data} />
                <SupportSection />
              </div>
            </CheckoutContainer>
            <DownLoadLinkContainer>
              <Button path={data?.appStoreDownloadUrl}>Download the Elo app</Button>
            </DownLoadLinkContainer>
          </> */}
        {/* )} */}

        {/* {showStory && <StoryReceipt data={data} />}
        {showProtein && <ProteinPlanReceipt data={data} />} */}        
        {/* <GummyReceipt data={data} /> */}
        <ReceiptNoApp data={data} />

      </Content>
    </SectionContainer>
  );
}
const Content = styled.div`
  min-height: 80vh;
`;
const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.eloBlack};
    text-decoration: underline;
  }
  button {
    margin-top: 1rem;
  }
`;

const CheckoutContainer = styled.div(
  ({ theme }) => `

  border-top: 1px solid ${theme.colors.eloGreyge};
  width: 100%;
  h2 {
    font-size: 21px;
    line-height: 28.35px;
    font-family: "Everett Medium";
  }
  > div {
    width: 100%;
  }
  ${theme.breakpoints.desktop} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    > div {
      &.right {
        border-left: 1px solid ${theme.colors.eloGreyge};
        padding-left: 65px;
      }
      &.left {
        padding-right: 65px;
      }
      width: 50%;
    }
  }

`
);

const DownLoadLinkContainer = styled.div(
  ({ theme }) => `
  margin: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.tablet} {
    margin-top: 6rem;
  }
`
);
