import React from 'react';
import { IContainerProps } from './ContainerProps.js';
import styled from 'styled-components';
import { responsiveV2 } from 'src/utils';
import { useQueryString } from 'src/hooks';

const constraintPadding = [0, 80, 80, 80, 80];

const getContstraints = (maxWidth: number[]) => {
  const constraints = maxWidth.map((width, i) => `${width - constraintPadding[i]}px`);
  return constraints;
};

// const breakpoints = [0, 600, 900, 1200, 1536];
const maxWidths = ['auto'].concat(getContstraints([600, 900, 1200, 1536]));

// DEBUGGING BORDER COLORS
const borders = [
  '1px dashed red',
  '2px dashed green',
  '2px dashed blue',
  '2px dashed purple',
  '2px dashed grey',
];

// DEFAULT INLINE MARGIN
const defaultInlineMargin = ['10px', '20px', '30px', '40px'];
const defaultBlockMargin = ['10px', '20px' ];

const StyledContainer = styled.div<IContainerProps>`
  ${({
    m = [''],
    mx = defaultInlineMargin,
    my = defaultBlockMargin,
    p = [''],
    px = [''],
    py = [''],
    border = [''],
    devMode,
  }) => {
    // Prepare the cssPropsVals object separately to ensure correct types
    const cssPropsVals: Record<string, (string | number)[]> = {
      position: ['relative'],
      'max-width': maxWidths,
      width: ['100%'],
      margin: Array.isArray(m) ? m : [m],
      'margin-inline': Array.isArray(mx) ? mx : [mx],
      'margin-block': Array.isArray(my) ? my : [my],
      padding: Array.isArray(p) ? p : [p],
      'padding-inline': Array.isArray(px) ? px : [px],
      'padding-block': Array.isArray(py) ? py : [py],
    };

    // Conditionally add border property
    if (devMode) {
      cssPropsVals.border = borders;
    } else {
      cssPropsVals.border = Array.isArray(border) ? border : [border];
    }

    return responsiveV2({ cssPropsVals });
  }};
`;

const VeiwPort = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * A styled container component for layout purposes. This component
 * applies responsive styles for margin, padding, and border based
 * on the provided props.
 *
 * Usage:
 * <Container m="10px" p="20px" border="1px solid black">...</Container>
 *
 * @param props - The container properties.
 * @param props.p - Padding (single value or responsive array).
 * @param props.px - Padding X Axis (single value or responsive array).
 * @param props.py - Padding Y Axis (single value or responsive array).
 * @param props.m - Margin (single value or responsive array).
 * @param props.border - Border style (single value or responsive array).
 * @param props.children - React child nodes.
 * @param props.className - Optional CSS class name.
 * @returns A styled container element.
 */

const Container = ({ children, ...props }: IContainerProps) => {
  /**************************************************************************
   * DEV MODE BORDERS
   * ------------------------------------------------------------------------
   * You can pass a query string parameter of borders to the URL to display
   * borders around the containers. This is useful for debugging layout issues.
   *************************************************************************/
  const qsv = useQueryString();  
  const devMode = 'dev' in qsv ? true : false;

  return (
    <VeiwPort>      
      <StyledContainer {...props} devMode={devMode}>
        {children}
      </StyledContainer>
    </VeiwPort>
  );
};

export default Container;
