import * as React from 'react';
import { useState } from 'react';
import { Button } from 'antd';

type ShowMoreSectionProps = {
  children: React.ReactNode;
  className?: string;
};

export const ShowMoreSection = ({ children, className }: ShowMoreSectionProps): JSX.Element => {
  const [fade, setFade] = useState<boolean>(true);
  function toggle() {
    setFade(!fade);
  }
  return (
    <div className={'showmore ' + (className ? className : '')}>
      <div className={fade ? 'fade' : ''}>{children}</div>
      <div className="showmore-button">
        <Button onClick={toggle}>{fade ? 'Show more' : 'Hide'}</Button>
      </div>
    </div>
  );
};

export default ShowMoreSection;
