import React from 'react';
import styled from 'styled-components';
import { IncludedOption, Product } from '../Product.types';
import { Button, Icon, Typography } from 'ui';

type Props = {
  product: Product;
  includes: readonly IncludedOption[];
};

const ProductCardFooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  button {
    ${({ theme }) => theme.breakpoints.mobile} {
      max-width: 45%;
      font-size: 14px;
    }
  }
`;
const Includes = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column wrap;
  ${({ theme }) => theme.breakpoints.tablet} {
    flex-flow: column wrap;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    width: 60%;
  }
`;
const IncludeOption = styled.p`
  margin: 5px 0 0 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  svg {
    margin-right: 5px;
    flex-shrink: 0;
  }
`;

const ProductCardFooter = ({ product, includes }: Props): JSX.Element => (
  <ProductCardFooterContainer>
    {includes && (
      <Includes>
        {includes.map(i => (
          <IncludeOption key={product.slug + i.name}>
            <Icon id="checkmarkwhitebg" />
            <Typography variant="body2">{i.name}</Typography>
          </IncludeOption>
        ))}
      </Includes>
    )}

    <Button ctaButton={product.productCardCta} showLoader />
  </ProductCardFooterContainer>
);
export default ProductCardFooter;
