import * as React from 'react';
import { SectionProps } from 'types';
import PageSectionListing, {
    createListingData,
  } from 'src/features/ui/pagesectionlisting/PageSectionListing';

type PageSectionSpotlightArticlesProps = SectionProps & {
  data: any;
};

const PageSectionSpotlightArticles = ({
  slug,
  headline,
  listingObjectType,
  data,
}: PageSectionSpotlightArticlesProps) => {
  const listingData = createListingData(data[listingObjectType], listingObjectType);
  const recentSlugs = data?.recentArticles.map((item) => item.slug);

  const featuredArticle = data?.featuredArticle;
  const featuredCategory = data?.featuredCategory;
  const items = listingData
    .filter((item) => item.slug !== featuredArticle)
    .filter((item) => !item.tags?.includes(featuredCategory))
    .filter((item) => !recentSlugs?.includes(item.slug))
    .sort((a: any, b: any) => parseInt(a.updatedDaysAgo) - parseInt(b.updatedDaysAgo));

  return <PageSectionListing slug={slug} title={headline} items={items} />;
};

export default PageSectionSpotlightArticles;
