import { LinkType } from 'types';
import { useStaticQuery, graphql } from 'gatsby'

export const getSiteUrl = () : { url: string, productionDomain: string} => {
  const data = useStaticQuery(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          siteUrl
          productionDomain
        }
      }
    }
  `);

  return {
    url: data.site.siteMetadata.siteUrl as string,
    productionDomain: data.site.siteMetadata.productionDomain as string
  }
}

export function isInternalLink(link: LinkType | string) {
  const siteMetadata = getSiteUrl();

  const url = (typeof link === 'string') ? link : link.url;

  if(url) {
    try {
      return url.startsWith('/') || new URL(url).hostname === new URL(siteMetadata.url).hostname;
    } catch (err) {
      // NOP
    }
  }

  return false;
}

export function parseLink(link: string): string {
  const siteMetadata = getSiteUrl();

  if(link.startsWith('mailto')) {
    return link;
  }
  else if (link.startsWith('/')) {
    // Add trailing slash if needed

    try {
      const url = new URL(link, siteMetadata.url);
      if(!url.pathname.endsWith('/')) {
        url.pathname = url.pathname + '/'
      }

      return url.href.slice(`${url.protocol}://${url.hostname}`.length - 1)
    } catch (err) { 
      // NOP
    }
  } 
  else {
    try {
      const url = new URL(link);

      if(
        url.hostname === new URL(siteMetadata.url).hostname ||
        url.hostname === new URL(siteMetadata.productionDomain).hostname) {

        if(!url.pathname.endsWith('')) {
          url.pathname = url.pathname + '/'
        }
  
        return url.href.slice(`${url.protocol}://${url.hostname}`.length - 1)
      }
    } catch (err) {
      // NOP
    }
  }   

  return link;
}

export function fullAssetUrl(url: string): string {
  return url && url.startsWith('//') ? 'https:' + url : url;
}
