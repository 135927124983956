import * as React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';
import { SectionProps } from 'types';

import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { DefaultRichTextRenderOptions } from '../../../../utils/render';
import { SectionContainer, Icon, Image } from 'ui';

export default function Steps({ name, body, blocks }: SectionProps): JSX.Element {
  const settings = {
    infinite: false,
    speed: 500,
    arrows: false,
    centerMode: true,
    slidesToShow: 4,
    centerPadding: '0',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerPadding: '56px',
          arrows: true,
          nextArrow: (
            <CarouselButton className="slider-arrow" right>
              <Icon id="line-arrow-right" />
            </CarouselButton>
          ),
          prevArrow: (
            <CarouselButton className="slider-arrow">
              <Icon id="line-arrow-left" />
            </CarouselButton>
          ),
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          centerPadding: '16px',
          arrows: true,
          nextArrow: (
            <CarouselButton className="slider-arrow" right>
              <Icon id="line-arrow-right" />
            </CarouselButton>
          ),
          prevArrow: (
            <CarouselButton className="slider-arrow">
              <Icon id="line-arrow-left" />
            </CarouselButton>
          ),
        },
      },
    ],
  };
  return (
    <SectionContainer id={name} usePadding contentBackground={'#F8F9F5'}>
      <Container>
        <h2>{body && renderRichText(body, DefaultRichTextRenderOptions)}</h2>
        <div className="blocks">
          <Slider {...settings}>
            {blocks.map((b, key) => {
              const image = isMobile
                ? b?.mobileBackground?.gatsbyImageData
                : b?.background?.gatsbyImageData;
              return (
                <div key={b.headline}>
                  <Image gatsbyImageData={image} />
                  <h3>
                    <span>{key + 1}</span>
                    {b.headline}
                  </h3>
                  {renderRichText(b.body, DefaultRichTextRenderOptions)}
                </div>
              );
            })}
          </Slider>
        </div>
      </Container>
    </SectionContainer>
  );
}

const CarouselButton = styled.button<{ right?: boolean }>`
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
  width: auto !important;
  height: auto !important;
  position: absolute;
  top: unset !important;
  bottom: 50px;
  right: ${(p) => (p.right ? '26px' : '86px')} !important;
  left: unset !important;
  ${({ theme }) => theme.breakpoints.tablet} {
    right: ${(p) => (p.right ? '150px' : '160px')} !important;
  }

  @media screen and (min-width: 751px) and (max-width: 900px) {
    right: ${(p) => (p.right ? '70px' : '126px')} !important;
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
  @media screen and (max-width: 900px) {
    bottom: 50px !important;
  }
  @media screen and (max-width: 550px) {
    bottom: 50px !important;
  }
  @media screen and (max-width: 420px) {
    bottom: 65px !important;
  }
  @media screen and (max-width: 380px) {
    bottom: 70px !important;
  }
  @media screen and (max-width: 330px) {
    bottom: 85px !important;
  }
  @media screen and (max-width: 300px) {
    bottom: 90px !important;
    right: ${(p) => (p.right ? '5px' : '61px')} !important;
  }
  &:before {
    content: '' !important;
  }

  display: flex !important;
  align-items: center;
  width: 50px !important;
  height: 25px !important;
  padding: 1.5px 12.5px !important;
  border-radius: 20px;
  z-index: 10;
  transition: all 0.25s ease-in-out;
  svg {
    width: 19px !important;
    height: 13px;
    margin: 0 auto;
    path {
      stroke: ${({ theme }) => theme.colors.eloLightGrey} !important;
    }
  }
  &.slick-disabled {
    background: ${({ theme }) => theme.colors.eloGreyge} !important;
    border: 1px solid ${({ theme }) => theme.colors.eloGreyge} !important;
    svg path {
      stroke: ${({ theme }) => theme.colors.eloMidGrey} !important;
    }
  }
  &:not(.slick-disabled) {
    background: ${({ theme }) => theme.colors.eloBlack} !important;
    border: 1px solid ${({ theme }) => theme.colors.eloBlack} !important;
  }
`;

const Container = styled.div`
  h2 .typography-p {
    font-family: 'Everett Light';
    color: ${({ theme }) => theme.colors.eloDarkGreen};
    font-size: 51px;
    line-height: 56px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 900px) {
    h2 .typography-p {
      font-size: 29px;
      line-height: 33px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .blocks {
    position: relative;
    margin-top: 0px;
    max-width: 100vw;
    overflow: hidden;

    @media screen and (max-width: 900px) {
      margin-left: -56px;
      margin-right: -56px;
      .slick-slide  {
        h3,
        div {
          transition: all 0.25s ease-in-out;
          opacity: 1;
        }
        &:not(.slick-current) {
          h3,
          div {
            opacity: 0;
          }
        }
      }
    }
    @media screen and (max-width: 750px) {
      margin-left: -16px;
      margin-right: -16px;
    }
    .slick-list {
      position: static !important;
    }
    .slick-track {
      display: flex;
      justify-content: space-between;
    }
    .slick-slide {
      margin: 0 8.5px !important;
      width: calc(25% - 17px);
      &:first-child {
        margin-left: 0 !important;
      }
      &:last-child {
        margin-right: 0 !important;
      }
    }

    > div {
      img {
        width: 100%;
      }
      h3 {
        span {
          color: ${({ theme }) => theme.colors.eloMidGreen};
          margin-right: 10px;
        }
        color: ${({ theme }) => theme.colors.eloBlack};
        font-size: 25px;
        line-height: 30px;
        margin-top: 20px;
      }
      .typography-p {
        font-family: 'Everett Regular';
        font-size: 15px;
        line-height: 21px;
        color: ${({ theme }) => theme.colors.eloDarkGrey};
      }
      @media screen and (max-width: 900px) {
        h3 {
          margin-top: 23px;
          margin-bottom: 15px;
          font-size: 18px;
          line-height: 20.7px;
        }
        .typography-p {
          font-size: 12.5px;
          line-height: 17.5px;
        }
      }
    }
  }
`;
