/*************************************************************************
 * Account Management Store
 * -----------------------------------------------------------------------
 *
 * * IMPORTANT NOTE
 * You should not need to modify this file unless you are adding
 * new actions. Add new state values to the initialState.ts file and
 * ensure that they are properly typed in the types.state.ts file.
 *
 * -----------------------------------------------------------------------
 * * HOW TO USE STORE VALUES AND FUNCTIONS
 * See the readme file in the store folder for instructions on how to use
 * the store values and functions. It's important to use the store correctly
 * to avoid unnecessary re-renders and to ensure that the store is used
 * consistently across the application.
 *
 * -----------------------------------------------------------------------
 * * ABOUT THIS STORE
 * The following store is used to manage the state of the Account Management
 * component. The store is created using Zustand, a small, fast, modern,
 * popular and scalable bearbones state-management solution that supports
 * typescript. Zustand handles immutable state by default and was one of the
 * reasons I chose it. I also chose it for it's small size and simplicity.
 *
 * Additonally, the store enables hooks to be used to access the state and
 * actions in the store. It allows for the state to be established in one
 * place and accessed in multiple components without prop drilling. It can
 * be done without the need to wrap the entire application in a context
 * provider. This in turn allows us to consolidate the state of the feature
 * in one place with the code that is related to Account Management.
 *************************************************************************/

/*************************************************************************
 * IMPORT ZUSTAND AND MIDDLEWARE
 * -----------------------------------------------------------------------
 * ***********************************************************************/
import { StateCreator, create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import produce from 'immer';

/*************************************************************************
 * IMPORT TYPES
 * -----------------------------------------------------------------------
 * ***********************************************************************/
import { NewUserData } from '../types/EloStoreState.types';

/*************************************************************************
 * IMPORT STATE, ACTIONS AND INITIAL STATE
 * -----------------------------------------------------------------------
 * ***********************************************************************/
import initialState from './initialState';
import { EloStoreState, EloStoreActions } from '../types';

// const createStore: StateCreator<EloStoreState & EloStoreActions> = set => ({
const createStore: StateCreator<EloStoreState & EloStoreActions> = set => ({
  /*************************************************************************
   * STATE VALUES
   * -----------------------------------------------------------------------
   * Spread the initial state values here. These are set in the initialState.ts
   * file. To add values to the state store, add them to the initialState.ts file
   * and ensure that they are properly typed in the types.state.ts file.
   *************************************************************************/
  ...initialState,

  /*************************************************************************
   * updateState Action
   * -----------------------------------------------------------------------
   * The updateState action is a generic action that will let you update any
   * value or nested value in the state store. This simplifies actions when
   * updating the state store. Especially if a component needs to update
   * multiple values in the state store or nested values in the state store.
   *
   * It is possible to create update functions that will update a single value
   * in the state store. However, the updateState action is more flexible and
   * can be used to update any value or nested value in the state store.
   *************************************************************************/
  updateAccountState: (fn: (state: EloStoreState) => void) => set(produce(fn)),
  updateNewUserData: (fn: (newUserData: NewUserData) => void) =>
    set(
      produce((state: EloStoreState) => {
        if (!state.newUserData) return;
        fn(state.newUserData);
      })
    ),
  // Reset State
  reset: () => {    
    set(initialState)
  },
});

/*************************************************************************
 * EXPORT THE STORE HOOK
 * -----------------------------------------------------------------------
 * The store is exported so that it can be used in the Account Management
 * component. It is also exported so that it can be used in tests.
 *
 * * USE SESSION STORAGE PERISTANCE
 * -----------------------------------------------------------------------
 * The store is persisted to session storage. This means that the state of
 * the store will be saved to session storage and will be available when
 * the user refreshes the page or navigates to a different page. This is
 * useful for the user account data. It means that the user account data
 * will be available when the user navigates to a different page or
 * refreshes the page.
 *
 * This is critical for a good user experience. It will prevent, for example,
 * the user seeing logged out results flash on the screen when they navigate
 * to a different page and come back or if the user refreshes the page.
 *************************************************************************/
// * WITHOUT PERSIST OPTION
// export const useAccountStore = create(immer(
//   createStore
//   ));

// * WITH PERSIST OPTION
// Assuming localStorage is available, apply immer and persist middleware
// export const useAccountStore = create(persist(immer(createStore), {
//   name: 'elo-account-store',
//   storage: createJSONStorage(() => sessionStorage),
// }));
export const useAccountStore = create(
  persist(immer(devtools(createStore)), {
    name: 'elo-account-store',
    storage: createJSONStorage(() => sessionStorage),
  })
);

export default useAccountStore;
