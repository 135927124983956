import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { productImages } from '../../../../../elo.config';

const Confirm = () => {
  return (
    <Stack
      width={'100%'}
      display={'flex'}
      alignContent={'center'}
      alignItems={'center'}
      justifyContent={'center'}
      // border={'1px solid'}
    >
      <Box my={'.47rem'}>
        <Typography variant="h2">Confirm your order</Typography>
      </Box>
      <Box my={'.47rem'}>
        <Typography variant="caption">
          Please allow up to 2 weeks for your product to arrive.
          You can safely consume the remainder of your Create supply until Form is
          delivered.
        </Typography>
      </Box>
      <Box my={'.47rem'}>
        <img
          src={productImages.form}
          alt={'Form'}
          style={{
            maxWidth: '100%',
            maxHeight: '240px',
            objectFit: 'contain',
            width: 'auto',
            height: 'auto',
          }}
        />
      </Box>
      <Box my={'.47rem'}>
        <Typography variant="caption">
          *Subscription starts immediately. You will continue to be billed for the remainder of your
          Create subscription.
        </Typography>
      </Box>
    </Stack>
  );
};

export default Confirm;
