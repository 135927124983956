import * as React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { SectionProps } from 'types';
import { SectionContainer, BackgroundImage, Button } from 'ui';

const Banner = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 26px 40px 34px 40px;
  ${({ theme }) => theme.breakpoints.tablet} {
    flex-direction: row;
    padding: 45px 60px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    padding: 72px 86px;
  }
`;

const BiggerText = styled.div`
  font-family: 'Everett Medium';
  font-size: 23px;
  line-height: 28px;
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 42px;
    line-height: 45px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 42px;
    line-height: 45px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    font-size: 56px;
    line-height: 64px;
  }
`;

const HeadlineContainer = styled(BiggerText)`
  margin-bottom: 20px;
  ${({ theme }) => theme.breakpoints.tablet} {
    margin-bottom: 0;
  }
`;

const PageSectionProductImage = ({
  headline,
  background,
  mobileBackground,
  slug,
  ctaButton,
}: SectionProps) => {
  const bgImage = isMobile ? mobileBackground : background;

  return (
    <SectionContainer id={slug} usePaddingNoMobile contentBackgroundColor={'white'}>
      <BackgroundImage desktopImage={bgImage} mobileImage={bgImage} />
      <Banner>
        <HeadlineContainer>{headline}</HeadlineContainer>
        <Button ctaButton={ctaButton} inverted />
      </Banner>
    </SectionContainer>
  );
};

export default PageSectionProductImage;
