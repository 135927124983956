import * as React from 'react';
import styled from 'styled-components';
import times from 'lodash/times';
import Typed from 'react-typed';
import { useEloStore } from '../../../store/EloStore.hooks';

import { SectionProps } from 'types';
import { SectionContainer, Image, Icon, Button } from 'ui';

import {
  displayPrice,
  getSubscription,
  getPricing,
  getCartPrice,
  getDiscountedPrice,
  applyDiscount,
  displayRecurringPrice,
} from '../../../product/utils';

import analytics from '../../../../utils/analytics';
import { Product } from '../../../product/Product.types';

const PLPHero = (props: SectionProps): JSX.Element => {
  const { products } = props;

  const { store, cart } = useEloStore();
  if (!products || !products[0]) return <></>;
  let product = products && products[0];
  const contextProduct = store.products[product.slug] || product;

  const selectedSubscription = getSubscription(contextProduct as Product);
  const subscriptionPrice = getPricing(selectedSubscription);
  product = applyDiscount(
    product as Product,
    store.promotions.filter((p) => product?.coupon?.stripeCouponId === p.id)
  );
  const totalCost = getCartPrice(selectedSubscription);
  const discount = getDiscountedPrice(totalCost, product);
  const hasDiscount = !!product?.coupon?.stripeCouponId;

  React.useEffect(() => {
    try {
      analytics
        .viewItem({
          items: [
            {
              item_name: product.title,
              item_id: product.stripeProductId ?? 'N/A',
              quantity: 1,
            },
          ],

          value: subscriptionPrice?.price ?? 0,
          currency: subscriptionPrice
            ? subscriptionPrice.currency === '$'
              ? 'USD'
              : subscriptionPrice.currency
            : 'USD',
        })
        .finally(/* NOP */);
    } catch (err) {
      console.debug('Analytics event failed.');
    }
  }, [product]);

  const [currentScroll, setCurrentScroll] = React.useState<number>(-1);

  React.useEffect(() => {
    const handleScroll = () => {
      const id = 'product-action-footer';
      const t = document?.getElementById(id);

      const scrolled = document?.scrollingElement?.scrollTop ?? scrollY;
      const position = t?.offsetTop ?? 276;
      const ofPos = window.innerHeight - position;

      if (!t?.classList.contains('active')) {
        setCurrentScroll(ofPos);
      }

      if (scrolled > currentScroll) {
        t?.classList.add('active');
      } else {
        t?.classList.remove('active');
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.body.addEventListener('touchmove', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.removeEventListener('touchmove', handleScroll);
    };
  }, [currentScroll]);

  const hasSwitcher = props.headline?.indexOf('SWITCHER') >= 0;

  return (
    <SectionContainer id={product.title} contentBackground={'#fff'}>
      <BuyProductContainer>
        <Image
          gatsbyImageData={props?.background?.gatsbyImageData}
          className={'bg bg-desktop'}
          loading="eager"
        />
        <Image
          gatsbyImageData={props?.mobileBackground?.gatsbyImageData}
          className={'bg bg-mobile'}
          loading="eager"
        />

        <ProductInfo>
          <h1>
            {props.headline?.replace(' __SWITCHER__', '')}
            {hasSwitcher && (
              <>
                <br />
                <Typed
                  strings={props.blocks.map((b) => b.headline)}
                  typeSpeed={115}
                  backSpeed={115}
                  backDelay={2300}
                  loop
                />
              </>
            )}
          </h1>
          <p className="description">{props.subHeading}</p>
          <ButtonContainer id="product-action-footer">
            <Button
              onClick={() => {
                cart.addOrUpdateProduct(
                  product.slug,
                  selectedSubscription?.slug || '',
                  {},
                  product.cartPath
                );
              }}
              showLoader
            >
              {product.pdpCtaText || 'Add to cart'}
            </Button>
            {selectedSubscription?.cartCtaText && (
              <p className="helper-text">
                {product.pdpCtaHelperText ?? selectedSubscription?.cartCtaText}
              </p>
            )}
            {product.pdpCtaCouponHelperText && (
              <p className="helper-text">{product.pdpCtaCouponHelperText}</p>
            )}
            <div className="mobile-sticky-footer">
              <Button
                onClick={() => {
                  cart.addOrUpdateProduct(
                    product.slug,
                    selectedSubscription?.slug || '',
                    {},
                    product.cartPath
                  );
                }}
                showLoader
              >
                {product.pdpCtaText || 'Add to cart'}
              </Button>
              {selectedSubscription?.cartCtaText && (
                <p className="helper-text">
                  {product.pdpCtaHelperText ?? selectedSubscription?.cartCtaText}
                </p>
              )}
            </div>
          </ButtonContainer>
          <div className="price-container">
            <p className="price">
              {!hasDiscount && displayRecurringPrice(subscriptionPrice, true)}

              {discount && (
                <>
                  <span className="discounted">
                    {displayRecurringPrice(subscriptionPrice, true)}
                  </span>
                  ${displayPrice(discount)} First month
                </>
              )}
            </p>
            <StarsContainer>
              {times(Math.ceil(product.rating), (i) => (
                <Icon id="star" className="full" key={`${product.slug}-star-${i}`} />
              ))}
              {product.rating < 4 && (
                <Icon id="halfstar" className="half" key={`${product.slug}-star`} />
              )}
              <span
                onClick={() => {
                  const elem = document.querySelector('.reviews');
                  if (elem && elem) {
                    elem.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }
                }}
                className="link"
              >
                See reviews
              </span>
            </StarsContainer>
          </div>
        </ProductInfo>
      </BuyProductContainer>
    </SectionContainer>
  );
};
export default PLPHero;

const BuyProductContainer = styled.div`
  position: relative;
  .bg {
    width: 100%;
  }
  .bg-desktop {
    ${({ theme }) => theme.breakpoints.mobile} {
      display: none;
    }
    ${({ theme }) => theme.breakpoints.tablet} {
      display: block;
    }
  }
  .bg-mobile {
    ${({ theme }) => theme.breakpoints.mobile} {
      display: block;
    }

    ${({ theme }) => theme.breakpoints.tablet} {
      display: none;
    }
  }

  margin-top: 55px;
  margin-bottom: 110px;

  ${({ theme }) => theme.breakpoints.small} {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  h1 {
    font-family: 'Everett Light';
    color: ${({ theme }) => theme.colors.eloDarkGreen};
    font-size: 33px;
    line-height: 36px;
    margin-bottom: 16px;
    br {
      display: none;
    }
    > span {
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
      margin-left: 10px;
      // min-width: 220px;
      // display: inline-block;
      .typed-cursor {
        display: none;
      }
    }
  }
  p.description {
    color: ${({ theme }) => theme.colors.eloDarkGrey};
    font-size: 14px;
    line-height: 20px;

    margin-bottom: 15px;
  }
  .price-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column;
    margin-top: 20px;
    ${({ theme }) => theme.breakpoints.small} {
      flex-flow: row;
      margin-top: 0px;
    }
    .discounted {
      color: ${({ theme }) => theme.colors.eloMidGrey};
      text-decoration: line-through;
      margin-right: 5px;
    }
    p.price {
      font-family: 'Everett Mono Medium';
      font-size: 13px;
      line-height: 13px;
      color: ${({ theme }) => theme.colors.eloDarkGrey};
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 10px;
      ${({ theme }) => theme.breakpoints.small} {
        margin-bottom: 0px;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.mobile} {
    h1,
    p.description {
      margin: 0 auto;
      text-align: center;
    }
    h1 {
      width: 353px;
      margin-bottom: 16px;
      br {
        display: block;
      }
    }
    p.description {
      // width: 314px;
      margin-bottom: 34px;
    }
  }
  ${({ theme }) => theme.breakpoints.tablet} {
    h1 {
      width: 600px;
      margin-bottom: 38px;
    }
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    h1 {
      font-size: 60px;
      line-height: 60px;
      width: 565px;
      margin-bottom: 38px;
    }
    p.description {
      font-size: 16px;
      line-height: 23px;
      width: 524px;
      margin-bottom: 32px;
    }
  }
`;
const ProductInfo = styled.div`
  padding: 0;
  margin-top: -223px;
  z-index: 20;
  position: absolute;
  width: 100%;

  ${({ theme }) => theme.breakpoints.small} {
    position: absolute;
    z-index: 50;
    top: 0 !important;
    left: 70px;
    width: 524px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    margin-top: 50px;
  }

  @media screen and (min-width: 1000px) and (max-width: 1200px) {
    h1 {
      font-size: 45px;
      line-height: 45px;
    }
  }
  @media screen and (min-width: 1000px) {
    margin-top: 30px;
  }
  @media screen and (min-width: 1100px) {
    top: 140px;
    left: 70px;
  }
  @media screen and (min-width: 1200px) {
    top: 140px;
    left: 70px;
  }
  @media screen and (min-width: 1300px) {
    top: 150px;
    left: 70px;
  }
  @media screen and (min-width: 1400px) {
    top: 170px;
    left: 70px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    top: 170px;
    left: 190px;
  }
`;
const ButtonContainer = styled.div`
  .mobile-sticky-footer {
    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    padding: 0 1rem 15px;
    box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.15);
    transform: translateY(100%);
    opacity: 0;
    transition: all 0.25s ease-in-out;
    button {
      margin-top: -25px;
      box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.05);
    }
  }

  .mobile-sticky-footer {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    .mobile-sticky-footer {
      display: block;
    }
  }
  &.active {
    ${({ theme }) => theme.breakpoints.mobile} {
      .mobile-sticky-footer {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  margin-bottom: 25px;

  button {
    width: 100%;
    margin-bottom: 11px;
  }
  p {
    font-size: 11px;
    line-height: 18.85px;
    color: ${({ theme }) => theme.colors.eloDarkGrey};
    text-align: center;
    white-space: pre;
    margin-bottom: 0;
    opacity: 0.7;
  }
  ${({ theme }) => theme.breakpoints.mobile} {
    max-width: 333px;
    margin: 0 auto;
    p {
      font-size: 10px;
      line-height: 13.5px;
    }
  }
`;
const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    height: 23px;
    width: 25px;
    ${({ theme }) => theme.breakpoints.mobile} {
      height: 16px;
      width: 16px;
    }
    &.full {
      path {
        &.full {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }
    }
    &.half {
      path {
        &.full {
          fill: ${({ theme }) => theme.colors.eloLightGrey};
        }
        &.half {
          fill: ${({ theme }) => theme.colors.primary};
        }
      }
    }

    margin-right: 0.25rem;
  }
  color: ${({ theme }) => theme.colors.eloMidGrey};
  span {
    transition: all 0.25s ease-in-out;
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.colors.eloMidGrey};
    font-size: 13px;
    line-height: 17.5px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.eloDarkGreen};
    }
  }
`;
