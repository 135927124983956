/*****************************************************
 * TODO: ADD SX PROPS TO BUTTON
 *----------------------------------------------------
 */
import * as React from 'react';
import { navigate } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import styled, { css } from 'styled-components';
import { useEloStore } from '../../store/EloStore.hooks';
import { parseLink } from '../../../utils/url';
import { getSubscription } from '../../product/utils';
import { Product, SubscriptionModel } from '../../product/Product.types';
import { LoadingOutlined } from '@ant-design/icons';
import { Typography, Box } from 'ui';

import posthog from 'posthog-js';

export interface CTAButtonProps {
  link: string;
  slug: string;
  title: string;
  type: 'button' | 'submit' | 'reset';
  product?: Product;
  style?: string;

  // Event Tracking
  eventName?: string | null | undefined;
  eventData?: { internal: { content: string } } | undefined;
  eventCohort?: string;
}

export interface ButtonProps extends React.PropsWithChildren {
  onClick?: () => void;
  id?: string;
  ctaButton?: CTAButtonProps;
  path?: string;
  inverted?: boolean;
  squared?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  form?: string;
  product?: Product;
  style?: string;
  showLoader?: boolean;
  sx?: any;

  // EVENT TRACKING
  eventName?: string | null;
  eventData?: { internal: { content: string } } | undefined;
  eventCohort?: string | null;
}

const ButtonComponent = styled.button<ButtonProps>(
  ({ inverted, squared, theme, sx }) => `      
    cursor: pointer;
    border: none;
    border-radius: 48px;
    min-height: 48px; 
    ${squared ? 'border-radius: 7px' : ''}
    padding: 13px 56px;   
    margin: 0;
    transition: background 1s;

    color: ${theme.colors.eloBlack};

    background-color: ${theme.colors.primary};
    &:hover {
        transition: background 1s;
        background-color: ${theme.colors.primaryHighlight};
    }
    ${
      inverted
        ? `
        color: ${theme.colors.white};
        background-color: ${theme.colors.black};
        &:hover {
            background-color: ${theme.colors.blackHighlight};
        }
    `
        : ``
    }

    &.transparent {
      background: transparent;
      color: ${theme.colors.eloBlack};
    } 
    &.border {
      border: 1px solid transparent;
      &.green {
        border-color: ${theme.colors.luminaryOrange};
      }
      &.orange {
        border-color: ${theme.colors.luminaryOrange};
      }
    }

    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row;
    white-space: nowrap;
    ${theme.breakpoints.tablet} {
        font-size: 15px;
        line-height: 21.75px;

    }

    ${
      sx &&
      Object.entries(sx)
        .map(([key, value]) => {
          return `${kebabCase(key)}: ${value};`;
        })
        .join(' ')
    };
    
`
);

export default function Button({
  onClick,
  id,
  ctaButton,
  path,
  inverted,
  squared,
  children,
  disabled,
  form,
  type = 'button',
  showLoader,
  sx,

  // EVENT TRACKING
  eventName,
  eventData,
  eventCohort,

}: ButtonProps): JSX.Element {
  if (!ctaButton && !onClick && !path) {
    return <></>;
  }

  let clickHandler: () => void;

  let currentUrl: string;
  if (typeof window !== 'undefined') {
    currentUrl = window.location.pathname;
  }

  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (loading && !showLoader) {
      setLoading(false);
    }
  }, [loading, showLoader]);

  const { cart, store } = useEloStore();
  let product: Product | undefined, selectedSubscription: SubscriptionModel | undefined;
  if (ctaButton) {
    product = ctaButton.product ?? undefined;
    const contextProduct = (product && store.products[product.slug]) || product;
    selectedSubscription = getSubscription(contextProduct as Product);
  }

  if (path) {
    const link = parseLink(path);
    clickHandler = () => {
      if (link !== currentUrl) {
        setLoading(true);
      }
      navigate(link);
    };
  }

  if (!onClick && ctaButton) {
    const link = parseLink(ctaButton.link);
    clickHandler = () => {
      if (ctaButton.link.startsWith('#')) {
        const elem = document.querySelector(ctaButton.link);
        if (elem) {
          elem.scrollIntoView({
            behavior: 'smooth',
          });
        }
      } else {
        if (link !== currentUrl) {
          setLoading(true);
        }
        navigate(link);
      }
    };
  }


  /**********************************************************************
   * EVENT TRACKING HANDLER
   * --------------------------------------------------------------------
   * This function sends events to PostHog when the button is clicked.
   * It uses data that originates from the contentful cms
   * ********************************************************************/
  const handleEvent = () => {
    if (ctaButton?.eventName) {
      const eventDataJSON = JSON.parse(ctaButton?.eventData?.internal?.content ?? '{}');      
      posthog.capture(ctaButton?.eventName, { ...eventDataJSON });      
    }
    if (eventName) {
      const eventDataJSON = JSON.parse(eventData?.internal?.content ?? '{}');      
      posthog.capture(eventName, { ...eventDataJSON });
    }
  }

  // Added product to button so this should add product to cart
  if (product && selectedSubscription) {
    clickHandler = () => {
      setLoading(true);
      cart.addOrUpdateProduct(
        product.slug,
        selectedSubscription?.slug || '',
        {},
        path ?? product?.cartPath
      );
    };
  }

  const handleClick = () => {
    handleEvent();
    if (onClick) {
      setLoading(true);
      onClick();
    }
    if (clickHandler) {
      clickHandler();
    }
  };

  return (
    <ButtonComponent
      id={id}
      inverted={inverted}
      squared={squared}
      onClick={handleClick}
      disabled={disabled}
      type={type}
      form={form}
      className={ctaButton?.style}
      sx={sx}
      {...(eventCohort ? { 'data-action': eventCohort } : {})}
      {...(ctaButton?.eventCohort ? { 'data-action': ctaButton.eventCohort } : {})}
    >
      {showLoader && loading && (
        <>
          <LoadingOutlined />
        </>
      )}
      {(!showLoader || !loading) && (
        <>
          <Typography variant="button" sx={{ color: 'inherit' }}>
            {ctaButton?.title}
            {children}
          </Typography>
        </>
      )}
    </ButtonComponent>
  );
}
