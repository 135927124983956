import React from 'react';
import { Link } from 'gatsby';
import { Container, Box, Typography } from 'ui';

const Error = () => {
  return (
    <>
      <Container>
        <Box>
          <Typography variant="h2">We seem to have hit a snag.</Typography>
          <Typography variant="body2">
            We couldn’t load the custom formula questionnaire. We have alerted our support team. You
            can try to refresh the page or try again later. You may also visit our{' '}
            <Link to="https://help.elo.health" target="_blank">
              Help Center
            </Link>{' '}
            or report this issue to us at <Link to="mailto:elo@elo.health">elo@elo.health</Link>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default Error;
