/*************************************************************************
 * Assessment Summary State Store
 * -----------------------------------------------------------------------
 * This file contains the state store for the Assessment Summary feature.
 * I wanted to use a state store to avoid prop drilling and to keep the
 * state of the feature in one place.
 *
 * * ZUSTAND
 * Zustand is a small, fast, modern, popular and scalable bearbones
 * state-management solution that supports typescript. Zustand handles
 * immutable state by default and was one of the reasons I chose it.
 * I also chose it for it's small size and simplicity.
 *
 * * MORE ON IMMUTABLE STATE
 * https://docs.pmnd.rs/zustand/guides/immutable-state-and-merging
 *
 * BLAME or PRAISE: Paul Whitaker for this implementation.
 *
 *************************************************************************/
import { Product } from 'src/types/product';
import { PreliminaryPlanMember, PreliminaryPlan } from 'src/API';
import { StateCreator, create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import produce from 'immer';
import { State, Store } from './store.types';

// type Store = State & Actions;

const createStore: StateCreator<Store> = set => ({

  /*************************************************************************
   * STATE VALUES
   * -----------------------------------------------------------------------
   * The following values are the state values for the Assessment Summary.
   * * Types
   * Types are defined in the store.types.ts file.
   * * NOTE ON NESTED OBJECT VALUES
   * The use of immer in the updateState action simplifies updating nested
   * values in the state store. Therefore it's possible to create store values
   * that are complex nested objects and update them while using an immutable
   * state store.
   *************************************************************************/
  storeLoaded: false,
  storeProduct: null,
  storeFormula: null,
  storeMember: null,
  storeProductSlug: null,
  storeSubscriptionSlug: null,

  /*************************************************************************
   * updateState Action
   * -----------------------------------------------------------------------
   * The updateState action is a generic action that will let you update any
   * value or nested value in the state store. This simplifies actions when
   * updating the state store. Especially if a component needs to update
   * multiple values in the state store or nested values in the state store.
   *************************************************************************/
  updateState: (fn: (state: State) => void) => set(produce(fn)),
  // The following actions are more granular and are used to update a single value in the state store.
  updateStoreProduct: (product: Product) => set(() => ({ storeProduct: product })),
  updateStoreFormula: (formula: PreliminaryPlan) => set(() => ({ storeFormula: formula })),
  updateStoreMember: (member: PreliminaryPlanMember) => set(() => ({ storeMember: member })),
  updateStoreProductSlug: (slug: string) => set(() => ({ storeProductSlug: slug })),
  updateStoreSubscriptionSlug: (slug: string) => set(() => ({ storeSubscriptionSlug: slug })),
  updateStoreLoaded: (loaded: boolean) => set(() => ({ storeLoaded: loaded })),
});

/*************************************************************************
 * useAssessmentStore Hook
 * -----------------------------------------------------------------------
 * The useAssessmentStore hook is used to access the state store from
 * anywhere in the wrapped assessment summary component.
 *
 * The useAssessmentStore hook is wrapped with devtools and immer.
 *
 * * DEVTOOLS
 * Devtools is provided by Zustand and is used to provide a redux devtools
 * like experience in the browser. It is only enabled in development mode.
 *
 * * IMMER
 * Immer is provided by Zustand and is used to handle immutable state.
 * A practical example of this is the updateState action. The updateState
 * action is used to update the state store. It takes a function as an
 * argument and uses immer to handle the immutable state. This simplifies
 * actions that update the state store. It enables a component to update any
 * value or nested value in the state store without having to use more granular
 * actions. For example, the updateStoreLoaded action could be used directly.
 * This is fine if you only want to updated a single value in the state store.
 * However, if you want to update multiple values in the state store, you would
 * have to create a new action for each value. This is not ideal. Instead, you
 * can use the updateState action to update multiple values in the state store.
 *************************************************************************/
export const useAssessmentStore = create(devtools(immer(createStore)));
export default useAssessmentStore;
