/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type QualificationAnswerInput = {
  customerId?: string | null,
  anonymousId?: string | null,
  email?: string | null,
  channel?: string | null,
  primary_goal?: string | null,
  isOver18?: boolean | null,
  phoneType?: string | null,
  state?: string | null,
  metadata?: string | null,
};

export type StartCheckoutInput = {
  lineItems?: Array< LineItem > | null,
  customerEmail?: string | null,
  anonymousId?: string | null,
  referenceId?: string | null,
  preliminaryPlanId?: string | null,
  targeting?: TargetingInput | null,
  promotionCode?: string | null,
  cancelUrl?: string | null,
  metaData?: string | null,
};

export type LineItem = {
  productId: string,
  quantity: number,
  priceId?: string | null,
};

export type TargetingInput = {
  utmCampaign?: string | null,
  utmSource?: string | null,
  utmMedium?: string | null,
  utmContent?: string | null,
  utmTerm?: string | null,
};

export type CheckoutDetails = {
  __typename: "CheckoutDetails",
  checkoutUrl?: string | null,
  expiresAt?: string | null,
  orderId?: string | null,
  name?: string | null,
  email?: string | null,
  shippingAddress?: Address | null,
  billingAddress?: Address | null,
  paymentMethodType?: string | null,
  paymentMethodDescription?: string | null,
  lineItems?:  Array<TransactionLineItem | null > | null,
  variants?: Array< string | null > | null,
  totalPrice?: PriceDetails | null,
  appStoreDownloadUrl?: string | null,
  fail?: CheckoutStartFailure | null,
};

export type Address = {
  __typename: "Address",
  name?: string | null,
  company?: string | null,
  street?: string | null,
  street2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
};

export type TransactionLineItem = {
  __typename: "TransactionLineItem",
  type: TransactionLineItemType,
  title: string,
  id: string,
  priceId?: string | null,
  sku?: string | null,
  imageUrl?: string | null,
  description?: string | null,
  quantity: number,
  unitPrice: number,
  trialUnitPrice?: number | null,
  currency: Currency,
};

export enum TransactionLineItemType {
  product = "product",
  discount = "discount",
  duty = "duty",
  shipping = "shipping",
  tax = "tax",
  total = "total",
}


export enum Currency {
  USD = "USD",
  EUR = "EUR",
}


export type PriceDetails = {
  __typename: "PriceDetails",
  currency?: Currency | null,
  discount?: number | null,
  shipping?: number | null,
  tax?: number | null,
  total?: number | null,
};

export type CheckoutStartFailure = {
  __typename: "CheckoutStartFailure",
  errorCode?: CheckoutStartError | null,
  errorMessage?: string | null,
};

export enum CheckoutStartError {
  DUPLICATE_SUBSCRIPTION = "DUPLICATE_SUBSCRIPTION",
  OTHER = "OTHER",
}


export type SubscribeMailingListInput = {
  customerEmail: string,
  anonymousId?: string | null,
  lists?: Array< string | null > | null,
  firstName?: string | null,
  lastName?: string | null,
  metaData?: string | null,
};

export type UpdateQuestionnaireStatusInput = {
  formId: string,
  anonymousId: string,
  email?: string | null,
  responseId?: string | null,
  targeting?: TargetingInput | null,
  startedAt?: string | null,
  updatedAt?: string | null,
  completedAt?: string | null,
  errorAt?: string | null,
  error?: boolean | null,
  errorMessages?: Array< string | null > | null,
  currentQuestion?: string | null,
};

export type ServiceStatus = {
  __typename: "ServiceStatus",
  service?: string | null,
  status?: string | null,
  updatedAt?: string | null,
};

export type ProductList = {
  __typename: "ProductList",
  products?:  Array<Product | null > | null,
};

export type Product = {
  __typename: "Product",
  id?: string | null,
  slug?: string | null,
  name?: string | null,
  category?: string | null,
  features?: Array< string | null > | null,
  pricing?:  Array<ProductPrice | null > | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  serving?: Serving | null,
  servingCount?: number | null,
  products?:  Array<Product | null > | null,
  ingredients?:  Array<Ingredient | null > | null,
  assessments?:  Array<Assessment | null > | null,
  assets?:  Array<WebAsset | null > | null,
};

export type ProductPrice = {
  __typename: "ProductPrice",
  id?: string | null,
  type?: string | null,
  currency?: Currency | null,
  billingScheme?: string | null,
  unitAmount?: number | null,
  recurring?: RecurringPricing | null,
};

export type RecurringPricing = {
  __typename: "RecurringPricing",
  aggregateUsage?: string | null,
  interval?: string | null,
  intervalCount?: number | null,
  usageType?: string | null,
};

export type Serving = {
  __typename: "Serving",
  unit?: string | null,
  quantity?: number | null,
};

export type Ingredient = {
  __typename: "Ingredient",
  id: string,
  slug: string,
  name: string,
  category?: string | null,
  shortDescription?: string | null,
  longDescription?: string | null,
  dose?: Dosage | null,
  assessments?:  Array<Assessment | null > | null,
  assets?:  Array<WebAsset | null > | null,
};

export type Dosage = {
  __typename: "Dosage",
  quantity: number,
  unit: string,
};

export type Assessment = {
  __typename: "Assessment",
  type: string,
  name: string,
  slug?: string | null,
};

export type WebAsset = {
  __typename: "WebAsset",
  createdAt?: string | null,
  contentType?: string | null,
  title?: string | null,
  description?: string | null,
  tags?: Array< string | null > | null,
  assetKey?: string | null,
  assetUrl?: string | null,
  thumbnailKey?: string | null,
  thumbnailUrl?: string | null,
  cmsKey?: string | null,
  cmsId?: string | null,
};

export enum DiscountCodeType {
  PROMOTION_CODE = "PROMOTION_CODE",
  COUPON_ID = "COUPON_ID",
  PROMOTION_CODE_ID = "PROMOTION_CODE_ID",
}

export type CouponDetails = {
  __typename: "CouponDetails",
  id: string,
  name?: string | null,
  appliesTo?: Array< string | null > | null,
  amountOff?: number | null,
  percentOff?: number | null,
};

export type PreliminaryPlanResult = ClientErrorPlanNotFound | ClientErrorPlanExpired | ExternalErrorS3InvalidData | PreliminaryPlan


export type ClientErrorPlanNotFound = {
  __typename: "ClientErrorPlanNotFound",
  message: string,
};

export type ClientError = {
  __typename: "ClientError",
  message: string,
};

export type ClientErrorPlanExpired = {
  __typename: "ClientErrorPlanExpired",
  message: string,
};

export type ExternalErrorS3InvalidData = {
  __typename: "ExternalErrorS3InvalidData",
  message: string,
};

export type ExternalError = {
  __typename: "ExternalError",
  message: string,
};

export type PreliminaryPlan = {
  __typename: "PreliminaryPlan",
  id: string,
  status: PreliminaryPlanStatus,
  createdAt: string,
  expiresAt?: string | null,
  member: Member,
  products?:  Array<Product | null > | null,
  error?: boolean | null,
  errorMessages?: Array< string | null > | null,
};

export enum PreliminaryPlanStatus {
  pending = "pending",
  created = "created",
  failed = "failed",
}


export type Member = {
  __typename: "Member",
  anonymousId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  name?: string | null,
  email?: string | null,
  affiliations?: Array< string | null > | null,
  age?: number | null,
  sex?: string | null,
  diets?: Array< string | null > | null,
  sports?: Array< string | null > | null,
  goals?: Array< string | null > | null,
  targeting?: Targeting | null,
};

export type Targeting = {
  __typename: "Targeting",
  utmCampaign?: string | null,
  utmSource?: string | null,
  utmMedium?: string | null,
  utmContent?: string | null,
  utmTerm?: string | null,
};

export type SendQualificationAnswersMutationVariables = {
  input: QualificationAnswerInput,
};

export type SendQualificationAnswersMutation = {
  sendQualificationAnswers?: boolean | null,
};

export type SubmitContactFormMutationVariables = {
  name: string,
  email: string,
  message: string,
  anonymousId?: string | null,
};

export type SubmitContactFormMutation = {
  submitContactForm?: boolean | null,
};

export type StartCheckoutMutationVariables = {
  input: StartCheckoutInput,
};

export type StartCheckoutMutation = {
  startCheckout?:  {
    __typename: "CheckoutDetails",
    checkoutUrl?: string | null,
    expiresAt?: string | null,
    orderId?: string | null,
    name?: string | null,
    email?: string | null,
    shippingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
    paymentMethodType?: string | null,
    paymentMethodDescription?: string | null,
    lineItems?:  Array< {
      __typename: "TransactionLineItem",
      type: TransactionLineItemType,
      title: string,
      id: string,
      priceId?: string | null,
      sku?: string | null,
      imageUrl?: string | null,
      description?: string | null,
      quantity: number,
      unitPrice: number,
      trialUnitPrice?: number | null,
      currency: Currency,
    } | null > | null,
    totalPrice?:  {
      __typename: "PriceDetails",
      currency?: Currency | null,
      discount?: number | null,
      shipping?: number | null,
      tax?: number | null,
      total?: number | null,
    } | null,
    appStoreDownloadUrl?: string | null,
    fail?:  {
      __typename: "CheckoutStartFailure",
      errorCode?: CheckoutStartError | null,
      errorMessage?: string | null,
    } | null,
  } | null,
};

export type SubscribeMailingListMutationVariables = {
  input: SubscribeMailingListInput,
};

export type SubscribeMailingListMutation = {
  subscribeMailingList?: boolean | null,
};

export type UpdateQuestionnaireStatusMutationVariables = {
  input?: UpdateQuestionnaireStatusInput | null,
};

export type UpdateQuestionnaireStatusMutation = {
  updateQuestionnaireStatus?: boolean | null,
};

export type GetStatusQuery = {
  getStatus?:  Array< {
    __typename: "ServiceStatus",
    service?: string | null,
    status?: string | null,
    updatedAt?: string | null,
  } | null > | null,
};

export type GetCheckoutQueryVariables = {
  sessionId: string,
};

export type GetCheckoutQuery = {
  getCheckout?:  {
    __typename: "CheckoutDetails",
    checkoutUrl?: string | null,
    expiresAt?: string | null,
    orderId?: string | null,
    name?: string | null,
    email?: string | null,
    shippingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
    billingAddress?:  {
      __typename: "Address",
      name?: string | null,
      company?: string | null,
      street?: string | null,
      street2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
    } | null,
    paymentMethodType?: string | null,
    paymentMethodDescription?: string | null,
    lineItems?:  Array< {
      __typename: "TransactionLineItem",
      type: TransactionLineItemType,
      title: string,
      id: string,
      priceId?: string | null,
      sku?: string | null,
      imageUrl?: string | null,
      description?: string | null,
      quantity: number,
      unitPrice: number,
      trialUnitPrice?: number | null,
      currency: Currency,
    } | null > | null,
    variants?: Array< string | null > | null,
    totalPrice?:  {
      __typename: "PriceDetails",
      currency?: Currency | null,
      discount?: number | null,
      shipping?: number | null,
      tax?: number | null,
      total?: number | null,
    } | null,
    appStoreDownloadUrl?: string | null,
    fail?:  {
      __typename: "CheckoutStartFailure",
      errorCode?: CheckoutStartError | null,
      errorMessage?: string | null,
    } | null,
  } | null,
};

export type GetPreliminaryPlanQueryVariables = {
  id: string,
};

export type GetPreliminaryPlanQuery = {
  getPreliminaryPlan: ( {
      __typename: "ClientErrorPlanNotFound",
      message: string,
    } | {
      __typename: "ClientErrorPlanExpired",
      message: string,
    } | {
      __typename: "ExternalErrorS3InvalidData",
      message: string,
    } | {
      __typename: "PreliminaryPlan",
      id: string,
      status: PreliminaryPlanStatus,
      createdAt: string,
      expiresAt?: string | null,
      member:  {
        __typename: string,
        anonymousId?: string | null,
        firstName?: string | null,
        lastName?: string | null,
        name?: string | null,
        email?: string | null,
        affiliations?: Array< string | null > | null,
        age?: number | null,
        sex?: string | null,
        diets?: Array< string | null > | null,
        sports?: Array< string | null > | null,
        goals?: Array< string | null > | null,
        targeting?:  {
          __typename: string,
          utmCampaign?: string | null,
          utmSource?: string | null,
          utmMedium?: string | null,
          utmContent?: string | null,
          utmTerm?: string | null,
        } | null,
      },
      products?:  Array< {
        __typename: string,
        id?: string | null,
        slug?: string | null,
        name?: string | null,
        category?: string | null,
        features?: Array< string | null > | null,
        pricing?:  Array< {
          __typename: string,
          id?: string | null,
          type?: string | null,
          currency?: Currency | null,
          billingScheme?: string | null,
          unitAmount?: number | null,
          recurring?:  {
            __typename: string,
            aggregateUsage?: string | null,
            interval?: string | null,
            intervalCount?: number | null,
            usageType?: string | null,
          } | null,
        } | null > | null,
        shortDescription?: string | null,
        longDescription?: string | null,
        serving?:  {
          __typename: string,
          unit?: string | null,
          quantity?: number | null,
        } | null,
        servingCount?: number | null,
        products?:  Array< {
          __typename: string,
          id?: string | null,
          slug?: string | null,
          name?: string | null,
          category?: string | null,
          features?: Array< string | null > | null,
          pricing?:  Array< {
            __typename: string,
            id?: string | null,
            type?: string | null,
            currency?: Currency | null,
            billingScheme?: string | null,
            unitAmount?: number | null,
          } | null > | null,
          shortDescription?: string | null,
          longDescription?: string | null,
          serving?:  {
            __typename: string,
            unit?: string | null,
            quantity?: number | null,
          } | null,
          servingCount?: number | null,
          products?:  Array< {
            __typename: string,
            id?: string | null,
            slug?: string | null,
            name?: string | null,
            category?: string | null,
            features?: Array< string | null > | null,
            shortDescription?: string | null,
            longDescription?: string | null,
            servingCount?: number | null,
          } | null > | null,
          ingredients?:  Array< {
            __typename: string,
            id: string,
            slug: string,
            name: string,
            category?: string | null,
            shortDescription?: string | null,
            longDescription?: string | null,
          } | null > | null,
          assessments?:  Array< {
            __typename: string,
            type: string,
            name: string,
            slug?: string | null,
          } | null > | null,
          assets?:  Array< {
            __typename: string,
            createdAt?: string | null,
            contentType?: string | null,
            title?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            assetKey?: string | null,
            assetUrl?: string | null,
            thumbnailKey?: string | null,
            thumbnailUrl?: string | null,
            cmsKey?: string | null,
            cmsId?: string | null,
          } | null > | null,
        } | null > | null,
        ingredients?:  Array< {
          __typename: string,
          id: string,
          slug: string,
          name: string,
          category?: string | null,
          shortDescription?: string | null,
          longDescription?: string | null,
          dose?:  {
            __typename: string,
            quantity: number,
            unit: string,
          } | null,
          assessments?:  Array< {
            __typename: string,
            type: string,
            name: string,
            slug?: string | null,
          } | null > | null,
          assets?:  Array< {
            __typename: string,
            createdAt?: string | null,
            contentType?: string | null,
            title?: string | null,
            description?: string | null,
            tags?: Array< string | null > | null,
            assetKey?: string | null,
            assetUrl?: string | null,
            thumbnailKey?: string | null,
            thumbnailUrl?: string | null,
            cmsKey?: string | null,
            cmsId?: string | null,
          } | null > | null,
        } | null > | null,
        assessments?:  Array< {
          __typename: string,
          type: string,
          name: string,
          slug?: string | null,
        } | null > | null,
        assets?:  Array< {
          __typename: string,
          createdAt?: string | null,
          contentType?: string | null,
          title?: string | null,
          description?: string | null,
          tags?: Array< string | null > | null,
          assetKey?: string | null,
          assetUrl?: string | null,
          thumbnailKey?: string | null,
          thumbnailUrl?: string | null,
          cmsKey?: string | null,
          cmsId?: string | null,
        } | null > | null,
      } | null > | null,
      error?: boolean | null,
      errorMessages?: Array< string | null > | null,
    }
  ) | null,
};
