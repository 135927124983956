/***********************************************************************
 * ACCORDIAN ANIMATION UTILITY
 * ---------------------------------------------------------------------
 * This utility is used to animate an accordian component.
 * The idea is to simplify creating accordian components by abstracting
 * the animation logic into a reusable hook.
 *
 * This allows you to create a component that can be opened and closed
 * with a single click. To create the component you need to:
 * - import the hook
 * - import the accordian indicator component
 * - create a state variable to track if the component is open or closed
 * - create a state variable to track if the component is hovered or not
 * - create a ref to the hook
 * - apply css classes to the elements you want to animate
 */

import React, { useEffect, useRef } from 'react';
import { motion, useAnimate } from 'framer-motion';
import styled from 'styled-components';

interface IUseAccordian {
  isOpen: boolean;
  isHover: boolean;
  options: {
    actionHoverColor: string;
  };
}

const useAccordian = ({
  isOpen,
  // isHover,
  options,
}: IUseAccordian) => {
  const [scope, animate] = useAnimate();
  const closeDuration = 0.1;
  const openDuration = 0.5;

  // This initial render is used to prevent the animation from resetting
  const initialRender = useRef(true);

  /******************************************************************
   * VALIDATE CSS ELEMENT EXISTENCE
   * ----------------------------------------------------------------
   * To make this hook easy to use we need to validate that the css
   * elements exist. If they don't exist, we need to skip the animation
   * logic. This is done by checking if the css selector exists in the
   * document. If it doesn't exist, we return an empty object.
   */
  // Ref Value to indicate all elements are present
  const elementsPresent = useRef(false);
  // Verify Element Function
  const verifyElement = (selector: string): boolean => {
    const exists = document.querySelector(selector) !== null;
    // Console log missing selectors for convineince during development
    if (!exists) {
      // console.log(`Accordian is Missing CSS Selector: ${selector}`);
    }
    return exists;
  };
  // Elements to Verify
  const selectors = [
    '.accordian-action-horizontal line',
    '.accordian-action-vertical line',
    '.accordian-action-container',
    '.accordian-content-container',
  ];

  useEffect(() => {
    /******************************************************************
     * INITIAL RENDER LOGIC
     * ----------------------------------------------------------------
     * On the initial render it's important to do 2 things
     * 1. Verify all the css elements required for the animation exist
     * 2. Exit the hook on the initial render to prevent the animation
     * from running on the initial render in the UI. If you don't prevent
     * animation on the initial render, then the UI will flash as the
     * animation sets itself for the first time.
     ******************************************************************/
    if (initialRender.current) {
      // console.log('VERIFY ELEMENTS ON INITIAL RENDER ONLY')
      elementsPresent.current = selectors.every(verifyElement);
      initialRender.current = false;
      return;
    }

    /******************************************************************
     * ANIMATION LOGIC
     * ----------------------------------------------------------------
     * This is the main animation logic. This is where you define the
     * animations that will run when the component is opened or closed.
     *
     * The animation logic is wrapped in a conditional that checks if
     * the css elements exist. If they don't exist, then the animation
     * logic is skipped.
     ******************************************************************/
    if (!elementsPresent.current) return;

    /******************************************************************
     * ACTION BUTTON ANIMATION
     * ----------------------------------------------------------------
     * Animates the horizontal and vertial lines of the button
     ******************************************************************/
    // Animate Line Color
    animate('.accordian-action-horizontal line', {
      stroke: isOpen ? '#ABABAB' : '#1A1919',
    });
    animate('.accordian-action-vertical line', {
      stroke: isOpen ? '#ABABAB' : '#1A1919',
    });

    // Animate Line Rotation
    animate('.accordian-action-horizontal', { rotate: isOpen ? -180 : 0 }, { duration: 0.5 });
    animate('.accordian-action-vertical', { rotate: isOpen ? -270 : 0 }, { duration: 0.5 });

    // animate(
    //   '.accordian-action-container',
    //   {
    //     backgroundColor: isHover
    //       ? options?.actionHoverColor ?? 'rgba(239, 239, 239, 1)'
    //       : 'rgba(239, 239, 239, 0)',
    //   },
    //   {
    //     duration: 0.2,
    //   }
    // );

    /******************************************************************
     * ACCORDIAN CONTENT CONTAINER
     * ----------------------------------------------------------------
     * The accoridan content container is the element that contains the
     * content that will be revealed when the component is opened. It
     * will grow to the height of the content when opened.
     ******************************************************************/

    

    animate(".accordian-content-container",
    {
      height: isOpen ? 'auto' : 0,
    },
    {
      ease: 'easeInOut',
    },
  );

  }, [isOpen]);

  return scope;
};

/***********************************************************************
 * ACCORDIAN COMPONENTS
 * ---------------------------------------------------------------------
 * The accordian commponents are build into the hook so that they can
 * be imported and used. This greatly simplifies implementing the hook
 * and the required components. The components are:
 * - Accordian Content Container
 * - Accordian Indicator
 * - Accordian Content Shade
 **********************************************************************/

/***********************************************************************
 * ACCORDIAN ACTION CONTAINER
 * ---------------------------------------------------------------------
 * The action containe is the container that is clickable and will react
 * to a hover state to indicate that the component is clickable.
 * It must be passed the open state and functions to toggle the open
 * state and hover state.
 **********************************************************************/

interface iAccordianActionContainer {
  open: boolean;
  toggleOpen: Function;
  toggleHover: Function;
  children: any;
}

export const AccordianActionContainer = ({
  open,
  toggleOpen,
  // toggleHover,
  children,
}: iAccordianActionContainer) => {
  return (
    <ActionContainer
      className="accordian-action-container"
      onClick={() => toggleOpen(!open)}
      // whileHover={{ backgroundColor: '#efefef' }}      
      whileTap={{ scale: 0.95 }}
    >
      {children}
    </ActionContainer>
  );
};

/***********************************************************************
 * ACCORDIAN INDICATOR
 * ---------------------------------------------------------------------
 * The accordian indicator is the component that is used to indicate
 * that the component is clickable and whether or not it's opened
 * or closed.
 **********************************************************************/

export const AccordianIndicator = () => {
  return (
    <IndicatorWrapper>
      <IndicatorLine>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          className="accordian-action-horizontal"
        >
          <line y1="5.76093" x2="12" y2="5.76093" stroke="black" />
        </svg>
      </IndicatorLine>
      <IndicatorLine>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          className="accordian-action-vertical"
        >
          <line x1="6" y1="0" x2="6" y2="12" stroke="black" />
        </svg>
      </IndicatorLine>
    </IndicatorWrapper>
  );
};

/***********************************************************************
 * ACCORDIAN CONTENT CONTAINER
 * ---------------------------------------------------------------------
 * The accordian content is the element that contains the content that
 * will be revealed when the component is opened. It will grow to the
 * height of the content when opened.
 * ********************************************************************/
export const AccordianContentContainer = ({ children }: any) => {
  return <ContentContainer className="accordian-content-container">{children}</ContentContainer>;
};

/***********************************************************************
 * STYLED COMPONENTS
 * ---------------------------------------------------------------------
 * These styled components are used to create the accordian components.
 **********************************************************************/

const ActionContainer = styled(motion.div)`
  position: relative;
  cursor: pointer;
`;

const IndicatorWrapper = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IndicatorLine = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;  
`;


export default useAccordian;
