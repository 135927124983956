/********************************************************************************
 * ACTION: CANCEL SUBSCRIPTION
 * ----------------------------------------------------------------------------
 * This action executes a mutation to cancel the user's subscription to a product.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { cancelSubscription as cancelSubscriptionQuery } from '../data/graphql/mutations';
import { CancelSubscriptionInput, CancelSubscriptionResult } from '../types/API';
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/******************************************************************************************
 * * IMPORT ACCOUNT STORE
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { useAccountStore } from '../store';

/******************************************************************************************
 * * CANCEL SUBSCRIPTION FUNCTION
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/

const cancelSubscription = async (input: CancelSubscriptionInput) => {    


  // * BUILD CANCEL SUBSCRIPTION MUTATION
  const buildMutation = (): GraphQLOptions => {  
    const mutation = cancelSubscriptionQuery;
    const authMode = 'userPool';
    const variables: { input: CancelSubscriptionInput } = {
      input: {
        subscriptionId: input.subscriptionId,
        reason: input.reason ?? 'No Reason Specified',
      },
    };
    return {
      query: mutation,
      authMode,
      variables,
    };
  };

  // * CALL THE MUTATION
    try {
        const mutation = buildMutation();
        const result = (await client.graphql(mutation)) as GraphQLResult<CancelSubscriptionResult>;

        if (result.data) {            
            return result;
        } else {
            throw new Error(result.errors?.[0].message);            
        }

    } catch (error) {
        console.error('Error canceling subscription: ', error);
        return { errors: [error] };
    }
};

export default cancelSubscription;
