import styled from 'styled-components';

export const ArticleBody = styled.div`
  /* Styles for all anchor child elements within ArticleBody */
  & a {
    color: ${({ theme }) => theme.colors.eloMidGreen} !important;
    text-decoration: underline;    
  }
  & a p {
    color: ${({ theme }) => theme.colors.eloMidGreen} !important;
  }
`;

export default ArticleBody;
