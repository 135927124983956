import { INVOICE_FRAGMENT } from './fragments';

export const me = /* GraphQL */ `
  ${INVOICE_FRAGMENT}
  query me {
    me {
      id
      email
      eloId
      firstName
      lastName
      email
      deletedAt
      deleteRequestedAt
      deleteScheduledAfter
      deleteReason
      deleteLog
      preferences {
        pregnancyStage
        gummyFlavor
        __typename
      }
      paymentMethod {
        type
        title
        last4
        partnerId
        partnerPaymentMethodId
        expirationYear
        expirationMonth
        __typename
      }
      shippingAddress {
        name
        company
        street
        street2
        city
        state
        zip
        country
        __typename
      }
      subscriptions {
        items {
          id
          title
          status
          userId
          eloId
          periodStartAt
          periodEndAt
          pauseRequestedAt
          pausedAt
          pausedUntil
          committedUntil
          cancellationRequestedAt
          cancellationReason
          endAt
          purchasableSubscriptionId
          createdAt
          updatedAt
          purchasedSubscription {
            slug
            phases {
              slug
            }
          }
          invoices {
            items {
              id
              status
              subscriptionId
              title
              total
              paidAt
              createdAt
              updatedAt
            }
          }
          upcomingInvoices {
            title
            status
            reason
            dueAt
            periodStartAt
            periodEndAt
            discount
            tax
            shipping
            subtotal
            total
          }
        }
      }
      invoices {
        ...invoicesFragment
      }
    }
  }
`;

export default me;
