import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useAccountStore } from '../../../../../store';
import { productImages } from '../../../../../elo.config';

const Begin = () => {

  const firstName = useAccountStore(state => state.userAccount?.firstName);
  const userConfiguration = useAccountStore(state => state.userConfiguration);
  const updateNewUserData = useAccountStore(state => state.updateNewUserData);

  /*****************************************************************************
   * ADD THE RELEVANT PRODUCT AND PRICE ID FOR THE PRODUCT BEING SWITCHED TO
   * -------------------------------------------------------------------------
   */
  // * FIND AND SET FORM PRODUCT ID
  const formProduct = userConfiguration?.purchasableSubscriptions.find(
    (product) => product.slug === 'form'  
  );
  updateNewUserData((state) => {
    state.productId = formProduct?.id ?? null
  })

  // * FIND AND SET FORM PRICE ID


  updateNewUserData((state) => {
    state.productId = 'form';
    state.priceId = 'price_1JG3ZvJ3b8tj3z3z3z3z3z3z';
  })

  return (
    <Stack
      width={'100%'}
      display={'flex'}
      alignContent={'center'}
      alignItems={'center'}
      justifyContent={'center'}
      // border={'1px solid'}
    >
      <Box my={'.47rem'}>
        {/* <Typography variant="h2">Congrats, {firstName}!</Typography> */}
        <Typography variant="h2">Switch to Form</Typography>
      </Box>
      <Box my={'.47rem'}>
        <Typography variant="caption">
          {/* Amazing news! Based on your update, we recommend you switch from Create to Form, a doctor formulated supplement supporting early pregnancy and healthy fetal development. */}
          Form is formulated by leading fertility doctors to support your body during early pregnancy.
          </Typography>
      </Box>
      <Box my={'.47rem'}>
        <img
          src={productImages.form}
          alt={'Form'}
          style={{
            maxWidth: '100%',
            maxHeight: '240px',
            objectFit: 'contain',
            width: 'auto',
            height: 'auto',
          }}
        />
      </Box>
    </Stack>
  );
};

export default Begin;
