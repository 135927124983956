/********************************************************************************
 * * ELO LIBRARY CONFIGURATION
 * ------------------------------------------------------------------------------
 * This file contains the configurationS for the ELO library.
 * TODO: Create overrides that can be passed in as an argument to the ELO library.
 * *****************************************************************************/

/********************************************************************************
 * * LOGIN REDIRECT
 * ------------------------------------------------------------------------------
 * When a user logs in, they are redirected to a predefined page. This configuration
 * allows for the login redirect to be defined.
 * *****************************************************************************/
export const loginRedirect = '/account';

/********************************************************************************
 * * GUMMY FLAVOR CUSTOM FORMAT
 * ------------------------------------------------------------------------------
 * Gummy flavors are formatted in a specific way. This configuration allows for
 * custom formats to be defined for specific GummyFlavor ENUM values.
 * 
 * Here is an example of a custom format:
 * { value: GummyFlavor.blackcurrant, output: 'Black Currant' },  
 * *****************************************************************************/
import { GummyFlavorCustomFormat } from "./types/config.types";
import { GummyFlavor, PregnancyStage } from "./types/API";

export const gummyFlavorLabel: GummyFlavorCustomFormat[] = [
    // { value: GummyFlavor.blackcurrant, output: 'Black Currant' },        
  ];



/********************************************************************************
 * * LABEL STYLE
 * ------------------------------------------------------------------------------
 * This configuration allows for the label style to be defined. The label style
 * is used to determine how the label for a specific ENUM value should be
 * formatted.
 * *****************************************************************************/
import { LabelFormat } from "./types/config.types";
export const labelFormat: LabelFormat = 'SentenceCase';

/********************************************************************************
 * * PREGNANCY STAGE
 * ------------------------------------------------------------------------------
 * This is a custom configuration for the pregnancy stage.
 * Map the pregnancy stage to a label that should be displayed.
 * *****************************************************************************/
export const pregnancyStage = [
  { stage: PregnancyStage.preconception, label: 'Preconception' },
  { stage: PregnancyStage.pregnant, label: 'Pregnant' },
  { stage: PregnancyStage.pregnantTrimester1, label: 'First Trimester'},
  { stage: PregnancyStage.pregnantTrimester2, label: 'Second Trimester'},
  { stage: PregnancyStage.pregnantTrimester3, label: 'Third Trimester'},
  { stage: PregnancyStage.postpartum, label: 'Postpartum'},
  { stage: PregnancyStage.lactating, label: 'Lactating'},
  { stage: PregnancyStage.postpartum, label: 'Postpartum'},
  { stage: PregnancyStage.lostPregnancy, label: 'Lost Pregnancy'},
  { stage: PregnancyStage.maleReproductiveHealth, label: 'Male Reproductive Health'}  
]

/********************************************************************************
 * * AVAILABLE FLAVORS
 * ------------------------------------------------------------------------------
 * This configuration allows for the available flavors to be defined.
 * *****************************************************************************/
export const availableFlavors = [
  { value: GummyFlavor.apple, label: 'Apple' },
  { value: GummyFlavor.peach, label: 'Peach' },
  { value: GummyFlavor.watermelon, label: 'Watermelon' },  
  { value: GummyFlavor.mixedBerry, label: 'Mixed Berry' },
]

/********************************************************************************
 * * PRODUCT IMAGES
 * ------------------------------------------------------------------------------
 * This configuration allows for the product images to be defined. The product
 * images are used to determine the image that should be displayed for a specific
 * product id. The format for this config is a key value pair where the key is
 * the product id and the value is the image url.
 * 
 * key: purchasableSubscriptionId
 * value: imageUrl
 * 
 * The key can be found in then nutrition app. This is the subscription id that
 * is assigned to the product there. It serves as the unique identifier for the
 * product.
 * 
 * The value is the url of the image that should be displayed for the product.
 * 
 * These can be set by the CMS so that you don't have to manually configure this
 * and then passed into the ELO library as an argument.
 * 
 * TODO: Create overrides that can be passed in as an argument to the ELO library.
 * *****************************************************************************/

export const productImages: Record<string, string> = {
  'create':'https://images.ctfassets.net/8acu1zqcl2uz/4dPVMrDNSGl1dHBssbpVK6/76a2a21c78f509b1b41b27b6f589f655/420-Create.webp',
  'form':'https://images.ctfassets.net/8acu1zqcl2uz/2fQL1xWOs7e2DmBhEXy03l/322c49effa87ad9c248d1ce9add99eea/420-Form.webp',
  'flourish':'https://images.ctfassets.net/8acu1zqcl2uz/6YfI3BWjtKHl1hwfltEz9T/3f254857778cf5f7936ec9e4313b1b3a/420-Flourish.webp',
  'nurture':'https://images.ctfassets.net/8acu1zqcl2uz/4NWWxHfDUbDmXzIs3upCDb/308597e99e20085e20883dff87e8b584/420-Nurture.webp'
}