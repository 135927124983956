/**
 * GET FORMULA DATA FROM THE API
 *
 * This function is called it may be requesting a preliminary plan that
 * - Has not been initialized
 * - Has been initialized but the plan JSON file has not been added to S3
 * - Has been initialized but the formula is still being built
 * - Has been initialized and the formula is ready to be retrieved
 * - Already exists and is ready to be retrieved
 *
 * To handle these cases the function uses a retry loop
 * This function retries the request until
 * - The formula data is loaded
 * - The max retries is reached
 * - The max fails is reached
 *
 * This function must handle
 * - The changing response status
 * - The changing response data
 * - The case where the response is null
 * - The case where the response id is invalid
 */

import { getPlan } from './getPlan';
import { PreliminaryPlan } from 'src/API';

const getData = async (
  responseId: string,
  formulaLoaded: boolean,
  setFormula: (value: PreliminaryPlan) => void,
  updateStoreFormula: (value: PreliminaryPlan) => void,
  setFormulaLoaded: (value: boolean) => void,
  setRemainingLoadTime: (value: number) => void,
  setNoResult: (value: boolean) => void,
  setFailed: (value: boolean) => void,
  minLoadingTime: number = 30000,
) => {

  /**
   * RETRY PARAMS
   * Set the retry params for the request
   * There are two retry counts
   * - one for the number of times the request is retried
   * - one for the number of times the request fails
   * We need to have a shorter counter for fails because
   * we don't want to retry too many completely failed requests
   */
  // Retry Count
  let retryCount = 1;
  const maxRetries = 30;

  // Fail Count
  let failCount = 0;
  const maxFails = 15;

  // Shared Retry Interval
  const retryInterval = 2000;

  // Exit the function if there is no response id (this should never happen)
  // Trigger the exit by setting the fail count to the max fails
  if (!responseId) failCount += 10;

  /**
   * RETRY LOOP
   */

  while (retryCount <= maxRetries && !formulaLoaded) {

  
    // console.log('====================== RETRY LOOP ======================')
    // console.log('retryCount', retryCount)
    // console.log('maxRetries', maxRetries)
    // console.log(`========================================================`)

    try {
      /**
       * MAKE REQUEST
       */
      // const res = await getPlan(id);
      const res = await getPlan(responseId);
      // console.log('getData res', res?.products);

      /**
       * STATUS PENDING
       * Handle the case when the response status is pending
       * This occurs when
       * - The plan has been initialized
       * - The plan JSON file has been added to S3
       * - The formula is still being built
       *
       * As of 2021-06-09 during this state the api sets the status to 'pending'
       */

      if (res && res.status === 'pending') {
        retryCount++;
        await new Promise(resolve => setTimeout(resolve, retryInterval));
        continue;
      }

      /**
       * STATUS FAILED AFTER PENDING
       * Handle the case when the response status is failed
       * This occurs when the plan is initialized but there is an error
       * This status assumes that the plan json was updated with the status of 'failed'
       * As of 2023-06-09 this occurs when the plan is initialized but the plan build fails
       *
       * NOTE THAT THIS IS A 'NO RESULT' STATE
       * This is not the same as the failed api request state because in order for the api
       * to return a failed status the request must have been successful. This means that
       * the request was successful but the plan build failed.
       *
       */
      if (res && res.status === 'failed') {
        retryCount++;
        setNoResult(true);
        // setFailed(true);
        return; // IMPORTANT: Exit the function immediately, stopping the loop.
      }

      /**
       * RETURN FORMULA DATA
       * If the response status is 'created' then we have the data we need
       * before we return the data we need to set the formulaLoaded state to true
       * and set the remainingLoadTime state to the time remaining until the minimum load time is reached
       * This is done so that the user doesn't see the loading screen flash for a split second
       * and so that there is the appearance of a lot of work going on behind the scenes
       */
      if (res && res.status === 'created') {      
      // if (res && res.createdAt) {   
      // if (res) {   
        
        // console.log('====================== FORMULA DATA RECEIVED ======================')
        
        const elapsedTime = retryCount * retryInterval;
        const timeToWait = minLoadingTime - elapsedTime;

        setFormula(res);
        updateStoreFormula(res);
        setFormulaLoaded(true);
        setRemainingLoadTime(timeToWait);
        return; // IMPORTANT: Exit the function immediately, stopping the loop.
      }
    } catch (error) {
      /**
       * HANDLE ERRORs
       * Errors occur when
       * - There is an actual network or api error
       * - The responseId is invalid
       * - The responseId is valid but the plan is not yet initialized
       *
       * In all cases we want to retry the request
       * We also want to keep track of the number of times the request fails
       * If the fail count is greater than the max fails then we should stop the request
       */      

      retryCount++; // increment the retry count
      failCount++; // increment the fail count

      // Stop the request if the fail count is greater than the max fails
      if (failCount > maxFails) {
        setFailed(true);
        // navigate('/');
        return; // IMPORTANT: Exit the function immediately, stopping the loop.
      } else {        
        await new Promise(resolve => setTimeout(resolve, retryInterval)); // Wait for the retry interval before the next attempt
        continue; // Continue the loop
      }
    }
    
    retryCount++;
    await new Promise(resolve => setTimeout(resolve, retryInterval));
    continue;

  }

  // If the retry count is greater than the max retries then we should stop the request
  if (retryCount >= maxRetries) {
    setNoResult(true);
    return; // IMPORTANT: Exit the function immediately, stopping the loop.
  }
};

export default getData;
