import * as React from 'react';
import JsonLDScript from '../jsonld/JsonLDScript';

type ArticleSchemaProps = {
  article: any;
};

const ArticleSchema = ({ article }: ArticleSchemaProps): JSX.Element => {
  const imageUrl = article?.thumbnail?.file?.url;
  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: article.title,
    image: imageUrl ? ['https:' + imageUrl] : [],
    datePublished: article.createdAt,
    dateModified: article.updatedAt,
    author: {
      '@type': 'Person',
      name: article.author?.name,
    },
    publisher: {
      '@type': 'Organization',
      name: 'Elo',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.elo.health/images/EloLogoLight.svg',
      },
    },
  };
  return <JsonLDScript jsonld={jsonld} />;
};

export default ArticleSchema;
