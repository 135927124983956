import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ImageFile } from '../types';

type Props = React.PropsWithChildren<{
  mobileImage: ImageFile;
  desktopImage: ImageFile;
  backgroundColor?: string;
}>;

const BackgroundContainer = styled.div`
  display: grid;
  background-color: ${(props) => props.backgroundColor};
  max-width: 100%;
`;

const ImageContainer = styled(GatsbyImage)`
  display: none !important;
  grid-area: 1/1;
  ${({theme}) => theme.breakpoints.tablet} {
    display: grid !important;
  }
`;

const MobileImageContainer = styled(GatsbyImage)`
  display: none !important;
  grid-area: 1/1;
  ${({theme}) => theme.breakpoints.mobile} {
    display: grid !important;
  }
`;

const ChildrenContainer = styled.div`
  position: relative;
  display: grid;
  grid-area: 1/1;
`;

export default function BackgroundImage({
  backgroundColor,
  mobileImage,
  desktopImage,
  children,
}: Props) {
  const [hydrated, setHydrated] = React.useState<boolean>(false);

  React.useEffect(() => {
    setHydrated(true);
  }, []);

  const desktopImageData = getImage(desktopImage);
  const mobileImageData = getImage(mobileImage);

  return (
    <BackgroundContainer backgroundColor={backgroundColor}>
      {desktopImageData && (
        <ImageContainer image={desktopImageData} loading="eager" alt="" />
      )}
      {mobileImageData && (
        <MobileImageContainer
          image={mobileImageData}
          loading="eager"
          alt=""
        />
      )}
      
      <ChildrenContainer>
        <div>{children}</div>
      </ChildrenContainer>
    </BackgroundContainer>
  );
}
