import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { isInternalLink, parseLink } from '../../../utils/url';
import { LinkType, CTAButton } from '../types';

import Icon from '../icon/Icon';

type ContentfulLink = LinkType & {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
};

export function ContentfulLink({ url, title, onClick, children }: ContentfulLink) {
  if (url && isInternalLink(url)) {
    return (
      <GatsbyLink to={parseLink(url)} onClick={onClick}>
        {children ?? title}
      </GatsbyLink>
    );
  }

  return <a href={url ?? undefined}>{children ?? title}</a>;
}

export function Link<T>(props: any) {
  const url = props.to ?? props.url;
  const content = props.children ?? props.title;
  if (isInternalLink(url)) {
    return (
      <GatsbyLink {...props} to={url}>
        {content}
      </GatsbyLink>
    );
  }

  return (
    <a {...props} href={url}>
      {content}
    </a>
  );
}

export function CTALink(props: CTAButton) {
  if (isInternalLink(props.link)) {
    <GatsbyLink to={props.link}>
      {props.title} <Icon id="arrow-right-black" />
    </GatsbyLink>;
  }
  return (
    <a href={props.link}>
      {props.title} <Icon id="arrow-right-black" />
    </a>
  );
}
