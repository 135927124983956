/***************************************************************
 * ICONS
 * -------------------------------------------------------------
 * This file contains all of the icons used in the app. The icons
 * found in the TSX section below represent the new way to add
 * icons. The icons and images below that represent the old way.
 *
 * Process for adding new Icons:
 * 1. Create a new TSX file in the src/images folder
 * 2. Import the icon into this file in the TSX Icons section
 * 3. Add the icon the the type IconId in src/types/SVGIcon.ts
 * 4. Add the icon to the icons object below
 * 5. Use the icon in the app
 ***************************************************************/

import * as React from 'react';
import { SVGIcon, IconId } from 'src/types/SVGIcon';
import {
  CheckCircleOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  MinusOutlined,
  ExportOutlined,
} from '@ant-design/icons';

/***************************************************************
 * TSX ICONS
 * -------------------------------------------------------------
 * That is to say that these icons are created using a TSX file
 * that contains the svg path. The path is then used in the
 * svg element below.
 *
 * These icons accept the following props:
 * - id
 * - stroke
 * - strokeWidth
 * - fill
 * - width
 * - height
 * - viewBox
 ***************************************************************/
import Avatar from 'src/images/Avatar';
import AvatarFemale from 'src/images/AvatarFemale';
import Trophy from 'src/images/Trophy';
import Running from 'src/images/Running';
import Diet from 'src/images/Diet';
import ShippingTruck from 'src/images/Shipping-Truck';
import Flavor from 'src/images/Flavor';
import Silverware from 'src/images/Silverware';
import PlantBased from 'src/images/PlantBased';
import GlutenFree from 'src/images/GlutenFree';
import LuminaryLogoWhite from 'src/images/LuminaryLogoWhite';
import Circle from 'src/images/Circle';
import Shield from 'src/images/Shield';

/***************************************************************
 * THE OLD WAYS
 * -------------------------------------------------------------
 * These icons remain here because they are still used in the app.
 * They are hard coded in various places and I have not taken
 * the time to replace them with the new way.
 ***************************************************************/

import EnergyIcon from '../../../images/energy.icon.svg';
import FocusIcon from '../../../images/focus.icon.svg';
import ImmunityIcon from '../../../images/immunity.icon.svg';
import SleepIcon from '../../../images/sleep.icon.svg';
import PerformanceIcon from '../../../images/performance.icon.svg';
import AgingIcon from '../../../images/aging.icon.svg';
import MenuClose from '../../../images/MenuClose.svg';
import SocialFacebook from '../../../images/fb.svg';
import SocialLinkedIn from '../../../images/linkedin.svg';
import SocialInstagram from '../../../images/ig.svg';
import SocialTwitter from '../../../images/twitter.svg';

import PaymentApple from '../../../images/payment.apple.svg';
import PaymentAmex from '../../../images/payment.amex.svg';
import PaymentMastercard from '../../../images/payment.mastercard.svg';
import PaymentVisa from '../../../images/payment.visa.svg';
import PaymentPaypal from '../../../images/payment.paypal.svg';
import Checkmark from '../../../images/checkmark.svg';
import CheckmarkWhiteBg from '../../../images/checkmarkwhitebg.svg';
import CheckmarkGreen from '../../../images/greencheck.svg';
import Star from '../../../images/Star.svg';
import HalfStar from '../../../images/halfstar.svg';
import Cart from '../../../images/cart.svg';
import Trash from '../../../images/trash.svg';
import ArrowLeft from '../../../images/arrowleft.svg';
import ArrowRight from '../../../images/arrowright.svg';
import ArrowLeftBlack from '../../../images//arrow-left-filled.svg';
import ArrowRightBlack from '../../../images/arrow-right-filled.svg';
import LineArrowRight from '../../../images/line-arrow-right.svg';
import LineArrowLeft from '../../../images/line-arrow-left.svg';
import CheckoutSuccess from '../../../images/checkoutsuccess.svg';
import AppleStore from '../../../images/applestore.svg';
import ChevronDown from '../../../images/chevrondown.svg';
import ChevronRight from '../../../images/chevronright.svg';
import EloLoaderAnimated from '../../../images/Elo-Loader-Animated.svg';
import WarningGlyph from '../../../images/exclamation-triangle-filled.svg';

/***************************************************************
 * ICONS OBJECT
 * -------------------------------------------------------------
 * This object contains all of the icons used in the app.
 * When an Icon is used, it is referenced by it's id. That id
 * is used to find the icon in this object.
 *
 * Here is an example usage:
 * <Icon id="circle" fill='black' width=... />
 *
 * When you add a new icon, you must add it to this object.
 *
 * Don't forget to add it to the IconId type in src/types/SVGIcon.ts
 * This is an important step because it will allow the compiler to
 * catch errors when you try to use an icon that does not exist.
 * It will also provide intellisense when you are using the
 * Icon component.
 ***************************************************************/
const icons: Record<IconId, any> = {
  check: CheckCircleOutlined,
  left: LeftOutlined,
  right: RightOutlined,
  energy: EnergyIcon,
  focus: FocusIcon,
  immunity: ImmunityIcon,
  sleep: SleepIcon,
  performance: PerformanceIcon,
  aging: AgingIcon,
  close: MenuClose,
  plus: PlusOutlined,
  minus: MinusOutlined,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-left-black': ArrowLeftBlack,
  'arrow-right-black': ArrowRightBlack,
  'line-arrow-right': LineArrowRight,
  'line-arrow-left': LineArrowLeft,
  export: ExportOutlined,
  'social-facebook': SocialFacebook,
  'social-linkedin': SocialLinkedIn,
  'social-instagram': SocialInstagram,
  'social-twitter': SocialTwitter,
  'payment-apple': PaymentApple,
  'payment-amex': PaymentAmex,
  'payment-mastercard': PaymentMastercard,
  'payment-visa': PaymentVisa,
  'payment-paypal': PaymentPaypal,
  checkmark: Checkmark,
  checkmarkwhitebg: CheckmarkWhiteBg,
  'checkmark-green': CheckmarkGreen,
  star: Star,
  halfstar: HalfStar,
  cart: Cart,
  trash: Trash,
  'checkout-success': CheckoutSuccess,
  applestore: AppleStore,
  chevrondown: ChevronDown,
  chevronright: ChevronRight,
  'elo-loader': EloLoaderAnimated,
  'warning-glyph': WarningGlyph,
  avatar: Avatar,
  trophy: Trophy,
  running: Running,
  diet: Diet,
  shippingtruck: ShippingTruck,
  flavor: Flavor,
  silverware: Silverware,
  'avatar-female': AvatarFemale,
  'plant-based': PlantBased,
  'gluten-free': GlutenFree,
  'luminary-logo-white': LuminaryLogoWhite,
  circle: Circle,
  shield: Shield,
};


/***************************************************************
 * ICON COMPONENT
 * -------------------------------------------------------------
 * FUTURE SELF and OTHER CODERS
 * Most of the icons here only use id,style and className.
 ***************************************************************/

export default function Icon({
  id,
  style,
  className,
  width,
  height,
  viewBox,
  stroke,
  strokeWidth,
  fill,
}: SVGIcon): JSX.Element {
  const IconComponent = icons[id];
  return (
    <IconComponent
      style={style}
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      stroke={stroke}
      strokeWidth={strokeWidth}
      fill={fill}
    />
  );
}
