import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Icon from '../icon/Icon';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
`;

interface Props {
  label: string;
  to: string;
}

export default function CtaLink({ label, to }: Props): JSX.Element {
  return (
    <StyledLink to={to}>
      {label} <Icon id="arrow-right-black" />
    </StyledLink>
  );
}
