/********************************************************************************
 * * UDATE USER NAME
 * -----------------------------------------------------------------------------
 * This function is used to update the user's first and last name only. This is
 * isolated to avoid creating any side effects when updating the user.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/**********************************************************************
 * * IMPORT GRAPHQL MUTATION and Types
 * ********************************************************************/
import { UserData } from '../types';
import { updateUser } from './graphql/mutations';

/******************************************************************************************
 * * IMPORT ACCOUNT STORE
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/
import { useAccountStore } from '../store';


/**********************************************************************
 * * UPDATE USER NAME
 * -----------------------------------------------------------------------------
 * * NOTE
 * It is not necessary to pass in the user's first and last name as arguments
 * because the data is already stored in the store.
 * *****************************************************************************/

export const updateUserName = async () => {
  /**********************************************************************
   * * SUBSCRIBE TO STORE DATA AND GET UPDATE FUNCTION
   * ********************************************************************/
  const updateAccountState = useAccountStore.getState().updateAccountState;
  const userAccount = useAccountStore.getState().userAccount;
  const firstName = userAccount?.firstName;
  const lastName = userAccount?.lastName;

  try {
    if (!firstName || !lastName) {
      throw new Error('First name and last name are required');
    }

    /**********************************************************************
     * * BUILD UPDATE USER MUTATION
     * ********************************************************************/
    const buildMutation = (): GraphQLOptions => {
      const mutation = updateUser;
      const authMode = 'userPool';
      const variables = {
        input: {
          firstName: firstName,
          lastName: lastName,
        },
      };
      return {
        query: mutation,
        authMode,
        variables,
      };
    };

    /**********************************************************************
     * * UPDATE USER
     * ********************************************************************/
    interface UpdateUserResponse {
        updateUser: UserData;
    }

    try {
      const query = buildMutation();
      const res = (await client.graphql(query)) as GraphQLResult<UpdateUserResponse>;
      const data = res.data;
      if (!data) {
        throw new Error('Failed to update user name');
      }

      

      /**********************************************************************
       * * UPDATE ACCOUNT STATE
       * ---------------------------------------------------------------------
       * Updating the account state will trigger a re-render of all components
       * subscribed to the store.
       * ********************************************************************/
      
      updateAccountState(state => {
        if (!state.userAccount) {
          throw new Error('User Account did not exist in state.');
        }
        state.userAccount.firstName = data.updateUser.firstName;
        state.userAccount.lastName = data.updateUser.lastName;
      });
      
      /**********************************************************************
       * * RETURN DATA
       * ---------------------------------------------------------------------
       * * NOTE
       * It's not strictly necessary to return the data here, but it's useful
       * for testing purposes. The reason you don't need to return the data is
       * because the data is updated in the store. All components should be
       * subscribed to the store and will update automatically.
       * ********************************************************************/
      return data;

    } catch (error) {
      throw new Error('Failed to update user name');
    }
  } catch (error) {
    /**********************************************************************
     * * HANDLE ERROR
     * ********************************************************************/
    if (error instanceof Error) {
      return {
        error: error.message,
      };
    }
  }
};

export default updateUserName;
