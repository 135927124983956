import React from 'react';
import styled from 'styled-components';

const NoImage = () => {
  return (
    <Root />    
  );
};

const Root = styled.div`
  content: "",
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #EFEFEF;
  /* background: ${({ theme }) => theme.colors.eloMidGrey}; */
  color: ${({ theme }) => theme.colors.eloDarkGreen};
  padding: 2rem;
`;

export default NoImage;
