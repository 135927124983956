import React from 'react';
import { Box } from 'ui';
import Layer from './Layer';
import { useAssessmentStore } from 'src/features/assessment-summary/store';

const ProductIngredients = () => {

  
  const storeProduct = useAssessmentStore(state => state.storeProduct);
  const storeFormula = useAssessmentStore(state => state.storeFormula);


  const ingredients = storeProduct?.ingredients?.ingredients ?? null;

  // BAIL if there is no formula or ingredients
  if (!storeFormula || !ingredients) return null;
  
  return (
    <>
      <Box>
        {ingredients.map((ingredient, index) => {
          return (
            <Box key={index}>              
              <Layer
               cmsIngredient={ingredient}
                dose={null}
                ingredientIndex={index}
                layerIndex={0}
                assessments={[]}
               />
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default ProductIngredients;
