/********************************************************************************
 * DATA FUNCTION: getUserSubscriptions
 * ----------------------------------------------------------------------------
 * This function is used to get the user subscriptions from the API.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/**********************************************************************
 * * IMPORT GRAPHQL QUERY AND TYPES
 * --------------------------------------------------------------------
 * TODO: Create custom types. Don't use the API.ts file
 * ********************************************************************/
// * GraphQL Query
import { listUserSubscriptions } from './graphql/listUserSubscriptions';
import { ListUserSubscriptionsQuery} from '../types/API'

/**********************************************************************
 * * BUILD USER SUBSCRIPTIONS QUERY
 * --------------------------------------------------------------------
 * ********************************************************************/

const buildQuery = (): GraphQLOptions => {
  const query = listUserSubscriptions;
  const authMode = 'userPool';
  const variables = { input: {} } as any;

  return {
    query: query,
    authMode: authMode,
    variables: variables,
  };
}

/**********************************************************************
 * * GET USER SUBSCRIPTIONS
 * --------------------------------------------------------------------
 * ********************************************************************/
export const getUserSubscriptions = async () => {
    const query = buildQuery();
    try {
        const result = (await client.graphql(query)) as GraphQLResult<any>;        
        return result.data;
    } catch (error) {
        console.error('Error getting user subscriptions', error);
        return error;
    }
}

export default getUserSubscriptions;
