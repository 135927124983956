/********************************************************************************
 * ACTION: CANCEL SUBSCRIPTION
 * ----------------------------------------------------------------------------
 * This action executes a mutation to cancel the user's subscription to a product.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { deleteUser } from '../data/graphql/mutations';
import { DeleteUserInput, DeleteUserResult } from '../types/API';
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';


/******************************************************************************************
 * * DELETE ACCOUNT FUNCTION
 * ---------------------------------------------------------------------------------------
 * ***************************************************************************************/

const deleteUserAccount = async (input: DeleteUserInput) => {            

  // * BUILD CANCEL SUBSCRIPTION MUTATION
  const buildMutation = (): GraphQLOptions => {  
    const mutation = deleteUser;
    const authMode = 'userPool';
    const variables: { input: DeleteUserInput } = {
      input: {        
        reason: input.reason ?? 'No Reason Specified',
      },
    };
    return {
      query: mutation,
      authMode,
      variables,
    };
  };

  // * CALL THE MUTATION
    try {
        const mutation = buildMutation();
        const result = (await client.graphql(mutation)) as GraphQLResult<DeleteUserResult>;

        if (result.data) {
            
            return result;
        } else {
            throw new Error(result.errors?.[0].message);            
        }

    } catch (error) {
        console.error('Error canceling subscription: ', error);
        return { errors: [error] };
    }
};

export default deleteUserAccount;
