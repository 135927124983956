import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Typography, Box } from 'ui';
import { useEloStore } from 'src/features/store/EloStore.hooks';
import { Member } from 'src/graphql/elomapi';
import { PreliminaryPlanMember } from 'src/API';
import { useAssessmentStore } from 'src/features/assessment-summary/store';
import posthog from 'posthog-js';

interface CheckoutButtonProps {
  id: string;
  // member: PreliminaryPlanMember;
  personalizedPlanId: string;
  setCheckoutButtonVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckoutButton = ({
  id,
  // member,
  personalizedPlanId,
  setCheckoutButtonVisible,
}: CheckoutButtonProps) => {
  // Get Member Data from the central store
  const storeMember = useAssessmentStore(state => state.storeMember);
  if (!storeMember) return null;

  const buttonRef = useRef<HTMLDivElement>(null);

  const {
    cart: { checkoutPersonalizedProtein },
  } = useEloStore();

  /**
   * TODO: Checkout Function is re-using the checkout function for personalized protein
   * This is because that function is generic but badly named. It should be renamed
   * to something more generic.
   */

  const handleSubmit = async () => {
    if (!storeMember) {
      console.error('No member found in CheckoutButton component');
      return;
    }

    posthog.capture('assessment checkout started', {
      personalizedPlanId,
    });

    await checkoutPersonalizedProtein(
      storeMember,
      personalizedPlanId,
      `/cart/assessment-summary/?responseId=${personalizedPlanId}&mlt=0`
    );
    
  };

  useEffect(() => {
    /**
     * FUTURE SELF and OTHERS
     * setCheckoutButtonVisible is a prop passed down from the parent component
     * Setting this value to true will cause the parent component to re-render and pass the value to the TopNav component
     * The TopNav component will then hide or reveal itself based on the value of checkoutButtonVisible
     */
    const observer = new IntersectionObserver(
      ([entry]) => {
        setCheckoutButtonVisible(entry.isIntersecting);
      },
      { rootMargin: '0px' } // Adjust rootMargin as needed
    );

    if (buttonRef.current) {
      observer.observe(buttonRef.current);
    }

    return () => {
      if (buttonRef.current) {
        observer.unobserve(buttonRef.current);
      }
    };
  }, []);

  return (
    <>
      <CheckoutButtonContainer>
        <Box display={'flex'} flexDirection={'column'}>
          <Button
            onClick={handleSubmit}
            id="checkout-gummy-summary"
            sx={{ display: 'flex' }}
            // data action for posthog            
            eventCohort="start-checkoutAssessment started"
          >
            <Typography variant="body1" sx={{ paddingInline: '12px' }}>
              Order today for $69/mo
            </Typography>
          </Button>

          <Box py=".47rem" display={'flex'} flexDirection={'column'}>
            <Typography
              variant={'tiny1'}
              fontColor={['#4B4B4B']}
              gutterBottom
              sx={{ textAlign: 'center' }}
            >
              Monthly subscription (3 month commitment). Free shipping.
            </Typography>
          </Box>
        </Box>
      </CheckoutButtonContainer>
    </>
  );
};

export default CheckoutButton;

const CheckoutButtonContainer = styled.div`
  position: fixed; // Fixes the position relative to the viewport
  left: 0; // Align to the left of the viewport
  right: 0; // Align to the right of the viewport
  bottom: -10px; // Align to the bottom of the viewport
  background: #fff; // Background color (change as needed)
  padding: 15px 10px 10px; // Padding around the content (adjust as needed)
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); // Optional shadow for better visibility
  z-index: 100; // Ensures it stays above other content
`;
