import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

const Running = ({
  stroke = '#333333',
  strokeWidth = '1.25',
  width = '50',
  height = '50',
  viewBox = '0 0 50 50',
}: SVGIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 14.5L6.4146 15.3866C5.92035 15.8808 5.11915 15.8808 4.62491 15.3866L1.35664 12.1173C0.881121 11.6418 0.881121 10.8697 1.35664 10.3942C1.83215 9.91866 2.60422 9.91866 3.07974 10.3942L5.53432 12.8488L11.8222 6.56091L10.1605 5.70352L7.43118 8.38078C7.07012 8.74184 6.48535 8.74184 6.12429 8.38078C5.76323 8.01972 5.76323 7.43495 6.12429 7.07389L9.2677 3.98251C9.51118 3.73902 9.86496 3.64018 10.199 3.72654C13.4922 4.57456 17.0456 8.05093 17.0456 8.05093L18.0247 9.03006L20.3357 6.71907C20.7165 6.33824 21.3335 6.33824 21.7144 6.71907C22.0952 7.0999 22.0952 7.71693 21.7144 8.09776L18.8852 10.9269C18.392 11.4201 17.5918 11.4201 17.0986 10.9269L15.6346 9.46292L12.0157 13.0818L14.0322 15.0984C14.5358 15.602 14.5358 16.4188 14.0322 16.9214L9.33221 21.6214C8.83796 22.1156 8.03677 22.1156 7.54252 21.6214C7.04827 21.1271 7.04827 20.3259 7.54252 19.8317L11.4653 15.9089L8.67772 13.1214C8.67772 13.1214 6.95255 11.4295 8.94514 9.4369"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M16.7045 5.90431C17.9544 5.90431 18.9677 4.89107 18.9677 3.64118C18.9677 2.39129 17.9544 1.37805 16.7045 1.37805C15.4546 1.37805 14.4414 2.39129 14.4414 3.64118C14.4414 4.89107 15.4546 5.90431 16.7045 5.90431Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path d="M1 22.5H22" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="square" />
  </svg>
);

export default Running;
