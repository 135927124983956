/********************************************************************************
 * DATA FUNCTION: getPurchasableSubscriptions
 * ----------------------------------------------------------------------------
 * This function is used to get the purchasable subscriptions from the ELO API.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { purchasableSubscriptions } from './graphql';
import { PurchasableSubscription } from '../types/API'

/**********************************************************************
 * * BUILD PURCHASABLE SUBSCRIPTIONS QUERY
 * --------------------------------------------------------------------
 * ********************************************************************/

const buildQuery = (): GraphQLOptions => {
    const query = purchasableSubscriptions;
    const authMode = 'userPool';
    const variables = { input: {} } as any;
  
    return {
      query,
      authMode: authMode,
      variables,
    };
  };

  /**********************************************************************
 * * GET PURCHASABLE SUBSCRIPTIONS
 * --------------------------------------------------------------------
 * ********************************************************************/

export const getPurchasableSubscriptions = async () => {    
    try {
      const query = buildQuery();
      const result = (await client.graphql(query)) as GraphQLResult<PurchasableSubscription>;      
      return result.data ?? null
    } catch (error) {
      console.error('Error getting products and prices', error);
    }
  };
  
  export default getPurchasableSubscriptions;
  