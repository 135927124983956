import React, { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { Divider, List, Typography } from 'antd';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
import { SectionProps } from 'types';

const { Title } = Typography;

type PageSectionQuestionListingProps = SectionProps & {
  slug: string;
  data: any;
};

const PageSectionQuestionListing = ({ slug, headline, data }: PageSectionQuestionListingProps) => {
  const categories = data.qaCategories;
  const showTitle = categories.length > 1;

  function renderListingItem(item: any, index: number): ReactNode {
    return (
      <List.Item>
        {showTitle && (
          <Title className="title" level={2}>
            <Link to={'/answers/' + kebabCase(item.headline) + '/'}>{item.headline}</Link>
          </Title>
        )}
        <Divider />
        <List
          size="small"
          itemLayout="vertical"
          dataSource={item.answers}
          renderItem={(q: any) => (
            <List.Item>
              <Link to={`/answers/${q.slug}/`}>{q.question}</Link>
            </List.Item>
          )}
        />
      </List.Item>
    );
  }

  return (
    <div className={`page-section-questions ${slug}`}>
      {headline && (
        <Title className="title" level={1}>
          {headline}
        </Title>
      )}
      {categories && categories.length > 0 && (
        <List
          split={isMobile}
          bordered={false}
          size="small"
          itemLayout="vertical"
          dataSource={categories}
          renderItem={renderListingItem}
        />
      )}
    </div>
  );
};

export default PageSectionQuestionListing;
