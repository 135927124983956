import { UserSubscription, SubscriptionStatus, Currency, InvoiceStatus } from '../types/API';

export const formPlaceholder: Partial<UserSubscription> = {
    __typename: "UserSubscription",
    id: "placeholder-id",
    owner: "placeholder-owner",
    userId: "placeholder-userId",
    status: SubscriptionStatus.active,
    title: "Luminary Form",
    cancellationReason: "placeholder-cancellationReason",
    eloId: "placeholder-eloId",
    purchasableSubscriptionId: "form-placeholder",
    invoices: {
      __typename: "ModelInvoiceConnection",
      items: [
        {
          __typename: "Invoice",
          id: "placeholder-invoice-id",
          owner: "placeholder-owner",
          userId: "placeholder-userId",
          status: InvoiceStatus.paid,
          subscriptionId: "placeholder-subscriptionId",
          title: "placeholder-title",
          net: 100,
          currency: Currency.USD,
          discount: 10,
          tax: 15,
          subtotal: 105,
          total: 69,
          amountPaid: 120,
          amountRefunded: 0,
          paymentError: "None",
          createdAt: "placeholder-createdAt-invoice",
          updatedAt: "placeholder-updatedAt-invoice",
          paidAt: "placeholder-paidAt",
          refundedAt: "placeholder-refundedAt",
        },
      ],
      nextToken: "placeholder-nextToken",
    },
    createdAt: "placeholder-createdAt",
    updatedAt: "placeholder-updatedAt",
    startAt: "placeholder-startAt",
    endAt: "placeholder-endAt",
    periodStartAt: "placeholder-periodStartAt",    
    periodEndAt: "placeholder-periodEndAt",
    committedUntil: "placeholder-committedUntil",
    pauseRequestedAt: "placeholder-pauseRequestedAt",
    pausedAt: "placeholder-pausedAt",
    pausedUntil: "placeholder-pausedUntil",
    cancellationRequestedAt: "placeholder-cancellationRequestedAt",
  };
  
  export default formPlaceholder;
  
