import { useState, useEffect } from 'react';

const useViewport = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    // clean up the window resize listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return { isMobile };
};

export default useViewport;
