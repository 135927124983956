import styled from 'styled-components';
const CartBreadcrumbContainer = styled.div(
    ({ theme }) => `
    display: flex;
    align-self: flex-start;
    flex-flow: row wrap;
    margin: 0 0 1rem 0;
    ${theme.breakpoints.tablet} {
      margin: 1rem 0;
    }
    a,
    span {
      color: ${theme.colors.eloMidGrey};
      display: flex;
      align-items: center;
      font-family: 'Everett Medium';
      font-size: 11px;
      line-height: 17.55px;
      ${theme.breakpoints.tablet} {
          font-size: 15px;
          line-height: 20px;
      }
      &.active {
        color: ${theme.colors.eloMidGreen};
        :hover {
          color: ${theme.colors.eloMidGreen};
        }
      }
      :hover {
        color: ${theme.colors.eloMidGrey};
      }
    }
    svg {
      margin: 0 5px;
      transform: rotateZ(270deg);
      width: 10px;
      height: 10px;
      path {
        stroke: ${theme.colors.eloDarkGrey};
      }
    }
  `
  );
  export default CartBreadcrumbContainer;