/****************************************************************************************
 * RESPONSIVE IMAGE COMPONENT
 * --------------------------------------------------------------------------------------
 * This component will be used to render images responsively. It can be used to render
 * any responsive image. It will handle detecting the device size and rendering the
 * appropriate image.
 *
 * It will also handle missing images. If an image is missing, it will render a default.
 * The default image will be the mobile image.
 ****************************************************************************************/

import * as React from 'react';
import { useViewport } from 'src/hooks';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';

export interface IResponsiveImageProps {
  data: {
    __typename: string;
    contentful_id: string;
    id: string;
    mobile: IImageProps;
    desktop: IImageProps;
  };
};

interface IImageProps {
  title?: string | null;
  description?: string | null;
  file: {
    url: string;
    fileName: string;
    contentType: string;
  };
  gatsbyImageData: IGatsbyImageData;
};

const ResponsiveImage = ({ data }: IResponsiveImageProps) => {
  // Descrtucture the data with defaults.
  const { mobile = null, desktop = null } = data;

  // Set isMobile to determine which image to render.
  const { isMobile } = useViewport();

  // Without a mobile image, return an error.
  if (mobile === null) {
    console.error('You must provide a mobile image.');
    return null;
  }

  /**
   * Render the mobile image if:
   * The device is mobile. Or...
   * The desktop image is null. This will be the case if the user is on a desktop
   * and the desktop image is not provided. This implicitly means that the mobile
   * image is the default image.
   */
  if (isMobile || desktop === null) {
    return (
      <>
        <StyledGatsbyImage
          image={mobile.gatsbyImageData}
          className="responsive-mobile-image"
          alt={mobile.title ?? ''}
        />
      </>
    );
  }

  if (desktop) {
    return (
      <>
        <StyledGatsbyImage
          image={desktop.gatsbyImageData}
          className="responsive-mobile-image"
          alt={desktop.title ?? ''}
        />
      </>
    );
  }

  // If we get here, something went wrong. Most likely, the desktop image is null.
  console.error('Something went wrong rendering the responsive image. Possibly no images were provided.');
  return null;
};

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
`;

export default ResponsiveImage;
