import React from 'react';
import EloLogoLight from 'src/images/EloLogoLight.svg';
import styled from 'styled-components';

const Logo = () => {
  return (
    <>
      <TopNavContainer>
        <EloLogoLight />
      </TopNavContainer>
    </>
  );
};

export default Logo;

const TopNavContainer = styled.div`
  background-color: ${props => props.theme.colors.eloGreygeLight};
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: center;
    align-items: flex-end;
  /* border: 1px solid red; */
`;
