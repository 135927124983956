import * as React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';
import {SectionContainer, HeadlineContainer, Icon, Image, Video} from 'ui';


const Headline = styled.div`
  .ourmission-headline {
    p {
      max-width: 1063px;
      ${({ theme }) => theme.breakpoints.desktop} {
        font-size: 58px !important;
        line-height: 63.8px !important;
      }
    }
  }
`;

const GoalsContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  ${({ theme }) => theme.breakpoints.tablet} {
    margin-top: 4rem;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    display: flex;
    flex-flow: wrap row;
    align-items: flex-start;
    justify-content: space-between;
  }
  > img {
    max-width: 65%;
    height: auto;
  }
`;
const Goals = styled.div`
  ${({ theme }) => theme.breakpoints.desktop} {
    width: 35%;
    flex-flow: wrap column;
    display: flex;
  }
`;
const Goal = styled.div`
  width: 100%;
  display: flex;
  flex-flow: wrap column;
  padding: 0;
  overflow: visible;
  img {
    margin-right: 10px;
  }
  padding: 1rem 0;
  ${({ theme }) => theme.breakpoints.tablet} {
    padding: 0;
  }
`;
const GoalSeparator = styled.div`
  display: none;
  ${({ theme }) => theme.breakpoints.desktop} {
    display: block;
    height: 1px;
    width: 90%;
    margin: 2rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.eloMidGrey};
  }
`;
const Personal = styled.div`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  margin-top: 2px;
  margin-bottom: 19px;
  overflow: visible;
  > div:not(.gatsby-image-wrapper) {
    dispaly: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    height: 100%;
  }
  ${({ theme }) => theme.breakpoints.tablet} {
    margin-bottom: 30px;
  }
  .gatsby-image-wrapper {
    margin-right: 10px;
    max-width: 64px;
    ${({ theme }) => theme.breakpoints.tablet} {
      max-width: 91px;
    }
  }
`;
const Name = styled.p`
  font-family: 'Everett Medium';
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;
  ${({ theme }) => theme.breakpoints.tablet} {
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;
  }
`;
const Title = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  max-width: 70%;
  ${({ theme }) => theme.breakpoints.tablet} {
    max-width: 250px;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: auto;
  width: 100%;
  ${({ theme }) => theme.breakpoints.mobile} {
    max-width: 90%;
  }
  ${({ theme }) => theme.breakpoints.tablet} {
    max-width: 490px;
  }
`;

const Tag = styled.span`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50px;
  border: 1px solid ${({ theme }) => theme.colors.eloLightGrey};
  color: ${({ theme }) => theme.colors.eloBlack};
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 16px;
    line-height: 21.6px;
  }
`;
const GoalColor = styled.span`
  display: inline-block;
  margin-left: 7px;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  &.orange,
  &.red {
    background: ${({ theme }) => theme.colors.eloOrange};
  }
  &.green {
    background: ${({ theme }) => theme.colors.primary};
  }
  &.yellow {
    background: ${({ theme }) => theme.colors.eloAmber};
  }
`;
const VideoContainer = styled.div`
  video {
    border-radius: 3px;
  }
  ${({ theme }) => theme.breakpoints.desktop} {
    width: 65%;
  }
`;
const CarouselButton = styled.button<{ right?: boolean }>(
  ({ theme, right }) => `
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: transparent !important;
  border: none !important;
  padding: 20px !important;
  cursor: pointer !important;
  width: auto !important;
  height: auto important;
  position: absolute;
  top: 28px !important;
  right: ${right ? '-20px' : '20px'} !important;
  left: unset !important;
  z-index: 10;
  svg {
    path { stroke-width: 2 }
  }
  &:before {
    content: '' !important;
  }
  ${theme.breakpoints.tablet} {
    top: 15px !important;
    right: ${right ? '-20px' : '70px'} !important;
  }
  ${theme.breakpoints.desktop} {}
  ${theme.breakpoints.wide} {}
`
);
const OurMission = (props: any): JSX.Element => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    nextArrow: (
      <CarouselButton className="slider-arrow" right>
        <Icon id="arrow-right-black" />
      </CarouselButton>
    ),
    prevArrow: (
      <CarouselButton className="slider-arrow">
        <Icon id="arrow-left-black" />
      </CarouselButton>
    ),
  };
  const renderGoals = props.blocks?.map((goal: any, index: number) => {
    const addSeparator = !isMobile && index < props.blocks.length - 1;
    return (
      <Goal key={goal.headline + index} style={{ width: '100%' }}>
        <Personal>
          <Image gatsbyImageData={goal.thumbnail.gatsbyImageData} alt="" />
          <div>
            <Name>{goal.headline}</Name>
            <Title>{goal.subHeading}</Title>
          </div>
        </Personal>
        <Tags>
          {goal.goals.map((g: any, index: number) => (
            <Tag key={g.title + index}>
              {g.title}
              <GoalColor className={g.color} />
            </Tag>
          ))}
        </Tags>
        {addSeparator && <GoalSeparator />}
      </Goal>
    );
  });
  return (
    <SectionContainer id="our mission" usePadding contentBackgroundColor="#fff">
      <Headline>
        <HeadlineContainer headline={props.headline} body={props.body} className="ourmission-headline" />
      </Headline>
      <GoalsContainer>
        {props.blocks && (
          <>
            <Goals>
              {!isMobile && props.blocks && renderGoals}
              {isMobile && <Slider {...settings}>{renderGoals}</Slider>}
            </Goals>

            <VideoContainer>
              <Video videoSrcURL={props.video.file.url} />
            </VideoContainer>
          </>
        )}
      </GoalsContainer>
    </SectionContainer>
  );
};
export default OurMission;
