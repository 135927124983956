import React from 'react';
import styled from 'styled-components';
const VideoContainer = styled.div`
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: -3px;
  video {
    width: 100%;
    border-radius: 3px;
  }
`;
const Video = ({ videoSrcURL, videoTitle, ...props }: any): JSX.Element => (
  <VideoContainer>
    <video autoPlay loop muted playsInline>
      <source src={videoSrcURL} type="video/mp4" />
    </video>
  </VideoContainer>
);
export default Video;
