/********************************************************************************
 * LOGIN BUTTON
 * ----------------------------------------------------------------------------
 * Simple button used to
 * - Login
 * - Logout
 * *****************************************************************************/
import React, { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useAccountStore } from '../../../../store';
import { Button, useMediaQuery, Typography } from '@mui/material';
import AccountDrawer from '../AccountDrawer/AccountDrawer';
// import { Typography } from 'ui';
import posthog from 'posthog-js';

interface LoginButtonProps {
  onLoginClick?: () => void;
}

export const LoginButton = ({onLoginClick}:LoginButtonProps) => {


  /**********************************************************************
   * * Detect Small Breakpoint
   * ********************************************************************/
  const isSmall = useMediaQuery('(max-width:600px)');

  /**********************************************************************
   * * DESTRUCTURE THE USER AND SIGNOUT VALUES FROM THE AUTHENTICATOR
   * ********************************************************************/
  const { user, signOut } = useAuthenticator(context => [context.user]);

  /**********************************************************************
   * * DESTRUCTURE THE updateAccountState ACTION FROM THE ACCOUNT STORE
   * ********************************************************************/
  const updateAccountState = useAccountStore(state => state.updateAccountState);

  /**********************************************************************
   * * HANDLE LOGIN
   * --------------------------------------------------------------------
   * When the user clicks the login button, the showLoginModal value in the
   * account store is updated to true. This will cause the LoginModal
   * component to render the Authenticator component from the AWS Amplify
   * UI library. This will display the login modal to the user.
   * ********************************************************************/
  const handleLogin = () => {

    if (onLoginClick) {
      onLoginClick();
    }
    
    // Capture the login event
    posthog.capture('Account Login Button Clicked', {});
    updateAccountState(state => {
      state.showLoginModal = true;
    });
  };

  /**********************************************************************
   * * HANDLE LOGOUT
   * --------------------------------------------------------------------
   * When the user clicks the logout button, the signOut function from the
   * Authenticator is called. Additionally, this function modifies the
   * specific part of the persisted state in session storage (e.g., clears
   * the state.userAccount) without affecting other parts of the stored state.
   * ********************************************************************/
  const handleLogout = () => {
    // Update any other state as needed
    updateAccountState(state => {
      state.showLoginModal = false;
    });
    // Log the user out
    signOut();
  };

  /**********************************************************************
   * * HANDLE KEYDOWN
   * --------------------------------------------------------------------
   * When the user presses the escape key, the showLoginModal value in the
   * account store is updated to false. This will cause the LoginModal
   * component to hide the Authenticator component from the AWS Amplify
   * UI library. This will hide the login modal from the user.
   * ********************************************************************/
  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.key === 'Escape') {
        updateAccountState(state => {
          state.showLoginModal = false;
        });
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [updateAccountState]);

  /**********************************************************************
   * * RETURN THE LOGIN BUTTON
   * --------------------------------------------------------------------
   * The login button will display the login button to the user. If the
   * user is logged in, the button will display the text 'Logout'. If the
   * user is not logged in, the button will display the text 'Login'.
   * ********************************************************************/
  return (
    <>
      {user ? (
        // <Button variant='outlined' onClick={handleLogout}>Logout</Button>
        isSmall ? (
          <AccountDrawer />
        ) : (
          <AccountDrawer />
        )
      ) : (
        // <AccountMenu />
        <Button
          variant="outlined"
          onClick={handleLogin}
          sx={{
            background: 'white',
            color: '#191919', // Set the text color directly on the button for simplicity
            '&:hover': {
              background: 'white', // Set hover background color
            },
          }}
          data-action="topnav__login"
        >
          <Typography variant="button" sx={{ color: '#191919' }}>
            Login
          </Typography>
        </Button>
      )}
    </>
  );
};

export default LoginButton;
