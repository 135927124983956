import React from 'react';
import styled, { css } from 'styled-components';
import { responsiveV2 } from 'src/utils';

interface styleObject {
  [key: string]: string;
}

export interface TypographyProps {
  children: React.ReactNode;
  variant?: TypographyVariantTypes;
  fontColor?: string[];
  fontFamily?: string[];
  component?: string;
  gutterBottom?: boolean;
  sx?: styleObject;
}

type TypographyVariantTypes =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'caption'
  | 'button'
  | 'overline'
  | 'tiny1'
  | 'tiny2';

// type ComponentTypes = TypographyVariantTypes | 'div';
type ComponentTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'span' | 'a';

const variantStyles = {
  /**********************************************************************
   * HEADER 1
   **********************************************************************/
  h1: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          color: fontColor ? fontColor : ['#1C1C1C'],
          'font-family': fontFamily ? fontFamily : ['Meno Display Regular'],
          'font-weight': ['300', '300', '400'],
          'font-size': ['35px', '35px', '70px'],
          'line-height': ['110%', '110%', '105%'],
          'letter-spacing': ['-0.35px', '-0.35px', '-0.7px'],
          'margin-bottom': gutterBottom ? ['.47rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * HEADER 2
   * Renders as H2
   **********************************************************************/
  h2: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          color: fontColor ? fontColor : ['#1C1C1C'],
          'font-family': fontFamily ? fontFamily : ['Meno Display Regular'],
          'font-weight': ['300', '300', '300'],
          'font-size': ['31px', '31px', '50px'],
          'line-height': ['110%', '110%', '115%'],
          'letter-spacing': ['-0.31px', '-0.31px', '-1px'],
          'margin-bottom': gutterBottom ? ['.47rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * HEADER 3
   * Renders as H3
   **********************************************************************/
  h3: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          color: fontColor ? fontColor : ['#1A1919'],
          'font-family': fontFamily ? fontFamily : ['Meno Display Regular'],
          'font-weight': ['400'],
          'font-size': ['24px', '24px', '32px', '38px'],
          'line-height': ['115%'],
          'letter-spacing': ['-0.225px', '-0.225px', '-0.72px'],
          'margin-bottom': gutterBottom ? ['.47rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * HEADER 4
   * Renders as H4
   **********************************************************************/
  h4: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          color: fontColor ? fontColor : ['#1A1919'],
          'font-family': fontFamily ? fontFamily : ['Meno Display Regular'],
          'font-weight': ['300'],
          'font-size': ['20px', '20px', '24px', '24px'],
          'line-height': ['115%'],
          'letter-spacing': ['-0.225px', '-0.225px', '-0.72px'],
          'margin-bottom': gutterBottom ? ['.47rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * HEADER 5
   * Renders as H4
   **********************************************************************/
  h5: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          color: fontColor ? fontColor : ['#1A1919'],
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          'font-weight': ['300'],
          'font-size': ['18px', '18px', '24px'],
          'line-height': ['115%'],
          'letter-spacing': ['-0.225px', '-0.225px', '-0.72px'],
          'margin-bottom': gutterBottom ? ['.47rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * HEADER 6
   * Renders as H4
   **********************************************************************/
  h6: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          color: fontColor ? fontColor : ['#1A1919'],
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          'font-weight': ['300'],
          'font-size': ['18px', '18px', '24px'],
          'line-height': ['115%'],
          'letter-spacing': ['-0.225px', '-0.225px', '-0.72px'],
          'margin-bottom': gutterBottom ? ['.47rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * SUBTITLE 1
   **********************************************************************/
  subtitle1: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          color: fontColor ? fontColor : ['#1A1919'],
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          'font-weight': ['300'],
          'font-size': ['15px', '15px', '18px'],
          'line-height': ['115%'],
          'letter-spacing': ['-0.225px', '-0.225px', '-0.72px'],
          'margin-bottom': gutterBottom ? ['.47rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * SUBTITLE 2
   **********************************************************************/
  subtitle2: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          color: fontColor ? fontColor : ['#1A1919'],
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          'font-weight': ['300'],
          'font-size': ['15px', '15px', '18px'],
          'line-height': ['115%'],
          'letter-spacing': ['-0.225px', '-0.225px', '-0.72px'],
          'margin-bottom': gutterBottom ? ['.47rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * BODY 1
   **********************************************************************/
  body1: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          color: fontColor ? fontColor : ['#1C1C1C'],
          'font-weight': ['500', '500', '500'],
          'font-size': ['18px', '18px', '22px'],
          'line-height': ['135%', '135%', '148%'],
          'letter-spacing': ['-0.2px'],
          'margin-bottom': gutterBottom ? ['0.47rem'] : [''],
        },
      })}

    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * BODY 2
   **********************************************************************/
  body2: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          color: fontColor ? fontColor : ['#000000'],
          'font-weight': ['400', '400', '400'],
          'font-size': ['14px', '14px', '18px'],
          'line-height': ['140%'],
          'letter-spacing': ['-0.2px'],
          'margin-bottom': gutterBottom ? ['0.3rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * BODY 3
   **********************************************************************/
  body3: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          color: fontColor ? fontColor : ['#1C1C1C'],
          'font-weight': ['500'],
          'font-size': ['12px', '14px', '16px'],
          'line-height': ['97%'],
          'margin-bottom': gutterBottom ? ['0.2rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * CAPTION
   **********************************************************************/
  caption: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          color: fontColor ? fontColor : ['#1C1C1C'],
          'font-weight': ['500'],
          'font-size': ['12px', '14px', '14px'],
          'line-height': ['97%'],
          'margin-bottom': gutterBottom ? ['0.2rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * BUTTON TEXT
   **********************************************************************/
  button: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          color: fontColor ? fontColor : ['#1C1C1C'],          
          'font-weight': ['500', '500', '500'],          
          'font-size': ['16px', '16px', '20px'],
          'line-height': ['140%'],
          'margin-bottom': gutterBottom ? ['0.2rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * OVERLINE
   **********************************************************************/

  overline: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          color: fontColor ? fontColor : ['#1C1C1C'],
          'font-weight': ['300'],
          'font-size': ['12px', '12px', '16px'],
          'line-height': ['145%'],
          'margin-bottom': gutterBottom ? ['0.2rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * Tiny 1
   **********************************************************************/
  tiny1: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) => {
      return responsiveV2({
        cssPropsVals: {
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          color: fontColor ? fontColor : ['#4B4B4B'],
          'font-weight': ['400', '400', '500'],
          'font-size': ['12px', '12px', '15px'],
          'line-height': ['145%'],
          'margin-bottom': gutterBottom ? ['.47rem'] : [''],
        },
      });
    }}
    ${({ sx }) => (sx ? sx : '')}
  `,

  /**********************************************************************
   * Tiny 2
   **********************************************************************/
  tiny2: css<TypographyProps>`
    ${({ gutterBottom, fontColor, fontFamily }) =>
      responsiveV2({
        cssPropsVals: {
          'font-family': fontFamily ? fontFamily : ['Montserrat'],
          color: fontColor ? fontColor : ['#4B4B4B'],
          'font-weight': ['400'],
          'font-size': ['10px', '10px', '12px'],
          'line-height': ['145%'],
          'margin-bottom': gutterBottom ? ['.47rem'] : [''],
        },
      })}
    ${({ sx }) => (sx ? sx : '')}
  `,
};

const StyledComponent = styled.div<TypographyProps>`
  font-family: 'Montserrat';
  line-height: 110%;
  ${({ variant = 'body2' }) => variantStyles[variant] || ''}
`;

const Typography: React.FC<TypographyProps> = ({
  children,
  variant = 'body2',
  component,
  fontColor,
  fontFamily,
  gutterBottom = false,
  sx,
}) => {
  const isHeaderType = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant);
  const Component = StyledComponent.withComponent(
    (component || (isHeaderType ? variant : 'div')) as ComponentTypes
  );

  return (
    <Component 
    variant={variant} 
    fontColor={fontColor} 
    fontFamily={fontFamily}
    gutterBottom={gutterBottom} 
    style={sx}
    >
      {children}
    </Component>
  );
};

export default Typography;
