import * as React from 'react';
import styled from 'styled-components';
import { applyRules } from '../../../utils/headline-parser';

interface Props {
  headline?: string;
  subHeading?: string;
}

const Headline = styled.h4`
  color: ${({ theme }) => theme.colors.black} !important;
  text-transform: uppercase;
  margin-bottom: 9px;
  ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.breakpoints.wide} {
    margin-bottom: 21px;
  }
`;

const SubHeading = styled.h1`
  color: ${({ theme }) => theme.colors.black} !important;
  margin-top: 0;
  margin-bottom: 20px;
`;

export default function HeadlineGroup({ headline, subHeading }: Props) {
  return (
    <>
      {headline && <Headline>{headline}</Headline>}
      {subHeading && <SubHeading>{applyRules(subHeading)}</SubHeading>}
    </>
  );
}
