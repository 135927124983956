import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { SectionProps } from 'types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BasicBlockRichTextRenderOptions } from 'src/utils/render';

import { SectionContainer2, Collapse, Typography, Box, Container } from 'ui';

const PageSectionFaq = ({
  headline,
  subHeading,
  body,
  slug,
  designNodes,
  sectionType,
}: SectionProps) => {
  // const theme = useTheme();
  const qaItems = body.references.map(entry => {
    return {
      question: entry.question,
      answer: entry?.answer?.answer,
      icon: entry.icon,
      iconColor: entry.iconColor,
      defaultOpen: entry.defaultOpen,
      richTextAnswer: entry?.richTextAnswer,
    };
  });

  const [active, setActive] = React.useState(-1);

  return (
    <SectionContainer2 designNodes={designNodes} sectionType={sectionType}>
      <Container>
        <Box sx={{ textAlign: 'center' }}>
          {headline && <Typography variant="h2">{headline}</Typography>}
          {subHeading && <Typography variant="body1">{subHeading}</Typography>}
        </Box>

        <Box>          
          {qaItems.map((item, key) => {            
            return (
              <Collapse
                key={item.question}
                title={item.question}
                isOpen={active === key}
                icon={item.icon}
                iconColor={item.iconColor}
                onClick={() => setActive(active === key ? -1 : key)}
              >
                {item?.answer && <ReactMarkdown>{item.answer}</ReactMarkdown>}
                {item?.richTextAnswer &&
                  renderRichText(item.richTextAnswer, BasicBlockRichTextRenderOptions)}
              </Collapse>
            );
          })}
          
        </Box>
      </Container>
    </SectionContainer2>
  );
};
export default PageSectionFaq;

// const FooContainer = styled.div`
//   display: flex;
//   flex-flow: column;
//   ${({ theme }) => theme.breakpoints.small} {
//     flex-flow: row wrap;
//   }
//   ${({ theme }) => theme.breakpoints.desktop} {
//     justify-content: space-between;
//   }
//   .heading {
//     width: 392px;
//     margin-right: 50px;
//     ${({ theme }) => theme.breakpoints.small} {
//       min-width: 392px;
//       max-width: 20%;
//     }
//     h2 {
//       font-size: 50px;
//       line-height: 57.5px;
//       font-family: 'Everett Light';
//       color: ${({ theme }) => theme.colors.eloBlack};
//     }
//     p,
//     a {
//       width: 392px;
//       font-size: 15px;
//       line-height: 21.75px;
//       color: ${({ theme }) => theme.colors.eloBlack};
//       display: none;
//     }
//     ${({ theme }) => theme.breakpoints.small} {
//       p,
//       a {
//         display: block;
//       }
//     }
//   }
//   .container {
//     max-width: 100%;
//     ${({ theme }) => theme.breakpoints.tablet} {
//       min-width: 500px;
//     }
//     ${({ theme }) => theme.breakpoints.desktop} {
//       max-width: 70%;
//     }
//     margin-top: 20px;
//     .instruction {
//       margin-top: 30px;
//       p,
//       a {
//         display: block;
//         font-size: 15px;
//         line-height: 21.75px;
//         color: ${({ theme }) => theme.colors.eloBlack};
//         margin-bottom: 0;
//       }
//       ${({ theme }) => theme.breakpoints.small} {
//         p,
//         a {
//           display: none;
//         }
//       }
//     }
//   }
// `;
