import * as React from 'react';
import { SectionProps } from 'types';
import { PageSectionListing } from 'ui';
import { createListingData } from 'src/features/ui/pagesectionlisting/PageSectionListing';

type PageSectionFeaturedArticleProps = SectionProps & {
  data: any;
};

const PageSectionFeaturedArticle = ({
  slug,
  headline,
  listingObjectType,
  data,
}: PageSectionFeaturedArticleProps) => {
  const listingData = createListingData(data[listingObjectType], listingObjectType);
  const items = listingData.filter((item) => item.slug?.includes(headline));

  return <PageSectionListing slug={slug} title={undefined} items={items} />;
};

export default PageSectionFeaturedArticle;
