import * as React from 'react';
import { SectionProps } from 'types';
import PageSectionListing, {
  createListingData,
} from 'src/features/ui/pagesectionlisting/PageSectionListing';

type PageSectionRecentArticlesProps = SectionProps & {
  data: any;
};

const PageSectionRecentArticles = ({
  slug,
  headline,
  listingObjectType,
  data,
}: PageSectionRecentArticlesProps) => {
  const listingData = createListingData(data?.recentArticles, listingObjectType);

  return <PageSectionListing slug={slug} title={headline} items={listingData} />;
};

export default PageSectionRecentArticles;
