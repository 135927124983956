/********************************************************************************
 * DATA FUNCTION: getUserConfiguration
 * ----------------------------------------------------------------------------
 * This function is used to get the user configuration from the API. It is used in
 * the useAuthListener hook to get the user configuration data once a user is
 * logged in.
 *
 * This contains data concerning what the user is able to buy, what flavors are
 * available, etc. Not to be confused with the 'me' query which contains actual
 * user data.
 * *****************************************************************************/

/**********************************************************************
 * * GENERATE API CLIENT
 * ********************************************************************/
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { GraphQLResult, GraphQLOptions } from '@aws-amplify/api-graphql';

/**********************************************************************
 * * IMPORT GRAPHQL RESULT AND GRAPHQL OPTIONS TYPES
 * ********************************************************************/
import { userConfiguration, UserConfigurationQuery } from './graphql';

/**********************************************************************
 * * BUILD USER CONFIGURATION QUERY
 * --------------------------------------------------------------------
 * ********************************************************************/
const buildQuery = (): GraphQLOptions => {
  const query = userConfiguration;
  const authMode = 'userPool';
  const variables = { input: {} } as any;

  return {
    query,
    authMode: authMode,
    variables,
  };
};

/**********************************************************************
 * * GET PRODUCTS AND PRICES
 * --------------------------------------------------------------------
 * ********************************************************************/

export const getUserConfiguration = async () => {
  try {
    const query = buildQuery();
    const result = (await client.graphql(query)) as GraphQLResult<UserConfigurationQuery>;
    return result.data ?? null;
  } catch (error) {
    console.error('Error getting user configuration', error);
  }
};

export default getUserConfiguration;
