import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

export const ShippingTruck = ({
  stroke = '#1A1919',
  strokeWidth = '1.25',
  width = '50',
  height = '50',
  viewBox = '0 0 33 21',
}: SVGIcon) => (
  <svg 
    width={width}
    height={height}
    viewBox={viewBox} 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M7.40234 3.40594L7.52923 1H22.975L22.0966 17.6756H13.9076"
      stroke={stroke}
      stroke-width={strokeWidth}
    />
    <path d="M8.59954 17.6756H6.65234L6.90611 12.854" stroke={stroke}
      stroke-width={strokeWidth} stroke-miterlimit="10" />
    <path d="M0 5.53369H11.7613" stroke={stroke}
      stroke-width={strokeWidth} stroke-miterlimit="10" />
    <path d="M11.4694 7.95428H1.74805" stroke={stroke}
      stroke-width={strokeWidth} stroke-miterlimit="10" />
    <path d="M11.2446 10.4822H2.94336" stroke={stroke}
      stroke-width={strokeWidth} stroke-miterlimit="10" />
    <path
      d="M24.0205 17.6756H22.0977L22.693 6.35846H27.9344C27.9344 6.35846 32.0484 8.95472 31.9996 14.4401C31.9996 16.7923 31.9996 17.6756 31.9996 17.6756H29.3301"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M26.6744 20.1889C28.1406 20.1889 29.3292 19.0003 29.3292 17.5341C29.3292 16.0679 28.1406 14.8793 26.6744 14.8793C25.2081 14.8793 24.0195 16.0679 24.0195 17.5341C24.0195 19.0003 25.2081 20.1889 26.6744 20.1889Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M11.2525 20.1889C12.7187 20.1889 13.9073 19.0003 13.9073 17.5341C13.9073 16.0679 12.7187 14.8793 11.2525 14.8793C9.78626 14.8793 8.59766 16.0679 8.59766 17.5341C8.59766 19.0003 9.78626 20.1889 11.2525 20.1889Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path d="M31.1926 10.3895H22.457" stroke={stroke}
      stroke-width={strokeWidth} stroke-miterlimit="10" />
  </svg>
);

export default ShippingTruck;
