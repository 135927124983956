import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

const Shield = ({
  stroke = '#333333',
  strokeWidth = '1.25',
  fill = 'none', // Default fill color is 'none'
  width = '30',
  height = '34',
  viewBox = '0 0 30 34',
}: SVGIcon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
  >
    <path
      d="M13.7477 32.659C4.13417 29.3708 1.14844 19.6286 1.14844 16.4563V5.02815L14.7451 1L28.3481 5.02815V16.4563C28.3481 19.635 25.3624 29.3708 15.7489 32.659L14.7515 33L13.7541 32.659H13.7477Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
      fill={fill} // Use the fill prop here
    />
    <path
      d="M5.00781 7.9023V16.4476C5.00781 18.4424 7.35007 26.2285 14.75 28.9118C22.15 26.2285 24.4923 18.4424 24.4923 16.4476V7.9023L14.75 5.01953L8.52764 6.85987"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
      fill={fill} // Use the fill prop here
    />
    <path
      d="M9.50781 16.9419L12.5836 19.9662L20.4405 12.1094"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
      fill={fill} // Use the fill prop here
    />
  </svg>
);

export default Shield;
