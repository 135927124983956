import { lighten } from 'polished';
type ScreenSizes = {
  mobile: string | number;
  tablet: string | number;
  small: string | number;
  desktop: string | number;
  wide: string | number;
}
export type Theme = {
  colorScheme: 'light' | 'dark';
  colors: {
    primary: string;
    primaryHighlight: string;
    text: string;
    textLight: string;
    black: string;
    blackHighlight: string;
    secondary: string;
    secondaryText: string;
    tertiary: string;
    tertiaryText: string;
    eloGreyge: string;
    eloGreygeLight: string;
    eloDarkGrey: string;
    eloGreyLight: string;
    eloLightGrey: string;
    eloMidGrey: string;
    eloBlack: string;
    eloOrange: string;
    eloAmber: string;
    eloDarkGreen: string;
    eloMidGreen: string;
    white: string;
    error: string;
    // LUMINARY COLORS
    luminaryOrange: string;
  };
  breakpoints: ScreenSizes;
  gradients: {
    vertialLightGrayWhite: string;
    vertialWhiteLightGray: string;
    horizontalGreenYellowRed: string;
  };
  CONTENT_MAX_WIDTH: number;
  padding: {
    vertical: ScreenSizes,
    horizontal: ScreenSizes
  }
};

export const Colors = {
  primary: '#F69B32',
  primaryHighlight: lighten(0.1, '#F69B32'),
  text: '#EEEFE6',
  textLight: '#000000',
  black: '#000000', // black: '#1A1919' ?
  blackHighlight: '#56504B',
  secondary: '#56504B',
  secondaryText: '#EEEFE6',
  tertiary: '#EEEFE6',
  tertiaryText: '#56504B',
  eloGreyge: '#EEEFE6',
  eloGreygeLight: '#F8F9F5',
  eloDarkGrey: '#4B4B4B',
  eloGreyLight: '#F8F9F5',
  eloLightGrey: '#E2E2E2',
  eloMidGrey: '#B6B6B6',
  eloBlack: '#1A1919',
  eloOrange: '#EC681D',
  eloAmber: '#FFBE01',
  eloDarkGreen: '#162513',
  eloMidGreen: '#9BC101',
  white: '#FFF',
  error: '#EA2207',
  // LUMINARY COLORS
  luminaryOrange: '#F69B32',
};

export const Sizes = {
  xsmall: '(xlarge)',
  small: '(min-width: 600px)',
  medium: '(min-width: 768px)',
  large: '(min-width: 992px)',
  xlarge: '(min-width: 1200px)',
};

export const base = {
  colorScheme: 'light',
  colors: Colors,
  breakpoints: {
    mobile: '@media (max-width: 750px)',
    tablet: '@media (min-width: 751px)',
    small: '@media (min-width: 821px)',
    desktop: '@media (min-width: 1200px)',
    wide: '@media (min-width: 1800px)',
  },
  gradients: {
    vertialLightGrayWhite: `linear-gradient(180deg, ${Colors.secondaryText} 0%, ${Colors.white} 100%)`,
    vertialWhiteLightGray: `linear-gradient(180deg, ${Colors.white} 0%, ${Colors.secondaryText} 100%)`,
    horizontalGreenYellowRed:
      'linear-gradient(90deg, #B9D517 0.16%, #FFBE00 49.64%, #CA4363 97.04%)',
  },
  CONTENT_MAX_WIDTH: 1450,
  padding: {
    vertical: {
      mobile: 30,
      small: 80,
      tablet: 80,
      desktop: 80,
      wide: 100,
    },
    horizontal: {
      mobile: 16,
      tablet: 56,
      small: 56,
      desktop: 70,
      wide: 190,
    },
  }
};
