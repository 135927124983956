/******************************************************************************************
 * * COMPONENT: TAB PANEL
 * ---------------------------------------------------------------------------------------
 * This component is used to render the active tab panel an tab panel content.
 * ****************************************************************************************/
import React from 'react';
import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = ({ children, index, value }: TabPanelProps) => {
  return (
    <>
      {value === index && (
        <>
          <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
          >
            {children}
          </Box>
        </>
      )}
    </>
  );
};

export default TabPanel;
