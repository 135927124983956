import * as React from 'react';
import styled from 'styled-components';
type Props = React.PropsWithChildren<{
  id: string;
  classes?: string;
  usePadding?: boolean;
  usePaddingNoMobile?: boolean;
  contentBackgroundColor?: string;
  contentBackground?: string;
  firstSection?: boolean;
  nonRelative?: boolean;
}>;

export const SectionPadding = {
  vertical: {
    mobile: 30,
    tablet: 80,
    desktop: 80,
    wide: 100,
  },
  horizontal: {
    mobile: 16,
    tablet: 56,
    desktop: 70,
    wide: 190,
  },
};

const SectionContainer = styled.section<Props>(
  (props) => `
  ${!props.nonRelative && 'position: relative;'}

  margin-top: ${props.firstSection ? '140px' : '0'};

  ${
    props.usePadding &&
    `
    padding: ${SectionPadding.vertical.mobile}px ${SectionPadding.horizontal.mobile}px;
    `
  }
    ${
      (props.usePadding || props.usePaddingNoMobile) &&
      `
        ${props.theme.breakpoints.tablet} {
            padding: ${SectionPadding.vertical.tablet}px ${SectionPadding.horizontal.tablet}px;
        }
        ${props.theme.breakpoints.desktop} {
            padding: ${SectionPadding.vertical.desktop}px ${SectionPadding.horizontal.desktop}px;
        }
        ${props.theme.breakpoints.wide} {
            padding: ${SectionPadding.vertical.wide}px ${SectionPadding.horizontal.wide}px;
        }
    `
    }
    background-color: ${props.contentBackgroundColor ?? 'transparent'};
    background: ${props.contentBackground ?? ''};

`
);

export default function Section({
  id,
  classes,
  usePadding,
  usePaddingNoMobile,
  firstSection,
  contentBackgroundColor,
  contentBackground,
  nonRelative = false,
  children,
}: Props): JSX.Element {
  return (
    <SectionContainer
      id={id}
      className={classes}
      usePadding={usePadding}
      usePaddingNoMobile={usePaddingNoMobile}
      firstSection={firstSection}
      contentBackgroundColor={contentBackgroundColor}
      contentBackground={contentBackground}
      nonRelative={nonRelative}
    >
      {children}
    </SectionContainer>
  );
}
