import * as React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { ReactNode } from 'react';

type FullscreenBackgroundImageProps = {
  fluid?: any;
  mobileFluid?: any;
  className?: string;
  children: ReactNode;
};

const FullscreenBackgroundImage = React.forwardRef<any, FullscreenBackgroundImageProps>(
  (
    { className, fluid = null, mobileFluid = null, children }: FullscreenBackgroundImageProps,
    ref
  ): JSX.Element => {
    let imageSources;

    if (fluid && mobileFluid) {
      imageSources = [
        mobileFluid,
        {
          ...fluid,
          media: `(min-width: 600px)`,
        },
      ];
    } else if (fluid) {
      imageSources = [fluid];
    } else {
      return (
        <div ref={ref} className={className}>
          {children}
        </div>
      );
    }

    return (
      <div ref={ref}>
        <BackgroundImage
          Tag="div"
          className={className}
          fluid={imageSources}
          // critical={true}
        >
          {children}
        </BackgroundImage>
      </div>
    );
  }
);

export default FullscreenBackgroundImage;
