import * as React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { DefaultRichTextRenderOptions } from '../../../../utils/render';
import { SectionProps } from 'types';
import { SectionContainer, Button, Image } from 'ui';

import { useEloStore } from '../../../store/EloStore.hooks';
import { getSubscription } from '../../../product/utils';
import { Product } from '../../../product/Product.types';
export default function FullImage({
  headline,
  body,
  ctaButton,
  background,
  mobileBackground,
  products,
}: SectionProps): JSX.Element {
  const bg = isMobile ? mobileBackground ?? background : background;

  const { store, cart } = useEloStore();
  const product = products && products[0];
  const contextProduct = store.products[product?.slug] || product;
  const selectedSubscription = getSubscription(contextProduct as Product);

  return (
    <Container>
      <div className="content">
        <SectionContainer id={headline} contentBackground="transparent" usePadding>
          <h2>{headline}</h2>
          {body && renderRichText(body, DefaultRichTextRenderOptions)}
          {ctaButton && product && (
            <Button
              onClick={() => {
                cart.addOrUpdateProduct(
                  product.slug,
                  selectedSubscription?.slug || '',
                  {},
                  product.cartPath
                );
              }}
              showLoader
            >
              {ctaButton.title}
            </Button>
          )}
        </SectionContainer>
      </div>
      <Image {...bg} />
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  div.content {
    position: absolute;
    z-index: 5;

    h2 {
      font-family: 'Everett Light';
      color: #fff;
      font-size: 29px;
      line-height: 33px;
    }
    .typography-p {
      color: #fff;
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 20px;
    }
    button {
        display: none;
    }
    ${({ theme }) => theme.breakpoints.tablet} {
        button {
            display: block;
        }
    }
    ${({ theme }) => theme.breakpoints.small} {
        h2 {
          font-size: 51px;
          line-height: 56px;
          max-width: 633px;
        }
        .typography-p {
          font-size: 20px;
          line-height: 29px;
          margin-bottom: 40px;
        }
      }
`;
