/********************************************************************************
 * * GRAPHQL QUERY - USER CONFIGURATION
 * ------------------------------------------------------------------------------
 * This query is used to get the user configuration from the API. It is used in
 * the useAuthListener hook to get the user configuration data once a user is
 * logged in.
 *
 * This contains data concerning what the user is able to buy, what flavors are
 * available, etc. Not to be confused with the 'me' query which contains actual
 * user data.
 *
 * It is also used whenever the user configuration is updated in the app and needs
 * to be refreshed. Cases where this is necessary include:
 * - When a user changes their subscription
 *
 * * NOTE:
 * Construct a specific type for this query and export it along with the
 * query. This will be used to type the response from the API.
 * *****************************************************************************/

import { GummyFlavor, PregnancyStage } from '../../types/API';

/********************************************************************************
 * * TYPES USER CONFIGURATION QUERY
 * ------------------------------------------------------------------------------
 * When we create the query for the user configuration, we need to create the
 * types that will be used the Account Store State and the response from the API.
 * This is because I don't want to use the default types that come from the
 * API. The reason for that is that I don't want my STORE data to have a nested
 * structure. I want it to be flat and easy to access. This is why I create a
 * custom type for the user configuration.
 * ******************************************************************************/
// Used by the data query function in the return type definition
// For example: const result = (await client.graphql(query)) as GraphQLResult<UserConfigurationQuery>;
export type UserConfigurationQuery = {
  userConfiguration: UserConfiguration;
};

// Used by the Account Store
// For Example: userConfiguration: UserConfiguration | null;
export type UserConfiguration = {
  gummyFlavors: GummyFlavor[];
  pregnancyStages: PregnancyStage[];
  purchasableSubscriptions: {
    id: string;
    eloId: string;
    slug: string;
    title: string;
    createdAt: string;
    updatedAt: string;
  }[];
  allPurchasableSubscriptions: {
    id: string;
    eloId: string;
    slug: string;
    title: string;
  }[];
};

/********************************************************************************
 * * USER CONFIGURATION QUERY
 * ------------------------------------------------------------------------------
 * This is the query used to get the user configuration from the API.
 * ******************************************************************************/
export const userConfiguration = /* GraphQL */ `
  query UserConfiguration {
    userConfiguration {
      gummyFlavors
      pregnancyStages
      purchasableSubscriptions {
        id
        eloId
        slug
        title
        createdAt
        updatedAt
      }
      allPurchasableSubscriptions {
        id
        eloId
        slug
        title
      }
    }
  }
`;

export default userConfiguration;
