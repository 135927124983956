/******************************************************************************************
 * GET PLAN
 * ----------------------------------------------------------------------------------------
 * This function gets the preliminary plan from the API.
 * 
 * * NOTE
 * The authentication mode is determined by the isAuthenticated function. Based on the
 * state of the user, the authentication mode will be either "userPool" or "iam".
 * ****************************************************************************************/

import { generateClient } from 'aws-amplify/api';
const client = generateClient();


import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  GetPreliminaryPlanQuery,
  GetPreliminaryPlanQueryVariables,
  PreliminaryPlan,
} from 'src/API';
import { getPreliminaryPlan } from 'src/GQL/getPreliminaryPlan';

/********************************************************************************
 * IS AUTHENTICATED
 * ------------------------------------------------------------------------------
 * Import the isAuthenticated function from the ELO package to determine the
 * authentication mode that is necessary to use. The isAuthenticated function
 * simplifies the process of determining the authentication mode by checking the
 * state of the user.
 * *****************************************************************************/
import { isAuthenticated } from 'src/elo';

export const getPlan = async (id: string): Promise<PreliminaryPlan | null> => {

  // Set the auth mode
  const authMode = isAuthenticated() ? "userPool" : "iam" as 'userPool' | 'iam';

  try {
    
    const query = {
      query: getPreliminaryPlan,
      authMode: authMode,
      variables: {
        id: id,
      } as GetPreliminaryPlanQueryVariables,      
    }    
    // amplify v6
    const result = (await client.graphql(query)) as GraphQLResult<GetPreliminaryPlanQuery>    

    const plan = result?.data?.getPreliminaryPlan;
    
    if (plan && plan.createdAt) {
      
      return plan as any as PreliminaryPlan;
    } else {
      // We will return null rather than throw an error
      // This is because it's possible that the plan doesn't exist
      // and we don't want to break the app if it doesn't.
      // Instead we will return null and handle the error or retry in the component
      // return null;
      return plan as any as PreliminaryPlan;
    }
  } catch (error) {
    console.error('getPlan Error', error);
    throw error;
  }
};
