import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

export const Silverware = ({
  stroke = '#1A1919',
  strokeWidth = '1.25',
  width = '21',
  height = '20',
  viewBox = '0 0 21 20',
}: SVGIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.62891 20V10.2769"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M3.62725 6.59478L3.66016 1"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M6.2516 1V5.93014C6.2516 7.38111 5.07522 8.5575 3.62424 8.5575C2.17326 8.5575 1 7.38111 1 5.93014V1"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path d="M11.5527 20V10.2769" stroke="#1A1919" stroke-width="1.25" stroke-miterlimit="10" />
    <path
      d="M19.6218 20V1.89551C19.6218 1.52418 19.1194 1.39937 18.9509 1.73013C18.4173 2.77546 17.7527 4.66953 17.1286 7.80863C16.7074 9.93673 16.9608 12.9715 17.1298 14.3016"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M14.1766 3.98613V4.16087C14.1766 2.70989 13.0002 1.53351 11.5492 1.53351C10.0983 1.53351 8.92188 2.70989 8.92188 4.16087V3.98613V5.927C8.92188 7.37798 10.0983 8.55436 11.5492 8.55436C13.0002 8.55436 14.1766 7.37798 14.1766 5.927V3.98613Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
  </svg>
);

export default Silverware;
