import * as React from 'react';
import { Typography } from 'antd';
import { kebabCase } from 'lodash';
import ReactDOMServer from 'react-dom/server';

const { Title } = Typography;

type TitleWithAnchorProps = {
  level: 1 | 2 | 3 | 4 | 5 | undefined;
  children: any;
};

const TitleWithAnchor = ({ level, children }: TitleWithAnchorProps): JSX.Element => {
  // [PROD-987] Ok this is a silly hack to get a sensible ID out of a heading with formatting, bold specifically.
  // The issue is that some of the articles on Contentful have headings that are additonally bolded which
  // causes trouble for our ID generation since they are now nested elements rather than strings.
  // This solution specifically combats the common case of Heading+Bold but will fail any other combination.
  const id = anchorId(ReactDOMServer.renderToString(children))
    .replace(/span-class-ant-typography-strong-/, '')
    .replace(/-strong-span/, '');
  return (
    <Title level={level} id={id}>
      {children}
    </Title>
  );
};

// Done as separate function since we might need something more sophisticated to cover edge cases
export function anchorId(title: string): string {
  return kebabCase(title);
}

export default TitleWithAnchor;
