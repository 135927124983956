/**
 * BOX
 * Example for creating a re-usable box
 */
import React from 'react';
import styled from 'styled-components';
import { responsiveV2 } from 'src/utils';
import { IBoxProps } from './BoxProps';

const StyledBox = styled.div<IBoxProps>`
  ${({
    id = null,
    position = ['relative'],
    display = [''],
    flexDirection = ['row'],
    justifyContent = ['center'],
    alignItems = ['center'],
    m = [''],
    mx = [''],
    my = [''],
    mt = [''],
    mb = [''],
    p = [''],
    px = [''],
    py = [''],
    pt = [''],
    pr = [''],
    pb = [''],
    pl = [''],
    border = [''],
    width = [''],
    minWidth = [''],
    maxWidth = [''],
    height = [''],
    minHeight = [''],
    maxHeight = [''],
    background = [''],
    textAlign = [''],
    borderRadius = [''],
    zIndex = [],
  }) =>
    responsiveV2({
      cssPropsVals: {
        position: Array.isArray(position) ? position : [position],
        display: Array.isArray(display) ? display : [display],
        'flex-direction': Array.isArray(flexDirection) ? flexDirection : [flexDirection],
        'justify-content': Array.isArray(justifyContent) ? justifyContent : [justifyContent],
        'align-items': Array.isArray(alignItems) ? alignItems : [alignItems],
        margin: Array.isArray(m) ? m : [m],
        'margin-inline': Array.isArray(mx) ? mx : [mx],
        'margin-block': Array.isArray(my) ? my : [my],
        'margin-top': Array.isArray(mt) ? mt : [mt],
        'margin-bottom': Array.isArray(mb) ? mb : [mb],
        padding: Array.isArray(p) ? p : [p],
        'padding-inline': Array.isArray(px) ? px : [px],
        'padding-block': Array.isArray(py) ? py : [py],
        'padding-top': Array.isArray(pt) ? pt : [pt],
        'padding-right': Array.isArray(pr) ? pr : [pr],
        'padding-bottom': Array.isArray(pb) ? pb : [pb],
        'padding-left': Array.isArray(pl) ? pl : [pl],
        border: Array.isArray(border) ? border : [border],
        width: Array.isArray(width) ? width : [width],
        'min-width': Array.isArray(minWidth) ? minWidth : [minWidth],
        'max-width': Array.isArray(maxWidth) ? maxWidth : [maxWidth],
        height: Array.isArray(height) ? height : [height],
        'min-height': Array.isArray(minHeight) ? minHeight : [minHeight],
        'max-height': Array.isArray(maxHeight) ? maxHeight : [maxHeight],
        background: Array.isArray(background) ? background : [background],
        bottom: ['0'],
        'text-align': Array.isArray(textAlign) ? textAlign : [textAlign],
        'border-radius': Array.isArray(borderRadius) ? borderRadius : [borderRadius],
        'z-index': Array.isArray(zIndex) ? zIndex : [zIndex],
      },
    })}
  ${({ sx }) => (sx ? sx : '')}
`;

const Box = ({ children, className, designNodes, ...props }: IBoxProps) => {
  /**************************************************************************
   * DESIGN OPTIONS
   * -----------------------------------------------------------------------
   * Design Options come from the Design Nodes in the CMS. They are passed
   * to the component as a prop called designNodes. The designNodes prop is
   * an array of objects. Each object has a key that is the name of the
   * css property and a value that is the value of the css property. The
   * value can be a string or an array of strings corresponding to the
   * responsive breakpoints.
   **************************************************************************/
  const designOptions = designNodes
    ? designNodes.reduce((acc: any, curr: any) => {
        Object.keys(curr).forEach(key => {
          if (curr[key] !== null) {
            acc[key] = curr[key];
            // const kebabKey = camelToKebabCase(key);
            // acc[kebabKey] = curr[key];
          }
        });
        return acc;
      }, {})
    : null;

  // SET THE EFFECTIVE CSS KEY VALUES
  // Design Option values override the hard coded prop values
  const effectiveBackground = designOptions?.backgroundColor || props.background;
  const effectiveMargin = designOptions?.margin || props.m;
  const effectiveMarginTop = designOptions?.marginTop || props.mt;
  const effectiveMarginBottom = designOptions?.marginBottom || props.mb;
  const effectivePadding = designOptions?.padding || props.p;
  const effectivePaddingTop = designOptions?.paddingTop || props.pt;
  const effectivePaddingBottom = designOptions?.paddingBottom || props.pb;
  const effectiveTextAlign = designOptions?.textAlign || props.textAlign;
  const effectiveMinHeight = designOptions?.minHeight || props.minHeight;
  const effectiveMaxHeight = designOptions?.maxHeight || props.maxHeight;
  const effectiveBorderRadius = designOptions?.borderRadius || props.borderRadius;
  const effectiveZIndex = designOptions?.zIndex || props.zIndex;

  return (
    <StyledBox      
      background={effectiveBackground}
      m={effectiveMargin}
      mt={effectiveMarginTop}
      mb={effectiveMarginBottom}
      p={effectivePadding}
      pt={effectivePaddingTop}
      pb={effectivePaddingBottom}
      textAlign={effectiveTextAlign}
      minHeight={effectiveMinHeight}
      maxHeight={effectiveMaxHeight}
      borderRadius={effectiveBorderRadius}
      zIndex={effectiveZIndex}
      {...props}
      className={className}
    >
      {children}
    </StyledBox>
  );
};

export default Box;
