/**
 * Component: ImageCarousel
 * Simple image carousel that will rotate through images.
 * Initial features will rotate through images using a timer
 * and allow button clicks.
 */

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import {Icon} from 'ui';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { ImageCarouselProps } from './ImageCarousel.types';
import NoImage from './NoImage';
import { utils } from './utils';


const ImageCarousel = ({
  images,
  className,
  rootStyles,
  useIconNext = true,
  useIconBack = true,
  iconNext,
  iconBack,
  buttonTextNext,
  buttonTextBack,
  buttonContainerStyles,
  buttonStyles,
  enter = {
    opacity: 0,
    scale: 0,
  },
  active = {},
  exit = {
    opacity: 0,
    scale: 0,
  },
  transition = {
    duration: 1,
    ease: 'easeInOut',
  },
  devMode = false,
}: ImageCarouselProps) => {
  /**
   * NO IMAGES
   * If there are no images in the images array then
   * return a no images message that looks good.
   */
  if (!images || images.length < 1) return <NoImage />;

  const [imageCount, setImageCount] = useState(images.length - 1);
  const [direction, setDirection] = useState(0);
  const [inProgress, setInProgress] = useState(false);

  const slideVariants = {
    enter: {
      x: direction > 0 ? '100%' : '-100%',
      ...enter,
    },
    active: {
      x: 0,
      opacity: 1,
      scale: 1,
      ...active,
    },
    exit: {
      x: direction > 0 ? '-100%' : '100%',
      position: 'absolute',
      opacity: 0,
      scale: 0,
      ...exit,
    },
  };

  const sliderTransition = {
    ...transition,
  };

  /**
   * swipteToImage
   * This function sets the new image index value value which determines
   * which image should be displayed. It also sets the direction of movement
   * for the transition.
   */
  const swipeToImage = (swipeDirection: number) => {
    let newImageCount = imageCount + swipeDirection;

    if (newImageCount >= images.length) newImageCount = 0;
    if (newImageCount < 0) newImageCount = images.length - 1;

    setDirection(swipeDirection);
    setImageCount(newImageCount);
  };

  /**
   * Handle the drag event
   * When the image is dragged with a mouse or touch this function
   * calls swipeToImage in order to execute the state update.
   */
  const dragEndHandler = (dragInfo: { offset: { x: number } }) => {
    const draggedDistance = dragInfo.offset.x;
    const swipeThreshold = 50;
    if (draggedDistance > swipeThreshold) {
      swipeToImage(-1);
    } else if (draggedDistance < -swipeThreshold) {
      swipeToImage(1);
    }
  };

  /**
   * COMPONENT UTILS
   * getImage
   * Will return an image object or null
   */
  const image = utils.getImage({
    images: images, // Array of responsive gatsby image objects
    index: imageCount, // Current array index to select from for display
    isMobile: isMobile, // Boolean value indicating whether or not we are using mobile or desktop
  })

  return (
    <ImageCarouselRoot devMode={devMode} style={rootStyles} className={className ?? ''}>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={imageCount}
          custom={direction}
          //@ts-ignore
          variants={slideVariants}
          initial="enter"
          animate="active"
          exit="exit"
          transition={sliderTransition}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(_, dragInfo) => dragEndHandler(dragInfo)}
          /**
           * FUTURE SELF
           * It is necessary to disable triggering the animateion
           * while it is currently in progress. If you don't the scroller
           * may cause images to appear to enter from the wrong direction
           */
          onAnimationStart={() => setInProgress(true)}
          onAnimationComplete={() => setInProgress(false)}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center', // horizontally center the image in the container.
            justifyItems: `center`,
          }}
        >
          {  
          // GatsbyImage type cannot accept a null value
          // check for the image before rendering GatsbyImage          
          image && (
            <GatsbyImage
              alt="Alt"
              image={image}              
              objectFit="contain" // This will keep the image inside the gatsby image wrapper
              style={{
                width: '100%',
                pointerEvents: 'none',
              }}
            />
          )}
        </motion.div>
      </AnimatePresence>
      <ButtonContainer
        className="image-carousel-buttons-wrapper"
        style={buttonContainerStyles}
        devMode={devMode}
      >
        <Button
          className="image-carousel-button image-carousel-button-left"
          style={buttonStyles}
          onClick={() => swipeToImage(1)}
          disabled={inProgress}
        >
          {useIconBack && (
            <Icon
              id={iconBack ? iconBack : 'line-arrow-left'}
              style={{
                marginLeft: buttonTextBack ? '.47rem' : undefined,                
              }}              
            />
          )}
          {buttonTextBack && <ButtonText>{buttonTextBack}</ButtonText>}
        </Button>
        <Button
          className="image-carousel-button image-carousel-button-right"
          style={buttonStyles}
          onClick={() => swipeToImage(-1)}
          disabled={inProgress}
        >
          {buttonTextNext && <ButtonText>{buttonTextNext}</ButtonText>}
          {useIconNext && (
            <Icon
              id={iconNext ? iconNext : 'line-arrow-right'}
              style={{
                marginRight: buttonTextNext ? '.47rem' : undefined,
              }}
            />
          )}
        </Button>
      </ButtonContainer>
      {devMode && (
        <DevMode>
          <h3> &gt; Dev Mode </h3>
          <hr />
          <ul>
            <li>Direction: {direction}</li>
            <li>Image Count: {images.length}</li>
            <li>Current Image: {imageCount + 1}</li>
            <li>Animation State: {inProgress ? 'In Progress' : 'Completed'}</li>
          </ul>
        </DevMode>
      )}
    </ImageCarouselRoot>
  );
};

const ButtonText = styled.div`
  padding: 0.47rem;
`;

const ImageCarouselRoot = styled.div<{ devMode: boolean }>`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  background: ${(props) => (props.devMode ? '#EFEFEF' : 'undefined')};
  border: ${(props) => (props.devMode ? '1px solid purple' : 'undefined')}; ;
`;

const ButtonContainer = styled.div<{ devMode: boolean }>`
  display: flex;
  justify-content: center;
  border: ${(props) => (props.devMode ? '2px solid purple' : 'undefined')};
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1a1919;
  color: white;
  min-width: 75px;
  height: 29px;
  border-radius: 15px;
  border: unset;
  margin: 0.47rem;
  &:active {
    background: #56504b;
  }
`;

const DevMode = styled.div`
  position: absolute;
  border: 1px solid;
  background: rgba(0, 0, 0, 0.8);
  color: #12d912;
  padding: 1rem;
  top: 10px;
  left: 10px;
`;
export default ImageCarousel;
