import * as React from 'react';
import { Link } from 'gatsby';
import { Breadcrumb } from 'antd';
import JsonLDScript from '../jsonld/JsonLDScript';
import { Icon } from 'ui';

type ArticleBreadcrumbProps = {
  title?: string;
};

export const HTML = ({ title }: ArticleBreadcrumbProps): JSX.Element => {
  return (
    <Breadcrumb separator={<Icon id="right" />}>
      <Breadcrumb.Item>
        <Link to="/">Home</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        {title ? <Link to="/articles/">Articles</Link> : <>Articles</>}
      </Breadcrumb.Item>
      {title ? <Breadcrumb.Item>{title}</Breadcrumb.Item> : <></>}
    </Breadcrumb>
  );
};

// https://developers.google.com/search/docs/data-types/breadcrumb#json-ld
export const JsonLD = ({ title }: ArticleBreadcrumbProps): JSX.Element => {
  const homeItem = {
    '@type': 'ListItem',
    position: 1,
    name: 'Home',
    item: 'https://www.elo.health/',
  };
  const articlesLink = {
    '@type': 'ListItem',
    position: 2,
    name: 'Articles',
    item: 'https://www.elo.health/articles/',
  };
  const articlesNoLink = {
    '@type': 'ListItem',
    position: 2,
    name: 'Articles',
  };
  const titleItem = {
    '@type': 'ListItem',
    position: 3,
    name: title,
  };
  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: title ? [homeItem, articlesLink, titleItem] : [homeItem, articlesNoLink],
  };
  return <JsonLDScript jsonld={jsonld} />;
};

const ArticlesBreadcrumb = ({ title }: ArticleBreadcrumbProps): JSX.Element => {
  return (
    <div className="page-section-breadcrumb">
      <HTML title={title} />
      <JsonLD title={title} />
    </div>
  );
};

export default ArticlesBreadcrumb;
