import React from 'react';
import styled from 'styled-components';
import { Box } from 'ui';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BasicBlockRichTextRenderOptions } from '../../../../utils/render';
import { BasicBlock } from 'types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { responsiveV2 } from 'src/utils';
import { over } from 'lodash';

interface iBlocksProps {
  basicBlocks?: BasicBlock[] | null;
}

const Blocks = ({ basicBlocks = null }: iBlocksProps) => {
  if (!basicBlocks) return <>No Blocks</>;

  /**********************************************************************
   * GET GRID COLUMNS
   * --------------------------------------------------------------------
   * Grid columns are set in the contentful CMS. The user can set the
   * grid column values in the CMS. The values are stored in the
   * gridColumns array. The values in the array are strings. We need to
   * convert the strings to the correct format for the grid-template-columns
   * css property.
   *
   * In the CMS the user defines an array like this
   * ['12', '12', '6']
   *
   * This function will convert the array to this format
   * ['span 12','span 12', 'span 6', 'span 4']
   *
   * Whic is then passed to the responseiveV2 function in the styled
   * component below. The responsiveV2 function will then create the
   * necessary css media queries to enabel responsive design behavior.
   **********************************************************************/

  const defaultColumns = 12;
  const defaultGridColumns = ['12', '12', '6'];

  const getGridColums = (gridColumns: string[]) => {
    return gridColumns.map(num => `span ${num}`);
  };

  const responsiveGridColums = getGridColums(defaultGridColumns);

  return (
    <Grid columns={defaultColumns}>
      {basicBlocks.map((block, index) => {
        /**********************************************
         * Clone the block body data before you pass it
         * to the renderRichText function. This is because
         * the renderRichText function will mutate the data
         * and we don't want to mutate the original data.
         * If you don't do this, you will get an error.
         * ********************************************/
        const clone = JSON.parse(JSON.stringify(block.body));

        const backgroundImage = block?.backgroundImage?.gatsbyImageData;

        /**********************************************************************
         * SET GRID COLUMNS
         * --------------------------------------------------------------------
         * The gridColumns variable is used to set the number of columns the grid
         * will use. Get the gridColumns value from the block object. Pass it to
         * the getGridColums function to convert the value to the correct format.
         * Default to the defaultGridColumns value if the gridColumns value is
         * not set in the CMS.
         **********************************************************************/        
        const gridColumns = getGridColums(block.gridColumns ?? defaultGridColumns);

        return (
          <>
            <GridItem gridColumns={gridColumns}>
              <Box
                position={['relative']}
                key={index}
                designNodes={block.designNodes}
                display={['flex']}
                flexDirection={['column']}
                justifyContent={['center']}
                alignItems={['center']}
                // * TODO: Add overflow hidden to design nodes
                sx={{ overflow: 'hidden' }}
              >
                {backgroundImage && (
                  <BackgroundImage image={backgroundImage} alt="Background Image" />
                )}
                {clone && (
                  <div className="basic-block-wrapper">
                    {renderRichText(clone, BasicBlockRichTextRenderOptions)}
                  </div>
                )}
              </Box>
            </GridItem>
          </>
        );
      })}
    </Grid>
  );
};

export default Blocks;

/**********************************************************************
 * GRID Styled Component
 * --------------------------------------------------------------------
 * The grid styled component is used to create a grid layout. The
 * grid layout is used to display the basic blocks. The grid layout
 * is responsive. The number of columns in the grid layout is set
 * at the top of the file by setting the defaultColumns variable.
 * TODO: Add this to the CMS so the user can set the number of columns.
 **********************************************************************/
interface iGridProps {
  columns: number;
}

const Grid = styled.div<iGridProps>`
  /* max-width: 1080px; // Set maximum width */
  margin-left: auto; // Center the container
  margin-right: auto; // Center the container
  display: grid;
  gap: 1rem;
  grid-template-columns: ${props =>
    `repeat(${
      props?.columns ?? 12
    }, 1fr)`}; // Define the number of columns that the grid will have */
`;

/**********************************************************************
 * GRID ITEM Styled Component
 * --------------------------------------------------------------------
 * The grid item styled component is used to create a grid item.
 * The grid item is used to display the basic block. The grid item
 * is responsive.
 * The number of columns that the grid item uses is set in the CMS.
 * The CMS value is processed by the getGridColums function above.
 * It is then passed to the responsiveV2 function below.
 **********************************************************************/

interface iGridItemProps {
  gridColumns: string[];
}

const GridItem = styled.div<iGridItemProps>`
  ${props =>
    responsiveV2({
      cssPropsVals: {
        'grid-column': props.gridColumns,
      },
    })};
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the image is behind the content */
`;
