/********************************************************************************
 * UPDATE
 * ----------------------------------------------------------------------------
 * This component is responsible for updating the user's pregnancy stage. It
 * will be executed once the user has confirmed that they want to update their
 * pregnancy stage.
 *
 * The flow for updating the pregnancy stage will be as follows:
 * - Update the user account data via ELO API
 * - Wait for the response from the ELO API
 * - Execute start checkout function
 * -
 * *****************************************************************************/

import React from 'react';
import { Box, Typography, CircularProgress, Stack } from '@mui/material';

const Update = () => {
  return (
    <Box height={'400px'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'100%'}
      >
        <Box my={'.47rem'}>
          <Typography variant="h2" textAlign={'center'}>
            Please Wait...
          </Typography>
        </Box>
        <Box display={'flex'} flexGrow={1} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
        <Box my={'.47rem'}>
          <Stack spacing={4}>
            <Typography variant="body2">
            We are updating your subscription from Create to Form to support the early stages of pregnancy.
            </Typography>
            <Typography variant="body2">
              This will take a few moments. Please do not close or refresh your browser.
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Update;
