import React, { ReactNode } from 'react';
import { Col, List, Row, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
import ListingItem, { ArticleListItem, ListItem } from '../listingitem/ListingItem';

const { Title } = Typography;

type PageSectionListingProps = {
  slug: string;
  title: string | undefined;
  titleLink?: boolean;
  className?: string;
  items: any[];
};

export const createListingData = (data: any = [], listingObjectType: string) => {
  return data.map((item) => {
    if (listingObjectType === 'Article') {
      return {
        discriminator: 'ArticleListItem',
        id: item.id,
        title: item.title,
        description: item.description || item.blurb.blurb,
        url: `/${listingObjectType.toLowerCase()}s/${item.slug}`,
        thumbnail: item.thumbnail,
        timeToRead: item.timeToRead,
        author: item.author,
        slug: item.slug,
        tags: item.tags,
        createdAt: item.createdAt,
        createdDaysAgo: item.createdDaysAgo,
        publishedAt: item.publishedAt,
        publilshedDaysAgo: item.publishedDaysAgo,
        updatedAt: item.updatedAt,
        updatedDaysAgo: item.updatedDaysAgo,
      } as ArticleListItem;
    } else {
      return {
        discriminator: 'ListItem',
        id: item.id,
        title: item.title,
        description: item.description || item.blurb.blurb,
        url: `/${listingObjectType.toLowerCase()}s/${item.slug}`,
        thumbnail: item.thumbnail,
      } as ListItem;
    }
  });
};

const PageSectionListing = ({
  slug,
  title,
  titleLink,
  className,
  items,
}: PageSectionListingProps) => {
  function renderListingItem(item: ListItem, index: number): ReactNode {
    return (
      <List.Item>
        <ListingItem item={item} index={index} />
      </List.Item>
    );
  }

  return (
    <div className={`page-section-listing ${className} ${slug}`}>
      {title && (
        <Row>
          <Col span={18}>
            <Title className="title" level={1}>
              {titleLink ? <Link to={'./' + kebabCase(title)}>{title}</Link> : title}
            </Title>
          </Col>
          <Col span={6}>
            {titleLink && (
              <div className="title-link">
                <Link to={'./' + kebabCase(title)}>See all {title} articles</Link>
              </div>
            )}
          </Col>
        </Row>
      )}

      {items && items.length > 0 && (
        <List
          split={isMobile}
          bordered={false}
          size="small"
          itemLayout="horizontal"
          dataSource={items}
          renderItem={renderListingItem}
        />
      )}
    </div>
  );
};

export default PageSectionListing;
