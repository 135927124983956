import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

const Diet = ({ 
  stroke = '#333333',
  strokeWidth = '1.25',
  width = '50', 
  height = '50', 
  viewBox = '0 0 50 50'
}: SVGIcon) => (
  <svg 
  width={width}
  height={height}
  viewBox={viewBox} 
  fill="none" 
  xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.96289 22.4258V11.4613"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M3.96289 7.30912L4 1"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M6.92212 1V6.55961C6.92212 8.19585 5.59554 9.52243 3.9593 9.52243C2.32306 9.52243 1 8.19585 1 6.55961V1"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path d="M12.9004 22.4258V11.4613" stroke="#9BC101" stroke-width="1.5" stroke-miterlimit="10" />
    <path
      d="M22.0008 22.4259V2.00988C22.0008 1.59114 21.4342 1.45039 21.2442 1.82338C20.6425 3.00218 19.893 5.13808 19.1893 8.67798C18.7142 11.0778 19 14.5 19.1906 16"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M15.8592 4.36739V4.56444C15.8592 2.92821 14.5326 1.60162 12.8964 1.60162C11.2602 1.60162 9.93359 2.92821 9.93359 4.56444V4.36739V6.55608C9.93359 8.19231 11.2602 9.5189 12.8964 9.5189C14.5326 9.5189 15.8592 8.19231 15.8592 6.55608V4.36739Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
  </svg>
);

export default Diet;
