import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';

type Props = {
  pushdownBanner: string;
};

const PushDownBanner = ({ pushdownBanner }: Props): JSX.Element => {
  const pushdownBannerRendered = React.useMemo(
    () =>
      renderRichText(
        { raw: pushdownBanner, references: [] },
        {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (_: unused, children: ReactNode) => (
              <PushdownBanner id='pushdown-banner'>{children}</PushdownBanner>
            ),
          },
        }
      ),
    [pushdownBanner]
  );
  return <>{pushdownBannerRendered}</>;
};

export default PushDownBanner;

const PushdownBanner = styled.div(
  ({ theme }) => `
      color: ${theme.colors.eloBlack};
      background-color: ${theme.colors.primary};
      width: 100%;
      
      min-height: 31px;
      display: flex;
      flex-flow: row wrap;
      font-size: 11px;
      line-height: 20px;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    
      a {
        text-decoration: underline;
        color: ${theme.colors.black};
        margin: 0 3px;
      }
    
      ${theme.breakpoints.tablet} {
        height: 33px;
        font-size: 12.5px;
      }
      ${theme.breakpoints.wide} {
        height: 51px;
        font-size: 17px;
      }
    `
);
