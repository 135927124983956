import React from 'react';
import { SVGIcon } from 'src/types/SVGIcon';

const GlutenFree = ({
  stroke = '#333333',
  strokeWidth = '1.25',
  width = '43',
  height = '44',
  viewBox = '0 0 43 44',
}: SVGIcon) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0299 31.3733L6.78516 36.6886"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M36.0803 6.99854L29.9365 13.2251"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M21.3952 42.7634C32.7763 42.7634 41.9994 33.416 41.9994 21.8817C41.9994 10.3474 32.7763 1 21.3952 1C10.0142 1 0.791016 10.3474 0.791016 21.8817C0.791016 33.416 10.0142 42.7634 21.3952 42.7634Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M27.9733 29.0724C28.243 30.7353 27.6736 32.5957 26.3024 33.9853C24.9313 35.3749 23.0957 35.952 21.4548 35.6786C21.1851 34.0157 21.7545 32.1553 23.1256 30.7657C24.4968 29.3761 26.3324 28.799 27.9733 29.0724Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M21.3277 35.6786C19.6869 35.952 17.8513 35.3749 16.4801 33.9853C15.109 32.5957 14.5396 30.7353 14.8093 29.0724C16.4502 28.799 18.2858 29.3761 19.6569 30.7657C21.028 32.1553 21.5975 34.0157 21.3277 35.6786Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M27.9733 21.9498C28.243 23.6128 27.6736 25.4732 26.3024 26.8627C24.9313 28.2523 23.0957 28.8294 21.4548 28.5561C21.1851 26.8931 21.7545 25.0327 23.1256 23.6432C24.4968 22.2536 26.3324 21.6765 27.9733 21.9498Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M21.3277 28.5485C19.6869 28.8218 17.8513 28.2448 16.4801 26.8552C15.109 25.4656 14.5396 23.6052 14.8093 21.9423C16.4502 21.6689 18.2858 22.246 19.6569 23.6356C21.028 25.0252 21.5975 26.8855 21.3277 28.5485Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M27.9733 14.8275C28.243 16.4905 27.6736 18.3508 26.3024 19.7404C24.9313 21.13 23.0957 21.7071 21.4548 21.4337C21.1851 19.7708 21.7545 17.9104 23.1256 16.5208C24.4968 15.1313 26.3324 14.5542 27.9733 14.8275Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M21.3952 6.62671C22.7438 7.60625 23.6429 9.32994 23.6429 11.2966C23.6429 13.2633 22.7438 14.9794 21.3952 15.9665C20.0466 14.987 19.1475 13.2633 19.1475 11.2966C19.1475 9.33753 20.0466 7.61384 21.3952 6.62671Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
    <path
      d="M21.3277 21.4262C19.6869 21.6995 17.8513 21.1224 16.4801 19.7329C15.109 18.3433 14.5396 16.4829 14.8093 14.82C16.4502 14.5466 18.2858 15.1237 19.6569 16.5133C21.028 17.9029 21.5975 19.7632 21.3277 21.4262Z"
      stroke={stroke}
      stroke-width={strokeWidth}
      stroke-miterlimit="10"
    />
  </svg>
);

export default GlutenFree;
