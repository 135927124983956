import React from 'react';
import { Box, Stack, Typography, Button } from '@mui/material';

import { useAccountStore } from '../../store';

export const Payment = () => {
    const userAccount = useAccountStore(state => state.userAccount);
  const paymentMethod = useAccountStore(state => state.userAccount?.paymentMethod);

  if (!paymentMethod) return <>No Payment Method Available</>;

  // console.log('userAccount', userAccount)

  return (


    <Box>
      <Stack spacing={2}>
        <Typography variant="body2" gutterBottom>
          {paymentMethod.type}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="body2" gutterBottom>
          Ening in 2419
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="body2" gutterBottom>
          Exp 6/2024
        </Typography>
      </Stack>
      <Box my={['1.47rem']}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ px: ['1.47rem'] }}
          //   onClick={handleUpdateShippingAddress}
        >
          <Typography variant="body2">Update</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Payment;
