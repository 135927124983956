import React from 'react';
import styled, { keyframes} from 'styled-components';
import Icon from '../icon/Icon';
import { useEloStore } from '../../store/EloStore.hooks';
import { Typography, Box } from 'ui';

const Loader = (): JSX.Element => {
  const { store, layout } = useEloStore();
  React.useEffect(() => {
    if (store.layout.loader.shown) {
      document.body.style = 'overflow: hidden;';
      layout.toggleLoader(true);
    }

    return () => {
      document.body.style = '';
      layout.toggleLoader(false);
    };
  }, [store.layout.loader.shown]);

  return (
    <LoaderContainer className={store.layout.loader.shown ? 'active' : ''}>
      {store.layout.loader.shown && (
        <>
          <Box>
            <Icon id="luminary-logo-white" stroke="white" />
          </Box>
        </>
      )}
    </LoaderContainer>
  );
};
export default Loader;

// Define the keyframes for the pulsing and scaling animation
const pulseScaleAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const LoaderContainer = styled.div`
  transition: all 0.25s ease-in-out;
  opacity: 0;

  &.active {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
  }

  > div {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 140px;

    svg {
      margin-left: 8px;
      margin-bottom: 2px;
      width: 140px;
      height: 140px;
      animation: ${pulseScaleAnimation} 2000ms infinite; // Apply the animation here
    }

    ${({ theme }) => theme.breakpoints.small} {
      width: 200px;
      height: 200px;

      svg {
        margin-left: 10px;
        margin-bottom: 5px;
        width: 200px;
        height: 200px;
      }
    }
  }
`;
