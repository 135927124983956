/********************************************************************************
 * LOGIN MODAL
 * ----------------------------------------------------------------------------
 * The login modal uses the Authenticator component from the AWS Amplify UI
 * library. It is used to handle the login and sign up process for the ELO and
 * ELO Partner applications.
 *
 * The modal is toggled on and off according to the state of the showLoginModal
 * value in the Account Management store. The showLoginModal value is updated
 * by the LoginButton component when the user clicks the login button. This
 * value could be toggled by and used by any component in the application that
 * imports and uses account store values and actions exported from the store.
 * *****************************************************************************/
import React from 'react';

/**********************************************************************
 * * IMPORT AUTHENTICATOR COMPONENT FROM AWS AMPLIFY UI
 * ********************************************************************/
import { Authenticator, Theme, ThemeProvider, useAuthenticator } from '@aws-amplify/ui-react';

import AuthPending from '../AuthPending/AuthPending';

/**********************************************************************
 * * IMPORT ACCOUNT STORE
 * ********************************************************************/
import { useAccountStore } from '../../../../store';

import { Footer } from './components';

export const LoginModal = () => {
  /**********************************************************************
   * * DESCTRUCTURE THE showLoginModal VALUE FROM THE ACCOUNT STORE
   * --------------------------------------------------------------------
   * This has the effect of subscribing to the showLoginModal value in the
   * account store. When the value changes, the LoginModal component will
   * re-render.
   * ********************************************************************/
  const showLoginModal = useAccountStore(state => state.showLoginModal);

  /**********************************************************************
   * * CUSTOMIZE THE LOGIN THEME
   * --------------------------------------------------------------------
   * The login modal uses the Authenticator component from the AWS Amplify
   * UI library. The Authenticator component is wrapped in a ThemeProvider
   * component. The theme is used to customize the appearance of the login
   * modal.
   * TODO: Move to config section and enable dynamic theming
   * ********************************************************************/
  // const { tokens } = useTheme();
  const theme: Theme = {
    name: 'elo',
    tokens: {
      components: {
        button: {
          primary: {
            backgroundColor: '#F99E32',
            color: '#1A1919',
            _hover: {
              backgroundColor: '#F99E32',
              color: '#FFFFFF',
            },
          },

          borderRadius: '30px',
          link: {
            color: '#1A1919',
          },
        },
        tabs: {
          item: {
            color: '#1A1919',
            _active: {
              borderColor: '#F99E32',
              color: '#1A1919',
            },
          },
        },
      },
    },
  };

  const modalComponents = {
    
    SignIn: {
      Footer () {
        const { toForgotPassword } = useAuthenticator();
        return <Footer forgotPassword={toForgotPassword}/>;
      
      }
    }
  };

  return (
    <>
      <AuthPending />
      {showLoginModal && (
        <ThemeProvider theme={theme}>
          <Authenticator
            variation="modal"
            signUpAttributes={['email']}
            loginMechanisms={['email']}
            // IMPORT AND ADD COMPONENTS HERE TO CUSTOMIZE THE MODAL
            components={modalComponents}
          />
        </ThemeProvider>
      )}
    </>
  );
};

export default LoginModal;
