import * as React from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';
import Image from '../image/Image';
import { AuthorProps } from './Author';

interface Props {
  author: AuthorProps;
}

export default function Author2({ author }: Props) {
  return (
    <AuthorContainer>
      <Avatar
        size={32}
        icon={
          author?.avatar?.gatsbyImageData && (
            <Image className="avatar" gatsbyImageData={author.avatar.gatsbyImageData} />
          )
        }
      />
      <AuthorTitle>
        {author.name}
        {author.title ? ', ' + author.title : ''}
      </AuthorTitle>
    </AuthorContainer>
  );
}

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const AuthorTitle = styled.div`
  margin-left: 8px;
`;
